import { Modal } from "antd";

interface IAlertOnClose {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    onOk: () => void;
}

const AlertOnClose = ({ isOpen, setIsOpen, onOk }: IAlertOnClose) => {
    const onCancel = () => {
        setIsOpen(false);
    };
    const onOkClick = () => {
        onOk();
        setIsOpen(false);
    };
    return (
        <Modal
            title="Уверены, что хотите отменить изменения?"
            open={isOpen}
            zIndex={99999}
            okText="Да"
            cancelText="Нет"
            onOk={onOkClick}
            onCancel={onCancel}
        ></Modal>
    );
};

export default AlertOnClose;
