import { entityRowModel, getParametersForRequest } from "@entities/Portal/EntityRow";
import {
    estimationApi,
    estimationLib,
    estimationModel,
} from "@entities/Portal/Estimations";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useEstimationValues = (entityId: number) => {
    const dispatch = useDispatch<AppDispatch>();
    const applyingLoading = useSelector(estimationModel.selectApplyingLoading);
    const filters = useSelector(entityRowModel.selectFilters);
    const estimation = useSelector(estimationModel.selectAppliedEstimation);

    useEffect(() => {
        const savedEstimation = localStorage.getItem(
            estimationLib.getEstimationStorageKey(entityId)
        );
        if (savedEstimation) {
            dispatch(estimationModel.setAppliedEstimation(JSON.parse(savedEstimation)));
        }
        estimationApi.getEntitySumTemplates(entityId).then(() => {});
        return () => {
            dispatch(estimationModel.setAppliedEstimation(null));
            dispatch(estimationModel.setAdditionalAppliedEstimation(null));
        };
    }, []);

    useEffect(() => {
        if (!estimation) return;
        const parameters = getParametersForRequest(filters);

        dispatch(
            estimationModel.applySumEstimationTemplateThunk({
                id: estimation.id,
                name: estimation.name,
                filters: parameters,
            })
        );
    }, [estimation?.id, filters]);

    const resetTemplate = () => {
        dispatch(estimationModel.setAppliedEstimation(null));
        dispatch(estimationModel.setAdditionalAppliedEstimation(null));
        localStorage.removeItem(estimationLib.getEstimationStorageKey(entityId));
    };
    const reloadTemplate = () => {
        if (!estimation) return;
        const parameters = getParametersForRequest(filters);

        dispatch(
            estimationModel.applySumEstimationTemplateThunk({
                id: estimation.id,
                name: estimation.name,
                filters: parameters,
            })
        );
    };
    return {
        estimation,
        applyingLoading,
        resetTemplate,
        reloadTemplate,
    };
};
