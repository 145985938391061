import { Tag } from "antd";

interface IAgreementListItemHeader {
    header: string;
    deadline_status: TDeadlineStatus;
    status: TBizProcStatus;
}

const AgreementListItemHeader = ({
    header,
    deadline_status,
    status,
}: IAgreementListItemHeader) => {
    return (
        <div className="agree-item__header">
            <div className="agree-item__desc">
                <p className="agree-item__title">{header}</p>
            </div>
            <div className="agree-item__tag">
                {deadline_status === "expired" && status === "executing" ? (
                    <Tag color="#cd201f">Срок согласования истёк</Tag>
                ) : (
                    <Tag
                        style={{ fontWeight: 600 }}
                        color={`${
                            status === "approve"
                                ? "green"
                                : status === "reject"
                                  ? "red"
                                  : "processing"
                        }`}
                    >
                        {`${
                            status === "approve"
                                ? "Согласовано"
                                : status === "reject"
                                  ? "Отказ"
                                  : "Ожидает согласования"
                        }`}
                    </Tag>
                )}
            </div>
        </div>
    );
};

export default AgreementListItemHeader;
