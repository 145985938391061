import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { INavigationRoute } from "./NavigationRouteSlice";
import { getNavigationRoutesThunk } from "./NavigationRouteThunk";

const loadBuilder = (builder: ActionReducerMapBuilder<INavigationRoute>) => {
    builder.addCase(getNavigationRoutesThunk.fulfilled, (state, action) => {
        state.isLoadingRoutes = false;
        state.navigationRoutes = action.payload;
    });
    builder.addCase(getNavigationRoutesThunk.pending, (state) => {
        state.isLoadingRoutes = true;
    });
    builder.addCase(getNavigationRoutesThunk.rejected, (state, rejectedValue) => {
        state.isLoadingRoutes = false;
    });
};
// const createBuilder = (builder: ActionReducerMapBuilder<IFieldOptionSlice>) => {
//     builder.addCase(createFieldOptionThunk.fulfilled, (state, action) => {
//         state.fieldOptions.unshift(action.payload.data);
//         localStorage.setItem("fieldOptionsLength", state.fieldOptions.length.toString());
//     });
// };
// const updateBuilder = (builder: ActionReducerMapBuilder<IFieldOptionSlice>) => {
//     builder.addCase(updateFieldOptionThunk.fulfilled, (state, action) => {
//         state.fieldOptions = updateArrByItemId(state.fieldOptions, action.payload.data);
//     });
// };
// const deleteBuilder = (builder: ActionReducerMapBuilder<IFieldOptionSlice>) => {
//     builder.addCase(deleteFieldOptionThunk.fulfilled, (state, action) => {
//         state.fieldOptions = state.fieldOptions.filter(
//             (item) => item.id !== action.payload.data.id
//         );
//         localStorage.setItem("fieldOptionsLength", state.fieldOptions.length.toString());
//     });
// };

export const navigationRouteBuilder = (
    builder: ActionReducerMapBuilder<INavigationRoute>
) => {
    loadBuilder(builder);
};
