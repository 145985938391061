import { CardWithAvatar } from "@shared/ui/Cards/CardWithAvatar";

interface IEntityUserCard {
    user: TUser;
    onClick?: (value: number | string) => void;
}

const EntityUserCard = ({ user, onClick }: IEntityUserCard) => {
    const _onClick = () => {
        onClick && onClick(user.id);
    };
    return (
        <CardWithAvatar
            onClick={_onClick}
            header={user.name}
            description={user.phone ?? user.email ?? user.login ?? "-"}
        />
    );
};

export default EntityUserCard;
