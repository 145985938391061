import { entityRowModel } from "@entities/Portal/EntityRow";
import { userModel } from "@entities/Portal/User";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useEntityRowsSchedulerWS = (entityId: number) => {
    const user = useSelector(userModel.selectUser);
    const dispatch = useDispatch();

    useEffect(() => {
        window.Echo.private(`User.${user.id}.EntityRow.${entityId}`)
            .listen(".entityRow.create", ({ entityRow }: { entityRow: any }) => {
                dispatch(entityRowModel.addEntityRow(entityRow));
            })
            .listen(".entityRow.update", ({ entityRow }: { entityRow: any }) => {
                dispatch(entityRowModel.updateEntityRow(entityRow));
            });

        return () => {
            window.Echo.leave(`User.${user.id}.EntityRow.${entityId}`);
        };
    }, [entityId]);
};
