import { DeleteOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { useTechMapTmplOperation } from "../model/UseTechMapTmplOperation";
import "./TechMapTmplOperationItem.scss";
interface ITechMapTmplOperationItem {
    tmtId: number | null;
    operation: TTechMapTemplateOperation;
}

const TechMapTmplOperationItem = ({ tmtId, operation }: ITechMapTmplOperationItem) => {
    const { onClick, onClickDelete, deleteLoading } = useTechMapTmplOperation(
        tmtId,
        operation
    );

    return (
        <div className="tmt-operation" onClick={onClick}>
            <div className="tmt-operation__content">
                <Typography.Text>
                    {operation.name}
                    {/* {loading && <Spin style={{ marginLeft: 5 }} />} */}
                </Typography.Text>
                <div>
                    <Button
                        loading={deleteLoading}
                        onClick={onClickDelete}
                        type="link"
                        danger
                        size="small"
                        style={{ margin: 0, padding: 0, minHeight: 2 }}
                        icon={<DeleteOutlined />}
                    />
                </div>
            </div>
            <div className="tmt-operation__arrow" />
        </div>
    );
};

export default TechMapTmplOperationItem;
