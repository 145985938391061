import { useCreateUpdateDefaultValue } from "../model";
import CreateUpdateDefaultValueItem from "./CreateUpdateDefaultValueItem";

interface ICreateUpdateDefaultValue {
    setEditingEntityField: (item: TEntityField) => void;
    editingEntityField: TEntityField;
    onChangeCalculation: (
        name: keyof TEntityField,
        is_multiple?: boolean,
        key?: number | string
    ) => void;
    onChangeCreateUpdateValue: (
        value: any,
        name: keyof TEntityField,
        is_multiple?: boolean,
        key?: number | string
    ) => void;
    onChangeIsCreateUpdateCheckbox: (
        item: any,
        name: string,
        is_multiple?: boolean
    ) => void;
}

const CreateUpdateDefaultValue = ({
    setEditingEntityField,
    editingEntityField,
    onChangeCalculation,
    onChangeCreateUpdateValue,
    onChangeIsCreateUpdateCheckbox,
}: ICreateUpdateDefaultValue) => {
    const { isDisabled, isMultiple, onAddCreateUpdateValue, onDelete } =
        useCreateUpdateDefaultValue(editingEntityField, setEditingEntityField);

    return (
        <div className="create-update-default">
            <CreateUpdateDefaultValueItem
                editingEntityField={editingEntityField}
                isDisabled={isDisabled}
                label="Значение по умолчанию"
                buttonLabel="По умолчанию"
                isMultiple={isMultiple}
                name="on_create_default"
                onAddCreateUpdateValue={onAddCreateUpdateValue}
                onChangeIsCreateUpdateCheckbox={onChangeIsCreateUpdateCheckbox}
                onChangeCalculation={onChangeCalculation}
                onChangeCreateUpdateValue={onChangeCreateUpdateValue}
                onDelete={onDelete}
            />
            <CreateUpdateDefaultValueItem
                editingEntityField={editingEntityField}
                isDisabled={isDisabled}
                label="Значение при обновлении"
                buttonLabel="При обновлении"
                isMultiple={isMultiple}
                name="on_update_default"
                onAddCreateUpdateValue={onAddCreateUpdateValue}
                onChangeIsCreateUpdateCheckbox={onChangeIsCreateUpdateCheckbox}
                onChangeCalculation={onChangeCalculation}
                onChangeCreateUpdateValue={onChangeCreateUpdateValue}
                onDelete={onDelete}
            />
        </div>
    );
};

export default CreateUpdateDefaultValue;
