import { entityRowConstants } from "@entities/Portal/EntityRow";

type TFilterValue = { key: string; value: any; condition: TComparisonType | null };

export const getCurrentFilters = (entityId: number) => {
    const storedFilters = localStorage.getItem(`${entityId}__saved-fields`);
    return storedFilters ? JSON.parse(storedFilters) : undefined;
};

export const saveCurrentFilters = (entityId: number, filterInputs: TFilterValue[]) => {
    localStorage.setItem(`${entityId}__saved-fields`, JSON.stringify(filterInputs));
};

export const getInitialFilters = (entityFields: TEntityField[]) => {
    return entityFields
        .filter(
            (field) => !entityRowConstants.nowAllowedFilterFields.includes(field.datatype)
        )
        .slice(0, 5)
        .map((field) => {
            return {
                value: null,
                condition: "=" as TComparisonType,
                key: field.key,
            };
        });
};
export const getFiltersWithAddedValues = (
    filters: TFilterValue[],
    filtersWithValues: TFilterValue[]
) => {
    return filters.map((currentFilter) => {
        const filter = filtersWithValues.find(
            (filter) => currentFilter.key === filter.key
        );
        if (filter) return filter;
        return currentFilter;
    });
};
export const getEntityFieldWithFilter = (
    currentFilterValues: TFilterValue[],
    entityFields: TEntityField[]
): (TBizProcFormField & { condition?: TComparisonType | null })[] => {
    const selectedFilters = currentFilterValues?.map((filter) => filter.key) ?? [];
    const filteredEntityField = entityFields.filter((entityField) =>
        selectedFilters.includes(entityField.key)
    );

    return filteredEntityField.map((field) => {
        const foundFilterItem = currentFilterValues.find(
            (item) => item.key === field.key
        );
        return {
            ...field,
            value: foundFilterItem?.value ?? null,
            condition: foundFilterItem?.condition ?? null,
            datatype: field.datatype,
            additional_parameters: {
                fields: [],
                entityFields: [],
            },
            is_required: false,
            is_readonly: false,
            unique_id: `${field.variable_type}-${field.key}`,
            variable_type: "entity",
        };
    });
};
