import { DnDList } from "@shared/ui/DnDList";
import { Spin } from "antd";
import { getEntityFieldColumns } from "./EntityFieldsColumn";
import EntityRowsKanbanConfigure from "./EntityRowsKanbanConfigure";
import KanbanItem from "./KanbanItem";

const groupColors: { [key: string]: string } = {};

interface IEntityRowsKanbanDesk {
    entityUuid: string;
    entityFields: TEntityField[];
    choosenVisibleFieldsOptions: TEntityField[] | null;
    choosenEntityField: TEntityField | null;
    isLoading: boolean;
    entityFieldValues: { [key: string]: any }[];
    entityRowsByEntityField: TEntityRowsByEntityField;
    isPaginationLoading: { [columnValue: string]: boolean };
    heightOfKanban?: string;
    isFullscreen?: boolean;
    setChoosenVisibleFieldsOptionsKeys: (item: string[]) => void;
    setChoosenEntityFieldId: (item: number | null) => void;
    onOpen?: (() => void) | undefined;
    onDragEndFunc:
        | ((item: any, lastColumnValue: any, currentColumnValue: any) => void)
        | undefined;
    loadEntityRowsByPagination: (columnValue: any) => void;
    openCloseDrawerWithNavigate: (
        isOpenDrawer: boolean,
        entityRowId?: number | undefined
    ) => void;

    onCloseFullscreen?: () => void;
}

const EntityRowsKanbanDesk = ({
    entityUuid,
    entityFields,
    choosenEntityField,
    choosenVisibleFieldsOptions,
    isLoading,
    entityFieldValues,
    entityRowsByEntityField,
    isPaginationLoading,
    heightOfKanban,
    isFullscreen,
    setChoosenEntityFieldId,
    setChoosenVisibleFieldsOptionsKeys,
    onOpen,
    onDragEndFunc,
    loadEntityRowsByPagination,
    openCloseDrawerWithNavigate,
    onCloseFullscreen,
}: IEntityRowsKanbanDesk) => {
    return (
        <>
            <EntityRowsKanbanConfigure
                entityUuid={entityUuid}
                choosenVisibleFieldsOptions={choosenVisibleFieldsOptions}
                setChoosenVisibleFieldsOptionsKeys={setChoosenVisibleFieldsOptionsKeys}
                choosenEntityField={choosenEntityField}
                setChoosenEntityFieldId={setChoosenEntityFieldId}
                onOpenFullscreen={onOpen}
                isFullscreen={isFullscreen}
                onCloseFullscreen={onCloseFullscreen}
            />
            <Spin spinning={isLoading} size="large">
                <DnDList<any>
                    data={entityFieldValues}
                    columnTypes={getEntityFieldColumns(
                        entityRowsByEntityField,
                        choosenEntityField,
                        groupColors
                    )}
                    height={heightOfKanban ?? "68vh"}
                    columnValue={choosenEntityField?.key ?? ""}
                    unicKey={"id"}
                    onDragEndFunc={onDragEndFunc}
                    columnBottomReachingHandler={loadEntityRowsByPagination}
                    isPaginationLoading={isPaginationLoading}
                    columnItem={(pureItem: any) => (
                        <KanbanItem
                            openCloseDrawerWithNavigate={openCloseDrawerWithNavigate}
                            choosenVisibleFieldsOptions={choosenVisibleFieldsOptions}
                            choosenEntityField={choosenEntityField}
                            pureItem={pureItem}
                            groupColors={groupColors}
                        />
                    )}
                />
            </Spin>
        </>
    );
};

export default EntityRowsKanbanDesk;
