import { SelectEntityRow } from "@entities/Portal/EntityRow";
import { memo } from "react";
import { useTechMapTmplRoles } from "../model";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { techMapTemplateConstant } from "@entities/Production/TechMapTemplate";

interface ITechMapTmplRoles {
    reviewerRoles: TUserRole[];
    executorRoles: TUserRole[];
    onChange: (key: keyof TTechMapTemplate, value: any) => void;
}

const TechMapTmplRoles = ({
    reviewerRoles,
    executorRoles,
    onChange,
}: ITechMapTmplRoles) => {
    const { reviewerRoleIds, executorRoleIds, onChangeExecutors, onChangeReviewers } =
        useTechMapTmplRoles(reviewerRoles, executorRoles, onChange);
    return (
        <>
            <IndentContainer>
                <>Согласующие</>
                <SelectEntityRow
                    multiple
                    entityInfo={techMapTemplateConstant.TECH_ROLES_TABLE}
                    value={reviewerRoleIds}
                    onChange={onChangeReviewers}
                />
            </IndentContainer>
            <IndentContainer>
                <>Исполнители</>
                <SelectEntityRow
                    multiple
                    entityInfo={techMapTemplateConstant.TECH_ROLES_TABLE}
                    value={executorRoleIds}
                    onChange={onChangeExecutors}
                />
            </IndentContainer>
        </>
    );
};

export default memo(TechMapTmplRoles);
