import { techMapModel } from "@entities/Production/TechMap";
import { useSelector } from "react-redux";

export const useTMNecessaryItems = () => {
    const startedTm = useSelector(techMapModel.selectStartedTm);
    const startedOperation = useSelector(techMapModel.selectStartedOperation);
    const templateItems = startedOperation?.template_operation.necessary_items ?? [];
    const operationItems = startedOperation?.operation_items ?? [];
    // const lastTmOperation = getLastTmtOperation(startedTm);
    // const isLastOperation =
    //     operation &&
    //     lastTmOperation?.uuid === operation?.technical_map_template_operation_uuid;
    return { templateItems, operationItems };
};
