import { FormItem } from "@shared/ui/Form";
import { Button, Input, Select, Space } from "antd";
import {
    tmtoNecessaryItemTypesOptions,
    tmtoNItemNecessaryTypesOptions,
} from "../../constants";
import { EntitySelect } from "@entities/Portal/Entity";
import { SelectEntityRow } from "@entities/Portal/EntityRow";
import { ChangeEvent, useState } from "react";

interface ITMTONecessaryItemForm {
    item: TOperationNecessaryItem | TOperationNecessaryItemCreating;

    onCancel: () => void;
    onSubmit: (item: TOperationNecessaryItem | TOperationNecessaryItemCreating) => void;
}

const TMTONecessaryItemForm = ({ item, onCancel, onSubmit }: ITMTONecessaryItemForm) => {
    const [formItem, setFormItem] = useState(item);
    // id: number;
    // technical_map_template_operation_uuid: string;
    // related_entity_uuid: string | null;
    // related_entity_row_id: number | null;
    // uuid: string;
    // necessary_type: TOperationNecessaryItemNecessaryType;
    // created_at: string;
    // updated_at: string;
    // condition_items: any[];

    const onChangeLabel = (event: ChangeEvent<HTMLInputElement>) => {
        setFormItem({
            ...formItem,
            label: event.target.value,
        });
    };
    const onChangeCount = (event: ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value);
        setFormItem({
            ...formItem,
            count:
                event.target.value === "" || value < 1 ? 1 : value > 1000 ? 1000 : value,
        });
    };
    const onChangeType = (type: string) => {
        setFormItem({
            ...formItem,
            type: type as TOperationNecessaryItemType,
            related_entity_uuid: null,
            related_entity_row_id: null,
        });
    };
    const onChangeNecessaryType = (type: string) => {
        setFormItem({
            ...formItem,
            necessary_type: type as TOperationNecessaryItemNecessaryType,
        });
    };
    const onChangeEntity = (entityUuid: string | null) => {
        setFormItem({
            ...formItem,
            related_entity_uuid: entityUuid,
            related_entity_row_id: null,
        });
    };
    const onChangeEntityRowId = (entityRowId: number | null) => {
        setFormItem({
            ...formItem,
            related_entity_row_id: entityRowId,
        });
    };
    const onClickSubmit = () => {
        onSubmit(formItem);
    };
    return (
        <div>
            <FormItem label={"Название"}>
                <Input value={formItem.label} onChange={onChangeLabel} />
            </FormItem>

            <FormItem label={"Тип"}>
                <Select
                    onChange={onChangeType}
                    style={{ width: "100%" }}
                    value={formItem.type}
                    options={tmtoNecessaryItemTypesOptions}
                />
            </FormItem>
            <FormItem label={"Необходимое количество"}>
                <Input value={formItem.count} onChange={onChangeCount} />
            </FormItem>
            {formItem.type === "filling" ? (
                <>
                    <FormItem label={"Необходимая сущность"}>
                        <EntitySelect
                            value={formItem.related_entity_uuid}
                            onChangeSelect={onChangeEntity}
                        />
                    </FormItem>
                    <FormItem label={"Конкретный элемент"}>
                        {formItem.related_entity_uuid ? (
                            <SelectEntityRow
                                labelKey="name"
                                byUuid
                                entityInfo={formItem.related_entity_uuid}
                                value={formItem.related_entity_row_id}
                                onChange={onChangeEntityRowId}
                            />
                        ) : (
                            <>Необходимо выбрать сущность</>
                        )}
                    </FormItem>
                    <FormItem label={"Тип необходимого элемента"}>
                        <Select
                            onChange={onChangeNecessaryType}
                            style={{ width: "100%" }}
                            value={formItem.necessary_type}
                            options={tmtoNItemNecessaryTypesOptions}
                        />
                    </FormItem>
                </>
            ) : (
                <></>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space>
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button onClick={onClickSubmit} type="primary">
                        Сохранить
                    </Button>
                </Space>
            </div>
        </div>
    );
};

export default TMTONecessaryItemForm;
