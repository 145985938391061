import { LoadingOutlined } from "@ant-design/icons";
import { techMapModel } from "@entities/Production/TechMap";
import { CardWithAvatar } from "@shared/ui/Cards/CardWithAvatar";
import { Spin } from "antd";
import { useSelector } from "react-redux";

interface ITMNecessaryItem {
    templateItem: TOperationNecessaryItem;
    operationItem?: TTechMapOperationItem;
}

const TMNecessaryItem = ({ templateItem, operationItem }: ITMNecessaryItem) => {
    const loading = useSelector(techMapModel.selectAttachItemLoading);
    const isAdded = !!operationItem;
    const needFill = templateItem.type === "filling";
    return (
        <CardWithAvatar
            customStyle={
                isAdded || !needFill
                    ? {
                          backgroundColor: "#c5efb0",
                          borderColor: "#c5efb0",
                      }
                    : {
                          backgroundColor: "#f5ecafaa",
                          borderColor: "#f5ecaf",
                      }
            }
            hideAvatar
            header={
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div>{templateItem.label}</div>
                    {!isAdded && needFill && (
                        <div
                            style={{
                                marginLeft: 10,
                                fontSize: 12,
                                color: "var(--primary)",
                            }}
                        >
                            {loading ? (
                                <div>
                                    <Spin indicator={<LoadingOutlined />} />
                                </div>
                            ) : (
                                "Ожидает заполнения"
                            )}
                        </div>
                    )}
                </div>
            }
            description={
                <div style={{ display: "flex" }}>
                    <span>Количество:</span>
                    <span style={{ marginLeft: 5 }}>{templateItem.count ?? 1}</span>
                </div>
            }
        />
    );
};

export default TMNecessaryItem;
