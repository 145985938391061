import { Navigate, Route, Routes } from "react-router-dom";
import { SiteLayout } from "../layout";

import { datatypeModel } from "@entities/Portal/Datatype";
import { useUserNotification } from "@features/Portal/User/UserNotification";
import { useWebsockets } from "@features/Portal/Websockets";
import { ActionsPage } from "@pages/Portal/ActionsPage";
import { AgreementPage } from "@pages/Portal/AgreementPage";
import { BizProcEditorCreatePage } from "@pages/Portal/BizProcEditorCreatePage";
import { BizProcEditorPage } from "@pages/Portal/BizProcEditorPage";
import { BizProcHistoryPage } from "@pages/Portal/BizProcInfoPage";
import { BizProcesTemplates } from "@pages/Portal/BizProcesTemplates";
import { BizProcsPage } from "@pages/Portal/BizProcsPage";
import { CalculatedValuePage } from "@pages/Admin/CalculatedValuePage";
import { CapabilitiesEditorPage } from "@pages/Admin/CapabilitiesEditorPage";
import { DashboardPage } from "@pages/Portal/DashboardPage";
import { DatatypesPage } from "@pages/Admin/DatatypesPage";
import { EntitiesPage } from "@pages/Portal/EntitiesPage";
import { EntityPage } from "@pages/Portal/EntityPage";
import { MyAgreementPage } from "@pages/Portal/MyAgreementPage";
import { MyFormsPage } from "@pages/Portal/MyFormsPage";
import { PortalSettingsPage } from "@pages/Admin/PortalSettingsPage";
import { ScannerQRCodePage } from "@pages/Portal/ScannerQRCodePage";
import { TablePageErp } from "@pages/Portal/TablePageErp";
import { UserPage } from "@pages/Portal/UserPage";
import { VariablePage } from "@pages/Admin/VariablePage";
import { DrawerManagerWidget } from "@widgets/Portal/DrawerManagerWidget";
import { TechMapsEditorPage } from "@pages/Production/TechMapsEditorPage";
import { TechMapEditorPage } from "@pages/Production/TechMapEditorPage";
import { ProductionPage } from "@pages/Production/ProductionPage";
import { ProducationStatsPage } from "@pages/Production/ProducationStatsPage";

const AuthorizedRouter = () => {
    useUserNotification();
    useWebsockets();
    datatypeModel.useLoadDatatypes();

    return (
        <>
            <SiteLayout>
                <Routes>
                    <Route path="/actions" element={<ActionsPage />} />
                    <Route
                        path="/bizproces-templates/:entityId"
                        element={<BizProcesTemplates />}
                    />
                    <Route path="/entities" element={<EntitiesPage />} />
                    <Route
                        path="/bizproc-editor/create"
                        element={<BizProcEditorCreatePage />}
                    />
                    <Route
                        path="/bizproc-editor/:bizprocId"
                        element={<BizProcEditorPage />}
                    />
                    <Route path="/user" element={<UserPage />} />
                    <Route path="/agreement" element={<AgreementPage />} />
                    <Route path="/biz-procs" element={<BizProcsPage />} />
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/my-agreement" element={<MyAgreementPage />} />
                    <Route path="/variables" element={<VariablePage />} />
                    <Route path="/entities-list" element={<EntitiesPage />} />
                    <Route path="/calculated-values" element={<CalculatedValuePage />} />
                    <Route path="/my-forms" element={<MyFormsPage />} />
                    <Route
                        path="/bizproc-dashboard/:id"
                        element={<BizProcHistoryPage />}
                    />
                    <Route path="/field-datatypes" element={<DatatypesPage />} />
                    <Route path="/qr-scanner" element={<ScannerQRCodePage />} />
                    <Route path="/portal-settings" element={<PortalSettingsPage />} />
                    <Route path="/erp/:tableName/*" element={<TablePageErp />} />
                    <Route path="/entity/:id" element={<EntityPage />} />
                    <Route
                        path="/capabilities-editor"
                        element={<CapabilitiesEditorPage />}
                    />
                    <Route path="/tech-map-editor" element={<TechMapsEditorPage />} />
                    <Route
                        path="/tech-map-editor/create"
                        element={<TechMapEditorPage />}
                    />
                    <Route path="/tech-map-editor/:id" element={<TechMapEditorPage />} />
                    <Route path="/production" element={<ProductionPage />} />
                    <Route path="/production-stats" element={<ProducationStatsPage />} />
                    <Route
                        path="/tech-map-editor/:id/operation/:operationId"
                        element={<TechMapEditorPage />}
                    />
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="*" element={null} />
                </Routes>
            </SiteLayout>
            <DrawerManagerWidget />
        </>
    );
};

export default AuthorizedRouter;
