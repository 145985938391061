import { FormReadValue } from "@shared/ui/Form";
import { Input } from "antd";
import { CSSProperties } from "react";

interface IStringInput {
    value: string;
    onlyRead?: boolean;
    onChangeValue: (value: string) => void;
    onClickName?: (e: any) => void;
    disabled?: boolean;
    isName?: boolean;
    readValueTextStyle?: CSSProperties;
}

const StringInput = ({
    value,
    onlyRead,
    onChangeValue,
    onClickName,
    disabled,
    readValueTextStyle,
}: IStringInput) => {
    const onChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = event.target.value;

        onChangeValue(value);
    };
    if (onlyRead)
        return (
            <FormReadValue
                onClick={onClickName}
                readValueTextStyle={readValueTextStyle}
                value={value ?? "-"}
            />
        );
    return (
        <Input disabled={onlyRead || disabled} onChange={onChange} value={value ?? ""} />
    );
};

export default StringInput;
