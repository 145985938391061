import { Button, Divider } from "antd";
import React, { ReactNode } from "react";
import "./ConfirmCard.scss";
import Markdown from "react-markdown";

interface IConfirmCard {
    dividerOrientation?: "left" | "center" | "right";
    dividerText?: string;
    title: React.ReactNode;
    body: string;
    description: React.ReactNode;
    comment?: string;
    statusTabAgreement?: ReactNode;
    submitLoading?: boolean;
    cancelLoading?: boolean;
    additionalButtons?: ReactNode;
    onChange?: (event: any) => void;
    onSubmit?: () => void;
    onCancel?: () => void;
}

const ConfirmCard = ({
    dividerText,
    dividerOrientation,
    title,
    body,
    submitLoading,
    cancelLoading,
    additionalButtons,
    description,
    onSubmit,
    onCancel,
    statusTabAgreement,
}: IConfirmCard) => {
    return (
        <>
            <div className="confirm-card">
                <div className="confirm-card__title">{title}</div>

                <Divider style={{ margin: 10 }} orientation={dividerOrientation}>
                    {dividerText && dividerText}
                </Divider>
                <div className="confirm-card__body">
                    {body && <Markdown>{body}</Markdown>}
                    {description && description}
                </div>
                {(onCancel || onSubmit || additionalButtons) && (
                    <div className="confirm-card__buttons">
                        {additionalButtons && additionalButtons}
                        {onCancel && (
                            <Button
                                loading={cancelLoading}
                                style={{
                                    marginRight: 10,
                                }}
                                type="primary"
                                danger
                                onClick={onCancel}
                            >
                                Отказать
                            </Button>
                        )}

                        {onSubmit && (
                            <Button
                                loading={submitLoading}
                                type="primary"
                                onClick={onSubmit}
                            >
                                Согласовать
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default ConfirmCard;
