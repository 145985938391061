import { List, Typography } from "antd";
import React from "react";
import { TListItemAddonNodes } from "../ListTypes";
import "./ListStatic.scss";

interface IListStatic<T> {
    listData: T[];
    onClickListItem: (item: T) => void;
    listItemMetaTitleText: (item: T, index: number) => React.ReactNode;
    listItemMetaDescriptionNode?: (item: T) => React.ReactNode;
    listItemAddonNodes?: (item: T) => TListItemAddonNodes;
}

const ListStatic = <T,>({
    listData,
    onClickListItem,
    listItemMetaTitleText,
    listItemMetaDescriptionNode,
    listItemAddonNodes,
}: IListStatic<T>) => {
    return (
        <List
            className="list-static"
            dataSource={listData}
            renderItem={(item, index) => {
                return (
                    <List.Item
                        onClick={() => onClickListItem(item)}
                        className="list-static__item"
                    >
                        <List.Item.Meta
                            title={
                                <Typography.Text style={{ margin: 0 }}>
                                    {listItemMetaTitleText(item, index)}
                                </Typography.Text>
                            }
                            description={
                                listItemMetaDescriptionNode ? (
                                    <Typography.Paragraph
                                        style={{ marginBottom: 0 }}
                                        ellipsis={{ rows: 1, expandable: false }}
                                    >
                                        {listItemMetaDescriptionNode(item)}
                                    </Typography.Paragraph>
                                ) : undefined
                            }
                        />
                        {listItemAddonNodes &&
                            listItemAddonNodes(item)?.map((listNode, index) => (
                                <div
                                    key={index}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        listNode.handler(item);
                                    }}
                                >
                                    {listNode.node}
                                </div>
                            ))}
                    </List.Item>
                );
            }}
        />
    );
};

export default ListStatic;
