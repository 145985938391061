export const selectEstimations = (state: RootState): TEstimationTemplate[] =>
    state.estimations.estimations;
export const selectChoosenEntityFieldsKeys = (state: RootState): string[] =>
    state.estimations.choosenEntityFieldsKeysForEstimations;
export const selectAppliedEstimation = (
    state: RootState
): TAppliedEstimationTemplate | null => state.estimations.appliedEstimation;
export const selectAppliedAdditionalEstimation = (
    state: RootState
): TEstimationValues | null => state.estimations.additionalAppliedEstimation;
export const selectEntityFields = (state: RootState): TEntityField[] =>
    state.estimations.entityFields;
export const selectLoading = (state: RootState): boolean => state.estimations.loading;
export const selectChoosenIdsForAdditionalEstimation = (state: RootState): number[] =>
    state.estimations.choosenIdsForAdditionalEstimation;
export const selectApplyingLoading = (state: RootState): boolean =>
    state.estimations.applyingLoading;
export const selectSavingLoading = (state: RootState): boolean =>
    state.estimations.savingLoading;
export const selectIsCalculateAdditional = (state: RootState): boolean =>
    state.estimations.isCalculateAdditional;
export const selectCreatingEditingEstimation = (
    state: RootState
): TCreatingEstimationTemplate | TEstimationTemplate | null =>
    state.estimations.creatingEditingEstimation;
