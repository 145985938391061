import { capabilitiesRolesModel } from "@entities/Portal/CapabilitiesRoles";
import { userModel } from "@entities/Portal/User";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useUserRoles = (
    userRolesEntityId: number | undefined,
    setRole: (item: TUserRole) => void
) => {
    const user = useSelector(userModel.selectUser);
    const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(true);
    const dispatch = useDispatch<AppDispatch>();
    capabilitiesRolesModel.useUserRolesWS(user);
    const { userRoles, loading, error } =
        capabilitiesRolesModel.useLoadUserRoles(userRolesEntityId);
    const userRolesListRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (error) {
            notificationEmit({ type: "error", title: "Ошибка загрузки ролей" });
        }
    }, [error]);

    useEffect(() => {
        if (isOpenSidebar) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [isOpenSidebar]);

    const onDelete = (item: TUserRole) => {
        userRolesEntityId &&
            dispatch(
                capabilitiesRolesModel.deleteUserRoleThunk({
                    entityId: userRolesEntityId,
                    entityRowId: item.id,
                })
            )
                .then(parseFormResponse)
                .then(() => {
                    notificationEmit({
                        type: "success",
                        title: "Успешно",
                        description: `Роль ${item.name} успешно удалена`,
                    });
                })
                .catch((e: any) => {
                    notificationEmit({
                        type: "error",
                        title: "Ошибка",
                        description: `Не удалось удалить роль ${item.name}`,
                    });
                });
    };

    const onClickUserRole = (item: TUserRole) => {
        localStorage.setItem("capabilities_editor_role_id", String(item.id));
        setIsOpenSidebar(false);
        setRole(item);
    };
    const closeSideBar = () => setIsOpenSidebar(false);
    const handleClickOutside = (event: any) => {
        if (
            userRolesListRef.current &&
            !userRolesListRef.current.contains(event.target) &&
            !document.querySelector(".ant-drawer-mask")?.contains(event.target) &&
            !document.querySelector(".ant-drawer-wrapper-body")?.contains(event.target)
        ) {
            closeSideBar();
        }
    };

    return {
        userRolesListRef,
        user,
        error,
        isOpenSidebar,
        setIsOpenSidebar,
        userRoles,
        loading,
        onDelete,
        onClickUserRole,
    };
};
