export const creatingVariable: TVariable = {
    id: 0,
    name: "",
    key: "",
    datatype: "",
    parent_type: "global",
    parent_id: undefined,
    variable_type: "variable",
    value: undefined,
    is_creating: true,
};

export const variableParentsOptions = [
    { label: "Глобальный тип", value: "global" },
    { label: "Сущность", value: "entity" },
    {
        label: "Шаблон бизнес-процесса",
        value: "biz_proc_template",
    },
    {
        label: "Бизнес-процесс",
        value: "biz_proc",
    },
];
