import EntityUserCard from "./EntityUserCard";

interface IEntityUsersCard {
    users: TUser[];
    onClick?: (value: number | string) => void;
}

const EntityUsersCard = ({ users, onClick }: IEntityUsersCard) => {
    if (users.length === 1) return <EntityUserCard user={users[0]} onClick={onClick} />;
    return (
        <div>
            {users
                .map((user) => {
                    return user.name;
                })
                .join(",")}
        </div>
    );
};

export default EntityUsersCard;
