import { createAsyncThunk } from "@reduxjs/toolkit";
import { createDatatype, deleteDatatype, getDatatypes, updateDatatype } from "../api";

export const loadDatatypesThunk = createAsyncThunk(
    "DatatypeSlice/loadDatatypes",
    async (params: TParams, { rejectWithValue }) => {
        try {
            const response = await getDatatypes(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateDatatypesThunk = createAsyncThunk(
    "DatatypeSlice/saveDatatypes",
    async (datatype: TDatatype, { rejectWithValue }) => {
        try {
            const response = await updateDatatype(datatype);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
export const createDatatypesThunk = createAsyncThunk(
    "DatatypeSlice/createDatatypes",
    async (datatype: TCreatingDatatype, { rejectWithValue }) => {
        try {
            const response = await createDatatype(datatype);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
export const deleteDatatypesThunk = createAsyncThunk(
    "DatatypeSlice/deleteDatatypes",
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await deleteDatatype(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
