import { Button, Dropdown } from "antd";
import { MenuProps } from "antd/lib";
import CopyEntityRow from "./EntityRowActions/CopyEntityRow";
import ViewEntityRow from "./EntityRowActions/ViewEntityRow";
import { CSSProperties } from "react";
import { EntityRowAccess, entityRowModel } from "@entities/Portal/EntityRow";
import { useSelector } from "react-redux";
import { userModel } from "@entities/Portal/User";
import DeleteEntityRow from "./EntityRowActions/DeleteEntityRow";

interface IEntityRowTableActions {
    entity: TEntity;
    entityRow?: { [key: string]: any };
    style?: CSSProperties;
    label?: string;
    levelKey?: number | string;
    type?: "link" | "text" | "default" | "primary" | "dashed" | undefined;
    onlyIcon?: boolean;
    icon?: React.ReactNode;
    disabled?: boolean;
    isDropdown?: boolean;
    openCloseDrawerWithNavigate?: (
        setIsOpenDrawer: boolean,
        entityRowId: number | null | undefined
    ) => void;
}

const EntityRowTableActions = ({
    entity,
    entityRow,
    style,
    label,
    type,
    onlyIcon,
    icon,
    levelKey,
    isDropdown,
    openCloseDrawerWithNavigate,
}: IEntityRowTableActions) => {
    const userId = useSelector(userModel.selectUserId);
    const entityRowId: number = entityRow?.id;
    const capabilities = useSelector(entityRowModel.selectEntityUserCapabilities);
    const allowDelete = capabilities.delete === 9;

    const onCreateEntityRow = () =>
        openCloseDrawerWithNavigate && openCloseDrawerWithNavigate(true, null);
    const items: MenuProps["items"] = [
        {
            label: (
                <ViewEntityRow
                    entityRowId={entityRowId}
                    openCloseDrawerWithNavigate={openCloseDrawerWithNavigate}
                />
            ),
            key: "ViewEntityRow",
            style: {
                padding: 0,
                margin: 0,
            },
        },
        {
            label: (
                <EntityRowAccess.CreateAccess userId={userId!}>
                    <CopyEntityRow
                        entityRow={entityRow}
                        openCloseDrawerWithNavigate={openCloseDrawerWithNavigate}
                    />
                </EntityRowAccess.CreateAccess>
            ),
            key: "CopyEntityRow",
            style: {
                padding: 0,
                margin: 0,
            },
        },
    ];
    if (allowDelete && entityRow) {
        items.push({
            label: (
                <DeleteEntityRow
                    levelKey={levelKey}
                    entityId={entity.id}
                    entityRowId={entityRow.id}
                    //openCloseDrawerWithNavigate={openCloseDrawerWithNavigate}
                />
            ),
            key: "DeleteEntityRow",
            style: {
                padding: 0,
                margin: 0,
            },
        });
    }
    return (
        <>
            {isDropdown ? (
                <Dropdown menu={{ items }} trigger={["click"]}>
                    {onlyIcon ? (
                        <Button type={type} icon={icon} />
                    ) : (
                        <Button icon={icon}>{label}</Button>
                    )}
                </Dropdown>
            ) : (
                <Button style={style} onClick={onCreateEntityRow} type={type} icon={icon}>
                    {label}
                </Button>
            )}
        </>
    );
};

export default EntityRowTableActions;
