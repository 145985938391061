export const selectExecutableTmts = (state: RootState): TTechMapTemplateExecuble[] =>
    state.techMap.executableTmts;
export const selectGetExecutableLoading = (state: RootState): boolean =>
    state.techMap.getExecutableLoading;
export const selectStartingLoading = (state: RootState, tmtId: number): boolean =>
    state.techMap.startingLoading === tmtId;
export const selectStartedTm = (state: RootState): TTechMapStarted | null =>
    state.techMap.startedTm;
export const selectStartedOperation = (
    state: RootState
): TTechMapOperationStarted | null => state.techMap.startedOperation;
export const selectGetStartedOperationLoading = (state: RootState): boolean =>
    state.techMap.getStartedOperationLoading;
export const selectGetStartedTmsLoading = (state: RootState): boolean =>
    state.techMap.getStartedTmsLoading;
export const selectGetTMLoading = (state: RootState): boolean =>
    state.techMap.getTMLoading;
export const selectAttachItemLoading = (state: RootState): boolean =>
    state.techMap.attachItemLoading;
export const selectTechMapsForReview = (state: RootState): TTechMapStarted[] =>
    state.techMap.techMapsForReview;
export const selectTechMaps = (state: RootState): TTechMapStarted[] =>
    state.techMap.techMaps;
export const selectNextOperationLoading = (state: RootState): boolean =>
    state.techMap.nextOperationLoading;
export const selectIsStartedTmt = (state: RootState, tmtUuid: string): boolean =>
    state.techMap.startedTm?.technical_map_template_uuid === tmtUuid;
export const selectIsApproveLoading = (state: RootState, tmId: number): boolean =>
    state.techMap.approveTmLoading[tmId] === "approve";
export const selectIsRejectLoading = (state: RootState, tmId: number): boolean =>
    state.techMap.approveTmLoading[tmId] === "reject";
