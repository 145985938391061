import { BaseOptionType } from "antd/es/select";
import dayjs from "dayjs";
import { useState } from "react";
import { schedulerFormats } from "../constants";
export const useConfigureSchedulerModal = (
    entityFields: TEntityField[],
    dateStartKey: string | undefined,
    dateEndKey: string | undefined,
    eventLabel: string | undefined,
    eventDescription: string | undefined,
    setDateEndKey: (item: string | undefined) => void,
    setDateStartKey: (item: string | undefined) => void,
    setEventLabel: (item: string | undefined) => void,
    setEventDescription: (item: string | undefined) => void,
    onClose: () => void,
    date: Date | undefined,
    viewMode: TScheduleViewMode,
    setViewMode: (item: TScheduleViewMode) => void,
    setDate: (item: Date) => void
) => {
    const [choosenDateStartKey, setChoosenDateStartKey] = useState<string | undefined>(
        dateStartKey
    );
    const [choosenDateEndKey, setChoosenDateEndKey] = useState<string | undefined>(
        dateEndKey
    );
    const [choosenEventLabel, setChoosenEventLabel] = useState<string | undefined>(
        eventLabel
    );
    const [choosenEventDescription, setChoosenEventDescription] = useState<
        string | undefined
    >(eventDescription);
    const [choosenViewMode, setChoosenViewMode] = useState<TScheduleViewMode>(viewMode);
    const [choosenDate, setChoosenDate] = useState<dayjs.Dayjs | null>(dayjs(date));
    const entityFieldsOptions = entityFields.map((entityField): BaseOptionType => {
        return {
            label: entityField.name,
            value: entityField.key,
            item: entityField,
        };
    });
    const viewModeOptions = Object.entries(schedulerFormats).map(([value, label]) => ({
        label,
        value,
    }));
    const dateOptions = entityFieldsOptions.filter(
        (item): item is BaseOptionType =>
            item.item.datatype === "date" ||
            item.item.datatype === "datetime" ||
            item.item.datatype === "timestamp"
    );

    const onSelectDateStartKey = (value: any) => {
        setChoosenDateStartKey(value);
    };
    const onSelectDateEndKey = (value: any) => {
        setChoosenDateEndKey(value);
    };
    const onSelectEventLabel = (value: any) => {
        setChoosenEventLabel(value);
    };
    const onSelectEventDescription = (value: any) => {
        setChoosenEventDescription(value);
    };

    const onSubmit = () => {
        setDateEndKey(choosenDateEndKey);
        setDateStartKey(choosenDateStartKey);
        setEventLabel(choosenEventLabel);
        setEventDescription(choosenEventDescription);
        choosenViewMode && setViewMode(choosenViewMode);
        setDate(dayjs(choosenDate).toDate());
        onClose();
    };

    const onCancel = () => {
        onClose();
        setChoosenDateStartKey(dateStartKey);
        setChoosenDateEndKey(dateEndKey);
        setChoosenEventLabel(eventLabel);
        setChoosenEventDescription(eventDescription);
    };

    const onChangeFormat = (value: TScheduleViewMode) => {
        setChoosenViewMode(value);
    };

    const onChangeDate = (value: dayjs.Dayjs | null) => {
        setChoosenDate(value);
    };

    return {
        choosenDateStartKey,
        choosenDateEndKey,
        choosenEventLabel,
        choosenEventDescription,
        choosenDate,
        choosenViewMode,
        entityFieldsOptions,
        viewModeOptions,
        dateOptions,
        onSelectDateStartKey,
        onSelectDateEndKey,
        onSelectEventLabel,
        onSelectEventDescription,
        onSubmit,
        onCancel,
        onChangeFormat,
        onChangeDate,
    };
};
