import { entityApi, entityModel } from "@entities/Portal/Entity";
import { datatypeModel } from "@entities/Portal/Datatype";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEntityFields, getNotUniqKeys } from "../lib";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { entityFieldModel } from "@entities/Portal/EntityField";
interface IUseCreateUpdateEntity {
    entityId: number;
    onCancel?: () => void;
    onSave?: () => void;
    editingEntity?: TEntity | null;
}
export const useCreateUpdateEntity = ({
    entityId,
    onCancel,
    onSave,
    editingEntity,
}: IUseCreateUpdateEntity) => {
    const dispatch = useDispatch<AppDispatch>();
    const [entity, setEntity] = useState<TEntity | null>(editingEntity ?? null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<TValidationError>({});
    const datatypes = useSelector(datatypeModel.selectDatatypes);
    const entities = useSelector(entityModel.selectEntities);
    const entityFields = useSelector(entityFieldModel.selectEntityFields);
    const initialEntity = useRef<TEntity | null>(editingEntity ?? null);
    const notUniqKeys = getNotUniqKeys(entity?.fields);

    useEffect(() => {
        if (!editingEntity && entityId)
            entityApi
                .getEntity(entityId)
                .then((response) => {
                    const entity = response.data.data;
                    setEntity(entity);
                    initialEntity.current = entity;
                })
                .catch(() => {})
                .finally(() => {});
        else {
            if (editingEntity) setEntity(editingEntity);
        }
    }, [entityId]);

    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (entity)
            setEntity({
                ...entity,
                [event.target.name]: event.target.value,
            });
    };
    const onChangeIsReferenceField = (event: CheckboxChangeEvent) => {
        if (entity)
            setEntity({
                ...entity,
                is_reference: event.target.checked,
            });
    };
    const onChangeIsGuidebookField = (event: CheckboxChangeEvent) => {
        if (entity)
            setEntity({
                ...entity,
                is_guidebook: event.target.checked,
            });
    };
    const onChangeIsWithCategories = (event: CheckboxChangeEvent) => {
        if (entity)
            setEntity({
                ...entity,
                is_with_categories: event.target.checked,
            });
    };

    const onChangeRelatedEntityFields = (newEntitiesFields: TEntityField[]) => {
        if (entity) {
            setEntity({
                ...entity,
                fields: newEntitiesFields,
            });
        }
    };

    const onSaveEntity = () => {
        setError({});
        if (entity) {
            setLoading(true);
            dispatch(
                entity.id === undefined
                    ? entityModel.createEntityThunk({
                          ...entity,
                          fields: entityFields,
                      })
                    : entityModel.updateEntityThunk(entity)
            )
                .then(parseFormResponse)
                .then(() => {
                    onSave && onSave();
                })
                .catch((error: TValidationError) => {
                    console.log("error", error);
                    setError(error);
                    notificationEmit({
                        error: error,
                        title: error.message ?? "Ошибка",
                        type: "error",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const onChangeRelations = (relations: null | string | string[]) => {
        if (entity && relations && Array.isArray(relations)) {
            const entities_fields = getEntityFields(entity.fields, relations, entities);
            setEntity({
                ...entity,
                fields: [...entities_fields],
                relations_id: relations,
            });
        }
    };
    const onCancelClick = () => {
        setEntity(initialEntity.current);
        onCancel && onCancel();
    };

    const entityRelations: string[] = entity?.relations_id ?? [];

    const isKeyNotUniq = (key: string) => notUniqKeys.includes(key);
    return {
        entity,
        entityRelations,
        datatypes,
        notUniqKeys,
        error,
        loading,
        onChangeName,
        onChangeIsReferenceField,
        onChangeIsGuidebookField,
        onSaveEntity,
        onChangeRelations,
        onChangeRelatedEntityFields,
        isKeyNotUniq,
        onCancelClick,
        onChangeIsWithCategories,
    };
};
