import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { Fragment } from "react";
import { useConditionGroup } from "../model";
import "./Condition.scss";
import ConditionGroupHeader from "./ConditionGroupHeader";
import ConditionItem from "./ConditionItem";

interface IConditionGroup {
    conditionGroup: TIfElseBranchConditionGroup;
    onChangeParentGroup: (
        conditionGroup: TIfElseBranchConditionGroup | TIfElseBranchCondition
    ) => void;
    onDeleteParentGroup: (
        conditionGroup: TIfElseBranchConditionGroup | TIfElseBranchCondition
    ) => void;
    bizProcTemplate: TBizProcTemplateItem;
    templateItem: TBizProcTemplateAction;
    disabled?: boolean;
}

const ConditionGroup = (props: IConditionGroup) => {
    const { conditionGroup, bizProcTemplate, templateItem } = props;
    const disabled = props.disabled;
    const {
        onAddGroup,
        onChangeGroup,
        onAddIf,
        onDeleteGroup,
        onChangeLogicalComparison,
        onDelete,
    } = useConditionGroup(props);
    const items = [
        {
            key: conditionGroup.id,
            label: (
                <ConditionGroupHeader
                    onChangeLogicalComparison={onChangeLogicalComparison}
                    logicalComparison={conditionGroup.logicalComparison}
                    onAddGroup={onAddGroup}
                    onAddIf={onAddIf}
                    onDelete={onDeleteGroup}
                    disabled={disabled}
                />
            ),
            style: {
                padding: 0,
            },
            children: (
                <>
                    {conditionGroup.conditions.map((condition) => {
                        return (
                            <Fragment key={condition.id}>
                                {condition.type === "group" ? (
                                    <ConditionGroup
                                        disabled={disabled}
                                        templateItem={templateItem}
                                        bizProcTemplate={bizProcTemplate}
                                        onDeleteParentGroup={onDelete}
                                        onChangeParentGroup={onChangeGroup}
                                        conditionGroup={condition}
                                    />
                                ) : (
                                    <ConditionItem
                                        disabled={disabled}
                                        onDelete={onDelete}
                                        onChange={onChangeGroup}
                                        templateItem={templateItem}
                                        bizProcTemplate={bizProcTemplate}
                                        condition={condition}
                                    />
                                )}
                            </Fragment>
                        );
                    })}
                </>
            ),
        },
    ];

    return (
        <>
            <Collapse
                expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                bordered={false}
                className="condition-group"
                items={items}
            />
        </>
    );
};

export default ConditionGroup;
