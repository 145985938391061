import { FormItem } from "@shared/ui/Form";
import { settingLabel } from "../constants";
import { usePortalSettingsFormItem } from "../model";
import { PortalSettingInput } from "./PortalSettingInput";
import "./PortalSettingsForm.scss";
interface IPortalSettingsFormItem {
    portalSetting: TPortalSetting;
}

const PortalSettingsFormItem = ({ portalSetting }: IPortalSettingsFormItem) => {
    const { onChange } = usePortalSettingsFormItem(portalSetting);
    return (
        <div className="portal-setting-item">
            <FormItem
                label={settingLabel[portalSetting.setting]}
                labelStyle={{ fontSize: 14, fontWeight: 600 }}
            >
                <div className="portal-setting-item__input">
                    <PortalSettingInput
                        portalSetting={portalSetting}
                        onChange={onChange}
                    />{" "}
                </div>
            </FormItem>
        </div>
    );
};

export default PortalSettingsFormItem;
