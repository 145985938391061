import axios from "axios";
export const clientId = "9bd20bfb-9c32-4018-b6b8-a786f862fb3c";
export const clientSecret = "wDGvQT4RsIBIi4M734BNkF50N0gWEbVSiuzO6cb0";
// export const clientId = "9bd1adf3-2448-4359-9190-ba8fd247a1bd";
// export const clientSecret = "1TrQHh4kGBgNWaWfYvKNx9HzE38fL5dKOCHqEPf0";
// export const clientId = "9bd1adf3-2448-4359-9190-ba8fd247a1bd";
// export const clientSecret = "1TrQHh4kGBgNWaWfYvKNx9HzE38fL5dKOCHqEPf0";
export const grantType = "password";

export const apiUrl = process.env.REACT_APP_API_URL;
export const apiUrlAuth = apiUrl; //"https://auth.vakio.ru";

export const $api = axios.create({
    baseURL: apiUrl,
});

export const $apiAuth = axios.create({
    baseURL: apiUrlAuth,
});

$api.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("access_token");

    config.headers.Authorization = `Bearer ${token}`;

    return config;
});
$apiAuth.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("access_token");

    config.headers.Authorization = `Bearer ${token}`;
    return config;
});
