import { bizProcModel } from "@entities/Portal/BizProc";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { Dispatch, useState } from "react";
import { useDispatch } from "react-redux";

export const useBizProcFillForm = (
    bizProc: TBizProc,
    setBizProc: Dispatch<SetStateAction<TBizProc | null>>
) => {
    const [loading, setLoading] = useState<null | "save" | "next">(null);
    const [targetForm, setTargetForm] = useState<TBizProcAction | null>(
        bizProc?.start_biz_proc_action ?? null
    );
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { loadingVariables, fields, setFields } = bizProcModel.useFormFields(
        targetForm?.biz_proc_id,
        targetForm?.collected_parameters?.fields
    );
    const onChangeFormFields = (fields: TBizProcFormField[]) => {
        setFields(fields);
    };

    const onStartEdit = () => {
        setIsEdit(true);
        if (bizProc?.start_biz_proc_action) setTargetForm(bizProc.start_biz_proc_action);
        //setTargetForm(bizProc.actions[bizProc.actions.length - 1])
    };
    const onSubmit = (onlySave: boolean) => {
        if (!targetForm) return;
        setLoading(onlySave ? "save" : "next");

        dispatch(
            bizProcModel.submitBizProcFormThunk({
                formId: targetForm?.id,
                fields: fields,
                onlySave: onlySave,
            })
        )
            .then((response: any) => {
                if (response.error) {
                    notificationEmit({
                        title: "Не удалось сохранить форму",
                        type: "error",
                    });
                } else {
                    setIsEdit(false);
                    if (bizProc)
                        setBizProc((bizProc) => ({
                            ...((bizProc as TBizProc) ?? {}),
                            start_biz_proc_action: onlySave ? targetForm : undefined,
                        }));
                }
            })

            .finally(() => {
                setLoading(null);
            });
    };

    return {
        onChangeFormFields,
        fields,
        onSubmit,
        loading,
        isEdit,
        onStartEdit,
        targetForm,
    };
};
