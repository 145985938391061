export const timeSplitter = (seconds: number) => {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor(((seconds % (3600 * 24)) % 3600) / 60);
    return [minutes, hours, days];
};

export const getSeconds = ({
    minutes,
    hours,
    days,
}: {
    minutes: number;
    hours: number;
    days: number;
}) => {
    return minutes * 60 + hours * 3600 + days * 3600 * 24;
};
