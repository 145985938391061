import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "./CreateCapabilitiesRolesButton.scss";

interface ICreateCapabilitiesRolesButton {
    disabled: boolean;
    onOpenModal: () => void;
}

const CreateCapabilitiesRolesButton = ({
    disabled,
    onOpenModal,
}: ICreateCapabilitiesRolesButton) => {
    return (
        <Button
            className={disabled ? undefined : "capabilities-roles-add-button"}
            type="link"
            style={{
                margin: 0,
                padding: 8,
                width: "100%",
                border: "none",
            }}
            disabled={disabled}
            icon={<PlusOutlined />}
            onClick={onOpenModal}
        >
            Добавить настройку прав
        </Button>
    );
};

export default CreateCapabilitiesRolesButton;
