import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    createViewPatternItem,
    deleteViewPatternItem,
    getViewPatternItems,
    massUpdateViewPatternItems,
    updateViewPatternItem,
} from "../api";

export const loadViewPatternItemsThunk = createAsyncThunk(
    "ViewPatternItemSlice/loadViewPatternItemsThunk",
    async (props: { viewPatternId: number }, { rejectWithValue }) => {
        try {
            const response = await getViewPatternItems(props.viewPatternId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateViewPatternItemThunk = createAsyncThunk(
    "ViewPatternItemSlice/updateViewPatternItemThunk",
    async (viewPatternItem: TViewPatternItem, { rejectWithValue }) => {
        try {
            const response = await updateViewPatternItem(viewPatternItem);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const reorderViewPatternItemsThunk = createAsyncThunk(
    "ViewPatternItemSlice/reorderViewPatternItemsThunk",
    async (viewPatternItems: any[], { rejectWithValue }) => {
        try {
            const response = await massUpdateViewPatternItems(viewPatternItems);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const createViewPatternItemThunk = createAsyncThunk(
    "ViewPatternItemSlice/createViewPatternItemThunk",
    async (viewPatternItem: TViewPatternItemCreating, { rejectWithValue }) => {
        try {
            const response = await createViewPatternItem(viewPatternItem);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const deleteViewPatternItemThunk = createAsyncThunk(
    "ViewPatternItemSlice/deleteViewPatternItemThunk",
    async (props: { id: number; viewPatternId: number }, { rejectWithValue }) => {
        try {
            const response = await deleteViewPatternItem(props.id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
