import { entityRowModel } from "@entities/Portal/EntityRow";
import { ConfigProvider, Input } from "antd";
import { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

interface IInputTextFitler {
    entityId: number;
}

const InputTextFitler = ({ entityId }: IInputTextFitler) => {
    const textFitlerValue = useSelector(entityRowModel.selectTextFilterValue);
    const dispatch = useDispatch();
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(
            entityRowModel.setTextFilterValue({
                textFilterValue: event.target.value,
                entityId,
            })
        );
    };
    return (
        <ConfigProvider
            theme={{
                components: {
                    Input: {
                        activeBorderColor: "#1677ff",
                    },
                },
            }}
        >
            <Input
                value={textFitlerValue}
                bordered={false}
                onChange={onChange}
                placeholder="Поиск"
            />
        </ConfigProvider>
    );
};

export default InputTextFitler;
