export const getViewPatternItemCreateSelectOptions = (
    entityFields: TEntityField[],
    viewPatternItems: TViewPatternItem[]
) => {
    return [
        ...entityFields.map((item) => {
            const id = item.id!.toString();

            return {
                value: id,
                label: item.name,
                entityField: item,
                viewPatternItem: viewPatternItems.find(
                    (item) =>
                        item.type === "entity-field" &&
                        item.type_value === id &&
                        item.parent_id === null
                ),
            };
        }),
    ];
};
