import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import "./TMTONecessaryItems.scss";
import TMTONecessaryItem from "./TMTONecessaryItem";
import { useState } from "react";
import TMTONecessaryItemModal from "./TMTONecessaryItemModal";
import { getNecessaryItemMock } from "../../lib";
import { Button } from "antd";

interface ITMTONecessaryItems {
    tmtoUuid: string;
    items: (TOperationNecessaryItem | TOperationNecessaryItemCreating)[];
    editingMode?: boolean;
    onChange: (
        items: (TOperationNecessaryItem | TOperationNecessaryItemCreating)[]
    ) => void;
}

const TMTONecessaryItems = ({
    tmtoUuid,
    items,
    editingMode,
    onChange,
}: ITMTONecessaryItems) => {
    const [isOpen, setIsOpen] = useState(false);
    const [editingItem, setEditingItem] = useState<
        TOperationNecessaryItem | TOperationNecessaryItemCreating | null
    >(null);

    const onClose = () => {
        setIsOpen(false);
    };
    const onOpen = (item: TOperationNecessaryItem | TOperationNecessaryItemCreating) => {
        setIsOpen(true);
        setEditingItem(item);
    };

    const onUpdateItem = (
        item: TOperationNecessaryItem | TOperationNecessaryItemCreating
    ) => {
        if (
            "tmp_id" in item &&
            !items.find((item_) => "tmp_id" in item_ && item_.tmp_id === item.tmp_id)
        ) {
            onChange([...items, item]);
        } else {
            onChange(
                items.map((item_) => {
                    const isEqual =
                        ("id" in item && "id" in item_ && item.id === item_.id) ||
                        ("tmp_id" in item &&
                            "tmp_id" in item_ &&
                            item.tmp_id === item_.tmp_id);
                    return isEqual ? item : item_;
                })
            );
        }
        onClose();
    };

    const onDelete = (
        item: TOperationNecessaryItem | TOperationNecessaryItemCreating
    ) => {
        onChange(
            items.filter((item_) => {
                const isEqual =
                    ("id" in item && "id" in item_ && item.id === item_.id) ||
                    ("tmp_id" in item &&
                        "tmp_id" in item_ &&
                        item.tmp_id === item_.tmp_id);
                return !isEqual;
            })
        );
    };
    const onCreate = () => {
        onOpen(getNecessaryItemMock(tmtoUuid));
    };
    return (
        <div className="tmton-items">
            <TMTONecessaryItemModal
                item={editingItem}
                onClose={onClose}
                isOpen={isOpen}
                onSubmit={onUpdateItem}
            />
            {editingMode && (
                <Button type="link" onClick={onCreate}>
                    Создать
                </Button>
            )}
            {items.map((item) => {
                return (
                    <IndentContainer key={"id" in item ? item.id : item.tmp_id}>
                        <TMTONecessaryItem
                            item={item}
                            editingMode={editingMode}
                            onEdit={onOpen}
                            onDelete={onDelete}
                        />
                    </IndentContainer>
                );
            })}
        </div>
    );
};

export default TMTONecessaryItems;
