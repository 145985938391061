import {
    AdmonitionDirectiveDescriptor,
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    CreateLink,
    InsertAdmonition,
    InsertTable,
    InsertThematicBreak,
    ListsToggle,
    MDXEditor,
    MDXEditorMethods,
    UndoRedo,
    directivesPlugin,
    linkDialogPlugin,
    linkPlugin,
    listsPlugin,
    quotePlugin,
    tablePlugin,
    thematicBreakPlugin,
    toolbarPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import i18next from "i18next";
import React from "react";
import "./EditorMarkdownInput.scss";
import { locales } from "./EditorMarkdownLocales";
import { Input } from "antd";
import { styled } from "styled-components";

interface IEditorMarkdownInput {
    value: string;
    onlyRead?: boolean;
    additionalParameters?: TLongTextAdditionalParameters;
    onChangeValue: (value: string) => void;
    disabled?: boolean;
}
i18next.init({
    lng: "ru",
    debug: true,
    resources: {
        ru: {
            translation: locales,
        },
    },
});

const MyMDXEditor = styled(MDXEditor)`
    .mdxeditor-toolbar {
        display: ${(props: any) => props.theme.display};
    }
`;

const EditorMarkdownInput = ({
    value,
    onlyRead,
    additionalParameters,
    onChangeValue,
    disabled,
}: IEditorMarkdownInput) => {
    const ref = React.useRef<MDXEditorMethods>(null);
    const onChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = event.target.value;
        onChangeValue(value);
    };

    if (additionalParameters?.isInFilterInput)
        return (
            <Input
                disabled={onlyRead || disabled}
                onChange={onChange}
                value={value ?? ""}
            />
        );

    return (
        <div className={onlyRead ? undefined : "mdx-editor-container"}>
            <MyMDXEditor
                theme={{ display: onlyRead ? "none" : "" }}
                translation={(key: any, defaultValue: any, interpolations: any) => {
                    return i18next.t(key, defaultValue, interpolations) as string;
                }}
                ref={ref}
                contentEditableClassName={`mdx-editor-container__editor${onlyRead ? "--onlyread" : ""}`}
                readOnly={onlyRead}
                markdown={value ?? ""}
                plugins={[
                    directivesPlugin({
                        directiveDescriptors: [AdmonitionDirectiveDescriptor],
                    }),
                    listsPlugin(),
                    tablePlugin(),
                    linkPlugin(),
                    linkDialogPlugin(),
                    thematicBreakPlugin(),
                    quotePlugin(),
                    toolbarPlugin({
                        toolbarContents: () =>
                            onlyRead ? (
                                <></>
                            ) : (
                                <>
                                    <UndoRedo />
                                    <BlockTypeSelect />
                                    <CreateLink />
                                    <BoldItalicUnderlineToggles />
                                    <ListsToggle />
                                    <InsertTable />
                                    <InsertAdmonition />
                                    <InsertThematicBreak />
                                </>
                            ),
                    }),
                ]}
                onChange={onChangeValue}
            />
        </div>
    );
};

export default EditorMarkdownInput;
