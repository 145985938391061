import { techMapTemplateModel } from "@entities/Production/TechMapTemplate";
import { useDispatch } from "react-redux";

export const useTechMapTemplateList = () => {
    const { techMapTemplates, loading } = techMapTemplateModel.useTechMapTemplatesLoad();
    const dispatch = useDispatch<AppDispatch>();
    const onDelete = (techMapTemplate: TTechMapTemplate) => {
        dispatch(
            techMapTemplateModel.deleteTechMapTemplateThunk({ id: techMapTemplate.id })
        );
    };
    return {
        onDelete,
        techMapTemplates,
        loading,
    };
};
