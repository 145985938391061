import { CardWithHead } from "@shared/ui/Cards/CardWithHead";
import EditTemplateBar from "../EditTemplateBar";
import ActionCard from "./ActionCard";

interface IFormAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const FormAction = ({ onDeleteItem, templateItem }: IFormAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="#4BC07C"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Форма"}
        ></ActionCard>
    );
};

export default FormAction;
