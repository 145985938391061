import CapabilitiesRolesConstraintsForm from "@features/Portal/CapabilitiesRoles/CapabilitiesRolesRow/ui/CapabilitiesRolesConstraints/CapabilitiesRolesConstraintsForm";
import {
    CreateCapabilitiesRolesButton,
    CreateCapabilitiesRolesModal,
} from "@features/Portal/CapabilitiesRoles/CreateCapabilitiesRoles";
import { useState } from "react";

interface ICreateCapabilitiesRoles {
    role?: TUserRole;
    entityCapabilitiesRolesId: number | undefined;
}

const CreateCapabilitiesRoles = ({
    role,
    entityCapabilitiesRolesId,
}: ICreateCapabilitiesRoles) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const onCloseModal = () => setIsOpen(false);
    const onOpenModal = () => setIsOpen(true);
    return (
        <div>
            {role && (
                <CreateCapabilitiesRolesModal
                    entityCapabilitiesRolesId={entityCapabilitiesRolesId!}
                    role={role}
                    open={isOpen}
                    onCloseModal={onCloseModal}
                    extraFormItem={(
                        entityRow: TCapabilitiesRolesRow,
                        value: string | null,
                        onChangeRow: (capType: TCapType, value: any) => void
                    ) => (
                        <CapabilitiesRolesConstraintsForm
                            entityCapabilitiesRolesId={entityCapabilitiesRolesId!}
                            entityRow={entityRow}
                            value={value}
                            onChangeRow={onChangeRow}
                            isCreating={true}
                        />
                    )}
                />
            )}

            <CreateCapabilitiesRolesButton disabled={!role} onOpenModal={onOpenModal} />
        </div>
    );
};

export default CreateCapabilitiesRoles;
