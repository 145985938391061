import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { useCategoriesList } from "../model";
import { CategoryListItem } from "@entities/Portal/Category";
import { ReactNode } from "react";
import CategoriesPanelList from "./CategoriesPanelList";

interface ICategoriesList {
    entityId: number;
    panelChildren?: (category: TCategory) => ReactNode;
    actions?: (category: TCategory) => ReactNode;
}

const CategoriesList = ({ entityId, panelChildren, actions }: ICategoriesList) => {
    const { categories } = useCategoriesList(entityId);

    return (
        <div>
            {panelChildren ? (
                <CategoriesPanelList
                    categories={categories}
                    actions={actions}
                    panelChildren={panelChildren}
                />
            ) : (
                categories.map((category) => (
                    <IndentContainer key={category.id}>
                        <CategoryListItem
                            category={category}
                            // onDelete={onDelete}
                            // onEdit={onEdit}
                        />
                    </IndentContainer>
                ))
            )}
        </div>
    );
};

export default CategoriesList;
