import ActionCard from "./ActionCard";

interface IChangeValuesAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const ChangeValuesAction = ({ onDeleteItem, templateItem }: IChangeValuesAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="DarkOrange"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Изменение переменных"}
        ></ActionCard>
    );
};

export default ChangeValuesAction;
