export const getLocalStorageKey = (entityId: number) => {
    return `${entityId}_table_grouping_keys`;
};

export const getGroupingKeysFromStorage = (entityId: number) => {
    return JSON.parse(localStorage.getItem(getLocalStorageKey(entityId)) ?? "[]");
};

export const setGroupingKeysFromStorage = (entityId: number, keys: string[]) => {
    localStorage.setItem(getLocalStorageKey(entityId), JSON.stringify(keys));
};
