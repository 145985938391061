import { bizProcModel } from "@entities/Portal/BizProc";

export const useActionsListWidget = () => {
    const { actions, loading } = bizProcModel.useLoadBizProcUserActions(true);
    const visible = actions.length !== 0;

    return {
        visible,
        actions,
        loading,
    };
};
