export const getEndStage = (
    currentStageIndex: number | undefined | null,
    orderedStages: TStage[],
    finalStages: TStage[]
) => {
    if (finalStages.length === 1) return finalStages[0];

    return currentStageIndex !== undefined &&
        currentStageIndex !== null &&
        orderedStages.length > 0
        ? orderedStages[currentStageIndex].type !== "in_progress"
            ? orderedStages[currentStageIndex]
            : getDefaultEndStage()
        : getDefaultEndStage();
};

export const getDefaultEndStage = () => {
    return {
        id: 0,
        uuid: "",
        category_uuid: "-",
        type: "successfull" as TStageType,
        label: "Завершено",
        prev_stage_uuid: "-",
        background_color: "green",
    };
};

export const getStageBackgroundColor = (
    index: number,
    stage: TStage,
    hoveredIndex: number | null,
    hoveredStage: TStage | null,
    currentStage: TStage | null,
    currentStageIndex: number | null
) => {
    if (hoveredIndex !== null && hoveredStage) {
        if (index <= hoveredIndex) {
            return hoveredStage.background_color;
        }
    }

    if (currentStageIndex !== null && index <= currentStageIndex) {
        return currentStage?.background_color;
    }
    return undefined;
};
