import { actionModel } from "@entities/Portal/Action";
import { getUnixTimestamp } from "@shared/lib/date";
import { useDispatch } from "react-redux";
import { createdAction } from "./constant";

export const useCreateAction = () => {
    const dispatch = useDispatch();

    const onAdd = () => {
        dispatch(
            actionModel.addAction({
                id: getUnixTimestamp(),
                is_creating: true,
                ...createdAction,
            })
        );
    };
    return {
        onAdd,
    };
};
