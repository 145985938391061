import { InfoCircleOutlined } from "@ant-design/icons";
import { FormInputItem } from "@entities/Portal/Datatype";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { FormItem } from "@shared/ui/Form";
import { Tooltip } from "antd";

interface IFillActionFormBuilder {
    fields: TBizProcFormField[];
    ignoreUnwritable?: boolean;
    allowFillAllFields?: boolean;
    error?: { [key: string]: boolean | string };
    onChangeFormFields: (fields: TBizProcFormField[], id?: string) => void;
    onlyRead?: boolean;
}

const FillActionFormBuilder = ({
    fields,
    error,
    onlyRead,
    allowFillAllFields,
    ignoreUnwritable,
    onChangeFormFields,
}: IFillActionFormBuilder) => {
    const onChangeField = (value: any, inputId: string | number | undefined) => {
        onChangeFormFields(
            fields?.map((field) =>
                field.unique_id === inputId
                    ? {
                          ...field,
                          value,
                      }
                    : field
            ),
            inputId?.toString()
        );
    };

    return (
        <div>
            {fields &&
                fields?.map((field) => {
                    const isWithError = error?.[field.unique_id] ?? false;
                    return (
                        <IndentContainer key={field.unique_id}>
                            <FormItem
                                labelStyle={
                                    field.is_readonly
                                        ? { color: "#8f8f8f", fontWeight: 400 }
                                        : undefined
                                }
                                label={
                                    <>
                                        {allowFillAllFields && !!field.is_readonly && (
                                            <Tooltip
                                                title="Заполнение доступно только администратору"
                                                color="var(--info)"
                                            >
                                                <InfoCircleOutlined />
                                            </Tooltip>
                                        )}{" "}
                                        {field.name}
                                    </>
                                }
                                error={isWithError as boolean}
                                errorText={error?.[field.unique_id + "_err"] as string}
                                required={Boolean(field.is_required)}
                            >
                                <FormInputItem
                                    field={field}
                                    inputId={field.unique_id}
                                    additionalParameters={field.additional_parameters}
                                    datatype={field.datatype as unknown as string}
                                    value={field.value}
                                    onlyRead={
                                        allowFillAllFields
                                            ? false
                                            : onlyRead ||
                                              Boolean(field.is_readonly) ||
                                              (field.variable_type === "entity" &&
                                                  field.is_unwritable &&
                                                  !ignoreUnwritable)
                                    }
                                    onChange={onChangeField}
                                />
                            </FormItem>
                        </IndentContainer>
                    );
                })}
        </div>
    );
};

export default FillActionFormBuilder;
