import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { selectUserIsAdmin } from "../model";

interface IAdminAccess {
    children: ReactNode;
}

const AdminAccess = ({ children }: IAdminAccess) => {
    const isUserAdmin = useSelector(selectUserIsAdmin);

    if (isUserAdmin) return <>{children}</>;

    return <></>;
};

export default AdminAccess;
