import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { datatypesBuilders } from "./DatatypeBuilders";

export interface IDatatypeSlice {
    datatypes: TDatatype[];
    isLoading: boolean;
    editingDatatype: TDatatype | TCreatingDatatype | null;
    error: any;
}

const initialState: IDatatypeSlice = {
    datatypes: [],
    isLoading: false,
    editingDatatype: null,
    error: null,
};

export const datatypeSlice = createSlice({
    name: "DatatypeSlice",
    initialState,
    reducers: {
        setEditingDatatype: (
            state,
            action: PayloadAction<TDatatype | TCreatingDatatype | null>
        ) => {
            state.editingDatatype = action.payload;
        },
    },
    extraReducers: (builder) => {
        datatypesBuilders(builder);
    },
});

export const { setEditingDatatype } = datatypeSlice.actions;
export default datatypeSlice.reducer;
