import { PlusOutlined } from "@ant-design/icons";
import { DragAndDropList } from "@shared/ui/DragAndDropList";
import { Button, Dropdown } from "antd";
import { useViewPatternItemGroup } from "../model";
import ViewPatternItem from "./ViewPatternItem";
import "./ViewPatternItem.scss";
import { ReactNode } from "react";

interface IViewPatternItemGroup {
    viewPattern: TViewPattern;
    viewPatternItemGroup: TViewPatternItem;
    entityFields?: TEntityField[];
    onReorderItems: (items: TViewPatternItem[]) => void;
    additionalItem?: (
        viewPattern: TViewPattern,
        onCreate: (
            label: string,
            type: TViewPatternItemType,
            type_value: string | number | null
        ) => void
    ) => ReactNode;
}

const ViewPatternItemGroup = ({
    viewPattern,
    viewPatternItemGroup,
    entityFields,
    additionalItem,
    onReorderItems,
}: IViewPatternItemGroup) => {
    const { type, viewPatternItems, allowEdit, items, onSelect, onCreate } =
        useViewPatternItemGroup(viewPattern, viewPatternItemGroup, entityFields ?? []);

    if (additionalItem) {
        items.push({
            label: additionalItem(viewPattern, onCreate),
            key: "additionalItem",
        });
    }
    return (
        <>
            {type === "group" && (
                <div>
                    <div>Колонки</div>
                    <div>
                        <DragAndDropList
                            disabled={!allowEdit}
                            keyName="id"
                            items={viewPatternItems}
                            onChangeItems={onReorderItems}
                            draggableItem={(dragHandleProps, viewPatternItem, index) => (
                                <div
                                    key={viewPatternItem.id}
                                    style={{ marginRight: 2 }}
                                    {...dragHandleProps}
                                >
                                    <ViewPatternItem
                                        onReorderItems={onReorderItems}
                                        viewPattern={viewPattern}
                                        viewPatternItem={viewPatternItem}
                                        entityFields={[]}
                                    />
                                </div>
                            )}
                        />
                    </div>
                    {allowEdit && (
                        <Dropdown
                            menu={{
                                items,
                                onClick: onSelect,
                            }}
                            trigger={["click"]}
                            overlayStyle={{ borderRadius: 8,maxHeight:400,overflow: "auto" }}
                            className="custom-dropdown"
                        >
                            <Button type="link" size="small" icon={<PlusOutlined />}>
                                Добавить колонку
                            </Button>
                        </Dropdown>
                    )}
                </div>
            )}
        </>
    );
};

export default ViewPatternItemGroup;
