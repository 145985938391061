import { CardWithHead } from "@shared/ui/Cards/CardWithHead";
import { Skeleton, Space } from "antd";

const FormSkeletonItem = () => {
    return (
        <div
            style={{ background: "white", borderRadius: "8px", width: 500, height: 128 }}
        >
            <CardWithHead
                children={
                    <>
                        <Space
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: 10,
                            }}
                        >
                            <Skeleton
                                title={false}
                                paragraph={{ rows: 1, width: [200] }}
                            />
                            <Skeleton
                                title={false}
                                paragraph={{ rows: 1, width: [100] }}
                            />
                        </Space>
                        <Space
                            style={{
                                display: "flex",
                                marginBottom: 30,
                            }}
                        >
                            <Skeleton
                                title={false}
                                paragraph={{ rows: 1, width: [100] }}
                            />
                            <Skeleton title={false} paragraph={{ rows: 1, width: 50 }} />
                        </Space>
                        <Space
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Skeleton
                                title={false}
                                paragraph={{ rows: 1, width: [200] }}
                            />
                            <Skeleton title={false} paragraph={{ rows: 1, width: 120 }} />
                        </Space>
                    </>
                }
                noColor
            />
        </div>
    );
};

export default FormSkeletonItem;
