import { TextWithLabel } from "@shared/ui/Text";
import "./EntityRowObserverItem.scss";
import { ReactNode } from "react";

interface IEntityRowObserverItem {
    item: TEntityRowObserver & { bizProcName?: string };
    actions?: (item: TEntityRowObserver) => ReactNode;
}

const EntityRowObserverItem = ({ item, actions }: IEntityRowObserverItem) => {
    return (
        <div className="observer-item">
            <TextWithLabel
                editing={false}
                label={"Бизнес-процесс"}
                text={item.bizProcName ?? ""}
            />
            {actions && <div>{actions(item)}</div>}
        </div>
    );
};

export default EntityRowObserverItem;
