import { bizProcStatusColors, bizProcStatusNames } from "../constants";

export const getBizProcStatusName = (status: TBizProcStatus) => {
    return bizProcStatusNames?.[status];
};
export const getBizProcStatusColor = (status: TBizProcStatus) => {
    return bizProcStatusColors[status];
};

export const getUserAgreementString = (status: TBizProcStatus) => {
    switch (status) {
        case "done":
            return "Завершено";
        case "executing":
            return "Ожидает согласования от:";
        case "reject":
            return "Кем отказано:";
        case "approve":
            return "Кем согласовано:";
        case "expired":
            return "Ожидает согласования от:";
        default:
            return "";
    }
};
