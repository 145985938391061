import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateArrByItemId } from "@shared/lib/object-helper";
import { viewPatternBuilder } from "./ViewPatternBuilder";

export interface IViewPattern {
    viewPatterns: TViewPattern[];
    editingViewPattern: TViewPattern | TViewPatternCreating | null;
    getLoading: boolean;
    createLoading: boolean;
    updateLoading: number[];
    currentViewPatternId: { [tabMode: string]: number };
    deleteLoading: number[];
}

const initialState: IViewPattern = {
    editingViewPattern: null,
    currentViewPatternId: {},
    viewPatterns: [],
    getLoading: false,
    createLoading: false,
    updateLoading: [],
    deleteLoading: [],
};

export const viewPatternSlice = createSlice({
    name: "ViewPatternSlice",
    initialState,
    reducers: {
        setViewPatterns: (state, action: PayloadAction<TViewPattern[]>) => {
            state.viewPatterns = action.payload;
        },
        setEditingViewPattern: (
            state,
            action: PayloadAction<TViewPattern | TViewPatternCreating | null>
        ) => {
            state.editingViewPattern = action.payload;
        },
        setCurrentViewPatternId: (
            state,
            action: PayloadAction<{
                tabMode: string;
                id: number;
            }>
        ) => {
            state.currentViewPatternId[action.payload.tabMode] = action.payload.id;
        },
        updateViewPattern: (state, action: PayloadAction<TViewPattern>) => {
            state.viewPatterns = updateArrByItemId(state.viewPatterns, action.payload);
        },
        deleteViewPattern: (state, action: PayloadAction<TViewPattern>) => {
            state.viewPatterns = state.viewPatterns.filter(
                (item) => item.id !== action.payload.id
            );
        },
    },
    extraReducers: (builder) => {
        viewPatternBuilder(builder);
    },
});

export const {
    setEditingViewPattern,
    setViewPatterns,
    updateViewPattern,
    deleteViewPattern,
    setCurrentViewPatternId,
} = viewPatternSlice.actions;
export default viewPatternSlice.reducer;
