import { CardWithHead } from "@shared/ui/Cards/CardWithHead";
import { Skeleton } from "antd";
import React from "react";

const VariableItemSkeleton = () => {
    return (
        <div style={{ background: "white", borderRadius: "8px" }}>
            <CardWithHead
                children={
                    <Skeleton
                        title={false}
                        active
                        paragraph={{ rows: 4, width: [141, 250, 110, 110] }}
                    />
                }
                noColor
            />
        </div>
    );
};

export default VariableItemSkeleton;
