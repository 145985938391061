import { hidedActionsToSelect } from "./constant";

export const getDropdownItemsFromActions = (actions: TAction[]) => {
    return actions
        .filter((action) => !hidedActionsToSelect.includes(action.type))
        .map((action) => ({
            key: action.id,
            label: action.name,
        }));
};
