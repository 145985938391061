import { ReactNode } from "react";
import Access from "./Access";

interface ICreateAccess {
    children: ReactNode;
    creatorUserId?: number | string | null;
    userId: number | string;
}

const CreateAccess = ({ children, userId, creatorUserId }: ICreateAccess) => {
    return (
        <Access userId={userId} creatorUserId={creatorUserId} type="create">
            {children}
        </Access>
    );
};

export default CreateAccess;
