import {
    estimationValuesTypesWithOptions,
    summableEntityFieldsOptions,
} from "./constants";

export const checkAccessForEditDeleteTemplate = (
    user: TUser | undefined,
    created_by_id: number | null
): boolean => {
    if (created_by_id === user?.id || user?.isAdmin) return true;
    return false;
};

export const getCreatingEstimationTemplate = (
    entityUuid: string,
    created_by_id: number
): TCreatingEstimationTemplate => {
    return {
        entity_uuid: entityUuid,
        name: "",
        is_public: false,
        is_default: false,
        created_by_id: created_by_id,
        users: [],
        sum_view_fields: [],
    };
};

export const getEstimationStorageKey = (entityId: number) => {
    return `${entityId}_estimation-tempalate`;
};

export const getSummableEntityFieldsOptions = (entityFields: TEntityField[]) => {
    return entityFields
        .filter(({ datatype }) => summableEntityFieldsOptions.includes(datatype))
        .map((entityField) => ({
            label: entityField.name,
            value: entityField.key,
            entityField,
        }));
};

export const isEstimationValueTypeWithOptions = (datatype: string) =>
    estimationValuesTypesWithOptions.includes(datatype);

export const getAdditionalSumStorageKey = (entityId: number) =>
    `additionalSum_${entityId}`;
export const getAdditionalCalculationStorageKey = (entityId: number) =>
    `additionalSum_${entityId}_isCalculate`;
