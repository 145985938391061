import { ReactNode } from "react";
import "./StageCardItem.scss";
import { contrastColor } from "@shared/lib/colors";
interface IStageCardItem {
    categoryId: number;
    entityId: number;
    dragHandleProps?: any;
    stage: TStage;
    actions?: (stage: TStage, entityId: number, categoryId: number) => ReactNode;
}

const StageCardItem = ({
    categoryId,
    entityId,
    dragHandleProps,
    stage,
    actions,
}: IStageCardItem) => {
    return (
        <div
            {...(dragHandleProps ?? {})}
            className="stage-card-item"
            style={{
                backgroundColor: stage.background_color,
            }}
        >
            <div style={{ color: contrastColor(stage.background_color) }}>
                {stage.label}
            </div>

            {actions && <div> {actions(stage, entityId, categoryId)}</div>}
        </div>
    );
};

export default StageCardItem;
