import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useStageCreate } from "../model";
import StageCreateUpdateModal from "./StageCreateUpdateModal";

interface IStageCreateButton {
    entityId: number;
    categoryId: number;
    stage: TStageCreating;
}

const StageCreateButton = ({ entityId, categoryId, stage }: IStageCreateButton) => {
    const { isOpen, onOpen, onCancel } = useStageCreate();
    return (
        <>
            <Button
                icon={<PlusOutlined />}
                onClick={onOpen}
                shape="circle"
                type="link"
                style={{ border: "none", boxShadow: "none" }}
            >
                Создать стадию
            </Button>
            <StageCreateUpdateModal
                stage={stage}
                entityId={entityId}
                categoryId={categoryId}
                onCancel={onCancel}
                isOpen={isOpen}
            />
        </>
    );
};

export default StageCreateButton;
