import { Button, Modal } from "antd";
import { TInputField } from "../FilterKitTypes";
import FilterInputItems from "./FilterInputItems";

interface IFilterModal {
    open: boolean;
    fields: TInputField[];
    onReset: () => void;
    onClose: () => void;
    onSubmit: () => void;
    onChange: (name: string, value: any, skipSendRequest?: boolean) => void;
}

const FilterModal = ({
    open,
    fields,
    onClose,
    onSubmit,
    onReset,
    onChange,
}: IFilterModal) => {
    const onChangeSkippedRequest = (name: string, value: any) => {
        onChange(name, value, true);
    };

    return (
        <Modal
            destroyOnClose
            onCancel={onClose}
            open={open}
            footer={[]}
            width={"80%"}
            title={"Настройки фильтров"}
        >
            <div className="filter-fields--modal">
                <div className="filter-fields__body--modal">
                    {fields.map((field: TInputField, index: number) => {
                        return (
                            <div key={index} className="filter-fields__item">
                                <FilterInputItems
                                    field={field}
                                    onChange={onChangeSkippedRequest}
                                />
                            </div>
                        );
                    })}
                    <div className="filter-fields__item">
                        <Button type="text" style={{ marginTop: 4 }} onClick={onReset}>
                            Сбросить фильтры
                        </Button>
                        <Button
                            type="primary"
                            style={{ marginTop: 4 }}
                            onClick={() => {
                                onSubmit();
                                onClose();
                            }}
                        >
                            Применить
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FilterModal;
