import { techMapTemplateOperationBuilder } from "./Operation/TechMapTemplateOperationBuilder";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateArrByItemId } from "@shared/lib/object-helper";
import { techMapTemplateBuilder } from "./Template/TechMapTemplateBuilder";
import { techMapTemplateResultBuilder } from "./Result/TechMapTemplateResultBuilder";

export interface ITechMapTemplate {
    techMapTemplates: TTechMapTemplate[];
    techMapTemplate: TTechMapTemplateFull | TTechMapTemplateCreating | null;
    techMapTemplateResults: TTechMapTemplateResult[];
    techMapTemplateOperations: (
        | TTechMapTemplateOperation
        | TTechMapTemplateOperationCreating
    )[];
    getLoading: boolean;
    createLoading: boolean;
    updateLoading: number[];
    deleteLoading: number[];
    getOneLoading: number[];
    getResultLoading: boolean;
    createResultLoading: boolean;
    updateResultLoading: number[];
    deleteResultLoading: number[];
    getOneResultLoading: number[];
    getOperationLoading: boolean;
    createOperationLoading: boolean;
    updateOperationLoading: string[];
    deleteOperationLoading: string[];
    getOneOperationLoading: string[];
}

const initialState: ITechMapTemplate = {
    techMapTemplates: [],
    techMapTemplate: null,
    techMapTemplateResults: [],
    techMapTemplateOperations: [],
    getLoading: false,
    createLoading: false,
    updateLoading: [],
    deleteLoading: [],
    getOneLoading: [],
    getOperationLoading: false,
    createOperationLoading: false,
    updateOperationLoading: [],
    deleteOperationLoading: [],
    getOneOperationLoading: [],
    getResultLoading: false,
    createResultLoading: false,
    updateResultLoading: [],
    deleteResultLoading: [],
    getOneResultLoading: [],
};

export const techMapTemplateSlice = createSlice({
    name: "TechMapTemplateSlice",
    initialState,
    reducers: {
        setTechMapTemplates: (state, action: PayloadAction<TTechMapTemplate[]>) => {
            state.techMapTemplates = action.payload;
        },
        setTechMapTmplOperations: (
            state,
            action: PayloadAction<
                (TTechMapTemplateOperation | TTechMapTemplateOperationCreating)[]
            >
        ) => {
            state.techMapTemplateOperations = action.payload;
        },
        setTechMapTemplate: (
            state,
            action: PayloadAction<TTechMapTemplateFull | TTechMapTemplateCreating | null>
        ) => {
            state.techMapTemplate = action.payload;
        },
        updateTechMapTemplate: (
            state,
            action: PayloadAction<
                NotRequired<TTechMapTemplateFull | TTechMapTemplateCreating>
            >
        ) => {
            if (state.techMapTemplate) {
                const newItem = state.techMapTemplate as any;
                Object.keys(action.payload as any).forEach((key) => {
                    newItem[key] = (action.payload as any)[key];
                });
                state.techMapTemplate = newItem;
            }
        },
        updateTechMapTemplates: (state, action: PayloadAction<TTechMapTemplate>) => {
            state.techMapTemplates = updateArrByItemId(
                state.techMapTemplates,
                action.payload
            );
        },
        deleteTechMapTemplate: (state, action: PayloadAction<TTechMapTemplate>) => {
            state.techMapTemplates = state.techMapTemplates.filter(
                (item) => item.id !== action.payload.id
            );
        },
    },
    extraReducers: (builder) => {
        techMapTemplateBuilder(builder);
        techMapTemplateResultBuilder(builder);
        techMapTemplateOperationBuilder(builder);
    },
});

export const {
    setTechMapTemplate,
    setTechMapTmplOperations,
    setTechMapTemplates,
    updateTechMapTemplate,
    deleteTechMapTemplate,
} = techMapTemplateSlice.actions;
export default techMapTemplateSlice.reducer;
