import { ComparisonTypeSelect } from "@features/Portal/FieldsTypes/ComparisonTypeSelect";
import {
    DatatypeCreateButton,
    DatatypeModifyModal,
} from "@features/Portal/FieldsTypes/DatatypeEditForm";
import { DatatypesList } from "@features/Portal/FieldsTypes/DatatypesList";
import { FormItem } from "@shared/ui/Form";

const DatatypesPage = () => {
    return (
        <div>
            <DatatypeCreateButton />
            <DatatypesList />
            <DatatypeModifyModal
                extraDatatypeInputs={(
                    datatype: TDatatype | TCreatingDatatype | null,
                    onChangeDatatype: (datatype: TDatatype | TCreatingDatatype) => void
                ) => {
                    return (
                        <>
                            {datatype && (
                                <FormItem label="Операторы сравнения">
                                    <ComparisonTypeSelect
                                        values={(datatype?.comparison_types ?? []).map(
                                            (item) => item.id
                                        )}
                                        onChange={(_, option) =>
                                            onChangeDatatype({
                                                ...datatype,
                                                comparison_types: (
                                                    option as TComparisonOption[]
                                                ).map((item) => item.item),
                                            })
                                        }
                                    />
                                </FormItem>
                            )}
                        </>
                    );
                }}
            />
        </div>
    );
};

export default DatatypesPage;
