import { apiUrl } from "@shared/api/api";
import { PaginatedSelect } from "@shared/ui/PaginatedSelect";

interface ISelectCategoryInput {}

interface ISelectCategoryInput {
    value: string;
    onChangeValue: (value: string | null) => void;
    onlyRead?: boolean;
    additionalParameters: TCategotyAdditionalParameters;
    field: TEntityField;
}

const SelectCategoryInput = ({
    value,
    onChangeValue,
    additionalParameters,
    onlyRead,
    field,
}: ISelectCategoryInput) => {
    const isIncludeFilter = additionalParameters?.isIncludeFilter;

    return (
        <PaginatedSelect
            valueToString
            onlyRead={onlyRead}
            multiple={field.is_multiple || isIncludeFilter}
            requestUrl={`${apiUrl}/api/entity-uuid/${field.entity_uuid}/categories`}
            value={value}
            valueKey="id"
            labelKey="label"
            onChangeValue={onChangeValue}
            initialOptions={additionalParameters?.options ?? []}
        />
    );
};

export default SelectCategoryInput;
