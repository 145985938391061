import { apiUrl } from "@shared/api/api";
import { PaginatedSelect } from "@shared/ui/PaginatedSelect";
import { EntitySelectCustomReadValue } from "./EntityCustomReadValue";

interface ISelectEntityListInput {}

interface ISelectEntityListInput {
    value: string;
    onChangeValue: (value: string) => void;
    onlyRead?: boolean;
    additionalParameters: TSelectAdditionalParameters;
    field: TEntityField | TVariable;
    readValueTextStyle: any;
}

const SelectEntityListInput = ({
    value,
    onChangeValue,
    additionalParameters,
    onlyRead,
    readValueTextStyle,
    field,
}: ISelectEntityListInput) => {
    const valueKey = field.key_value ?? "id";
    const labelKey = field.key_label ?? "name";
    const isIncludeFilter = additionalParameters?.isIncludeFilter;
    const isCard = additionalParameters?.isCard;

    return (
        <PaginatedSelect
            readValueTextStyle={readValueTextStyle}
            valueToString={!field.is_multiple}
            multiple={field.is_multiple || isIncludeFilter}
            requestUrl={`${apiUrl}/api/entity/${field.related_entity_uuid}/options?keyValue=${valueKey}&keyLabel=${labelKey}`}
            value={value}
            params={additionalParameters?.params}
            valueKey={valueKey}
            onlyRead={onlyRead}
            selectLoading={additionalParameters?.loading}
            labelKey={labelKey}
            customReadValue={
                additionalParameters?.entityTableName
                    ? (props) => (
                          <EntitySelectCustomReadValue
                              readValueTextStyle={readValueTextStyle}
                              isCard={isCard}
                              renderEntity={additionalParameters.entityTableName}
                              nameField={additionalParameters.nameField}
                              descriptionFields={additionalParameters.descriptionFields}
                              value={props.value}
                              options={props.options}
                          />
                      )
                    : undefined
            }
            onChangeValue={onChangeValue}
            initialOptions={additionalParameters?.options ?? []}
        />
    );
};

export default SelectEntityListInput;
