import { actionModel } from "@entities/Portal/Action";
import { bizProcTemplateModel } from "@entities/Portal/BizProcTemplate";
import { BizProcTemplateEditor } from "@features/Portal/BizProcTemplate/BizProcTemplateEditor";
import { useHeaderLayout } from "@shared/ui/Header";
import { useSelector } from "react-redux";

interface IBizProcEditorCreatePage {}

const BizProcEditorCreatePage = ({}: IBizProcEditorCreatePage) => {
    const bizProcTemplateItem = useSelector(
        bizProcTemplateModel.selectEditingBizProcTemplateItem
    );

    useHeaderLayout({
        title: "Создание бизнес-процесса",
    });
    actionModel.useLoadActions(true);

    if (bizProcTemplateItem === null) return <></>;

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <BizProcTemplateEditor initialBizProcTemplateItem={bizProcTemplateItem} />
        </div>
    );
};

export default BizProcEditorCreatePage;
