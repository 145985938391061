import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Popconfirm } from "antd";
import { useDeleteCapabilitiesRolesCell } from "../model";

interface ICapabilitiesRolesTableDeleteCell {
    entityCapabilitiesRolesId: number;
    entityRowCapabiitiesByRole: TCapabilitiesRolesRow;
}

const DeleteCapabilitiesRoles = ({
    entityCapabilitiesRolesId,
    entityRowCapabiitiesByRole,
}: ICapabilitiesRolesTableDeleteCell) => {
    const { onDelete, isLoading } = useDeleteCapabilitiesRolesCell(
        entityCapabilitiesRolesId,
        entityRowCapabiitiesByRole
    );

    return (
        <Col
            span={1}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Popconfirm
                placement="topRight"
                title="Удалить сущность?"
                okText="Удалить"
                cancelText="Отмена"
                onConfirm={onDelete}
            >
                <Button
                    loading={isLoading}
                    disabled={isLoading}
                    icon={<DeleteOutlined />}
                    danger
                    type="link"
                />
            </Popconfirm>
        </Col>
    );
};

export default DeleteCapabilitiesRoles;
