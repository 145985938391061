import { $api } from "@shared/api/api";
const routeTemplate = "/api/production/tm-template";
const route = "/api/production/technical-map";
const routeOperation = "/api/production/tm-operation";
export const getExecutableTmts = () => {
    return $api.get<TResponseList<TTechMapTemplateExecuble>>(
        `${routeTemplate}/executable`
    );
};

export const getStartedOperation = (tmoId: number) => {
    return $api.get<TResponse<TTechMapOperationStarted>>(`${routeOperation}/${tmoId}`);
};
export const getTechMaps = () => {
    return $api.get<TResponseList<TTechMapStarted>>(`${route}`);
};
export const getTechMapsFoReview = () => {
    return $api.get<TResponseList<TTechMapStarted>>(`${route}/reviewable`);
};
export const getStartedTms = (userId: number) => {
    return $api.get<TResponseList<TTechMap>>(`${route}`, {
        params: {
            filter: {
                condition_type: "AND",
                conditions: [
                    {
                        key: "user_id",
                        condition: "=",
                        value: userId,
                    },
                    {
                        key: "status",
                        condition: "=",
                        value: `in_progress`,
                    },
                ],
            },
        },
    });
};
export const getTm = (tmId: number) => {
    return $api.get<TResponse<TTechMapStarted>>(`${route}/${tmId}`);
};
export const getTmtOperations = (tmtUuid: string) => {
    return $api.get<TResponseList<TTechMapStarted>>(
        `/api/production/tmt-operation/by-uuid/${tmtUuid}`
    );
};
export const startTmt = (tmtId: number) => {
    return $api.post<TResponse<TTechMapStarted>>(`${routeTemplate}/${tmtId}/start`);
};
export const attachItem = (oId: number, value: string) => {
    return $api.post<TResponse<TTechMapOperationItem>>(
        `${routeOperation}/${oId}/item/attach`,
        {
            value,
        }
    );
};

export const nextOperation = (tmoId: number) => {
    return $api.post<TResponseList<TTechMapOperationStarted>>(
        `${routeOperation}/${tmoId}/next`
    );
};
export const finishTm = (tmId: number) => {
    return $api.post<TResponse<TTechMapStarted>>(`${route}/${tmId}/finish`);
};
export const approveTm = (tmId: number, decision: TDecision) => {
    return $api.post<TResponse<TTechMapStarted>>(`${route}/${tmId}/review`, { decision });
};
