import { techMapModel } from "@entities/Production/TechMap";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useTMActions = () => {
    const startedTm = useSelector(techMapModel.selectStartedTm);
    const startedOperation = useSelector(techMapModel.selectStartedOperation);
    const nextLoading = useSelector(techMapModel.selectNextOperationLoading);
    const templateItems = startedOperation?.template_operation.necessary_items ?? [];
    const operationItems = startedOperation?.operation_items ?? [];
    const isLast = startedOperation?.template_operation?.child_operations?.length === 0;
    const isFilled =
        startedOperation &&
        templateItems.filter((item) => item.type === "filling").length ===
            operationItems.length;
    const dispatch = useDispatch<AppDispatch>();

    const onClickNext = () => {
        if (!isFilled) {
            notificationEmit({
                type: "error",
                title: "Не заполнены необходимые компоненты",
            });
            return;
        }
        if (!startedOperation || !startedTm) {
            notificationEmit({
                type: "warning",
                title: "Не выбран процесс!",
            });
            return;
        }
        dispatch(
            !isLast
                ? techMapModel.nextOperationThunk({
                      tmoId: startedOperation.id,
                  })
                : techMapModel.finishTmThunk({
                      tmId: startedTm.id,
                  })
        );
    };
    return { nextLoading, isFilled, isLast, onClickNext };
};
