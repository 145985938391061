import { Empty, Spin } from "antd";
import { ReactNode } from "react";
import "./CardWithScroll.scss";
interface ICardWithScroll {
    header: string;
    loading: boolean;
    children: ReactNode;
    containerStyle?: React.CSSProperties;
    isEmpty: boolean;
}

const CardWithScroll = ({
    loading,
    isEmpty,
    containerStyle,
    children,
    header,
}: ICardWithScroll) => {
    return (
        <div style={containerStyle} className="card-list">
            <div className="card-list__header">{header}</div>
            <div className="card-list__items">
                {loading ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <Spin />
                    </div>
                ) : isEmpty ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <Empty />
                    </div>
                ) : (
                    <>{children}</>
                )}
            </div>
        </div>
    );
};

export default CardWithScroll;
