import { userModel } from "@entities/Portal/User";
import { ERP_LOCATION_PATTERN } from "@shared/constants";
import { openDrawer } from "@shared/ui/DrawerManager";
import { useSelector } from "react-redux";

export const useTablePageErpLoaded = (entity: TEntity) => {
    const userId = useSelector(userModel.selectUserId);
    const openCloseDrawerWithNavigate = (
        isOpenDrawer: boolean,
        entityRowId?: number | null,
        initialValues?: any
    ) => {
        openDrawer({
            id: entityRowId,
            name: entity?.table_name,
            pattern: ERP_LOCATION_PATTERN,
            additional: { initialValues },
        });
    };

    return {
        userId,
        openCloseDrawerWithNavigate,
    };
};
