import { useEffect, useState } from "react";
interface IUseActionItem {
    action: TAction;
    setIsEditing?: (state: boolean) => void;
    onDelete: (action: TAction) => void;
    onCloseCustom?: (action: TAction) => void;
    onSave: (action: TAction) => void;
}
export const UseActionItem = ({
    action,
    setIsEditing,
    onCloseCustom,
    onDelete,
    onSave,
}: IUseActionItem) => {
    const [name, setName] = useState(action.name);
    const [type, setType] = useState(action.type);
    const [parameters, setParameters] = useState(action.parameters);
    useEffect(() => {
        setName(action.name);
        setType(action.type);
        setParameters(action.parameters);
    }, [action.parameters, action.name, action.type]);
    const onEdit = () => {
        setIsEditing && setIsEditing(true);
    };
    const onClose = () => {
        if (onCloseCustom) {
            onCloseCustom(action);
            return;
        }

        setIsEditing && setIsEditing(false);
        setName(action.name);
        setType(action.type);
        setParameters(action.parameters);
    };
    const onChangeName = (value: string) => {
        setName(value);
    };
    const onChangeParameters = (value: any) => {
        setParameters(value);
    };
    const onChangeType = (value: string) => {
        setType(value as any);
    };

    const onSubmit = () => {
        onSave({
            ...action,
            name,
            parameters,
            type,
        });
        setIsEditing && setIsEditing(false);
    };
    const onSubmitDelete = () => {
        onDelete(action);
    };

    return {
        name,
        type,
        parameters,
        onChangeName,
        onChangeParameters,
        onChangeType,
        onSubmit,
        onEdit,
        onClose,
        onSubmitDelete,
    };
};
