import { entityApi } from "@entities/Portal/Entity";
import { entityFieldApi } from "@entities/Portal/EntityField";
import { entityRowModel } from "@entities/Portal/EntityRow";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getEntityRowFromFields, prepareEntityFieldsWithValues } from "./lib";

interface IUseCreateEditEntityRow {
    entityId?: number;
    entityTableName?: string;
    entityRowId?: number | null;
    onlyReadMode: boolean;
    initialValues?: { [key: string]: any } | null;
    setOnlyReadMode?: (item: boolean) => void;
    additionalActions?: () => void;
}

export const useCreateEditEntityRow = ({
    entityId,
    entityTableName,
    entityRowId,
    initialValues,
    onlyReadMode,
    additionalActions,
    setOnlyReadMode,
}: IUseCreateEditEntityRow) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [isDisabledActions, setIsDisabledActions] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const [entity, setEntity] = useState<TEntity | null>(null);

    const [editingEntityRow, setEditingEntityRow] = useState<any>(initialValues ?? {});
    const [entityRow, setEntityRow] = useState<any>(initialValues ?? {});
    const [editableEntityFieldsWithValues, setEditableEntityFieldsWithValues] = useState<
        TEntityField[]
    >([]);
    const [entityFieldsWithValues, setEntityFieldsWithValues] = useState<
        TEntityFieldWithValue[]
    >([]);
    const [readeableEntityFieldsWithValues, setReadableEntityFieldsWithValues] = useState<
        TEntityField[]
    >([]);
    const [entityRowCapabilities, setEntityRowCapabilities] =
        useState<TEntityRowCapabilities>({
            delete: false,
            update: false,
        });
    const fields = useMemo(() => {
        return prepareEntityFieldsWithValues(
            editingEntityRow,
            onlyReadMode,
            entityFieldsWithValues,
            editableEntityFieldsWithValues,
            readeableEntityFieldsWithValues
        );
    }, [
        editingEntityRow,
        onlyReadMode,
        editableEntityFieldsWithValues,
        readeableEntityFieldsWithValues,
    ]);

    const creatorId = fields?.find((field: any) => field.key === "created_by_id")?.value;

    const dispatch = useDispatch<AppDispatch>();

    const getEntityRowFieldsByCapabilities = async (
        entityRowId: number | null | undefined,
        entityId: number
    ) => {
        if (entityRowId) {
            const getEditingFields = entityApi.getEntityFieldsForUpdateByCapabilities(
                entityId,
                entityRowId
            );
            const getReadableFields = entityApi.getEntityFieldsForReadByCapabilities(
                entityId,
                entityRowId
            );

            const [editingResponse, readingResponse] = await Promise.all([
                getEditingFields,
                getReadableFields,
            ]);
            setReadableEntityFieldsWithValues(readingResponse.data.data);
            setEditableEntityFieldsWithValues(editingResponse.data.data);
            return;
        }
        const response = await entityApi.getEntityFieldsForCreateByCapabilities(entityId);
        setEditableEntityFieldsWithValues(response.data.data);
    };
    const getEntityFieldsWithValues = async (
        entityRowId: number | null | undefined,
        entityUuid: string
    ) => {
        const response = entityRowId
            ? await entityFieldApi.getEntityRowWithEntityFields(entityUuid, entityRowId)
            : await entityFieldApi.getEntityFieldsWithValues(entityUuid);

        const entityFields = entityRowId
            ? (response.data.data as TEntityRowResponse).entityRowWithFields
            : (response.data.data as TEntityFieldWithValue[]);

        setEntityRowCapabilities(
            entityRowId
                ? (response.data.data as TEntityRowResponse).capabilities
                : {
                      update: true,
                      delete: true,
                  }
        );
        setEntityFieldsWithValues(entityFields);
        const entityRow = getEntityRowFromFields(entityFields, initialValues);
        setEntityRow(entityRow);
        setEditingEntityRow(entityRow);
    };
    useEffect(() => {
        if (entityId || entityTableName) {
            setIsLoading(true);
            (entityId
                ? entityApi.getEntity(entityId)
                : entityApi.getEntityByTableName(entityTableName!)
            )
                .then(async (response) => {
                    const entity = response.data.data;
                    setEntity(entity);
                    try {
                        await getEntityRowFieldsByCapabilities(entityRowId, entity.id);
                        await getEntityFieldsWithValues(entityRowId, entity.uuid);
                    } catch (e) {
                        setError(e);
                        notificationEmit({
                            type: "error",
                            title: "Ошибка загрузки полей сущности",
                        });
                    }
                })
                .catch((e: any) => {
                    setError(e);
                    notificationEmit({
                        type: "error",
                        title: "Ошибка загрузки сущности",
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }

        return () => {
            setEditingEntityRow({});
            setError(null);
            setOnlyReadMode && setOnlyReadMode(true);
        };
    }, [entityRowId]);

    const onChangeFormFields = (fields: TBizProcFormField[], id?: string) => {
        const newValue = fields.reduce((prev, current) => {
            return {
                ...prev,
                [current.key]: current.value,
            };
        }, {});
        setEditingEntityRow(newValue);
    };

    const onChangeField = (value: any, inputId: string | number | undefined) => {
        onChangeFormFields(
            fields?.map((field: TBizProcFormField) =>
                field.unique_id === inputId
                    ? {
                          ...field,
                          value: value ?? null,
                      }
                    : field
            ),
            inputId?.toString()
        );
    };

    const onSave = () => {
        if (entity?.id) {
            setIsLoadingSubmit(true);
            const newEntityRow = {
                ...editingEntityRow,
                id: entityRowId,
            };
            entityApi
                .saveEntityRowItem(entity.id, newEntityRow)
                .then(() => {
                    notificationEmit({
                        type: "success",
                        title: entityRowId
                            ? "Запись успешно изменена"
                            : "Запись успешно создана",
                    });
                    setEntityRow(newEntityRow);
                    setOnlyReadMode && setOnlyReadMode(true);
                    if (!entityRowId) additionalActions && additionalActions();
                })
                .catch((e: any) => {
                    notificationEmit({
                        type: "error",
                        title: "Ошибка",
                        description: `Не удалось ${entityRowId ? "изменить" : "создать"} запись сущности`,
                    });
                })
                .finally(() => {
                    setIsLoadingSubmit(false);
                });
        }
    };

    const onCancel = () => {
        setEditingEntityRow(entityRow);
        setOnlyReadMode && setOnlyReadMode(true);
    };

    const onDelete = () => {
        if (!!entityRowId && entity && entity.id) {
            setIsLoadingDelete(true);
            dispatch(
                entityRowModel.deleteEntityRowThunk({ entityId: entity.id, entityRowId })
            )
                .then(() => {
                    additionalActions && additionalActions();
                })
                .catch((e: any) => {
                    notificationEmit({
                        type: "error",
                        title: "Ошибка",
                        description: "Не удалость удалить запись сущности",
                    });
                })
                .finally(() => {
                    setIsLoadingDelete(false);
                });
        }
    };

    const onChangeEntityRow = (entityRow: any) => {
        setEditingEntityRow(entityRow);
        setEntityRow(entityRow);
    };

    return {
        isLoading,
        isLoadingSubmit,
        isLoadingDelete,
        isDisabledActions,
        error,
        entity,
        fields,
        creatorId,
        entityRowCapabilities,
        editingEntityRow,
        entityRow,
        editableEntityFieldsWithValues,
        onChangeEntityRow,
        setIsDisabledActions,
        onChangeFormFields: onChangeField,
        onSave,
        onCancel,
        onDelete,
    };
};
