import { ThemeConfig } from "antd";

export const theme: ThemeConfig = {
    token: {
        borderRadius: 6,
        colorPrimary: "#8F85E8",
        colorBorder:"#eeeeee",
        colorInfo: "#8F85E8",
        fontFamily: "Golos Text",
        // colorPrimaryText: "red",
        // colorPrimaryBorder: "red",
        colorLink: "#8F85E8",
        // colorPrimaryActive: "#81A9B0",
    },
};
