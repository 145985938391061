import { useEffect, useState } from "react";
import {
    getTechMapTemplateOperation,
    getTechMapTemplateOperationByUuid,
} from "../../api/api-tmt-operation";
import { getTechMapTmplOperationMock } from "../../lib";

interface IUseTechMapTmplOperationLoadorMock {
    operationId: number | null | undefined;
    operationUuid: string | null | undefined;
    tmtUuid: string | null;
    parentUuid: string | null;
}
export const useTechMapTmplOperationLoadorMock = ({
    operationId,
    operationUuid,
    tmtUuid,
    parentUuid,
}: IUseTechMapTmplOperationLoadorMock) => {
    const [loading, setLoading] = useState(false);
    const [operation, setOperation] = useState<
        TTechMapTemplateOperationFull | TTechMapTemplateOperationCreating | null
    >(
        operationId || operationUuid
            ? null
            : getTechMapTmplOperationMock(tmtUuid, parentUuid)
    );

    const loadOperation = (
        operationId: number | null | undefined,
        operationUuid: string | null | undefined
    ) => {
        setLoading(true);
        (operationId
            ? getTechMapTemplateOperation(operationId)
            : getTechMapTemplateOperationByUuid(operationUuid!)
        )
            .then((response) => {
                setLoading(false);
                setOperation(response.data.data);
            })
            .catch(() => {});
    };

    useEffect(() => {
        if (operationId || operationUuid) {
            loadOperation(operationId, operationUuid);
        }
        return () => {};
    }, [operationId]);

    return {
        operation,
        loading,
    };
};
