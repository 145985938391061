import { getStageBackgroundColor } from "../lib";
import { useStagesEntityRow } from "../model";
import StageEntityRowFinalStageModal from "./StageEntityRowFinalStageModal";
import StageEntityRowItem from "./StageEntityRowItem";
import "./StagesEntityRow.scss";
import StagesEntityRowSkeleton from "./StagesEntityRowSkeleton";
interface IStagesEntityRow {
    categoryId: number;
    entityId: number;
    withMargins?: boolean;
    onlyRead?: boolean;
    entityRow: any;
    onChangeEntityRow?: (entityRow: any) => void;
}

const StagesEntityRow = ({
    categoryId,
    entityId,
    withMargins,
    entityRow,
    onlyRead,
    onChangeEntityRow,
}: IStagesEntityRow) => {
    const {
        loading,
        stages,
        finalStages,
        notAllowed,
        currentStage,
        hoveredIndex,
        hoveredStage,
        submitLoading,
        currentStageIndex,
        isOpen,
        onEnter,
        onClickStage,
        onClickEndStage,
        onLeave,
        onClose,
    } = useStagesEntityRow({
        categoryId,
        entityId,
        entityRow,
        onlyRead,
        onChangeEntityRow,
    });
    if (notAllowed) return <></>;

    return (
        <div>
            <StageEntityRowFinalStageModal
                isOpen={isOpen}
                onClose={onClose}
                finalStages={finalStages}
                onClickStage={onClickStage}
            />
            <div
                className="stages-entity-row"
                style={{
                    margin: withMargins ? "15px 0px" : undefined,
                }}
            >
                {loading ? (
                    <StagesEntityRowSkeleton />
                ) : (
                    stages.map((stage, index, fullArr) => {
                        return (
                            <div
                                onMouseEnter={() => !onlyRead && onEnter(stage, index)}
                                onMouseLeave={() => onLeave()}
                                key={stage.id}
                                style={{ width: "100%" }}
                            >
                                <StageEntityRowItem
                                    loading={submitLoading === stage.id}
                                    onClickStage={(stage) =>
                                        fullArr.length === index + 1
                                            ? onClickEndStage(stage.id)
                                            : onClickStage(stage.id)
                                    }
                                    stage={stage}
                                    specificColor={getStageBackgroundColor(
                                        index,
                                        stage,
                                        hoveredIndex,
                                        hoveredStage,
                                        currentStage,
                                        currentStageIndex
                                    )}
                                />
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};

export default StagesEntityRow;
