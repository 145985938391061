import { Card } from "antd";
import { usePortalSettingsForm } from "../model";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import PortalSettingsFormItem from "./PortalSettingsFormItem";

interface IPortalSettingsForm {}

const PortalSettingsForm = ({}: IPortalSettingsForm) => {
    const { portalSettings } = usePortalSettingsForm();

    return (
        <Card style={{ width: "fit-content" }}>
            {portalSettings.map((portalSetting) => {
                return (
                    <IndentContainer key={portalSetting.id}>
                        <PortalSettingsFormItem portalSetting={portalSetting} />
                    </IndentContainer>
                );
            })}
        </Card>
    );
};

export default PortalSettingsForm;
