import { techMapTemplateModel } from "@entities/Production/TechMapTemplate";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { messageEmit } from "@shared/ui/NotificationAndMessage/model/emits";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useTechMapTmlEditorSubmit = (
    setInitialTMT?: (tmt: TTechMapTemplate) => void,
    initialTMT?: TTechMapTemplateFull | TTechMapTemplateCreating
) => {
    const techMapTemplate = useSelector(techMapTemplateModel.selectTechMapTemplate);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const isUpdating = techMapTemplate && "id" in techMapTemplate;

    const submitLoading = useSelector(
        isUpdating
            ? (state) =>
                  techMapTemplateModel.selectUpdateLoading(state, techMapTemplate!.id)
            : techMapTemplateModel.selectCreateLoading
    );
    const updateTemplate = (techMapTemplate: TTechMapTemplateReq) => {
        dispatch(techMapTemplateModel.updateTechMapTemplateThunk({ techMapTemplate }))
            .then(parseFormResponse)
            .then(({ data }: { data: TTechMapTemplate }) => {
                messageEmit({
                    type: "success",
                    content: "Технологическая карта успешно обновлена",
                });
                setInitialTMT && setInitialTMT(data);
            })
            .catch(() => {});
    };
    const createTemplate = (techMapTemplate: TTechMapTemplateReq) => {
        dispatch(techMapTemplateModel.createTechMapTemplateThunk({ techMapTemplate }))
            .then(parseFormResponse)
            .then(({ data }: { data: TTechMapTemplate }) => {
                setInitialTMT && setInitialTMT(data);
                navigate(`/tech-map-editor/${data.id}`);
                messageEmit({
                    type: "success",
                    content: "Технологическая карта успешно создана",
                });
            })
            .catch(() => {});
    };
    const onSubmit = () => {
        if (techMapTemplate) {
            const submitingTemplate: TTechMapTemplateReq = {
                ...techMapTemplate,
                reviewer_role_ids: techMapTemplate.reviewer_roles.map((role) => role.id),
                executor_role_ids: techMapTemplate.executor_roles.map((role) => role.id),
            };
            if (isUpdating) updateTemplate(submitingTemplate);
            else createTemplate(submitingTemplate);
        }
    };

    return { submitLoading, isUpdating, onSubmit };
};
