import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { updateArrByItemId } from "@shared/lib/object-helper";
import {
    createDatatypesThunk,
    deleteDatatypesThunk,
    loadDatatypesThunk,
    updateDatatypesThunk,
} from "./DatatypeThunk";
import { IDatatypeSlice } from "./DatatypeSlice";

const loadBuilder = (builder: ActionReducerMapBuilder<IDatatypeSlice>) => {
    builder.addCase(loadDatatypesThunk.fulfilled, (state, action) => {
        state.datatypes = action.payload.data;
        localStorage.setItem("datatypesLength", state.datatypes.length.toString());
        // state.isLoading = false;
    });
    builder.addCase(loadDatatypesThunk.pending, (state) => {
        // state.isLoading = true;
        // state.error = null;
    });
    builder.addCase(loadDatatypesThunk.rejected, (state, rejectedValue) => {
        // state.error = rejectedValue;
        // state.isLoading = false;
        // state.error = rejectedValue;
        // state.isLoading = false;
    });
};

const createBuilder = (builder: ActionReducerMapBuilder<IDatatypeSlice>) => {
    builder.addCase(createDatatypesThunk.fulfilled, (state, action) => {
        state.datatypes.unshift(action.payload.data);
        localStorage.setItem("datatypesLength", state.datatypes.length.toString());
    });
};

const updateBuilder = (builder: ActionReducerMapBuilder<IDatatypeSlice>) => {
    builder.addCase(updateDatatypesThunk.fulfilled, (state, action) => {
        state.datatypes = updateArrByItemId(state.datatypes, action.payload.data);
    });
};
const deleteBuilder = (builder: ActionReducerMapBuilder<IDatatypeSlice>) => {
    builder.addCase(deleteDatatypesThunk.fulfilled, (state, action) => {
        state.datatypes = state.datatypes.filter(
            (item) => item.id !== action.payload.data.id
        );
        localStorage.setItem("datatypesLength", state.datatypes.length.toString());
    });
};

export const datatypesBuilders = (builder: ActionReducerMapBuilder<IDatatypeSlice>) => {
    loadBuilder(builder);
    createBuilder(builder);
    updateBuilder(builder);
    deleteBuilder(builder);
};
