import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
} from "@ant-design/icons";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { ErrorText } from "@shared/ui/Text";
import { Button, Input, Space } from "antd";
import { useOptionItem } from "../model";

interface IOptionItem {
    fieldId: number;
    option: TFieldOption;
    type: TFieldOptionType;
}

const OptionItem = ({ option, fieldId, type }: IOptionItem) => {
    const {
        value,
        label,
        error,
        loading,
        editing,
        deleteLoading,
        onChangeValue,
        onChangeLabel,
        onClickEdit,
        onClickClose,
        onAddOrCreate,
        onClickDelete,
    } = useOptionItem({
        option,
        fieldId,
        type,
    });

    return (
        <FlexContainer>
            <Space
                style={{
                    alignItems: "flex-end",
                    marginBottom: 5,
                }}
            >
                <div>
                    <Input
                        style={{ color: editing ? "black" : undefined }}
                        disabled={!editing}
                        value={label}
                        onChange={onChangeLabel}
                    />
                    <ErrorText absolute text={error.label} />
                </div>
                <div>
                    <Input
                        style={{ color: editing ? "black" : undefined }}
                        disabled={!editing}
                        value={value}
                        onChange={onChangeValue}
                    />
                    <ErrorText absolute text={error.value} />
                </div>
                <Button
                    onClick={editing ? onClickClose : onClickDelete}
                    type="link"
                    loading={deleteLoading}
                    icon={editing ? <CloseOutlined /> : <DeleteOutlined />}
                    danger={!editing}
                />
                <Button
                    loading={loading}
                    onClick={editing ? onAddOrCreate : onClickEdit}
                    type="link"
                    style={{
                        color: editing ? "green" : undefined,
                    }}
                    icon={editing ? <CheckOutlined /> : <EditOutlined />}
                />
            </Space>
        </FlexContainer>
    );
};

export default OptionItem;
