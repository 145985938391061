import { $api } from "@shared/api/api";
const route = "/api/biz_proc";

export const getBizProcs = (params: TParams) => {
    return $api.get<TResponseList<TGroupedBizProc>>(`${route}`, {
        params: { ...params, grouped: 1 },
    });
};
export const getBizProcCounters = () => {
    return $api.get<TResponse<TBizProcCounters>>(`${route}/counters`);
};
export const getBizProcsAgreements = (props: {
    isMy: boolean;
    bizProcTemplateId?: number;
    grouped?: boolean;
    filter?: any;
}) => {
    return $api.get<
        TResponseList<
            (TBizProcTemplateItem & { biz_proc_actions: TBizProcAction[] }) | any
        >
    >(
        `${route}/agreements?grouped=${props.grouped === undefined ? "1" : props.grouped ? "1" : "0"}&my=${props.isMy ? 1 : 0} `,
        {
            params: {
                bizProcTemplateId: props.bizProcTemplateId,
                filter: props.filter,
            },
        }
    );
};
export const getBizProcsForms = (props: {
    isMy?: boolean;
    bizProcTemplateId?: number;
}) => {
    return $api.get<
        TResponseList<TBizProcTemplateItem & { biz_proc_actions: TBizProcAction[] }>
    >(`${route}/forms?grouped=1&my=${props.isMy ? 1 : 0}`, {
        params: props.bizProcTemplateId
            ? {
                  bizProcTemplateId: props.bizProcTemplateId,
              }
            : {},
    });
};
export const getBizProcUserActions = (params: any) => {
    return $api.get<TResponseList<TBizProcAction>>(`api/biz_proc_actions`, {
        params,
    });
};
export const getBizProcVariablesValues = (
    bizProcId: number,
    fields: TBizProcTemplateActionField[]
) => {
    return $api.post<TResponseList<TBizProcFormField>>(
        `${route}/${bizProcId}/add-values`,
        {
            fields,
        }
    );
};
export const getBizProcFormVariablesValues = (
    bizProcId: number,
    fields: TBizProcFormVariable[]
) => {
    return $api.post<TResponseList<TBizProcFormVariable>>(
        `${route}/${bizProcId}/add-form-values`,
        {
            fields,
        }
    );
};
export const approveBizProcAgreement = (
    agreementId: number,
    status: "reject" | "approve",
    parameters: any
) => {
    return $api.post<TResponseList<TBizProcAction>>(
        `${route}/agreements/${agreementId}`,
        {
            status,
            parameters,
        }
    );
};
export const submitBizProcForm = (
    formId: number,
    fields: TBizProcFormField[],
    onlySave?: boolean
) => {
    return $api.post<TResponse<TBizProcAction & { biz_proc_template_id: number }>>(
        `${route}/forms/submit/${formId}`,
        {
            fields,
            onlySave,
        }
    );
};
export const getBizProc = (id: number, params?: any) => {
    return $api.get<TResponse<TBizProc>>(`${route}/${id}`, { params });
};
export const updateBizProcVariables = (id: number, fields: TBizProcFormField[]) => {
    return $api.post<TResponse<TBizProc>>(`${route}/${id}/variables`, {
        fields,
    });
};

export const updateBizProc = (bizProc: TBizProc) => {
    return $api.put<TResponse<TBizProc>>(`${route}/${bizProc.id}`, bizProc);
};
export const startBizProc = (bizProcTemplateId: number, entityRowId?: number) => {
    return $api.post<TResponse<TBizProcTemplateItem>>(
        `${route}/start/${bizProcTemplateId}${entityRowId ? `?entityRowId=${entityRowId}` : ""}`
    );
};
export const deleteBizProc = (id: number) => {
    return $api.delete<TResponse<TBizProc>>(`${route}/${id}`);
};
export const goToBizProcActions = (id: number) => {
    return $api.post<TResponse<TBizProc>>(`${route}_actions/${id}/goto`);
};
