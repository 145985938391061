export const getNewNotificationAction = (
    name: string,
    key: string
): TBizProcTemplateAction => {
    return {
        action: "NotificationAction",
        key: key,
        name: name,
        parameters: {},
        children: [],
    };
};
export const getNewDefaultAction = (
    acton: TBizProcActionType,
    name: string,
    key: string,
    parameters?: any
): TBizProcTemplateAction => {
    return {
        action: acton,
        key: key,
        name: name,
        parameters: parameters ? { ...parameters } : {},
        children: [],
    };
};
export const getNewEmailMessageAction = (
    name: string,
    key: string
): TBizProcTemplateAction => {
    return {
        action: "EmailMessageAction",
        key: key,
        name: name,
        parameters: {},
        children: [],
    };
};
export const getNewAgreementAction = (
    name: string,
    key: string
): TBizProcTemplateAction => {
    return {
        action: "AgreementAction",
        key: key,
        name: name,
        parameters: {},
        children: [
            getNewRejectBranchAction("Отклонено", key + "2", key),
            getNewApproveBranchAction("Согласовано", key + "1", key),
        ],
    };
};
export const getNewIfElseAction = (name: string, key: string): TBizProcTemplateAction => {
    return {
        action: "IfElseAction",
        key: key,
        parameters: {},
        name: name,
        children: [
            getNewIfElseBranchAction("Ветвь Условие", key + "1", key),
            getNewIfElseBranchAction("Ветвь Условие", key + "2", key),
        ],
    };
};
export const getNewIfElseBranchAction = (
    name: string,
    key: string,
    parent_key?: string
): TBizProcTemplateAction => {
    return {
        action: "IfElseActionBranch",
        key: key,
        parent_key: parent_key ?? null,
        parameters: {},
        name: name,
        children: [],
    };
};

export const getNewParallelAction = (
    name: string,
    key: string
): TBizProcTemplateAction => {
    return {
        action: "ParallelAction",
        key: key,
        parameters: {},
        name: name,
        children: [
            getNewParallelBranchAction("Ветвь Параллельное выполнение", key + "1", key),
            getNewParallelBranchAction("Ветвь Параллельное выполнение", key + "2", key),
        ],
    };
};
export const getNewParallelBranchAction = (
    name: string,
    key: string,
    parent_key?: string
): TBizProcTemplateAction => {
    return {
        action: "ParallelBranchAction",
        key: key,
        parent_key: parent_key ?? null,
        parameters: {},
        name: name,
        children: [],
    };
};

export const getNewApproveBranchAction = (
    name: string,
    key: string,
    parent_key?: string
): TBizProcTemplateAction => {
    return {
        action: "ApproveBranchAction",
        key: key,
        parent_key: parent_key ?? null,
        parameters: {},
        name: name,
        children: [],
    };
};
export const getNewRejectBranchAction = (
    name: string,
    key: string,
    parent_key?: string
): TBizProcTemplateAction => {
    return {
        action: "RejectBranchAction",
        key: key,
        parent_key: parent_key ?? null,
        parameters: {},
        name: name,
        children: [],
    };
};

export const getNotFoundedAction = (
    action: TBizProcActionType,
    key: string
): TBizProcTemplateAction => {
    return {
        action: action,
        key: key,
        parameters: {},
        name: action,
        children: [],
    };
};
