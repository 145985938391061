import { ImportOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { useImport } from "./model";

interface IImportActionsButton {}

const ImportActionsButton = ({}: IImportActionsButton) => {
    const { onImport, isLoading } = useImport();

    return (
        <Upload fileList={[]} customRequest={onImport}>
            <Button icon={<ImportOutlined />} loading={isLoading}>
                Импорт
            </Button>
        </Upload>
    );
};

export default ImportActionsButton;
