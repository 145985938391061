import { CardWithHead } from "@shared/ui/Cards/CardWithHead";
import { Skeleton } from "antd";
import "./TechMapTemplateListItem.scss";

interface ITechMapTemplateListItemSkeleton {
    count: number;
}

const TechMapTemplateListItemSkeleton = ({ count }: ITechMapTemplateListItemSkeleton) => {
    const arrayWithItems = Array.from(Array(count).keys());
    return (
        <>
            {arrayWithItems.map((item) => {
                return (
                    <div className="item-list-card max-w-4" key={item}>
                        <CardWithHead
                            children={
                                <Skeleton
                                    title={false}
                                    active
                                    paragraph={{
                                        rows: 2,
                                        width: ["100%", "50%"],
                                    }}
                                />
                            }
                            noColor
                        />
                    </div>
                );
            })}
        </>
    );
};

export default TechMapTemplateListItemSkeleton;
