import { Button } from "antd";
import { useTMActions } from "../model";

interface ITMActions {}

const TMActions = ({}: ITMActions) => {
    const { isFilled, nextLoading, isLast, onClickNext } = useTMActions();
    return (
        <div>
            <Button
                loading={nextLoading}
                disabled={!isFilled}
                onClick={onClickNext}
                type="primary"
            >
                {isLast ? "Завершить" : "Далее"}
            </Button>
        </div>
    );
};

export default TMActions;
