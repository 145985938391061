import { TechMapTemplateList } from "@features/Production/TechMapTemplateFeatures/TechMapTemplateList";
import { useHeaderLayout } from "@shared/ui/Header";

interface ITechMapsEditorPage {}

const TechMapsEditorPage = ({}: ITechMapsEditorPage) => {
    useHeaderLayout({
        title: "Редактор технологических карт",
    });

    return (
        <div>
            <TechMapTemplateList />
        </div>
    );
};

export default TechMapsEditorPage;
