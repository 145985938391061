import { TMList } from "@features/Production/TechMapFeatures/TMList";

interface IProducationStatsPage {}

const ProducationStatsPage = ({}: IProducationStatsPage) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
            }}
        >
            <TMList />
        </div>
    );
};

export default ProducationStatsPage;
