import { Button } from "antd";

interface IGenerateQRCodeFooter {
    loading: boolean;
    onOpenDrawer: () => void;
    onCancel: () => void;
    onOk: () => void;
}

const GenerateQRCodeFooter = ({
    loading,
    onOpenDrawer,
    onCancel,
    onOk,
}: IGenerateQRCodeFooter) => {
    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={onOpenDrawer} style={{ marginRight: 8 }}>
                Конфигурация PDF
            </Button>
            <div>
                <Button onClick={onCancel}>Отмена</Button>
                <Button
                    type="primary"
                    loading={loading}
                    disabled={loading}
                    onClick={onOk}
                >
                    ОК
                </Button>
            </div>
        </div>
    );
};

export default GenerateQRCodeFooter;
