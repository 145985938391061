import { TechMapTmlResultTypeSelect } from "@entities/Production/TechMapTemplate";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { useTechMapTemplateResultItem } from "../model";
import { Input, Select } from "antd";
import "./TechMapTemplateResultItem.scss";
import { FormItem } from "@shared/ui/Form";
import { SelectEntityRow } from "@entities/Portal/EntityRow";
interface ITechMapTemplateResultItem {
    entityUuid: string;
    allowMultiple?: boolean;

    entityFieldsOptionsForCodes: TSelectOption<TEntityField>[];
    entityFieldsOptionsForQty: TSelectOption<TEntityField>[];
    item: TTechMapTemplateResult | TTechMapTemplateResultCreating;
    onChange: (item: TTechMapTemplateResult | TTechMapTemplateResultCreating) => void;
}

const TechMapTemplateResultItem = ({
    entityUuid,
    allowMultiple,
    entityFieldsOptionsForCodes,
    entityFieldsOptionsForQty,
    item,
    onChange,
}: ITechMapTemplateResultItem) => {
    const {
        onChangeQuantityKey,
        onChangeQuantity,
        onChangeType,
        onChangeCodeKey,
        onChangeEntityRowId,
    } = useTechMapTemplateResultItem(item, onChange);
    // type: TTechMapTemplateResultType;
    // entity_row_id: number;
    return (
        <div className="tmt-result-item">
            <IndentContainer>
                <FormItem label={<>Тип</>}>
                    <TechMapTmlResultTypeSelect
                        value={item.type}
                        onChange={onChangeType}
                    />
                </FormItem>
            </IndentContainer>
            {item.type === "increment" ? (
                <>
                    <IndentContainer>
                        <FormItem label={<>Запись</>}>
                            <SelectEntityRow
                                byUuid
                                entityInfo={entityUuid}
                                value={item.entity_row_id}
                                onChange={onChangeEntityRowId}
                            />
                        </FormItem>
                    </IndentContainer>
                    <IndentContainer>
                        <FormItem label={<>Количество выпускаемых единиц за раз</>}>
                            <Input value={item.quantity} onChange={onChangeQuantity} />
                        </FormItem>
                    </IndentContainer>
                    <IndentContainer>
                        <FormItem label={<>Поле для расчета количества</>}>
                            <Select
                                style={{ width: "100%" }}
                                onChange={onChangeQuantityKey}
                                value={item.entity_row_quantity_key}
                                options={entityFieldsOptionsForQty}
                            />
                        </FormItem>
                    </IndentContainer>
                </>
            ) : (
                <></>
            )}

            <IndentContainer>
                <FormItem label={<>Поле для сохранения QR/Bar кода</>}>
                    <Select
                        style={{ width: "100%" }}
                        onChange={onChangeCodeKey}
                        value={item.qr_code_field_key}
                        options={entityFieldsOptionsForCodes}
                    />
                </FormItem>
            </IndentContainer>
        </div>
    );
};

export default TechMapTemplateResultItem;
