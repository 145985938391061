import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IViewPattern } from "./ViewPatternSlice";
import {
    createViewPatternThunk,
    deleteViewPatternThunk,
    loadViewPatternsThunk,
    updateViewPatternThunk,
} from "./ViewPatternThunk";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { updateArrByItemId } from "@shared/lib/object-helper";

const viewPatternsLoadBuilder = (builder: ActionReducerMapBuilder<IViewPattern>) => {
    builder.addCase(loadViewPatternsThunk.fulfilled, (state, action) => {
        state.viewPatterns = action.payload.data;
        state.getLoading = false;
    });
    builder.addCase(loadViewPatternsThunk.pending, (state, action) => {
        state.getLoading = true;
    });
    builder.addCase(loadViewPatternsThunk.rejected, (state, rejectedValue) => {
        state.getLoading = false;
        notificationEmit({
            title: "Не удалось загрузить элемент",
            type: "error",
        });
    });
};
const viewPatternCreateBuilder = (builder: ActionReducerMapBuilder<IViewPattern>) => {
    builder.addCase(createViewPatternThunk.fulfilled, (state, action) => {
        state.viewPatterns = [...state.viewPatterns, action.payload.data];
        state.createLoading = false;
    });
    builder.addCase(createViewPatternThunk.pending, (state, action) => {
        state.createLoading = true;
    });
    builder.addCase(createViewPatternThunk.rejected, (state, rejectedValue) => {
        state.createLoading = false;
        notificationEmit({
            title: "Не удалось создать элемент",
            type: "error",
        });
    });
};

const viewPatternUpdateBuilder = (builder: ActionReducerMapBuilder<IViewPattern>) => {
    builder.addCase(updateViewPatternThunk.fulfilled, (state, action) => {
        const id = action.meta.arg.id;
        state.viewPatterns = updateArrByItemId(state.viewPatterns, action.payload.data);
        state.updateLoading = state.updateLoading.filter((item) => item !== id);
    });
    builder.addCase(updateViewPatternThunk.pending, (state, action) => {
        state.updateLoading.push(action.meta.arg.id);
    });
    builder.addCase(updateViewPatternThunk.rejected, (state, rejectedValue) => {
        state.updateLoading = state.updateLoading.filter(
            (item) => item !== rejectedValue.meta.arg.id
        );
        console.log("rejectedValue", rejectedValue);

        notificationEmit({
            title: "Не удалось обновить элемент",
            type: "error",
        });
    });
};

const viewPatternDeleteBuilder = (builder: ActionReducerMapBuilder<IViewPattern>) => {
    builder.addCase(deleteViewPatternThunk.fulfilled, (state, action) => {
        const id = action.meta.arg;
        state.viewPatterns = state.viewPatterns.filter((item) => item.id !== id);

        state.deleteLoading = state.deleteLoading.filter((item) => item !== id);
    });
    builder.addCase(deleteViewPatternThunk.pending, (state, action) => {
        state.deleteLoading.push(action.meta.arg);
    });
    builder.addCase(deleteViewPatternThunk.rejected, (state, rejectedValue) => {
        state.deleteLoading = state.deleteLoading.filter(
            (item) => item !== rejectedValue.meta.arg
        );
        notificationEmit({
            title: "Не удалось удалить элемент",
            type: "error",
        });
    });
};

export const viewPatternBuilder = (builder: ActionReducerMapBuilder<IViewPattern>) => {
    viewPatternsLoadBuilder(builder);
    viewPatternCreateBuilder(builder);
    viewPatternUpdateBuilder(builder);
    viewPatternDeleteBuilder(builder);
};
