import { Input } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../model";

const UserFormItem = () => {
    const user = useSelector(selectUser);

    return (
        <>
            <div style={{ marginBottom: 6 }}>Сотрудник</div>
            <Input value={user.name} disabled />
        </>
    );
};

export default UserFormItem;
