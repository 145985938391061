import { variableModel } from "@entities/Portal/Variables";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useVariableListItem = (initialVariable?: TVariable) => {
    const dispatch = useDispatch<AppDispatch>();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const onDelete = (variable: TVariable) => {
        setDeleteLoading(true);
        dispatch(variableModel.deleteVariableThunk(variable.id)).finally(() => {
            setDeleteLoading(false);
        });
    };

    return {
        deleteLoading,
        onDelete,
    };
};
