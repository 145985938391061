import EditAgreementAction from "./EditActions/EditAgreementAction";
import EditGotoAction from "./EditActions/EditGotoAction";
import { EditChangeValuesAction } from "./EditActions/EditChangeValuesAction";
import EditDelayAction from "./EditActions/EditDelayAction";
import { EditDocumentGenerateAction } from "./EditActions/EditDocumentGenerateAction";
import EditEmailMessageAction from "./EditActions/EditEmailMessageAction";
import EditFormAction from "./EditActions/EditFormAction/EditFormAction";
import { EditIfElseBranchAction } from "./EditActions/EditIfElseBranchAction";
import EditInitialAction from "./EditActions/EditInitialAction";
import EditNotificationAction from "./EditActions/EditNotificationAction";
import EditPortalNotificationAction from "./EditActions/EditPortalNotificationAction";
import EditStartAnotherBizProcAction from "./EditActions/EditStartAnotherBizProcAction";
import EditChangeStageAction from "./EditActions/EditChangeStageAction";
import { EditCreateChildEntityRowAction } from "./EditActions/EditEntityRowActions/EditCreateChildEntityRowAction";
import { EditCreateEntityRowAction } from "./EditActions/EditEntityRowActions/EditCreateEntityRowAction";

interface IBizProcEditAction {
    disabled?: boolean;
    bizProcTemplate: TBizProcTemplateItem;
    templateItem: TBizProcTemplateAction;
    setItem: (templateItem: TBizProcTemplateAction) => void;
    updateItemParameters: (parameters: TBizProcParameters) => void;
}

const BizProcEditAction = ({
    bizProcTemplate,
    templateItem,
    disabled,
    setItem,
    updateItemParameters,
}: IBizProcEditAction) => {
    if (templateItem.action === "EmailMessageAction")
        return (
            <EditEmailMessageAction
                disabled={disabled}
                setItem={setItem}
                bizProcTemplate={bizProcTemplate}
                templateItem={templateItem}
            />
        );
    if (templateItem.action === "PortalNotificationAction")
        return (
            <EditPortalNotificationAction
                disabled={disabled}
                setItem={setItem}
                bizProcTemplate={bizProcTemplate}
                templateItem={templateItem}
            />
        );
    if (templateItem.action === "IfElseActionBranch")
        return (
            <EditIfElseBranchAction
                disabled={disabled}
                updateItemParameters={updateItemParameters}
                setItem={setItem}
                bizProcTemplate={bizProcTemplate}
                templateItem={templateItem}
            />
        );
    if (templateItem.action === "InitialAction")
        return (
            <EditInitialAction
                disabled={disabled}
                updateItemParameters={updateItemParameters}
                setItem={setItem}
                bizProcTemplate={bizProcTemplate}
                templateItem={templateItem}
            />
        );
    if (templateItem.action === "AgreementAction")
        return (
            <EditAgreementAction
                disabled={disabled}
                bizProcTemplate={bizProcTemplate}
                setItem={setItem}
                templateItem={templateItem}
            />
        );
    if (templateItem.action === "FormAction")
        return (
            <EditFormAction
                disabled={disabled}
                bizProcTemplate={bizProcTemplate}
                setItem={setItem}
                templateItem={templateItem}
            />
        );
    if (templateItem.action === "ChangeValuesAction")
        return (
            <EditChangeValuesAction
                disabled={disabled}
                bizProcTemplate={bizProcTemplate}
                setItem={setItem}
                templateItem={templateItem}
            />
        );
    if (templateItem.action === "NotificationAction")
        return (
            <EditNotificationAction
                disabled={disabled}
                setItem={setItem}
                bizProcTemplate={bizProcTemplate}
                templateItem={templateItem}
            />
        );
    if (templateItem.action === "DocumentGenerateAction")
        return (
            <EditDocumentGenerateAction
                disabled={disabled}
                setItem={setItem}
                bizProcTemplate={bizProcTemplate}
                templateItem={templateItem}
            />
        );
    if (templateItem.action === "GotoAction")
        return (
            <EditGotoAction
                disabled={disabled}
                setItem={setItem}
                bizProcTemplate={bizProcTemplate}
                templateItem={templateItem}
            />
        );
    if (templateItem.action === "ChangeStageAction")
        return (
            <EditChangeStageAction
                disabled={disabled}
                setItem={setItem}
                bizProcTemplate={bizProcTemplate}
                templateItem={templateItem}
            />
        );
    if (templateItem.action === "StartAnotherBizProcAction")
        return (
            <EditStartAnotherBizProcAction
                disabled={disabled}
                setItem={setItem}
                bizProcTemplate={bizProcTemplate}
                templateItem={templateItem}
            />
        );
    if (templateItem.action === "DelayAction")
        return (
            <EditDelayAction
                disabled={disabled}
                setItem={setItem}
                bizProcTemplate={bizProcTemplate}
                templateItem={templateItem}
            />
        );
    if (templateItem.action === "CreateChildEntityRowAction")
        return (
            <EditCreateChildEntityRowAction
                disabled={disabled}
                setItem={setItem}
                bizProcTemplate={bizProcTemplate}
                templateItem={{
                    ...templateItem,
                    parameters: Array.isArray(templateItem.parameters) // Приходит пустой массив
                        ? {}
                        : templateItem.parameters,
                }}
            />
        );
    if (templateItem.action === "CreateEntityRowAction")
        return (
            <EditCreateEntityRowAction
                disabled={disabled}
                setItem={setItem}
                bizProcTemplate={bizProcTemplate}
                templateItem={{
                    ...templateItem,
                    parameters: Array.isArray(templateItem.parameters) // Приходит пустой массив
                        ? {}
                        : templateItem.parameters,
                }}
            />
        );
    return <div></div>;
};

export default BizProcEditAction;
