import { FormItem } from "@shared/ui/Form";
import { ErrorText } from "@shared/ui/Text";
import { Input, Modal } from "antd";
import { useUserPasswordModal } from "../model/UseUserPasswordModal";

interface IUserPasswordModal {
    userId: number;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const UserPasswordModal = ({ userId, isOpen, setIsOpen }: IUserPasswordModal) => {
    const {
        password,
        confirmPassword,
        loading,
        passwordError,
        onCancel,
        onSubmit,
        onChangePassword,
        onChangeConfirmPassword,
    } = useUserPasswordModal(userId, isOpen, setIsOpen);
    return (
        <Modal
            okText="Сохранить"
            destroyOnClose
            open={isOpen}
            okButtonProps={{
                loading,
            }}
            onCancel={onCancel}
            onOk={onSubmit}
        >
            <FormItem label="Новый пароль">
                <>
                    <Input
                        value={password}
                        placeholder="Введите пароль"
                        onChange={onChangePassword}
                    />
                    {passwordError && <ErrorText text={passwordError} absolute />}
                </>
            </FormItem>
            <FormItem label="Повтор пароля">
                <>
                    <Input
                        value={confirmPassword}
                        placeholder="Повторите пароль"
                        onChange={onChangeConfirmPassword}
                    />
                    {passwordError && <ErrorText text={passwordError} absolute />}
                </>
            </FormItem>
        </Modal>
    );
};

export default UserPasswordModal;
