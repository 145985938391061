import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useCreateButton } from "../model/UseCreateButton";
import "./DatatypeCreateButton.scss";
interface IDatatypeCreateButton {
    isEmptyPage?: boolean;
}

const DatatypeCreateButton = ({ isEmptyPage }: IDatatypeCreateButton) => {
    const { onClick } = useCreateButton();
    return (
        <div
            className={`field-type-create-button ${
                isEmptyPage ? "field-type-create-button--empty" : ""
            }`}
        >
            <Button
                onClick={onClick}
                icon={<PlusOutlined />}
                style={{ marginBottom: 10 }}
            >
                Создать поле
            </Button>
        </div>
    );
};

export default DatatypeCreateButton;
