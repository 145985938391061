import { bizProcModel } from "@entities/Portal/BizProc";
import { ApproveAgreement } from "@features/Portal/BizProc/ApproveAgreement";
import { BizProcAgreementsList } from "@features/Portal/BizProc/BizProcsAgreementsList";
import { useHeaderLayout } from "@shared/ui/Header";

const MyAgreementPage = () => {
    useHeaderLayout({
        title: "Мои согласования",
    });
    bizProcModel.useLoadMyAgreements(true);

    return (
        <>
            <BizProcAgreementsList isMyAgreements={true} />
            <ApproveAgreement />
        </>
    );
};

export default MyAgreementPage;
