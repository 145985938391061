import { PlusOutlined } from "@ant-design/icons";
import { TechMapTmplMainInfo } from "@features/Production/TechMapTemplateFeatures/TechMapTmplMainInfo";
import { TechMapTmplOperations } from "@features/Production/TechMapTemplateFeatures/TechMapTmplOperations";
import { TechMapTemplateResult } from "@features/Production/TechMapTemplateFeatures/TechMapTmplResult";
import { TechMapTmplRoles } from "@features/Production/TechMapTemplateFeatures/TechMapTmplRoles";
import { TMT_OPERATION_LOCATION_PATTERN } from "@shared/constants";
import { CardWithHead } from "@shared/ui/Cards/CardWithHead";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { openDrawer } from "@shared/ui/DrawerManager";
import { Button, Divider, Space } from "antd";
import { useTechMapTmlEditor } from "../model";
import "./TechMapTmlEditor.scss";
import TechMapTmlEditorSubmit from "./TechMapTmlEditorSubmit";
interface ITechMapTmlEditor {
    techMapTemplateId: number | null;
}

const TechMapTmlEditor = ({ techMapTemplateId }: ITechMapTmlEditor) => {
    const {
        techMapTemplate,

        onChange,
        onChangeTmTResult,
        onCancel,
        setInitialTMT,
    } = useTechMapTmlEditor(techMapTemplateId);

    const onCreateOperation = () => {
        if (!techMapTemplate) return;
        openDrawer({
            pattern: TMT_OPERATION_LOCATION_PATTERN + "/" + techMapTemplateId ?? "create",
            id: null,
            name: "operation",
            additional: {
                tmtId: techMapTemplateId ?? "create",
            },
        });
    };
    if (!techMapTemplate) return <></>;
    return (
        <div>
            <IndentContainer>
                <div className="tech-map-tmp-block">
                    <CardWithHead
                        title={<span>Общая информация</span>}
                        childrenBackgroundColor="white"
                    >
                        <div style={{ height: "max-content", display: "flex" }}>
                            <div style={{ width: "100%", marginRight: 5 }}>
                                <TechMapTmplMainInfo
                                    onChange={onChange}
                                    entityUuid={techMapTemplate.entity_uuid}
                                    isMultiple={techMapTemplate.is_multiple}
                                    isSkippable={techMapTemplate.is_skippable}
                                    name={techMapTemplate.name}
                                />
                            </div>
                            <Divider type="vertical" style={{ height: "auto" }} />
                            <div style={{ width: "100%", marginLeft: 5 }}>
                                <TechMapTmplRoles
                                    reviewerRoles={techMapTemplate.reviewer_roles}
                                    executorRoles={techMapTemplate.executor_roles}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        {techMapTemplate.entity_uuid && (
                            <>
                                <Divider>Результат</Divider>
                                <IndentContainer>
                                    <TechMapTemplateResult
                                        entityUuid={techMapTemplate.entity_uuid}
                                        tmtResults={techMapTemplate.results ?? []}
                                        allowMultiple={techMapTemplate.is_multiple}
                                        onChange={onChangeTmTResult}
                                    />
                                </IndentContainer>
                            </>
                        )}
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Space>
                                <Button onClick={onCancel}>Отменить</Button>
                                <TechMapTmlEditorSubmit setInitialTMT={setInitialTMT} />
                            </Space>
                        </div>
                    </CardWithHead>
                </div>
            </IndentContainer>

            {techMapTemplateId && (
                <CardWithHead
                    title={
                        <div>
                            <span>Операции</span>
                            <Divider type="vertical" style={{ background: "white" }} />
                            <Button
                                onClick={onCreateOperation}
                                type="link"
                                icon={<PlusOutlined />}
                                style={{
                                    color: "white",
                                    margin: 0,
                                    padding: 0,
                                }}
                            >
                                Создать
                            </Button>
                        </div>
                    }
                    childrenBackgroundColor="white"
                >
                    <TechMapTmplOperations tmtId={techMapTemplateId} />
                </CardWithHead>
            )}
        </div>
    );
};

export default TechMapTmlEditor;
