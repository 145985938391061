import { ReactNode } from "react";

interface IFloatingContainer {
    children: ReactNode;
    right?: number;
    top?: number;
    left?: number;
    bottom?: number;
}

const FloatingContainer = ({
    children,
    right,
    top,
    left,
    bottom,
}: IFloatingContainer) => {
    return (
        <div
            onClick={(event: any) => event.stopPropagation()}
            style={{
                position: "absolute",
                right: right ?? 10,
                top: top ?? 8,
                bottom: bottom,
                left: left,
            }}
        >
            {children}
        </div>
    );
};

export default FloatingContainer;
