import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useStageCreate } from "../model";
import StageCreateUpdateModal from "./StageCreateUpdateModal";

interface IStageUpdateButton {
    stage: TStage;
    categoryId: number;
    entityId: number;
}

const StageUpdateButton = ({ stage, entityId, categoryId }: IStageUpdateButton) => {
    const { isOpen, onOpen, onCancel } = useStageCreate();
    return (
        <>
            <Button
                icon={<EditOutlined />}
                onClick={onOpen}
                type="link"
                shape="circle"
                style={{ border: "none", boxShadow: "none" }}
            />
            <StageCreateUpdateModal
                stage={stage}
                entityId={entityId}
                categoryId={categoryId}
                onCancel={onCancel}
                isOpen={isOpen}
            />
        </>
    );
};

export default StageUpdateButton;
