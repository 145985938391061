import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useCreateButton } from "../UseCreateButton";
import "./EntityCreateButton.scss";

interface IEntityCreateButton {
    isEmptyPage?: boolean;
}

const EntityCreateButton = ({ isEmptyPage }: IEntityCreateButton) => {
    const { onClick } = useCreateButton();

    return (
        <div
            className={`entity-create-button ${isEmptyPage ? "entity-create-button--empty" : ""}`}
        >
            <Button onClick={onClick} icon={<PlusOutlined />}>
                Создать сущность
            </Button>
        </div>
    );
};

export default EntityCreateButton;
