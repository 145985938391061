import { useRef } from "react";
import { useEntityFieldsForFilters } from "../model";
import ConfigureFilterInputsModal from "./ConfigureFilterInputsModal";
import CurrentActiveFiltersValues from "./CurrentActiveFiltersValues";
import "./EntityRowsFilters.scss";
import EntityRowsFiltersForm from "./EntityRowsFiltersForm";
import InputTextFitler from "./InputTextFitler";

interface IEntityRowsFilters {
    entityId: number;
}

const EntityRowsFilters = ({ entityId }: IEntityRowsFilters) => {
    const inputFieldRef = useRef<HTMLDivElement | null>(null);
    const topCoordinateOfModal = inputFieldRef.current?.offsetHeight;
    const {
        isOpenConfiguringModal,
        visibleInputs,
        isVisible,
        currentFilters,
        entityFields,
        openConfiguringModal,
        closeConfiguringModal,
        changeEntityFieldsForFilterModalVisibility,
        searchByFilters,
        setCurrentFilters,
        setDefaultFilters,
        resetFilters,
        deleteFilterInput,
        onSubmitFilterFieldsModal,
        onRemoveFiltersByKeys,
    } = useEntityFieldsForFilters(entityId);

    return (
        <div style={{ width: "100%" }}>
            <ConfigureFilterInputsModal
                entityId={entityId}
                entityFields={entityFields}
                currentFilters={currentFilters}
                visibleInputs={visibleInputs}
                isOpenConfiguringModal={isOpenConfiguringModal}
                onSubmitFilterFieldsModal={onSubmitFilterFieldsModal}
                closeConfiguringModal={closeConfiguringModal}
            />
            <div
                ref={inputFieldRef}
                className="entity-value-filter"
                onClick={(event) => {
                    changeEntityFieldsForFilterModalVisibility();
                    event.stopPropagation();
                }}
            >
                <CurrentActiveFiltersValues
                    onRemoveFiltersByKeys={onRemoveFiltersByKeys}
                />
                <div className="entity-value-filter__global-input">
                    <InputTextFitler entityId={entityId} />
                </div>

                <EntityRowsFiltersForm
                    isVisible={isVisible}
                    topCoordinateOfModal={topCoordinateOfModal}
                    visibleInputs={visibleInputs}
                    openConfiguringModal={openConfiguringModal}
                    setCurrentFilters={setCurrentFilters}
                    setDefaultFilters={setDefaultFilters}
                    resetFilters={resetFilters}
                    searchByFilters={searchByFilters}
                    deleteFilterInput={deleteFilterInput}
                />
            </div>
        </div>
    );
};

export default EntityRowsFilters;
