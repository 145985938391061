import { FormItem } from "@shared/ui/Form";
import { DatePicker, Modal, Select } from "antd";
import dayjs from "dayjs";
import { useConfigureSchedulerModal } from "../model";
interface IConfigureSchedulerModal {
    isOpen: boolean;
    entityFields: TEntityField[];
    dateStartKey: string | undefined;
    dateEndKey: string | undefined;
    eventLabel: string | undefined;
    eventDescription: string | undefined;
    viewMode: TScheduleViewMode;
    date?: Date;
    setViewMode: (item: TScheduleViewMode) => void;
    setDate: (item: Date) => void;
    onClose: () => void;
    setDateStartKey: (item: any) => void;
    setDateEndKey: (item: any) => void;
    setEventLabel: (item: any) => void;
    setEventDescription: (item: any) => void;
}

const ConfigureSchedulerModal = ({
    isOpen,
    entityFields,
    dateStartKey,
    dateEndKey,
    eventLabel,
    eventDescription,
    viewMode,
    date,
    setDate,
    onClose,
    setDateStartKey,
    setDateEndKey,
    setEventLabel,
    setEventDescription,
    setViewMode,
}: IConfigureSchedulerModal) => {
    const {
        choosenDateStartKey,
        choosenDateEndKey,
        choosenEventLabel,
        choosenEventDescription,
        choosenDate,
        choosenViewMode,
        entityFieldsOptions,
        dateOptions,
        viewModeOptions,
        onSelectDateStartKey,
        onSelectDateEndKey,
        onSelectEventLabel,
        onSelectEventDescription,
        onSubmit,
        onCancel,
        onChangeFormat,
        onChangeDate,
    } = useConfigureSchedulerModal(
        entityFields,
        dateStartKey,
        dateEndKey,
        eventLabel,
        eventDescription,
        setDateEndKey,
        setDateStartKey,
        setEventLabel,
        setEventDescription,
        onClose,
        date,
        viewMode,
        setViewMode,
        setDate
    );

    const windowWidth = window.innerWidth;

    return (
        <Modal
            destroyOnClose
            title="Конфигуратор календаря"
            open={isOpen}
            onOk={onSubmit}
            onCancel={onCancel}
        >
            {windowWidth < 1420 && (
                <>
                    <FormItem label="Формат календаря">
                        <Select
                            value={choosenViewMode}
                            options={viewModeOptions}
                            onChange={onChangeFormat}
                            placeholder="Формат календаря"
                            style={{ width: "100%" }}
                        />
                    </FormItem>
                    <FormItem
                        label={
                            <>
                                {["day", "agenda"].includes(choosenViewMode) &&
                                    "Выбор даты"}
                                {choosenViewMode === "week" && "Выбор недели"}
                                {choosenViewMode === "month" && "Выбор месяца"}
                                {choosenViewMode === "year" && "Выбор года"}
                            </>
                        }
                    >
                        <DatePicker
                            style={{ width: "100%" }}
                            onChange={onChangeDate}
                            value={dayjs(choosenDate)}
                            picker={
                                choosenViewMode === "agenda" || choosenViewMode === "day"
                                    ? undefined
                                    : choosenViewMode
                            }
                        />
                    </FormItem>
                </>
            )}
            <FormItem label="Выберите поле для начала отсчета времени">
                <Select
                    onChange={onSelectDateStartKey}
                    style={{ width: "100%" }}
                    options={dateOptions}
                    value={choosenDateStartKey}
                    allowClear
                />
            </FormItem>
            <FormItem label="Выберите поле для окончания отсчета времени">
                <Select
                    onChange={onSelectDateEndKey}
                    style={{ width: "100%" }}
                    options={dateOptions}
                    value={choosenDateEndKey}
                    allowClear
                />
            </FormItem>
            <FormItem label="Выберите ключ названия">
                <Select
                    onChange={onSelectEventLabel}
                    style={{ width: "100%" }}
                    options={entityFieldsOptions}
                    value={choosenEventLabel}
                    allowClear
                />
            </FormItem>
            <FormItem label="Выберите ключ описания">
                <Select
                    onChange={onSelectEventDescription}
                    style={{ width: "100%" }}
                    options={entityFieldsOptions}
                    value={choosenEventDescription}
                    allowClear
                />
            </FormItem>
        </Modal>
    );
};

export default ConfigureSchedulerModal;
