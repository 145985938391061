import { apiUrl } from "@shared/api/api";
import { PaginatedSelect } from "@shared/ui/PaginatedSelect";

interface ISelectEntityRow<T> {
    multiple?: boolean;
    onlyRead?: boolean;
    placeholder?: string;
    labelKey?: string;
    value: T | null;
    entityInfo: string;
    byUuid?: boolean;
    initialOptions?: any[];
    onChange: (value: T, entityRow: any) => void;
}

const SelectEntityRow = <T,>({
    multiple,
    onlyRead,
    placeholder,
    value,
    labelKey,
    entityInfo,
    byUuid,
    initialOptions,
    onChange,
}: ISelectEntityRow<T>) => {
    const _onChange = (value: T, option: any) => {
        onChange(
            value,
            Array.isArray(option) ? option.map((option) => option.option) : option
        );
    };
    return (
        <PaginatedSelect
            onlyRead={onlyRead}
            multiple={multiple}
            requestUrl={`${apiUrl}/api/items/${byUuid ? `by-uuid/${entityInfo}` : entityInfo}`}
            value={value}
            placeholder={placeholder}
            valueKey="id"
            labelKey={labelKey ?? "name"}
            onChangeValue={_onChange}
            initialOptions={initialOptions ?? []}
        />
    );
};

export default SelectEntityRow;
