import { useDispatch } from "react-redux";
import { setEditingCalculatedValue } from "./CalculatedValuesSlice";

export const useCalculatedValueItem = (
    calculatedValue: TCalculatedValue,
    onDelete: (calculatedValue: TCalculatedValue) => void
) => {
    const dispatch = useDispatch<AppDispatch>();
    const onSubmitDelete = () => {
        onDelete(calculatedValue);
    };
    const onEdit = () => {
        dispatch(setEditingCalculatedValue(calculatedValue));
    };

    return {
        onEdit,
        onSubmitDelete,
    };
};
