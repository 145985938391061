import { categoryModel } from "@entities/Portal/Category";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useCategoryCreateUpdateForm = (
    category: TCategory | TCategoryCreating,
    entityId: number,
    onSave?: (category: TCategory | TCategoryCreating) => void
) => {
    const [editingCategory, setEditingCategory] = useState(category);
    const createLoading = useSelector(categoryModel.selectCreateLoading);
    const updateLoading = useSelector(categoryModel.selectUpdateLoading);
    const dispatch = useDispatch<AppDispatch>();
    const isCreated = "id" in category;
    const onChangeCategoryLabel = (event: ChangeEvent<HTMLInputElement>) => {
        setEditingCategory({
            ...editingCategory,
            label: event.target.value,
        });
    };
    const onSubmit = () => {
        dispatch(
            isCreated
                ? categoryModel.updateCategoryThunk({
                      entityId,
                      category: editingCategory as TCategory,
                  })
                : categoryModel.createCategoryThunk({
                      entityId,
                      category: editingCategory as TCategoryCreating,
                  })
        )
            .then(parseFormResponse)
            .then(() => {
                onSave && onSave(editingCategory);
            })
            .catch(() => {});
    };
    return {
        loading: createLoading || updateLoading,
        isCreated,
        editingCategory,
        onChangeCategoryLabel,
        onSubmit,
    };
};
