import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Typography } from "antd";
import { useSignInForm } from "./model";
import "./SignInForm.scss";
import SignInFormLogo from "./SignInFormLogo";

const SignInForm = () => {
    const { formRef, error, loading, onSubmit } = useSignInForm();
    return (
        <div className="signin-form">
            <Form
                name="normal_username"
                onFinish={onSubmit}
                initialValues={{ remember: true }}
                ref={formRef}
            >
                <SignInFormLogo />
                <Typography.Title level={2} style={{ textAlign: "center" }}>
                    Вход
                </Typography.Title>

                <Form.Item
                    name="username"
                    validateStatus={error ? "error" : undefined}
                    rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
                    help={error ? "Проверьте правильность данных" : undefined}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="E-mail"
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    validateStatus={error ? "error" : undefined}
                    rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
                    help={error ? "Проверьте правильность данных" : undefined}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Пароль"
                        // value={isPassAvailable ? password : ""}
                    />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Запомнить меня</Checkbox>
                    </Form.Item>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="username-form-button"
                        loading={loading}
                    >
                        Войти
                    </Button>
                    {/* Или{" "}
                    <a onClick={() => history.push("/sign-up")}>
                        зарегистрироваться
                    </a> */}
                </Form.Item>
            </Form>
        </div>
    );
};

export default SignInForm;
