import { $api } from "@shared/api/api";
export const updateFieldOption = (
    FieldId: number,
    FieldOption: TFieldOption,
    type: TFieldOptionType
) => {
    return $api.put<TResponse<TFieldOption>>(
        `/api/${type}/${FieldId}/field-option/${FieldOption.id}`,
        FieldOption
    );
};
export const deleteFieldOption = (
    FieldId: number,
    FieldOptionId: number,
    type: TFieldOptionType
) => {
    return $api.delete<TResponse<TFieldOption>>(
        `/api/${type}/${FieldId}/field-option/${FieldOptionId}`
    );
};
export const createFieldOption = (
    FieldId: number,
    FieldOption: TFieldOption,
    type: TFieldOptionType
) => {
    return $api.post<TResponse<TFieldOption>>(
        `/api/${type}/${FieldId}/field-option`,
        FieldOption
    );
};
export const getFieldOptions = (
    FieldId: number,
    type: TFieldOptionType,
    params?: TParams
) => {
    return $api.get<TResponseList<TFieldOption>>(`/api/${type}/${FieldId}/field-option`, {
        params: params,
    });
};
