import { Spin } from "antd";
import { useTMTBarItem } from "../model";
import "./TMTBar.scss";
import { LoadingOutlined } from "@ant-design/icons";

interface ITMTBarItem {
    item: TTechMapTemplateExecuble;
}

const TMTBarItem = ({ item }: ITMTBarItem) => {
    const { isStartedTmt, startingLoading, onStartTmt } = useTMTBarItem(item);
    return (
        <div
            onClick={onStartTmt}
            className="tmt-bar-item"
            style={{
                backgroundColor:
                    isStartedTmt || startingLoading ? "var(--primary-card)" : undefined,
            }}
        >
            <div className="tmt-bar-item__label">{item.name}</div>
            {startingLoading && (
                <Spin
                    size="small"
                    style={{ marginLeft: 20 }}
                    indicator={<LoadingOutlined />}
                />
            )}
            {isStartedTmt && <span className="tmt-bar-item__active">Активный</span>}
        </div>
    );
};

export default TMTBarItem;
