import { getUnixTimestamp } from "@shared/lib/date";
import { FormItem } from "@shared/ui/Form";
import { ErrorText } from "@shared/ui/Text";
import { Button, Input, Space } from "antd";
import { useOptionItem } from "../model";

interface IAddOptionItem {
    fieldId: number;
    type: TFieldOptionType;
}

const AddOptionItem = ({ fieldId, type }: IAddOptionItem) => {
    const { value, label, error, loading, onChangeValue, onChangeLabel, onAddOrCreate } =
        useOptionItem({
            fieldId,
            option: { label: "", value: `option_${getUnixTimestamp()}` },
            type,
        });
    return (
        <Space
            style={{
                alignItems: "flex-end",
                marginBottom: 15,
            }}
        >
            <FormItem label="Название" style={{ marginBottom: 0 }}>
                <>
                    <Input value={label} onChange={onChangeLabel} />
                    <ErrorText absolute text={error.label} />
                </>
            </FormItem>
            <FormItem label="Значение" style={{ marginBottom: 0 }}>
                <>
                    <Input value={value} onChange={onChangeValue} />
                    <ErrorText absolute text={error.value} />
                </>
            </FormItem>
            <Button loading={loading} onClick={onAddOrCreate}>
                Добавить
            </Button>
        </Space>
    );
};

export default AddOptionItem;
