import { estimationModel } from "@entities/Portal/Estimations";
import { Button, Flex } from "antd";
import { useSelector } from "react-redux";
import { useEstimationTemplateEditActions } from "../model";

interface IEstimationTemplateEditActions {
    estimationTemplate: TEstimationTemplate | TCreatingEstimationTemplate;
    viewMode: TEstimationFormViewMode;
}

const EstimationTemplateEditActions = ({
    estimationTemplate,
    viewMode,
}: IEstimationTemplateEditActions) => {
    const savingLoading = useSelector(estimationModel.selectSavingLoading);
    const { onCancel, onSubmit } = useEstimationTemplateEditActions(estimationTemplate);

    return (
        <Flex justify="end" gap={4}>
            <Button disabled={savingLoading} onClick={onCancel}>
                {viewMode === "viewing" ? "Закрыть" : "Отмена"}
            </Button>
            {viewMode !== "viewing" && (
                <Button onClick={onSubmit} type="primary" loading={savingLoading}>
                    Сохранить
                </Button>
            )}
        </Flex>
    );
};

export default EstimationTemplateEditActions;
