export const dataSimmulation = [
    { link: "43534523", name: "Инван Попов" },
    { link: "4353dsf4523", name: "Петр Васильченко" },
    { link: "43534cvb523", name: "Гаврила Музилкин" },
    { link: "4353fsdf4523", name: "Молекула" },
    { link: "43523421334523", name: "Олег Виноградов" },
    { link: "4354334523", name: "Клавдия Евдокимова" },
    { link: "43534dfsdvcv523", name: "Стульчик Мебельный" },
    { link: "43534rwe523", name: "Клавдия Евдокимова" },
    { link: "435dsfds34523", name: "Олег Виноградов" },
    { link: "4353324124523", name: "Гаврила Музилкин" },
    { link: "4353ret4523", name: "Инван Попов" },
    { link: "4353gdz4523", name: "Vasya dsfdssfsdfsdfsd" },
    { link: "43534rwe523", name: "Инван Попов" },
    { link: "435dsfds34523", name: "Стульчик Мебельный" },
    { link: "4353324124523", name: "Стульчик Мебельный" },
    { link: "4353ret4523", name: "Инван Попов" },
    { link: "4353gdz4523", name: "Клавдия Евдокимова" },
    { link: "43534rwe523", name: "Инван Попов" },
    { link: "435dsfds34523", name: "Клавдия Евдокимова" },
    { link: "4353324124523", name: "Гаврила Музилкин" },
    { link: "4353324124523", name: "Стульчик Мебельный" },
    { link: "4353ret4523", name: "Инван Попов" },
    { link: "4353gdz4523", name: "Клавдия Евдокимова" },
    { link: "43534rwe523", name: "Инван Попов" },
    { link: "435dsfds34523", name: "Клавдия Евдокимова" },
    { link: "4353324124523", name: "Гаврила Музилкин" },
    { link: "4353324124523", name: "Стульчик Мебельный" },
    { link: "4353ret4523", name: "Инван Попов" },
];

export const minimalQrCodeSize = 150;
export const maximumVerticalQrCodeSize = 740;
export const maximumHorizontalQrCodeSize = 540;
