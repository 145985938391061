import { bizProcModel } from "@entities/Portal/BizProc";
import { useDispatch, useSelector } from "react-redux";

export const useUserAgreementsList = () => {
    const { agreements, loading } = bizProcModel.useLoadUserAgreements(true);
    const dispatch = useDispatch();
    const actionsLength = useSelector(bizProcModel.selectUserActionsLength);

    const onClick = (agreement: TBizProcAction<TBizProcAgreementParameters>) => {
        dispatch(
            bizProcModel.setTargetAgreement({
                ...agreement,
                isMy: false,
            })
        );
    };

    return {
        actionsLength,
        loading,
        agreements,
        onClick,
    };
};
