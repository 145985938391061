import { Button, Popconfirm } from "antd";
import "./CreateEditEntityRowActions.scss";

interface ICreateEditEntityRowActions {
    isLoadingSubmit: boolean;
    isLoadingDelete: boolean;
    entityRowId?: number | null;
    entityRowCapabilities: TEntityRowCapabilities;
    creatorId: number | string | null;
    onlyReadMode: boolean;
    setOnlyReadMode: (item: boolean) => void;
    onSave: () => void;
    onCancel: () => void;
    onDelete: () => void;
}

const CreateEditEntityRowActions = ({
    isLoadingSubmit,
    isLoadingDelete,
    entityRowId,
    onlyReadMode,
    entityRowCapabilities,
    setOnlyReadMode,
    onSave,
    onCancel,
    onDelete,
}: ICreateEditEntityRowActions) => {
    const canDelete = entityRowCapabilities.delete;
    const canUpdate = entityRowCapabilities.update;
    return (
        <div className="create-edit-entity-row-container__actions">
            {entityRowId ? (
                <>
                    {canUpdate && (
                        <>
                            <Button
                                style={{ marginRight: 8 }}
                                type="primary"
                                disabled={!onlyReadMode || isLoadingDelete}
                                onClick={() => setOnlyReadMode(false)}
                            >
                                Редактировать
                            </Button>
                            <Button
                                loading={isLoadingSubmit}
                                type="primary"
                                disabled={onlyReadMode || isLoadingDelete}
                                onClick={onSave}
                            >
                                Сохранить
                            </Button>
                            <Button
                                type="link"
                                disabled={
                                    onlyReadMode || isLoadingSubmit || isLoadingDelete
                                }
                                onClick={onCancel}
                            >
                                Отмена
                            </Button>
                        </>
                    )}

                    {canDelete && (
                        <Popconfirm
                            title="Удалить элемент?"
                            okText="Удалить"
                            onConfirm={onDelete}
                            okButtonProps={{
                                danger: true,
                            }}
                            cancelText="Отмена"
                        >
                            <Button
                                danger
                                type="primary"
                                disabled={isLoadingDelete}
                                loading={isLoadingDelete}
                            >
                                Удалить
                            </Button>
                        </Popconfirm>
                    )}
                </>
            ) : (
                <Button type="primary" loading={isLoadingSubmit} onClick={onSave}>
                    Создать
                </Button>
            )}
        </div>
    );
};

export default CreateEditEntityRowActions;
