import { PortalSettingsForm } from "@features/Portal/PortalSettings/PortalSettingsForm";
import { useHeaderLayout } from "@shared/ui/Header";

interface IPortalSettingsPage {}

const PortalSettingsPage = ({}: IPortalSettingsPage) => {
    useHeaderLayout({ title: "Настройки портала" });
    return (
        <div>
            <PortalSettingsForm />
        </div>
    );
};

export default PortalSettingsPage;
