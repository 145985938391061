import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ChangeEvent } from "react";

export const useTechMapTmplMainInfo = (
    onChange: (key: keyof TTechMapTemplate, value: any) => void
) => {
    const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        onChange("name", event.target.value);
    };
    const onChangeEntity = (_: any, option: { option: TEntity } | null) => {
        onChange("entity_uuid", option ? option.option.uuid : null);
    };
    const onChangeIsMultiple = (event: CheckboxChangeEvent) => {
        onChange("is_multiple", event.target.checked);
    };
    const onChangeIsSkippable = (event: CheckboxChangeEvent) => {
        onChange("is_skippable", event.target.checked);
    };
    return { onChangeName, onChangeEntity, onChangeIsMultiple, onChangeIsSkippable };
};
