import { DeleteOutlined, LockOutlined, MenuOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { Button, Checkbox } from "antd";
import FormFieldsListCreator from "./FormFieldsListCreator";
import { useEffect, useState } from "react";
import { entityFieldApi } from "@entities/Portal/EntityField";

interface IFormListCreatorItem {
    dragHandleProps: any;
    onChangeFields: (item: any) => void;
    selectedFields: any;
    valueKey: string;
    labelKey: string;
    disabled?: boolean;
    field: any;
}

const FormListCreatorItem = ({
    dragHandleProps,
    valueKey,
    labelKey,
    field,
    disabled,
    onChangeFields,
    selectedFields,
}: IFormListCreatorItem) => {
    const [entityFields, setEntityFields] = useState<any>([]);
    useEffect(() => {
        if (field.datatype === "entity") {
            entityFieldApi
                .getEntityFields(field.related_entity_uuid)
                .then((response) => {
                    setEntityFields(response.data.data);
                })
                .catch(() => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onDeleteField = () => {
        onChangeFields(
            selectedFields.filter((field_: any) => field_[valueKey] !== field[valueKey])
        );
    };

    const onCliclRequiredCheckbox = (e: CheckboxChangeEvent) => {
        onChangeFields(
            selectedFields.map((field_: any) =>
                field_[valueKey] === field[valueKey]
                    ? {
                          ...field,
                          is_required: e.target.checked,
                      }
                    : field_
            )
        );
    };
    const onCliclOnlyReadCheckbox = (e: CheckboxChangeEvent) => {
        onChangeFields(
            selectedFields.map((field_: any) =>
                field_[valueKey] === field[valueKey]
                    ? {
                          ...field,
                          is_readonly: e.target.checked,
                      }
                    : field_
            )
        );
    };

    const onChangeSelectedFields = (fields: TBizProcTemplateField[]) => {
        onChangeFields(
            selectedFields.map((field_: any) => {
                return field_[valueKey] === field[valueKey]
                    ? {
                          ...field,
                          additional_parameters: {
                              fields: fields.map((field: any, index: number) => {
                                  const createdField =
                                      field_.additional_parameters?.fields?.find(
                                          (innField: any) =>
                                              `${innField.variable_type}-${innField.key}` ===
                                              `${field.variable_type}-${field.key}`
                                      );

                                  return {
                                      unique_id: `${field.variable_type}-${field.key}`,
                                      key: field?.key,
                                      datatype: field?.datatype,
                                      variable_type: field.variable_type,
                                      name: field.name,
                                      is_required: createdField?.is_required ?? false,
                                      is_readonly: createdField?.is_readonly ?? false,
                                  };
                              }),
                          },
                      }
                    : field_;
            })
        );
    };

    const onChangeEntityFields = (fields: TBizProcTemplateActionField[]) => {
        onChangeFields(
            selectedFields.map((field_: any) =>
                field_[valueKey] === field[valueKey]
                    ? {
                          ...field,
                          additional_parameters: {
                              fields: fields.map(
                                  (
                                      field: TBizProcTemplateActionField,
                                      index: number
                                  ) => ({
                                      unique_id: `${field.variable_type}-${field.key}`,
                                      key: field?.key,
                                      variable_type: field.variable_type,
                                      related_entity_uuid: field.related_entity_uuid,
                                      datatype: field?.datatype,
                                      additional_parameters: field?.additional_parameters,
                                      name: field.name,
                                      is_required: field.is_required,
                                      is_readonly: field.is_readonly,
                                  })
                              ),
                          },
                      }
                    : field_
            )
        );
    };

    const isWithEntity = field.datatype === "entity";
    return (
        <div>
            <div className={`fields-list-creator__item`}>
                <MenuOutlined
                    style={{ marginRight: 10, cursor: "grab" }}
                    {...dragHandleProps}
                />

                <div>{field?.[labelKey]}</div>
                <Checkbox
                    disabled={disabled}
                    checked={field.is_readonly}
                    onChange={onCliclOnlyReadCheckbox}
                    style={{ fontWeight: 700 }}
                >
                    <LockOutlined />
                </Checkbox>
                <Checkbox
                    disabled={disabled}
                    checked={field.is_required}
                    onChange={onCliclRequiredCheckbox}
                    style={{ color: "red", fontWeight: 700 }}
                >
                    *
                </Checkbox>
                <Button
                    danger
                    disabled={disabled}
                    onClick={onDeleteField}
                    style={{ padding: 4 }}
                    type="text"
                    icon={<DeleteOutlined />}
                />
            </div>

            {isWithEntity && (
                <div className={isWithEntity ? "field-with-entity" : ""}>
                    <FormFieldsListCreator
                        disabled={disabled}
                        onChangeSelectedFields={onChangeSelectedFields}
                        onChangeFields={onChangeEntityFields}
                        labelKey="name"
                        valueKey="key"
                        selectedFields={field.additional_parameters?.fields ?? []}
                        fields={entityFields ?? []}
                    />
                </div>
            )}
        </div>
    );
};

export default FormListCreatorItem;
