import { entityFieldApi } from "@entities/Portal/EntityField";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useRef, useState } from "react";

export const useCapabilitiesRolesCell = (
    entityCapabilitiesRolesId: number,
    type: TCapType,
    entityId: number | undefined,
    entity_uuid: string,
    onChange: (key: TCapType, value: any) => void,
    isMultiSelectType: boolean | undefined
) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [entityFieldsOptions, setEntityFieldsOptions] = useState<
        { label: string; value: string }[]
    >([]);
    const timer = useRef<any>();

    const onFocus = () => {
        setIsFocused(true);
    };

    const onMouseEnterCell = () => {
        if (timer.current) clearTimeout(timer.current);
    };

    const onMouseLeaveCell = () => {
        timer.current = setTimeout(() => {
            setIsFocused(false);
        }, 600);
    };

    useEffect(() => {
        if (isFocused && entityFieldsOptions.length === 0) {
            setIsLoading(true);
            entityFieldApi
                .getEntityFields(entity_uuid!, entityId)
                .then((response) => {
                    setEntityFieldsOptions(
                        response.data.data.map((entityField) => ({
                            label: entityField.name,
                            value: entityField.key,
                        }))
                    );
                })
                .catch((e) => {
                    console.log(e);
                    notificationEmit({
                        type: "error",
                        title: "Ошибка",
                        description: "Не удалось загрузить поля сущности",
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [isFocused]);

    const onChangeValue = (value: any) => {
        const newValue = isMultiSelectType
            ? value.length > 0
                ? value.join(",")
                : null
            : !!value || value === 0
              ? value
              : null;

        onChange(type, newValue);
    };

    return {
        isLoading,
        isFocused,
        entityFieldsOptions,
        onChangeValue,
        onFocus,
        onMouseLeaveCell,
        onMouseEnterCell,
    };
};
