import { BrowserQRCodeReader } from "@zxing/library";
import { DefaultOptionType } from "antd/es/select";
export const getAccessMediaDevices = (
    setIsAllowed: (item: boolean) => void,
    codeReader: BrowserQRCodeReader,
    setDevices: (item: DefaultOptionType[]) => void,
    setSelectedDeviceId: (item: string) => void
) => {
    navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((result: any) => {
            setIsAllowed(result.active);
        })
        .then(() => {
            codeReader.getVideoInputDevices().then((result: any) => {
                const devicesOptions = [
                    ...result.map((device: any) => ({
                        value: device.deviceId,
                        label: device.label,
                    })),
                ];
                setDevices(devicesOptions);
                setSelectedDeviceId(devicesOptions[0].value);
            });
        })
        .catch(() => {
            setIsAllowed(false);
            setDevices([]);
        });
};
