import ActionCard from "./ActionCard";

interface IIfElseAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const IfElseAction = ({ onDeleteItem, templateItem }: IIfElseAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="silver"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Условие"}
        ></ActionCard>
    );
};

export default IfElseAction;
