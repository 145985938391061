import { useState } from "react";

interface IUseCreateEditEntityRowDrawer {
    openCloseDrawerWithNavigate: (item: boolean, entityRowId?: number | null) => void;
}

export const useCreateEditEntityRowDrawer = ({
    openCloseDrawerWithNavigate,
}: IUseCreateEditEntityRowDrawer) => {
    const [onlyReadMode, setOnlyReadMode] = useState<boolean>(true);
    const onCloseDrawer = () => {
        openCloseDrawerWithNavigate(false);
        setOnlyReadMode(true);
    };

    const afterOpenChange = (open: boolean) => {
        if (!open) {
        }
    };

    return {
        onlyReadMode,
        setOnlyReadMode,
        onCloseDrawer,
        afterOpenChange,
    };
};
