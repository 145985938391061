import { fieldOptionModel } from "@entities/Portal/FieldOption";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { getUnixTimestamp } from "@shared/lib/date";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useDispatch } from "react-redux";

type TValidateError = {
    label?: string;
    value?: string;
};

interface IOptionItem {
    fieldId: number;
    option: TFieldOption;
    type: TFieldOptionType;
}

export const useOptionItem = ({ fieldId, option, type }: IOptionItem) => {
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [label, setLabel] = useState(option.label);
    const [value, setValue] = useState(option.value);
    const [error, setError] = useState<TValidateError>({});
    const dispatch = useDispatch<AppDispatch>();
    const onChangeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLabel(event.target.value);
    };
    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };
    const validateOption = (newOption: TFieldOption) => {
        let error: TValidateError = {
            label: undefined,
            value: undefined,
        };
        if (newOption.label.length === 0) error.label = "Необходимо указать название!";
        if (newOption.value.length === 0) error.value = "Необходимо указать значение!";
        return error;
    };

    const onClickEdit = () => {
        setEditing(true);
    };
    const onClickDelete = () => {
        if (option.id) {
            setDeleteLoading(true);
            dispatch(
                fieldOptionModel.deleteFieldOptionThunk({
                    fieldId,
                    fieldOptionId: option.id,
                    type,
                })
            ).finally(() => {
                setDeleteLoading(false);
            });
        }
    };

    const onClickClose = () => {
        setError({});
        setLabel(option.label);
        setValue(option.value);
        setEditing(false);
    };
    const onAddOrCreate = () => {
        const newOption: TFieldOption = {
            id: option.id ?? undefined,
            label: label.trim(),
            value: value.trim(),
        };
        const errorResult = validateOption(newOption);
        if (errorResult.label || errorResult.value) {
            setError(errorResult);
            return;
        }
        setLoading(true);
        dispatch(
            newOption?.id
                ? fieldOptionModel.updateFieldOptionThunk({
                      fieldId,
                      fieldOption: newOption,
                      type,
                  })
                : fieldOptionModel.createFieldOptionThunk({
                      fieldId,
                      fieldOption: newOption,
                      type,
                  })
        )
            .then(parseFormResponse)
            .then(() => {
                if (!newOption?.id) {
                    setLabel(option.label);
                    setValue(`option_${getUnixTimestamp()}`);
                }
                setEditing(false);
            })
            .catch((error: any) => {
                notificationEmit({
                    error: error,
                    title: error.message ?? "Ошибка",
                    type: "error",
                });
            })
            .finally(() => {
                setError({});
                setLoading(false);
            });
    };
    return {
        label,
        value,
        loading,
        error,
        editing,
        deleteLoading,
        onChangeValue,
        onChangeLabel,
        onAddOrCreate,
        onClickEdit,
        onClickClose,
        onClickDelete,
    };
};
