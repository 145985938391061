import { Button } from "antd";
import { useTechMapTemplateResult } from "../model";
import TechMapTemplateResultItem from "./TechMapTemplateResultItem";

import "./TechMapTemplateResult.scss";
import { DeleteOutlined } from "@ant-design/icons";

interface ITechMapTemplateResult {
    entityUuid: string;
    allowMultiple?: boolean;
    tmtResults: (TTechMapTemplateResult | TTechMapTemplateResultCreating)[];
    onChange: (
        tmtResults: (TTechMapTemplateResult | TTechMapTemplateResultCreating)[]
    ) => void;
}

const TechMapTemplateResult = ({
    entityUuid,
    allowMultiple,
    tmtResults,
    onChange,
}: ITechMapTemplateResult) => {
    const {
        entityFieldsOptionsForQty,
        entityFieldsOptionsForCodes,
        onChangeItem,
        onCreate,
        onDelete,
    } = useTechMapTemplateResult(entityUuid, tmtResults, onChange);

    return (
        <>
            {tmtResults.length === 0 ? (
                <div>
                    <div>Результат выполнения не выбран</div>
                    <Button type="link" onClick={onCreate}>
                        Добавить
                    </Button>
                </div>
            ) : (
                <div className="tmt-results">
                    {tmtResults.map((result) => {
                        return (
                            <div
                                key={"id" in result ? result.id : result.creating}
                                style={{ position: "relative" }}
                            >
                                <div className="floating-card-element">
                                    <Button
                                        onClick={() => onDelete(result)}
                                        style={{ backgroundColor: "white" }}
                                        type="link"
                                        shape="round"
                                        danger
                                        icon={<DeleteOutlined />}
                                    />
                                </div>
                                <TechMapTemplateResultItem
                                    entityUuid={entityUuid}
                                    item={result}
                                    allowMultiple={allowMultiple}
                                    entityFieldsOptionsForQty={entityFieldsOptionsForQty}
                                    entityFieldsOptionsForCodes={
                                        entityFieldsOptionsForCodes
                                    }
                                    onChange={onChangeItem}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default TechMapTemplateResult;
