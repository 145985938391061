import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { techMapTemplateBuilder } from "./TechMapBuilder";

export interface ITechMap {
    executableTmts: TTechMapTemplateExecuble[];
    getExecutableLoading: boolean;
    startedTm: TTechMapStarted | null;
    startedOperation: TTechMapOperationStarted | null;
    startingLoading: number | null;
    getStartedTmsLoading: boolean;
    getStartedOperationLoading: boolean;
    nextOperationLoading: boolean;
    attachItemLoading: boolean;
    techMaps: TTechMapStarted[];
    techMapsForReview: TTechMapStarted[];
    getTMLoading: boolean;
    approveTmLoading: { [id: number]: TDecision };
}

const initialState: ITechMap = {
    executableTmts: [],
    getExecutableLoading: false,
    techMaps: [],
    techMapsForReview: [],
    getStartedTmsLoading: false,
    getStartedOperationLoading: false,
    startingLoading: null,
    nextOperationLoading: false,
    attachItemLoading: false,
    getTMLoading: false,
    startedOperation: null,
    startedTm: null,
    approveTmLoading: {},
};

export const techMapSlice = createSlice({
    name: "TechMapSlice",
    initialState,
    reducers: {
        setGetStartedTmsLoading: (state, action: PayloadAction<boolean>) => {
            state.getStartedTmsLoading = action.payload;
        },
        setGetStartedOperationLoading: (state, action: PayloadAction<boolean>) => {
            state.getStartedOperationLoading = action.payload;
        },
        setStartedTm: (state, action: PayloadAction<TTechMapStarted | null>) => {
            state.startedTm = action.payload;
        },
        setTechMaps: (state, action: PayloadAction<TTechMapStarted[]>) => {
            state.techMaps = action.payload;
        },
        setTechMapsForReview: (state, action: PayloadAction<TTechMapStarted[]>) => {
            state.techMapsForReview = action.payload;
        },
        setGetTMLoading: (state, action: PayloadAction<boolean>) => {
            state.getTMLoading = action.payload;
        },
        setStartedOperation: (
            state,
            action: PayloadAction<TTechMapOperationStarted | null>
        ) => {
            state.startedOperation = action.payload;
        },
    },
    extraReducers: (builder) => {
        techMapTemplateBuilder(builder);
    },
});

export const {
    setStartedTm,
    setTechMaps,
    setGetStartedTmsLoading,
    setStartedOperation,
    setGetTMLoading,
    setTechMapsForReview,
    setGetStartedOperationLoading,
} = techMapSlice.actions;
export default techMapSlice.reducer;
