export const createdEntity: TCreatingEntity = {
    id: undefined,
    name: "",
    table_name: "",
    is_reference: false,
    is_guidebook: false,
    fields: [],
    relations_id: [],
    entities_fields: [],
};
