import { CopyOutlined, QrcodeOutlined } from "@ant-design/icons";

interface IGenerateQRCodePreviewConfig {
    pdfOrientation: "p" | "l";
    qrCodeSize: number;
}

const GenerateQRCodePreviewConfig = ({
    pdfOrientation,
    qrCodeSize,
}: IGenerateQRCodePreviewConfig) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", color: "gray" }}>
            <span>
                <CopyOutlined /> Ориентация:{" "}
                {pdfOrientation === "p" ? "Вертикальная" : "Горизонтальная"}
            </span>
            <span>
                <QrcodeOutlined /> Размер: {qrCodeSize}
            </span>
        </div>
    );
};

export default GenerateQRCodePreviewConfig;
