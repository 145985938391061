import { useFilter } from "@features/Portal/Filters";
import { apiUrl } from "@shared/api/api";
import { FilterKit } from "@shared/ui/Filters";
import { useBizProcFilters } from "./model";

const BizProcFilters = () => {
    const { initialState, isAdmin, request } = useBizProcFilters();
    const { onChange, onReset, values, onSubmit } = useFilter(initialState, request);

    return (
        <FilterKit
            onSubmit={onSubmit}
            onChange={onChange}
            onReset={onReset}
            fields={[
                {
                    type: "input",
                    label: "Название",
                    name: "name",
                    value: values.name,
                },
                {
                    type: "select",
                    options: [
                        { label: "Завершён", value: "done" },
                        { label: "Выполняется", value: "executing" },
                        { label: "Отказано", value: "reject" },
                        { label: "Согласован", value: "approve" },
                        { label: "Просрочен", value: "expired" },
                    ],
                    label: "Статус",
                    name: "status",
                    value: values.status,
                },
                {
                    type: "requestedSelect",
                    label: "Кто запустил",
                    name: "assigned_id",
                    value: values.assigned_id ?? [],
                    requestUrl: `${apiUrl}/api/user/employees`,
                    queryTypedParam: "name",
                    queryChoosenParams: "userIds",
                    valueLabel: "name",
                    hide: !isAdmin,
                    valueKey: "id",
                },
            ]}
        />
    );
};

export default BizProcFilters;
