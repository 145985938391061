import { entityApi } from "@entities/Portal/Entity";
import { useEntityRowCodeParse } from "@features/Portal/EntityRow/EntityRowCodeParse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const useTablePageErp = () => {
    const params = useParams();
    useEntityRowCodeParse();
    const [entity, setEntity] = useState<TEntity>();
    const [loading, setLoading] = useState(false);
    const tableName = params?.tableName ?? "";

    useEffect(() => {
        setLoading(true);
        entityApi
            .getEntityByTableName(tableName)
            .then((response) => {
                setEntity(response.data.data);
            })
            .catch((error) => {
                notificationEmit({
                    error: error,
                    title: error.message ?? "Не удалось загрузить информацию",
                    type: "error",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [tableName]);

    return {
        entity,
        loading,
    };
};
