import { apiUrl } from "@shared/api/api";
import { FormItem } from "@shared/ui/Form";
import { RequestedSelect } from "@shared/ui/RequestedSelect";
import { Checkbox } from "antd";

interface IEditChangeStageAction {
    templateItem: TBizProcTemplateAction;
    bizProcTemplate: TBizProcTemplateItem;
    disabled?: boolean;
    setItem: (templateItem: TBizProcTemplateAction) => void;
}

const EditChangeStageAction = ({
    templateItem,
    setItem,
    disabled,
    bizProcTemplate,
}: IEditChangeStageAction) => {
    const stageUuid = templateItem.parameters?.stageUuid;
    const categoryUuid = templateItem.parameters?.categoryUuid;
    const silentMode = templateItem.parameters?.silent;
    const onSelectStage = (key: number, items: any) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                stageUuid: key,
            },
        });
    };
    const onSelectCategory = (key: number, items: any) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                categoryUuid: key,
                stageUuid: null,
            },
        });
    };
    const onChangeSilentMode = () => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                silent: !templateItem.parameters?.silent,
            },
        });
    };

    return (
        <>
            <FormItem label="Выберите категорию">
                <RequestedSelect
                    disabled={disabled}
                    values={categoryUuid}
                    onSelect={onSelectCategory}
                    requestUrl={`${apiUrl}/api/entity-uuid/${bizProcTemplate?.entity_uuid}/categories`}
                    queryTypedParam="label"
                    queryChoosenParams="uuid"
                    valueLabel="label"
                    valueKey="uuid"
                />
            </FormItem>
            <FormItem label="Выберите стадию">
                <RequestedSelect
                    disabled={disabled}
                    values={stageUuid}
                    onSelect={onSelectStage}
                    requestUrl={`${apiUrl}/api/entity-uuid/${bizProcTemplate?.entity_uuid}/categories-uuid/${categoryUuid}/stages`}
                    queryTypedParam="label"
                    queryChoosenParams="uuid"
                    valueLabel="label"
                    valueKey="uuid"
                />
            </FormItem>
            <Checkbox onChange={onChangeSilentMode} checked={silentMode}>
                Тихое обновление
            </Checkbox>
        </>
    );
};

export default EditChangeStageAction;
