import { categoryApi } from "@entities/Portal/Category";
import { fieldOptionApi } from "@entities/Portal/FieldOption";
import { stageApi } from "@entities/Portal/Stage";
import { useEffect, useState } from "react";
import EstimationValuesListItem from "./EstimationValuesListItem";

const EstimationValuesListItemWithOptions = ({
    templateValue,
    entityId,
}: {
    templateValue: TEntityField & { value: any };
    entityId: number;
}) => {
    const [options, setOptions] = useState<Record<string | number, string>>({});

    useEffect(() => {
        if (templateValue.datatype === "list") {
            fieldOptionApi
                .getFieldOptions(templateValue.id!, "entity-field")
                .then((response) => {
                    const result = response.data.data.reduce<any>((acc, item) => {
                        const newAcc = {
                            ...acc,
                            [item.value]: item.label,
                        };
                        return newAcc;
                    }, {});
                    setOptions({ ...result, "": "Список не выбран" });
                });
        }
        if (templateValue.datatype === "category") {
            categoryApi.getCategories(entityId).then((response) => {
                const result = response.data.data.reduce<any>((acc, item) => {
                    const newAcc = {
                        ...acc,
                        [item.id]: item.label,
                    };
                    return newAcc;
                }, {});
                setOptions({ ...result, "": "Категория не выбрана" });
            });
        }
        if (templateValue.datatype === "stage") {
            stageApi.getStages(entityId, null).then((response) => {
                const result = response.data.data.reduce<any>((acc, item) => {
                    const newAcc = {
                        ...acc,
                        [item.id]: item.label ? item.label : "Стадия не выбрана",
                    };
                    return newAcc;
                }, {});
                setOptions({ ...result, "": "Стадия не выбрана" });
            });
        }
    }, []);

    return (
        <>
            {Object.entries(templateValue.value).map(
                ([key, value]: any, index: number) => {
                    return (
                        <EstimationValuesListItem
                            key={`${index}_${value}`}
                            name={options[key] ?? "-"}
                            value={value}
                        />
                    );
                }
            )}
        </>
    );
};

export default EstimationValuesListItemWithOptions;
