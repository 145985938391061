import { TMT_OPERATION_LOCATION_PATTERN } from "@shared/constants";
import { openDrawer } from "@shared/ui/DrawerManager";

interface ITMTOperationCard {
    operation: TTechMapTemplateOperation;
    tmtId: number;
}

const TMTOperationCard = ({ operation, tmtId }: ITMTOperationCard) => {
    const onClick = () => {
        openDrawer({
            pattern: TMT_OPERATION_LOCATION_PATTERN + "/" + tmtId,
            id: operation.id,
            name: "operation",
            additional: {
                tmtId: tmtId ?? "create",
            },
        });
    };
    return (
        <div className="link" onClick={onClick}>
            {operation.name}
        </div>
    );
};

export default TMTOperationCard;
