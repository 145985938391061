import { $api } from "@shared/api/api";
const route = "/api/action";

export const getActions = () => {
    return $api.get<TResponseList<TAction>>(route);
};
export const createAction = (action: TCreateAction) => {
    return $api.post<TResponse<TAction>>(route, action);
};
export const updateAction = (action: TAction) => {
    return $api.put<TResponse<TAction>>(`${route}/${action.id}`, action);
};
export const deleteAction = (id: number) => {
    return $api.delete<TResponse<TAction>>(`${route}/${id}`);
};
export const exportActions = () => {
    return $api.get<TResponse<TAction>>(`${route}/export`);
};
