import { Button } from "antd";
import { Dispatch } from "react";
import { FillActionFormBuilder } from "../FillActionForm";
import { useBizProcFillForm } from "./model";

interface IBizProcFillForm {
    bizProc: TBizProc;
    setBizProc: Dispatch<SetStateAction<TBizProc | null>>;
}

const BizProcFillForm = ({ bizProc, setBizProc }: IBizProcFillForm) => {
    const {
        onChangeFormFields,
        onSubmit,
        onStartEdit,
        loading,
        fields,
        isEdit,
        targetForm,
    } = useBizProcFillForm(bizProc, setBizProc);
    // console.log('bizProc?.start_biz_proc_action', bizProc?.start_biz_proc_action);
    return (
        <>
            <div className="bizproc-info__fields__inputs">
                {
                    <div className="bizproc-info__fields__inputs__actions">
                        {isEdit ? (
                            <div>
                                <Button
                                    loading={loading === "save"}
                                    type="link"
                                    onClick={() => onSubmit(true)}
                                >
                                    Сохранить
                                </Button>
                                <Button
                                    loading={loading === "next"}
                                    type="primary"
                                    onClick={() => onSubmit(false)}
                                >
                                    Отправить
                                </Button>
                            </div>
                        ) : (
                            <Button type="link" onClick={onStartEdit}>
                                Продолжить заполнение
                            </Button>
                        )}
                    </div>
                }
                <FillActionFormBuilder
                    ignoreUnwritable
                    onlyRead={!isEdit}
                    onChangeFormFields={onChangeFormFields}
                    fields={fields}
                />
            </div>
        </>
    );
};

export default BizProcFillForm;
