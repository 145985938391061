import { message } from "antd";
import { bizProcTemplateModel } from "@entities/Portal/BizProcTemplate";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

export const useBizProcTemplateListItem = (
    initialBizProcTemplateItem: TBizProcTemplateItem,
    onDeleted?: (id: number) => void
) => {
    const dispatch = useDispatch<AppDispatch>();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const navigate = useNavigate();
    const onDelete = () => {
        if (initialBizProcTemplateItem?.is_creating) {
            dispatch(
                bizProcTemplateModel.deleteBizProcEntityTemplateItem(
                    initialBizProcTemplateItem
                )
            );
            return;
        }
        setDeleteLoading(true);
        if (initialBizProcTemplateItem.id)
            dispatch(
                bizProcTemplateModel.deleteBizProcEntityTemplateItemThunk(
                    initialBizProcTemplateItem.id
                )
            )
                .then(parseFormResponse)
                .then(() => {
                    onDeleted && onDeleted(initialBizProcTemplateItem.id!);
                })
                .catch(() => {
                    notificationEmit({
                        type: "error",
                        title: "Не удалось удалить бизнес-процесс",
                    });
                })
                .finally(() => {
                    setDeleteLoading(false);
                });
    };
    const onClick = () => {
        if (initialBizProcTemplateItem?.id)
            navigate(`/bizproc-editor/${initialBizProcTemplateItem?.id}`);
    };

    const onCreate = (bizProcTemplate: TBizProcTemplateItem) => {
        // dispatch(bizProcTemplateModel.createBizProcTemplateItemThunk({
        //     name: bizProcTemplate.name,
        // })).then(parseFormResponse)
        //     .then((data: TResponse<TBizProcTemplateItem>) => {
        //         dispatch(bizProcTemplateModel.deleteBizProcTemplateItem(bizProcTemplate))
        //         setIsEditing(false)
        //     }).catch(setError)
    };
    return {
        onClick,
        deleteLoading,
        onCreate,
        onDelete,
    };
};
