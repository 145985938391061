import { StarFilled, StarOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { usePinEntityToNavigation } from "../model";

interface IPinEntityToNavigation {
    entityId: number;
    entityTableName: string;
    entityName: string;
}

const PinEntityToNavigation = ({
    entityId,
    entityTableName,
    entityName,
}: IPinEntityToNavigation) => {
    const { isPinned, loading, onClick } = usePinEntityToNavigation(
        entityId,
        entityName,
        entityTableName
    );

    return (
        <Button
            loading={loading}
            onClick={onClick}
            type="link"
            icon={isPinned ? <StarFilled /> : <StarOutlined />}
        />
    );
};

export default PinEntityToNavigation;
