import { entityFieldApi } from "@entities/Portal/EntityField";
import { DefaultOptionType } from "antd/es/select";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect, useState } from "react";

export const useDatatypeDependingInputs = (
    editingField: TEntityField | TVariable,
    setEditingField: (editingField: TEntityField | TVariable) => void
) => {
    const [entityFieldsOptions, setEntityFieldsOptions] = useState<
        DefaultOptionType["options"]
    >([]);

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditingField({
            ...editingField,
            [event.target.name]: event.target.value,
        });
    };

    const loadEntityFields = (entityUuid: string) => {
        entityFieldApi.getEntityFields(entityUuid).then((result) => {
            const entityFields = result.data.data;
            setEntityFieldsOptions([
                ...entityFields.map((entityField) => {
                    return {
                        label: entityField.name,
                        value: entityField.key,
                    };
                }),
            ]);
        });
    };
    useEffect(() => {
        if (editingField.related_entity_uuid)
            loadEntityFields(editingField.related_entity_uuid);
    }, []);

    const onSelectEntity = (entityUuid: string) => {
        if (editingField.variable_type === "entity") {
            setEditingField({
                ...editingField,
                related_entity_uuid: entityUuid,
                key_label: null,
                key_value: editingField.datatype === "childEntity" ? "id" : undefined,
                on_create_default: null,
                on_update_default: null,
            });
        } else
            setEditingField({
                ...editingField,
                related_entity_uuid: entityUuid,
                key_label: null,
                key_value: null,
            });
        loadEntityFields(entityUuid);
    };
    const onUpdateIsMultiple = (value: CheckboxChangeEvent) => {
        if (editingField.variable_type === "entity") {
            setEditingField({
                ...editingField,
                is_multiple: value.target.checked,
                // @ts-ignore
                on_create_default: value.target.checked ? [] : null,
                // @ts-ignore
                on_update_default: value.target.checked ? [] : null,
            });
        } else
            setEditingField({
                ...editingField,
                is_multiple: value.target.checked,
            });
    };

    const onSelectEntityField = (name: string, value: string) => {
        if (editingField.variable_type === "entity" && name !== "key_label") {
            setEditingField({
                ...editingField,
                [name]: value,
                on_create_default: null,
                on_update_default: null,
            });
        } else {
            setEditingField({
                ...editingField,
                [name]: value,
            });
        }
    };

    return {
        entityFieldsOptions,
        onChangeInput,
        onSelectEntity,
        onUpdateIsMultiple,
        onSelectEntityField,
    };
};
