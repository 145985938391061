import { Button } from "antd";
import { useCapabilitiesRolesDataController } from "./UseCapabilitiesRolesDataController";

const CapabilitiesRolesDataController = ({
    entityCapabilitiesRolesId,
}: {
    entityCapabilitiesRolesId: number;
}) => {
    const { isEditing, isLoading, onSubmit, onCancel } =
        useCapabilitiesRolesDataController(entityCapabilitiesRolesId);

    return (
        <>
            <Button
                type="text"
                disabled={!isEditing || isLoading}
                loading={isLoading}
                onClick={onCancel}
                style={{ marginRight: 8 }}
            >
                Отмена
            </Button>
            <Button
                type="primary"
                loading={isLoading}
                disabled={!isEditing || isLoading}
                onClick={onSubmit}
            >
                Сохранить
            </Button>
        </>
    );
};

export default CapabilitiesRolesDataController;
