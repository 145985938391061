import Icon, { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { CSSProperties } from "react";

const TelegramIcon = ({ style }: { style: CSSProperties }) => {
    const TelegramSvg = () => (
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.6732 0.849699C16.4406 0.861745 16.2215 0.937031 16.0268 1.02135H16.0241C15.8266 1.10868 14.888 1.54836 13.4625 2.21389C12.037 2.88243 10.1868 3.74973 8.35017 4.611C4.68228 6.33054 1.07661 8.02297 1.07661 8.02297L1.11989 8.0049C1.11989 8.0049 0.871035 8.09525 0.611362 8.294C0.478821 8.39037 0.332754 8.52287 0.205623 8.73066C0.0784908 8.93845 -0.0242969 9.25767 0.0135724 9.58591C0.075786 10.143 0.400378 10.4773 0.633001 10.661C0.868331 10.8477 1.09284 10.935 1.09284 10.935H1.09825L4.47941 12.2029C4.63089 12.7449 5.50999 15.9612 5.72098 16.702C5.8454 17.1447 5.96713 17.4217 6.1186 17.6325C6.19163 17.7409 6.27819 17.8313 6.38098 17.9035C6.42155 17.9306 6.46483 17.9517 6.50811 17.9698C6.52164 17.9788 6.53516 17.9818 6.55139 17.9848L6.51623 17.9758C6.52705 17.9788 6.53516 17.9879 6.54328 17.9909C6.57033 17.9999 6.58926 18.0029 6.62442 18.0089C7.16 18.1896 7.59008 17.8192 7.59008 17.8192L7.61443 17.7981L9.61067 15.7744L12.9567 18.6323L13.0324 18.6684C13.7303 19.0087 14.4363 18.819 14.8095 18.4847C15.1855 18.1475 15.3316 17.7168 15.3316 17.7168L15.3559 17.6476L17.9419 2.9005C18.0149 2.53611 18.0338 2.19582 17.9527 1.86456C17.8715 1.5333 17.6632 1.22312 17.4117 1.05749C17.1574 0.888848 16.9059 0.837653 16.6732 0.849699ZM16.6029 2.43071C16.6002 2.47889 16.6083 2.47287 16.5894 2.56622V2.57526L14.0278 17.1687C14.017 17.1898 13.9981 17.235 13.9467 17.2802C13.8926 17.3284 13.8493 17.3585 13.6248 17.2591L9.53222 13.7658L7.05992 16.2743L7.57926 12.5823C7.57926 12.5823 13.99 5.93001 14.2659 5.64393C14.5418 5.35784 14.4498 5.29761 14.4498 5.29761C14.4687 4.94828 14.0332 5.19522 14.0332 5.19522L5.60196 11.0103L5.59925 10.9953L1.55809 9.48051V9.4775C1.55538 9.4775 1.54997 9.47449 1.54727 9.47449C1.54997 9.47449 1.56891 9.46546 1.56891 9.46546L1.59055 9.45341L1.61219 9.44438C1.61219 9.44438 5.22056 7.75194 8.88845 6.0324C10.7251 5.17113 12.5753 4.30383 13.9981 3.63529C15.4209 2.96976 16.4731 2.4819 16.5326 2.4548C16.5894 2.43071 16.5623 2.43071 16.6029 2.43071Z"
                fill="white"
            />
        </svg>
    );
    const TelegramIcon = (props: Partial<CustomIconComponentProps>) => (
        <Icon component={TelegramSvg} {...props} />
    );
    return <TelegramIcon style={style} />;
};

export default TelegramIcon;
