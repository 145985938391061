import { CloseCircleFilled, CloseOutlined } from "@ant-design/icons";

const CurrentActiveFiltersValueDeleteIcon = ({
    action,
    value,
    top,
    right,
    isFilledIcon,
}: {
    action: (event: any) => void;
    value: string | string[];
    top?: number;
    right?: number;
    isFilledIcon?: boolean;
}) => {
    return (
        <div
            onClick={(event) => {
                action(Array.isArray(value) ? value : [value]);
                event.stopPropagation();
            }}
            style={{
                position: "absolute",
                top: top ? top : 2,
                right: right ? right : 4,
                fontSize: 12,
                cursor: "pointer",
                color: "#cccccc",
            }}
        >
            {isFilledIcon ? <CloseCircleFilled /> : <CloseOutlined />}
        </div>
    );
};

export default CurrentActiveFiltersValueDeleteIcon;
