import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { Spin, Typography } from "antd";
import { useEntityRowObserversList } from "../model";
import AddEntityRowObserver from "./AddEntityRowObserver";
import EntityRowObserversListItems from "./EntityRowObserversListItems";

interface IEntityRowObserversList {
    entityUuid: string;
    entity?: TEntity;
    hideTitle?: boolean;
}

const EntityRowObserversList = ({ entityUuid, hideTitle }: IEntityRowObserversList) => {
    const {
        loading,
        entityRowObserversByUpdate,
        entityRowObserversByCreate,
        entityRowObservers,
        bizProcTemplates,
    } = useEntityRowObserversList(entityUuid);
    return (
        <>
            <FlexContainer type="row-start">
                {!hideTitle && (
                    <IndentContainer type={["marginRight"]}>
                        <Typography.Title
                            level={5}
                            style={{ marginTop: 0, marginBottom: 0 }}
                        >
                            Настройка процессов
                        </Typography.Title>
                    </IndentContainer>
                )}
                {entityRowObservers.length > 0 && (
                    <AddEntityRowObserver
                        small
                        bizProcTemplates={bizProcTemplates}
                        entityUuid={entityUuid}
                    />
                )}
            </FlexContainer>
            <div>
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            minHeight: 150,
                        }}
                    >
                        <Spin />
                    </div>
                ) : entityRowObservers.length === 0 ? (
                    <FlexContainer>
                        <div
                            style={{
                                minHeight: 150,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <IndentContainer>
                                <Typography.Text style={{ fontSize: 15 }}>
                                    Нет привязанных бизнес-процессов
                                </Typography.Text>
                            </IndentContainer>
                            <AddEntityRowObserver
                                bizProcTemplates={bizProcTemplates}
                                entityUuid={entityUuid}
                            />
                        </div>
                    </FlexContainer>
                ) : (
                    <>
                        {entityRowObserversByCreate.length > 0 && (
                            <IndentContainer>
                                <EntityRowObserversListItems
                                    items={entityRowObserversByCreate}
                                    label="При создании"
                                />
                            </IndentContainer>
                        )}

                        {entityRowObserversByUpdate.length > 0 && (
                            <>
                                <EntityRowObserversListItems
                                    items={entityRowObserversByUpdate}
                                    label="При обновлении"
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default EntityRowObserversList;
