import { PlusOutlined } from "@ant-design/icons";
import { apiUrl } from "@shared/api/api";
import type { UploadFile, UploadProps } from "antd";
import { Modal, Upload, message } from "antd";
import { useState } from "react";

interface IPortalSettingInputFile {
    value: string;
    name: string;
    onChange: (value: string, name: string) => void;
}
type FileType = any;
const beforeUpload = (file: FileType) => {
    const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/x-icon";
    if (!isJpgOrPng) {
        message.error("Вы можете загрузить только JPG/PNG/ICO файлы!");
    }
    const isLt2M = file.size / 1024 / 1024 < 15;
    if (!isLt2M) {
        message.error("Файл должен быть менее 15MB!");
    }
    return (isJpgOrPng && isLt2M) || Upload.LIST_IGNORE;
};

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

const PortalSettingInputFile = ({ value, name, onChange }: IPortalSettingInputFile) => {
    const fileList = [
        {
            uid: value,
            url: apiUrl + "/" + value,
            xhr: {
                headers: {
                    authorization: "sdfsadsfd",
                },
            },
            name: value,
            status: "done",
        },
    ] as any;
    const uploadUrl = `${apiUrl}/api/portal-setting/file`;
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const accessToken = localStorage.getItem("access_token");
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file: UploadFile) => {
        if (
            (file.name ?? "").includes(".png") ||
            (file.name ?? "").includes(".jpg") ||
            (file.name ?? "").includes(".webp") ||
            (file.name ?? "").includes(".jpeg")
        ) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj as FileType);
            }

            setPreviewImage(file.url || (file.preview as string));
            setPreviewOpen(true);
            setPreviewTitle(
                file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
            );
        }
    };

    const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
        //  setFileList(newFileList);
    };

    const onRemove = (file: UploadFile) => {
        return true;
    };

    const uploadFile = (options: any) => {
        const url = uploadUrl; // File upload web service path
        const file = options.file;
        const formData = new FormData();

        formData.append("file", file);
        formData.append("filename", file.name);

        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.upload.onprogress = ({ total, loaded }) => {};

        xhr.onload = () => {
            const response = JSON.parse(xhr.response);
            const data = response.data;
            onChange(data, name);
        };
        xhr.onerror = function () {};

        xhr.setRequestHeader("authorization", `Bearer ${accessToken}`);
        xhr.send(formData);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {/* <img style={{ marginBottom: 10 }} src={`${apiUrl}/${value}`} alt="logo" /> */}

            <>
                <Upload
                    listType="picture-card"
                    customRequest={uploadFile}
                    onRemove={onRemove}
                    fileList={fileList}
                    beforeUpload={beforeUpload}
                    headers={{
                        authorization: `Bearer ${accessToken}`,
                    }}
                    showUploadList={{
                        showDownloadIcon: true,
                    }}
                    itemRender={(
                        originNode: React.ReactElement,
                        file: UploadFile,
                        fileList: Array<UploadFile>,
                        actions: {
                            download: () => void;
                            preview: () => void;
                            remove: () => void;
                        }
                    ) => {
                        return originNode;
                    }}
                    multiple
                    openFileDialogOnClick
                    onPreview={handlePreview}
                    onChange={handleChange}
                >
                    <button style={{ border: 0, background: "none" }} type="button">
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Изменить</div>
                    </button>
                </Upload>
                <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                    style={{ minWidth: "fit-content" }}
                >
                    <div
                        className="image-container"
                        style={{ width: "100%", textAlign: "center" }}
                    >
                        <img
                            alt="example"
                            src={previewImage}
                            style={{ aspectRatio: "auto", maxHeight: "70vh" }}
                        />
                    </div>
                </Modal>
            </>
        </div>
    );
};

export default PortalSettingInputFile;
