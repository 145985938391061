export const reorderStages = (stages: TStage[]) => {
    const initialStage = stages.find((stage) => stage.prev_stage_uuid === null);
    if (!initialStage) return [];
    const finalStages: TStage[] = [];
    const additionalStages: TStage[] = [];

    stages.forEach((stage) => {
        if (stage.prev_stage_uuid !== null && stage.type === "in_progress") {
            additionalStages.push(stage);
        }
        if (stage.prev_stage_uuid !== null && stage.type !== "in_progress") {
            finalStages.push(stage);
        }
    });

    return [
        initialStage,
        ...reorderAdditionalStages(additionalStages, initialStage.uuid),
        ...finalStages,
    ];
};
export const reorderAdditionalStages = (stages: TStage[], initUuid: string) => {
    return stages.reduce((prev, current, index, stages) => {
        if (prev.length === 0) {
            const initialStage = stages.find(
                (stage) => stage.prev_stage_uuid === initUuid
            );
            if (!initialStage) return [];
            return [initialStage] as TStage[];
        } else {
            const nextStage = stages.find(
                (stage) => stage.prev_stage_uuid === prev[prev.length - 1].uuid
            );
            if (nextStage) {
                return [...prev, nextStage];
            }
        }
        return prev;
    }, [] as TStage[]);
};
