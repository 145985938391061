import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

interface ITechMapTemplateCreate {}

const TechMapTemplateCreate = ({}: ITechMapTemplateCreate) => {
    const navigate = useNavigate();
    const onClick = () => {
        navigate(`/tech-map-editor/create`);
    };

    return (
        <div>
            <Button type="primary" onClick={onClick} icon={<PlusOutlined />}>
                Создать
            </Button>
        </div>
    );
};

export default TechMapTemplateCreate;
