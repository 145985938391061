export const getEntityNavigationRoute = (
    entityTableName: string,
    entityName: string,
    order: number
): TCreatingNavigationRoute => {
    return {
        name: entityName,
        key: `erp/${entityTableName}`,
        parent_key: null,
        type: "route",
        order: order,
        route: `/erp/${entityTableName}`,
        blank: 0,
        subroutes: [],
    };
};
