import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateArrByItemId } from "@shared/lib/object-helper";
import {
    approveBizProcAgreementThunk,
    deleteBizProcThunk,
    loadBizProcCountersThunk,
    loadBizProcTemplateThunk,
    loadBizProcThunk,
    loadBizProcUserActionsThunk,
    loadBizProcsAgreementsThunk,
    loadBizProcsFormsThunk,
    loadBizProcsThunk,
    startBizProcThunk,
    submitBizProcFormThunk,
    updateBizProcThunk,
} from "./BizProcThunk";

export interface IBizProcSlice {
    bizProcs: TGroupedBizProc[];
    bizProcsAgreements: (TBizProcTemplateItem & { biz_proc_actions: TBizProcAction[] })[];
    userActions: TBizProcAction[];
    userAgreements: TBizProcAction[];
    bizProcsMyForms: (TBizProcTemplateItem & { biz_proc_actions: TBizProcAction[] })[];
    isBizProcsAgreementsLoading: boolean | null;
    isBizProcsFormsLoading: boolean | null;
    bizProcsMyAgreements: (TBizProcTemplateItem & {
        biz_proc_actions: TBizProcAction[];
    })[];
    targetAgreement: TBizProcAction | null;
    targetForm: TBizProcAction | null;
    editingTemplateItem: TBizProc | null;
    loadBizProcAgreementsIds: number[];
    loadBizProcFormsIds: number[];
    loadBizProcsIds: number[];
    bizProcAgreementLoadingError: any;
    formsCounter: number;
    agreementsCounter: number;
}

const initialState: IBizProcSlice = {
    bizProcs: [],
    bizProcsAgreements: [],
    userActions: [],
    userAgreements: [],
    loadBizProcFormsIds: [],
    isBizProcsAgreementsLoading: false,
    isBizProcsFormsLoading: false,
    bizProcsMyAgreements: [],
    bizProcsMyForms: [],
    editingTemplateItem: null,
    targetForm: null,
    formsCounter: 0,
    agreementsCounter: 0,
    targetAgreement: null,
    bizProcAgreementLoadingError: null,
    loadBizProcAgreementsIds: [],
    loadBizProcsIds: [],
};

export const bizProcSlice = createSlice({
    name: "BizProcSlice",
    initialState,
    reducers: {
        setEntities: (state, action: PayloadAction<TGroupedBizProc[]>) => {
            state.bizProcs = action.payload;
        },
        setFormsCounter: (state, action: PayloadAction<number>) => {
            state.formsCounter = action.payload;
        },
        setAgreementsCounter: (state, action: PayloadAction<number>) => {
            state.agreementsCounter = action.payload;
        },
        setEditingBizProc: (state, action: PayloadAction<TBizProc>) => {
            state.editingTemplateItem = action.payload;
        },
        setBizProcAgreementsIds: (state, action: PayloadAction<number[]>) => {
            state.loadBizProcAgreementsIds = action.payload;
        },
        setBizProcFormsIds: (state, action: PayloadAction<number[]>) => {
            state.loadBizProcFormsIds = action.payload;
        },
        addMyAgreement: (state, action: PayloadAction<TBizProcAction>) => {
            const bizProcAction = action.payload;
            const isCreated = state.bizProcsMyAgreements.find(
                (bizProc) => bizProc.id === bizProcAction.biz_proc_template_id
            );
            if (isCreated)
                state.bizProcsMyAgreements = state.bizProcsMyAgreements.map((bizProc) =>
                    bizProc.id === bizProcAction.biz_proc_template_id
                        ? {
                              ...bizProc,
                              biz_proc_actions: [
                                  bizProcAction,
                                  ...bizProc.biz_proc_actions,
                              ],
                          }
                        : bizProc
                );
            else
                state.loadBizProcAgreementsIds = [
                    ...state.loadBizProcAgreementsIds,
                    bizProcAction.biz_proc_template_id,
                ];
        },
        addUserAction: (state, action: PayloadAction<TBizProcAction>) => {
            state.userActions = [action.payload, ...state.userActions];
        },
        setUserAction: (state, action: PayloadAction<TBizProcAction[]>) => {
            state.userActions = action.payload;
        },
        updateUserAction: (state, action: PayloadAction<TBizProcAction>) => {
            const index = state.userActions.findIndex(
                (bizProcAction) => bizProcAction.id === action.payload.id
            );
            if (index > -1) {
                state.userActions = state.userActions.map((bizProcAction) =>
                    bizProcAction.id === action.payload.id
                        ? action.payload
                        : bizProcAction
                );
            } else {
                state.userActions = [action.payload, ...state.userActions];
            }
        },
        deleteUserAction: (state, action: PayloadAction<TBizProcAction>) => {
            state.userActions = state.userActions.filter(
                (bizProcAction) => bizProcAction.id !== action.payload.id
            );
        },
        addUserAgreement: (state, action: PayloadAction<TBizProcAction>) => {
            state.userAgreements = [action.payload, ...state.userAgreements];
        },
        setUserAgreements: (state, action: PayloadAction<TBizProcAction[]>) => {
            state.userAgreements = action.payload;
        },
        updateUserAgreement: (state, action: PayloadAction<TBizProcAction>) => {
            const index = state.userAgreements.findIndex(
                (bizProcAction) => bizProcAction.id === action.payload.id
            );
            if (index > -1) {
                state.userAgreements[index] = action.payload;
                //  state.userAgreements.map((bizProcAction) =>
                //     bizProcAction.id === action.payload.id
                //         ? action.payload
                //         : bizProcAction
                // );
            } else {
                state.userAgreements = [action.payload, ...state.userAgreements];
            }
        },
        deleteUserAgreement: (state, action: PayloadAction<TBizProcAction>) => {
            state.userAgreements = state.userAgreements.filter(
                (bizProcAction) => bizProcAction.id !== action.payload.id
            );
        },
        updateMyAgreement: (state, action: PayloadAction<TBizProcAction>) => {
            const bizProcAction = action.payload;
            state.bizProcsMyAgreements = state.bizProcsMyAgreements.map((bizProc) =>
                bizProc.id === bizProcAction.biz_proc_template_id
                    ? {
                          ...bizProc,
                          biz_proc_actions: bizProc.biz_proc_actions.map((action) =>
                              action.id === bizProcAction.id ? bizProcAction : action
                          ),
                      }
                    : bizProc
            );
        },
        deleteMyAgreement: (state, action: PayloadAction<TBizProcAction>) => {
            const bizProcAction = action.payload;
            state.bizProcsMyAgreements = state.bizProcsMyAgreements.map((bizProc) =>
                bizProc.id === bizProcAction.biz_proc_template_id
                    ? {
                          ...bizProc,
                          biz_proc_actions: bizProc.biz_proc_actions.filter(
                              (action) => action.id !== bizProcAction.id
                          ),
                      }
                    : bizProc
            );
        },
        addAgreement: (state, action: PayloadAction<TBizProcAction>) => {
            const bizProcAction = action.payload;
            const isCreated = state.bizProcsAgreements.find(
                (bizProc) => bizProc.id === bizProcAction.biz_proc_template_id
            );
            if (isCreated)
                state.bizProcsAgreements = state.bizProcsAgreements.map((bizProc) =>
                    bizProc.id === bizProcAction.biz_proc_template_id
                        ? {
                              ...bizProc,
                              biz_proc_actions: [
                                  bizProcAction,
                                  ...bizProc.biz_proc_actions,
                              ],
                          }
                        : bizProc
                );
            else
                state.loadBizProcAgreementsIds = [
                    ...state.loadBizProcAgreementsIds,
                    bizProcAction.biz_proc_template_id,
                ];
        },
        updateAgreement: (state, action: PayloadAction<TBizProcAction>) => {
            const bizProcAction = action.payload;

            state.bizProcsAgreements = state.bizProcsAgreements.map((bizProc) =>
                bizProc.id === bizProcAction.biz_proc_template_id
                    ? {
                          ...bizProc,
                          biz_proc_actions: bizProc.biz_proc_actions.map((action) =>
                              action.id === bizProcAction.id ? bizProcAction : action
                          ),
                      }
                    : bizProc
            );
        },
        deleteAgreement: (state, action: PayloadAction<TBizProcAction>) => {
            const bizProcAction = action.payload;

            state.bizProcsAgreements = state.bizProcsAgreements.map((bizProc) =>
                bizProc.id === bizProcAction.biz_proc_template_id
                    ? {
                          ...bizProc,
                          biz_proc_actions: bizProc.biz_proc_actions.filter(
                              (action) => action.id !== bizProcAction.id
                          ),
                      }
                    : bizProc
            );
        },
        addBizProcForm: (state, action: PayloadAction<TBizProcAction>) => {
            const bizProcAction = action.payload;
            const isCreated = state.bizProcsMyForms.find(
                (bizProc) => bizProc.id === bizProcAction.biz_proc_template_id
            );

            if (isCreated)
                state.bizProcsMyForms = state.bizProcsMyForms.map((bizProc) =>
                    bizProc.id === bizProcAction.biz_proc_template_id
                        ? {
                              ...bizProc,
                              biz_proc_actions: [
                                  bizProcAction,
                                  ...bizProc.biz_proc_actions,
                              ],
                          }
                        : bizProc
                );
            else
                state.loadBizProcFormsIds = [
                    ...state.loadBizProcFormsIds,
                    bizProcAction.biz_proc_template_id,
                ];
        },

        setTargetAgreement: (state, action: PayloadAction<TBizProcAction | null>) => {
            state.targetAgreement = action.payload;
        },
        setTargetForm: (state, action: PayloadAction<TBizProcAction | null>) => {
            state.targetForm = action.payload;
        },
        addBizProc: (state, action: PayloadAction<TBizProc>) => {
            const bizProcAdded = action.payload;
            const isCreated = state.bizProcs.find(
                (bizProc) => bizProc.id === bizProcAdded.biz_proc_template_id
            );
            if (isCreated) {
                state.bizProcs = state.bizProcs.map((bizProc) => {
                    if (bizProc.id === bizProcAdded.biz_proc_template_id) {
                        const buff = { ...bizProc };
                        buff.biz_procs?.unshift(bizProcAdded);
                        return buff;
                    } else {
                        return bizProc;
                    }
                });
            } else {
                if (bizProcAdded.id)
                    state.loadBizProcsIds = [bizProcAdded.id, ...state.loadBizProcsIds];
            }
        },
        updateBizProc: (state, action: PayloadAction<TBizProc>) => {
            const bizProcUpd = action.payload;
            state.bizProcs = state.bizProcs.map((bizProc) => {
                if (bizProc.id === bizProcUpd.biz_proc_template_id) {
                    const buff = { ...bizProc };
                    buff.biz_procs = buff.biz_procs?.map((biz_proc) =>
                        biz_proc.id === bizProcUpd.id ? bizProcUpd : biz_proc
                    );
                    return buff;
                } else {
                    return bizProc;
                }
            });
        },
        deleteBizProc: (state, action: PayloadAction<TBizProc>) => {
            const bizProcDeleted = action.payload;
            state.bizProcs = state.bizProcs
                .map((bizProc) => {
                    if (bizProc.id === bizProcDeleted.biz_proc_template_id) {
                        const buff = { ...bizProc };
                        buff.biz_procs = buff.biz_procs?.filter(
                            (biz_proc) => biz_proc.id !== bizProcDeleted.id
                        );
                        return buff;
                    } else {
                        return bizProc;
                    }
                })
                .filter((item) => item.biz_procs && item.biz_procs.length > 0);
        },
        updateBizProcForm: (state, action: PayloadAction<TBizProcAction>) => {
            const formAction = action.payload;

            state.bizProcsMyForms = state.bizProcsMyForms.map((bizProc) =>
                bizProc.id === formAction.biz_proc_template_id
                    ? {
                          ...bizProc,
                          biz_proc_actions: bizProc.biz_proc_actions.map((action) =>
                              action.id === formAction.id ? formAction : action
                          ),
                      }
                    : bizProc
            );
        },
        deleteBizProcForm: (state, action: PayloadAction<TBizProcAction>) => {
            const formAction = action.payload;
            state.bizProcsMyForms = state.bizProcsMyForms.map((bizProc) =>
                bizProc.id === formAction.biz_proc_template_id
                    ? {
                          ...bizProc,
                          biz_proc_actions: bizProc.biz_proc_actions.filter(
                              (action) => action.id !== formAction.id
                          ),
                      }
                    : bizProc
            );
        },
    },
    extraReducers: (builder) => {
        loadBuilder(builder);
        updateBuilder(builder);
        deleteBuilder(builder);
        loadOneBuilder(builder);
        loadBizProcCounters(builder);
        loadBizProcFormsBuilder(builder);
        loadBizProcAgreementsBuilder(builder);
        loadBizProcTemplate(builder);
        approveBizProcAgreementBuilder(builder);
        startBizProcBuilder(builder);
        submitFormBuilder(builder);
        loadBizProcUserAction(builder);
    },
});

const loadBizProcAgreementsBuilder = (
    builder: ActionReducerMapBuilder<IBizProcSlice>
) => {
    builder.addCase(loadBizProcsAgreementsThunk.fulfilled, (state, action) => {
        const adding = action.payload.add;
        const bizProcTemplates = action.payload.data;
        bizProcTemplates.forEach((bizProcTemplate) => {
            state.loadBizProcAgreementsIds = state.loadBizProcAgreementsIds.filter(
                (id) => id !== bizProcTemplate.id
            );
        });

        if (action.payload.isMy) {
            state.bizProcsMyAgreements = adding
                ? [...bizProcTemplates, ...state.bizProcsMyAgreements]
                : bizProcTemplates;
            const buff: any = {};
            bizProcTemplates.forEach((item) => {
                buff[item.name] = item.biz_proc_actions.length;
            });
            localStorage.setItem("MyAgreementsCount", JSON.stringify(buff));
        } else {
            state.bizProcsAgreements = adding
                ? [...bizProcTemplates, ...state.bizProcsAgreements]
                : bizProcTemplates;
            const buff: any = {};
            bizProcTemplates.forEach((item) => {
                buff[item.name] = item.biz_proc_actions.length;
            });
            localStorage.setItem("AgreementsCount", JSON.stringify(buff));
        }
        state.isBizProcsAgreementsLoading = false;
    });
    builder.addCase(loadBizProcsAgreementsThunk.pending, (state) => {
        state.isBizProcsAgreementsLoading = true;
    });
    builder.addCase(loadBizProcsAgreementsThunk.rejected, (state, rejectedValue) => {
        state.bizProcAgreementLoadingError = rejectedValue;
        state.isBizProcsAgreementsLoading = false;
    });
};
const loadBizProcTemplate = (builder: ActionReducerMapBuilder<IBizProcSlice>) => {
    builder.addCase(loadBizProcTemplateThunk.fulfilled, (state, action) => {
        const bizProcTemplate = action.payload.data;
        state.bizProcs = [...bizProcTemplate, ...state.bizProcs];
        state.loadBizProcsIds = [];
    });
};
const loadBizProcUserAction = (builder: ActionReducerMapBuilder<IBizProcSlice>) => {
    builder.addCase(loadBizProcUserActionsThunk.fulfilled, (state, action) => {
        const actions = action.payload.data;
        state.userActions = actions;
    });
};
const loadBizProcFormsBuilder = (builder: ActionReducerMapBuilder<IBizProcSlice>) => {
    builder.addCase(loadBizProcsFormsThunk.fulfilled, (state, action) => {
        const adding = action.payload.add;
        const bizProcTemplates = action.payload.data;
        bizProcTemplates.forEach((bizProcTemplate) => {
            state.loadBizProcFormsIds = state.loadBizProcFormsIds.filter(
                (id) => id !== bizProcTemplate.id
            );
        });

        state.bizProcsMyForms = adding
            ? [...bizProcTemplates, ...state.bizProcsMyForms]
            : bizProcTemplates;

        const buff: any = {};
        action.payload.data.forEach((item) => {
            buff[item.name] = item.biz_proc_actions.length;
        });
        localStorage.setItem("MyFormsCount", JSON.stringify(buff));
        state.isBizProcsFormsLoading = false;
    });
    builder.addCase(loadBizProcsFormsThunk.pending, (state) => {
        state.isBizProcsFormsLoading = true;
    });
    builder.addCase(loadBizProcsFormsThunk.rejected, (state) => {
        state.isBizProcsFormsLoading = false;
    });
};
const startBizProcBuilder = (builder: ActionReducerMapBuilder<IBizProcSlice>) => {
    builder.addCase(startBizProcThunk.fulfilled, (state, action) => {
        //state.bizProcsAgreements = action.payload.data;
    });
};
const approveBizProcAgreementBuilder = (
    builder: ActionReducerMapBuilder<IBizProcSlice>
) => {
    builder.addCase(approveBizProcAgreementThunk.fulfilled, (state, action) => {
        state.bizProcsAgreements = updateArrByItemId(
            state.bizProcsAgreements,
            action.payload.data
        );
    });
};
const loadBuilder = (builder: ActionReducerMapBuilder<IBizProcSlice>) => {
    builder.addCase(loadBizProcsThunk.fulfilled, (state, action) => {
        state.bizProcs = action.payload.data;
    });
};
const loadBizProcCounters = (builder: ActionReducerMapBuilder<IBizProcSlice>) => {
    builder.addCase(loadBizProcCountersThunk.fulfilled, (state, action) => {
        state.agreementsCounter = action.payload.data["agreements"];
        state.formsCounter = action.payload.data["forms"];
    });
};

const updateBuilder = (builder: ActionReducerMapBuilder<IBizProcSlice>) => {
    builder.addCase(updateBizProcThunk.fulfilled, (state, action) => {
        state.bizProcs = updateArrByItemId(state.bizProcs, action.payload.data);
    });
};
const submitFormBuilder = (builder: ActionReducerMapBuilder<IBizProcSlice>) => {
    builder.addCase(submitBizProcFormThunk.fulfilled, (state, action) => {
        const formAction = action.payload.data;

        state.bizProcsMyForms = state.bizProcsMyForms.map((bizProcTemplate) => {
            return bizProcTemplate.id === formAction.biz_proc_template_id
                ? {
                      ...bizProcTemplate,
                      biz_proc_actions: bizProcTemplate.biz_proc_actions.map((action) =>
                          action.id === formAction.id ? formAction : action
                      ),
                  }
                : bizProcTemplate;
        });
    });
};
const deleteBuilder = (builder: ActionReducerMapBuilder<IBizProcSlice>) => {
    builder.addCase(deleteBizProcThunk.fulfilled, (state, action) => {
        state.bizProcs = state.bizProcs
            .map((item) => {
                if (item.id === action.payload.data.biz_proc_template_id) {
                    return {
                        ...item,
                        biz_procs: item.biz_procs?.filter((bizProc) => {
                            return bizProc.id !== action.payload.data.id;
                        }),
                    };
                }
                return item;
            })
            .filter((item) => item.biz_procs && item.biz_procs.length > 0);
    });
};
const loadOneBuilder = (builder: ActionReducerMapBuilder<IBizProcSlice>) => {
    builder.addCase(loadBizProcThunk.fulfilled, (state, action) => {
        state.editingTemplateItem = action.payload.data;
    });
};
export const {
    setEntities,
    addMyAgreement,
    addAgreement,
    updateAgreement,
    setBizProcFormsIds,
    addUserAction,
    addUserAgreement,
    deleteAgreement,
    deleteUserAgreement,
    updateUserAgreement,
    setUserAgreements,
    setUserAction,
    updateUserAction,
    deleteUserAction,
    addBizProcForm,
    addBizProc,
    setBizProcAgreementsIds,
    updateMyAgreement,
    updateBizProcForm,
    deleteBizProcForm,
    updateBizProc,
    setFormsCounter,
    setAgreementsCounter,
    deleteBizProc,
    setTargetAgreement,
    setEditingBizProc,
    setTargetForm,
} = bizProcSlice.actions;
export default bizProcSlice.reducer;
