import { Link } from "react-router-dom";

interface ISidebarItem {
    collapsed: boolean;
    navigationRoute: TNavigationRoute;
    badge?: number;
}

export const SidebarItem = ({ collapsed, navigationRoute, badge }: ISidebarItem) => {
    if (navigationRoute.key === "admin") {
        return <>{collapsed ? navigationRoute.name?.[0] : navigationRoute.name}</>;
    }
    return (
        <Link
            onClick={(e) => e.preventDefault()}
            to={`${navigationRoute.route}`}
            style={{
                marginRight: 6,
                display: "inline-block",
                width: "100%",
                height: "100%",
            }}
        >
            {collapsed && navigationRoute.parent_key === null
                ? navigationRoute.name?.[0]
                : navigationRoute.name}
        </Link>
    );
};
