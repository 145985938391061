import { bizProcApi } from "@entities/Portal/BizProc";
import { userModel } from "@entities/Portal/User";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useBizProcInfo = (bizProcId: number) => {
    const [loading, setLoading] = useState(true);
    const [bizProc, setBizProc] = useState<TBizProc | null>(null);
    const [error, setError] = useState<TBizProc | null>(null);
    const user = useSelector(userModel.selectUser);
    //const targetForm = bizProc?.start_biz_proc_action as TBizProcAction;
    const isWithStartFormAction =
        bizProc?.start_biz_proc_action?.collected_parameters?.userId === user.id;
    useEffect(() => {
        bizProcApi
            .getBizProc(bizProcId)
            .then((response) => {
                setBizProc(response.data.data);
            })
            .catch((e) => {
                setError(e);
                console.log("Error ", e);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (bizProc) {
            window.Echo.channel(`User.${user.id}.BizProcAction`)
                .listen(
                    ".bizProcAction.create",
                    ({ bizProcAction }: { bizProcAction: TBizProcAction }) => {
                        if (bizProcAction.biz_proc_id === bizProcId)
                            setBizProc((prev: any) => ({
                                ...prev,
                                actions: [bizProcAction, ...(prev.actions ?? [])],
                            }));
                    }
                )
                .listen(
                    ".bizProcAction.update",
                    ({ bizProcAction }: { bizProcAction: TBizProcAction }) => {
                        if (bizProcAction.biz_proc_id === bizProcId)
                            setBizProc((prev: any) => ({
                                ...prev,
                                actions: (prev.actions ?? []).map((action: any) =>
                                    action.id === bizProcAction.id
                                        ? bizProcAction
                                        : action
                                ),
                            }));
                    }
                );
            window.Echo.channel(`BizProc.${bizProcId}`).listen(
                ".bizProc.update",
                ({ bizProc }: { bizProc: TBizProc }) => {
                    setBizProc((prev: any) => ({
                        ...prev,
                        ...bizProc,
                    }));
                }
            );
        }

        return () => {
            window.Echo.leave(`BizProc.${bizProcId}`);
            window.Echo.leave(`User.${user.id}.BizProcAction`);
        };
    }, [bizProc?.id]);

    const [showHidden, setShowHidden] = useState(false);
    const onChecked = (value: CheckboxChangeEvent) => {
        setShowHidden(value.target.checked);

        if (bizProc?.id)
            if (value.target.checked) {
                bizProcApi
                    .getBizProc(bizProc.id, {
                        showHidden: 1,
                    })
                    .then((response) => {
                        setBizProc(response.data.data);
                    })
                    .catch((e) => console.log(e));
            } else {
                bizProcApi
                    .getBizProc(bizProc.id)
                    .then((response) => {
                        setBizProc(response.data.data);
                    })
                    .catch((e) => console.log(e));
            }
    };

    return {
        loading,
        bizProc,
        setBizProc,
        error,
        isWithStartFormAction,
        showHidden,
        onChecked,
    };
};
