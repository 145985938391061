import { CloseOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { ReactNode } from "react";
import "./Bookmark.scss";
interface IBookmark {
    children: ReactNode;
    tooltip?: string | ReactNode;
    zIndex?: number;
    top?: number;
    onClose?: () => void;
}

const Bookmark = ({ children, tooltip, zIndex, top, onClose }: IBookmark) => {
    return (
        <div className="bookmark" style={{ zIndex, top }} onClick={onClose}>
            {onClose && (
                <span className="bookmark__close link" onClick={onClose}>
                    <CloseOutlined />
                </span>
            )}
            {tooltip ? <Tooltip title={tooltip}>{children}</Tooltip> : children}
        </div>
    );
};

export default Bookmark;
