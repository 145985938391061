import { bizProcModel } from "@entities/Portal/BizProc";
import { checkRequiredFields } from "@entities/Portal/Datatype";
import { userModel } from "@entities/Portal/User";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useApproveAgreement = () => {
    const agreement = useSelector(bizProcModel.selectTargetAgreement);
    const userId = useSelector(userModel.selectUser).id;
    const [comment, setComment] = useState<string>(
        agreement?.collected_parameters.comment ?? ""
    );
    const parameters = agreement?.collected_parameters as TBizProcAgreementParameters;
    const dispatch = useDispatch<AppDispatch>();
    const canApprove =
        agreement?.status === "executing" &&
        agreement?.collected_parameters?.users_ids?.includes(userId);
    const [loading, setLoading] = useState<"reject" | "approve" | null>(null);
    const [error, setError] = useState<any>({});
    const { loadingVariables, fields, setFields } = bizProcModel.useFormFields(
        agreement?.biz_proc_id,
        agreement?.collected_parameters?.fields
    );
    const approveBizProcAgreement = (status: "reject" | "approve") => {
        if (!agreement) return;
        const { isValid, errorFields } = checkRequiredFields(fields);
        setError(errorFields);
        if (!isValid) return;
        setLoading(status);
        dispatch(
            bizProcModel.approveBizProcAgreementThunk({
                agreementId: agreement.id,
                status,
                collected_parameters: {
                    ...agreement.collected_parameters,
                    fields: fields,
                },
            })
        )
            .then(() => {
                dispatch(bizProcModel.setTargetAgreement(null));
                setComment("");
            })
            .finally(() => {
                setLoading(null);
            });
    };

    const onChangeFormFields = (fields: TBizProcFormField[], id?: string) => {
        if (id)
            setError({
                ...error,
                [id]: false,
            });
        if (agreement) {
            setFields(fields);
        }
    };

    const onCloseUserModal = () => {
        setFields([]);
    };
    const onChange = (event: any) => {
        setComment(event.target.value);
    };

    const onSubmit = () => {
        approveBizProcAgreement("approve");
    };
    const onCancel = () => {
        approveBizProcAgreement("reject");
    };

    const onClose = () => {
        dispatch(bizProcModel.setTargetAgreement(null));
        setFields([]);
    };
    return {
        onChange,
        onSubmit,
        onCancel,
        onClose,
        onCloseUserModal,
        onChangeFormFields,
        comment,
        fields,
        canApprove,
        error,
        loading,
        agreement,
        loadingVariables,
        parameters,
    };
};
