import { Button } from "antd";
import "./Condition.scss";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
interface IConditionGroupHeader {
    onAddGroup: () => void;
    onAddIf: () => void;
    onDelete: () => void;
    onChangeLogicalComparison: () => void;
    logicalComparison: TLogicalComparison;
    disabled?: boolean;
}

const ConditionGroupHeader = ({
    onAddGroup,
    onAddIf,
    onDelete,
    onChangeLogicalComparison,
    logicalComparison,
    disabled,
}: IConditionGroupHeader) => {
    const onClickAddGroup = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onAddGroup();
    };
    const onClickDelete = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onDelete();
    };
    const onClickAddIf = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onAddIf();
    };
    const onClickChangeLogicalComparison = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onChangeLogicalComparison();
    };
    return (
        <div className="condition-group-header">
            <Button
                onClick={onClickChangeLogicalComparison}
                type="link"
                disabled={disabled}
            >
                {logicalComparison === "and" ? "И" : "ИЛИ"}
            </Button>
            <span className="condition-group-header__label">Группа условий</span>
            <Button
                onClick={onClickAddIf}
                style={{
                    paddingTop: 0,
                }}
                icon={<PlusOutlined />}
                type="link"
                disabled={disabled}
            >
                Условие
            </Button>
            <Button
                onClick={onClickAddGroup}
                style={{
                    paddingTop: 0,
                }}
                icon={<PlusOutlined />}
                type="link"
                disabled={disabled}
            >
                Группу
            </Button>

            <Button
                onClick={onClickDelete}
                style={{
                    paddingTop: 0,
                }}
                icon={<DeleteOutlined />}
                type="link"
                danger
                disabled={disabled}
            >
                Удалить
            </Button>
        </div>
    );
};

export default ConditionGroupHeader;
