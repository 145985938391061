import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTechMapTmplOperations } from "../TechMapTemplateSlice";
import {
    selectGetOperationLoading,
    selectOperations,
} from "./TechMapTemplateOperationSelectors";
import { loadTechMapTemplateOperationsThunk } from "./TechMapTemplateOperationThunk";

export const useTechMapTmplOperationsLoad = (tmtId: number | null) => {
    const loading = useSelector(selectGetOperationLoading);
    const tmtOperations = useSelector(selectOperations);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (tmtId) {
            dispatch(
                loadTechMapTemplateOperationsThunk({
                    techMapTemplateId: tmtId,
                })
            );
        }
        return () => {
            dispatch(setTechMapTmplOperations([]));
        };
    }, [tmtId]);

    return {
        tmtOperations,
        loading,
    };
};
