import { apiUrl } from "@shared/api/api";
import { RequestedSelect } from "@shared/ui/RequestedSelect";
import { Checkbox } from "antd";
import InputWithVariables from "./InputWithVariables";

interface IUserInputWithVariables {
    calculationValue: string;
    isCalculation: boolean;
    disabled?: boolean;
    multiple?: boolean;
    values: any | any[];
    bizProcTemplateId: number;
    onChangeIsCalculation: (isCalculation: boolean) => void;
    onChangeCalculationValue: (value: string) => void;
    onChangeUsers: (usersIds: number[] | number, item: any) => void;
}

const UserInputWithVariables = ({
    isCalculation,
    calculationValue,
    disabled,
    values,
    multiple,
    bizProcTemplateId,
    onChangeIsCalculation,
    onChangeCalculationValue,
    onChangeUsers,
}: IUserInputWithVariables) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <RequestedSelect
                disabled={disabled}
                mode={multiple ? "multiple" : undefined}
                values={values}
                onSelect={onChangeUsers}
                requestUrl={`${apiUrl}/api/user/employees`}
                queryTypedParam="name"
                queryChoosenParams="usersIds"
                valueLabel="name"
                valueKey="id"
            />
            <Checkbox
                style={{
                    marginTop: 10,
                }}
                disabled={disabled}
                checked={isCalculation}
                onChange={() => onChangeIsCalculation(!isCalculation)}
            >
                Вычисляемое значение по формуле
            </Checkbox>
            {isCalculation && (
                <InputWithVariables
                    disabled={disabled}
                    itemId={bizProcTemplateId}
                    isTextArea={true}
                    onChange={(name, value) => onChangeCalculationValue(value)}
                    name="input"
                    value={calculationValue}
                />
            )}
        </div>
    );
};

export default UserInputWithVariables;
