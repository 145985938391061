import { $api } from "@shared/api/api";
const route = "/api/entity";

export const getCategories = (entityId: number, params?: TParams) => {
    return $api.get<TResponseList<TCategory>>(`${route}/${entityId}/categories`, {
        params: params,
    });
};
export const updateCategory = (entityId: number, category: TCategory) => {
    return $api.put<TResponse<TCategory>>(
        `${route}/${entityId}/categories/${category.id}`,
        category
    );
};
export const createCategory = (entityId: number, category: TCategoryCreating) => {
    return $api.post<TResponse<TCategory>>(`${route}/${entityId}/categories`, category);
};
export const deleteCategory = (entityId: number, categoryId: number) => {
    return $api.delete<TResponse<TCategory>>(
        `${route}/${entityId}/categories/${categoryId}`
    );
};
