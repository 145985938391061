import { entityRowModel } from "@entities/Portal/EntityRow";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useEntityRowsFilters = (entityId: number) => {
    const filters = useSelector(entityRowModel.selectFilters);
    const dispatch = useDispatch();

    useEffect(() => {
        const savedFilters = localStorage.getItem(`${entityId}_filters`);

        dispatch(
            entityRowModel.setFilters({
                filters: savedFilters ? JSON.parse(savedFilters) : undefined,
                entityId,
            })
        );
        const savedTextFilterValue = localStorage.getItem(`${entityId}__special-key`);
        dispatch(
            entityRowModel.setTextFilterValue({
                textFilterValue: savedTextFilterValue ?? "",
                entityId: entityId,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId]);

    return {
        filters,
    };
};
