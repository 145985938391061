import { bizProcModel } from "@entities/Portal/BizProc";
import { ApproveAgreement } from "@features/Portal/BizProc/ApproveAgreement";
import { BizProcAgreementsList } from "@features/Portal/BizProc/BizProcsAgreementsList";
import { useHeaderLayout } from "@shared/ui/Header";

const AgreementPage = () => {
    useHeaderLayout({
        title: "На согласовании",
    });
    bizProcModel.useLoadAgreements(true);
    return (
        <>
            <BizProcAgreementsList isMyAgreements={false} />
            <ApproveAgreement />
        </>
    );
};

export default AgreementPage;
