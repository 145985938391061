import { DropdownActions } from "@shared/ui/DropdownActions";
import { MenuProps } from "antd";
import { useBizProcListActions } from "../../model";
import BizProcDeleteAction from "./BizProcDeleteAction";
interface IBizProcListActions {
    bizProc: TBizProc;
}
const BizProcListActions = ({ bizProc }: IBizProcListActions) => {
    const { onConfirmDelete } = useBizProcListActions(bizProc);

    const items: MenuProps["items"] = [
        {
            label: <BizProcDeleteAction onConfirmDelete={onConfirmDelete} />,
            key: "deleteAction",
            danger: true,
            style: { padding: 0 },
        },
    ];

    return <DropdownActions placement="bottomRight" items={items} />;
};

export default BizProcListActions;
