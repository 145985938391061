import Title from "antd/es/typography/Title";
import "./EstimationValuesTitle.scss";

const EstimationValuesTitle = ({ name }: { name: string }) => {
    return (
        <Title className="estimation-template-title" level={5}>
            {name ?? "Шаблон"}
        </Title>
    );
};

export default EstimationValuesTitle;
