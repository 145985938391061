import { fieldOptionModel } from "@entities/Portal/FieldOption";

export const useFieldOptionEditor = (fieldId: number, type: TFieldOptionType) => {
    const { options, loading } = fieldOptionModel.useLoadFieldOptions(fieldId, type);

    return {
        options,
        loading,
    };
};
