import { FormItem } from "@shared/ui/Form";
import { RequestedSelect } from "@shared/ui/RequestedSelect";
import { TFilterInputItem, TInputRequestedSelect } from "../../FilterKitTypes";

const FilterRequestedSelectItem = ({
    field,
    onChange,
}: TFilterInputItem<TInputRequestedSelect>) => {
    const {
        label,
        value,
        requestUrl,
        queryChoosenParams,
        queryTypedParam,
        valueLabel,
        valueKey,
        name,
    } = field;
    return (
        <FormItem label={label} style={{ width: "100%" }}>
            <>
                <RequestedSelect
                    mode="multiple"
                    values={value}
                    requestUrl={requestUrl}
                    queryTypedParam={queryTypedParam}
                    queryChoosenParams={queryChoosenParams}
                    valueLabel={valueLabel}
                    valueKey={valueKey}
                    onSelect={(value) => {
                        onChange(name, value);
                    }}
                />
            </>
        </FormItem>
    );
};

export default FilterRequestedSelectItem;
