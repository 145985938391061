import AgreementAction from "./BizProcTypedActionItem/AgreementAction";
import ApproveBranchAction from "./BizProcTypedActionItem/ApproveBranchAction";
import GotoAction from "./BizProcTypedActionItem/GotoAction";
import ChangeValuesAction from "./BizProcTypedActionItem/ChangeValuesAction";
import DelayAction from "./BizProcTypedActionItem/DelayAction";
import DocumentGenerateAction from "./BizProcTypedActionItem/DocumentGenerateAction";
import EmailMessageAction from "./BizProcTypedActionItem/EmailMessageAction";
import FormAction from "./BizProcTypedActionItem/FormAction";
import IfElseAction from "./BizProcTypedActionItem/IfElseAction";
import IfElseActionBranch from "./BizProcTypedActionItem/IfElseActionBranch";
import InitialAction from "./BizProcTypedActionItem/InitialAction";
import NotFoundAction from "./BizProcTypedActionItem/NotFoundAction";
import NotificationAction from "./BizProcTypedActionItem/NotificationAction";
import ParallelAction from "./BizProcTypedActionItem/ParallelAction";
import ParallelBranchAction from "./BizProcTypedActionItem/ParallelBranchAction";
import PortalNotificationAction from "./BizProcTypedActionItem/PortalNotificationAction";
import RejectBranchAction from "./BizProcTypedActionItem/RejectBranchAction";
import StartAnotherBizProcAction from "./BizProcTypedActionItem/StartAnotherBizProcAction";
import ChangeStageAction from "./BizProcTypedActionItem/ChangeStageAction";
import CreateChildEntityRowAction from "./BizProcTypedActionItem/CreateChildEntityRowAction";
import CreateEntityRowAction from "./BizProcTypedActionItem/CreateEntityRowAction";

interface IBizProcActionItem {
    templateItem?: TBizProcTemplateAction;
    action?: TBizProcActionType;
    disabled?: boolean;
    onDeleteItem?: (templateItem: TBizProcTemplateAction) => void;
    onUserClick?: (user: TUser) => void;
}

const BizProcActionItem = ({
    templateItem,
    action,
    disabled,
    onDeleteItem,
    onUserClick,
}: IBizProcActionItem) => {
    const onDeleteItem_ = () => {
        if (onDeleteItem && templateItem && !disabled) onDeleteItem(templateItem);
    };

    if (action === "NotificationAction")
        return (
            <NotificationAction
                templateItem={templateItem}
                onDeleteItem={onDeleteItem_}
            />
        );
    if (action === "PortalNotificationAction")
        return (
            <PortalNotificationAction
                templateItem={templateItem}
                onDeleteItem={onDeleteItem_}
            />
        );
    if (action === "FormAction")
        return <FormAction templateItem={templateItem} onDeleteItem={onDeleteItem_} />;
    if (action === "InitialAction") return <InitialAction templateItem={templateItem} />;
    if (action === "IfElseActionBranch")
        return (
            <IfElseActionBranch
                templateItem={templateItem}
                onDeleteItem={onDeleteItem_}
            />
        );
    if (action === "IfElseAction")
        return <IfElseAction templateItem={templateItem} onDeleteItem={onDeleteItem_} />;
    if (action === "EmailMessageAction")
        return (
            <EmailMessageAction
                templateItem={templateItem}
                onDeleteItem={onDeleteItem_}
            />
        );
    if (action === "AgreementAction")
        return (
            <AgreementAction templateItem={templateItem} onDeleteItem={onDeleteItem_} />
        );
    if (action === "ApproveBranchAction")
        return (
            <ApproveBranchAction
                templateItem={templateItem}
                onDeleteItem={onDeleteItem_}
            />
        );
    if (action === "RejectBranchAction")
        return (
            <RejectBranchAction
                templateItem={templateItem}
                onDeleteItem={onDeleteItem_}
            />
        );

    if (action === "ChangeValuesAction")
        return (
            <ChangeValuesAction
                templateItem={templateItem}
                onDeleteItem={onDeleteItem_}
            />
        );
    if (action === "GotoAction")
        return <GotoAction templateItem={templateItem} onDeleteItem={onDeleteItem_} />;
    if (action === "ChangeStageAction")
        return (
            <ChangeStageAction templateItem={templateItem} onDeleteItem={onDeleteItem_} />
        );
    if (action === "DocumentGenerateAction")
        return (
            <DocumentGenerateAction
                templateItem={templateItem}
                onDeleteItem={onDeleteItem_}
            />
        );
    if (action === "StartAnotherBizProcAction")
        return (
            <StartAnotherBizProcAction
                templateItem={templateItem}
                onDeleteItem={onDeleteItem_}
            />
        );
    if (action === "DelayAction")
        return <DelayAction templateItem={templateItem} onDeleteItem={onDeleteItem_} />;
    if (action === "ParallelAction")
        return (
            <ParallelAction templateItem={templateItem} onDeleteItem={onDeleteItem_} />
        );
    if (action === "ParallelBranchAction")
        return (
            <ParallelBranchAction
                templateItem={templateItem}
                onDeleteItem={onDeleteItem_}
            />
        );
    if (action === "CreateChildEntityRowAction")
        return (
            <CreateChildEntityRowAction
                templateItem={templateItem}
                onDeleteItem={onDeleteItem_}
            />
        );
    if (action === "CreateEntityRowAction")
        return (
            <CreateEntityRowAction
                templateItem={templateItem}
                onDeleteItem={onDeleteItem_}
            />
        );
    return <NotFoundAction templateItem={templateItem} onDeleteItem={onDeleteItem_} />;
};

export default BizProcActionItem;
