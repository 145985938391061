import { ViewPatternCreateButton } from "@features/Portal/ViewPattern/ViewPatternCreate";
import { ViewPatternSelect } from "@features/Portal/ViewPattern/ViewPatternSelect";
import ViewPatternEditModal from "./ViewPatternEditModal";
import { Tooltip } from "antd";

interface IViewPatternSelectAndEdit {
    entityId: number;
    tabMode: string;
}

const ViewPatternSelectAndEdit = ({ entityId, tabMode }: IViewPatternSelectAndEdit) => {
    return (
        <div>
            <ViewPatternEditModal entityId={entityId} />
            <Tooltip title={"Шаблоны отображения"} placement="topLeft">
                <div>
                    <ViewPatternSelect
                        tabMode={tabMode}
                        entityId={entityId}
                        additionalNode={<ViewPatternCreateButton entityId={entityId} />}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

export default ViewPatternSelectAndEdit;
