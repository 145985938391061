import { createAsyncThunk } from "@reduxjs/toolkit";
import { getNavigationRoutes } from "../api";

export const getNavigationRoutesThunk = createAsyncThunk(
    "NavigationRouteSlice/getNavigationRoutes",
    async (_, { rejectWithValue }) => {
        try {
            const response = await getNavigationRoutes();
            return response.data.data;
        } catch (error: any) {
            console.log(error);
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
