import { useDispatch, useSelector } from "react-redux";
import {
    selectBizProcAllTemplates,
    selectBizProcTemplates,
} from "./BizProcTemplateSelectors";
import { useEffect } from "react";
import {
    loadBizProcEntityTemplatesThunk,
    loadBizProcTemplatesThunk,
} from "./BizProcTemplateThunk";

export const useLoadBizProcTemplates = (
    entityId: number | undefined,
    preload?: boolean
) => {
    const bizProcTemplates = useSelector(
        entityId ? selectBizProcTemplates : selectBizProcAllTemplates
    );
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (preload) {
            loadBizProcTemplates();
        }
    }, []);

    const loadBizProcTemplates = () => {
        if (entityId) {
            dispatch(loadBizProcEntityTemplatesThunk(entityId));
        } else {
            dispatch(loadBizProcTemplatesThunk());
        }
    };
    return {
        bizProcTemplates,
        loadBizProcTemplates,
    };
};
