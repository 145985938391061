import BizProcListItem from "./BizProcListItem";
import "./BizProcsList.scss";

interface IBizProcsList {
    bizProcs: TBizProc[];
}

const BizProcsList = ({ bizProcs }: IBizProcsList) => {
    return (
        <>
            <div className="bizprocs__list">
                {bizProcs.length > 0 ? (
                    bizProcs.map((bizProc: TBizProc) => {
                        return <BizProcListItem key={bizProc.id} bizProc={bizProc} />;
                    })
                ) : (
                    <div className="bizprocs__list__empty">
                        Бизнес процессы не найдены
                    </div>
                )}
            </div>
        </>
    );
};

export default BizProcsList;
