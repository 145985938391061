export const tmStatusNames = {
    in_progress: "В процессе",
    failed: "Ошибка",
    approved: "Согласован",
    rejected: "Отклонён",
    waiting: "Ожидает согласования",
};
export const tmStatusTagColor = {
    in_progress: "processing",
    failed: "error",
    approved: "success",
    rejected: "warning",
    waiting: "default",
};
