import { Skeleton } from "antd";

interface IStagesEntityRowSkeleton {
    withMargins?: boolean;
}

const StagesEntityRowSkeleton = ({ withMargins }: IStagesEntityRowSkeleton) => {
    return (
        <Skeleton.Input
            block
            size="large"
            active
            style={{ width: "100%", margin: withMargins ? "15px 0" : undefined }}
        />
    );
};

export default StagesEntityRowSkeleton;
