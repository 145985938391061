import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateArrByItemId } from "@shared/lib/object-helper";
import { viewPatternItemBuilder } from "./ViewPatternItemBuilder";

export interface IViewPatternItem {
    viewPatternItems: { [key: number]: TViewPatternItem[] };
    getLoading: boolean;
    createLoading: boolean;
    updateLoading: number[];
    deleteLoading: number[];
}

const initialState: IViewPatternItem = {
    viewPatternItems: [],
    getLoading: false,
    createLoading: false,
    updateLoading: [],
    deleteLoading: [],
};

export const viewPatternItemSlice = createSlice({
    name: "ViewPatternItemSlice",
    initialState,
    reducers: {
        setViewPatternItems: (
            state,
            action: PayloadAction<{
                viewPatternId: number;
                viewPatternItems: TViewPatternItem[];
            }>
        ) => {
            state.viewPatternItems[action.payload.viewPatternId] =
                action.payload.viewPatternItems;
        },
        updateViewPatternItem: (state, action: PayloadAction<TViewPatternItem>) => {
            state.viewPatternItems[action.payload.view_pattern_id] = updateArrByItemId(
                state.viewPatternItems[action.payload.view_pattern_id],
                action.payload
            );
        },
        massUpdateViewPatternItem: (
            state,
            action: PayloadAction<{
                viewPatternId: number;
                viewPatternItems: TViewPatternItem[];
            }>
        ) => {
            const items = action.payload.viewPatternItems;
            items.forEach((item) => {
                state.viewPatternItems[action.payload.viewPatternId] = updateArrByItemId(
                    state.viewPatternItems[action.payload.viewPatternId],
                    item
                );
            });
        },
        deleteViewPatternItem: (state, action: PayloadAction<TViewPatternItem>) => {
            state.viewPatternItems[action.payload.view_pattern_id] =
                state.viewPatternItems[action.payload.view_pattern_id].filter(
                    (item) => item.id !== action.payload.id
                );
        },
    },
    extraReducers: (builder) => {
        viewPatternItemBuilder(builder);
    },
});

export const {
    setViewPatternItems,
    updateViewPatternItem,
    deleteViewPatternItem,
    massUpdateViewPatternItem,
} = viewPatternItemSlice.actions;
export default viewPatternItemSlice.reducer;
