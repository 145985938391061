export const selectOperations = (state: RootState): TTechMapTemplateOperation[] =>
    state.techMapTemplate.techMapTemplateOperations;

export const selectGetOperationLoading = (state: RootState): boolean =>
    state.techMapTemplate.getOperationLoading;
export const selectUpdateOperationLoading = (
    state: RootState,
    operationUuid: string
): boolean => state.techMapTemplate.updateOperationLoading.includes(operationUuid);
export const selectGetOneOperationLoading = (
    state: RootState,
    operationUuid: string | null
): boolean => state.techMapTemplate.getOneOperationLoading.includes(operationUuid);
export const selectCreateOperationLoading = (state: RootState): boolean =>
    state.techMapTemplate.createOperationLoading;
export const selectDeleteOperationLoading = (
    state: RootState,
    operationUuid: string
): boolean => state.techMapTemplate.deleteOperationLoading.includes(operationUuid);
