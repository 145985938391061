import { FormItem } from "@shared/ui/Form";
import { Button, Select, Space } from "antd";
import { useEntityRowObserversForm } from "../model";
import { EntityRowObserversTypesOptions } from "../constants";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";

interface IEntityRowObserverForm {
    onCancel?: () => void;
    entityUuid: string;
    bizProcTemplates: TBizProcTemplateItem[];
}

const EntityRowObserverForm = ({
    entityUuid,
    bizProcTemplates,
    onCancel,
}: IEntityRowObserverForm) => {
    const {
        bizProcTemplateOptions,
        bizProcTempalateUuid,
        type,
        loading,
        onChangeBizProcTemplate,
        onSubmit,
        onClose,
        onChangeType,
    } = useEntityRowObserversForm({ entityUuid, bizProcTemplates, onCancel });
    return (
        <div>
            <FormItem label="Тип слушителя">
                <>
                    <Select
                        style={{ width: "100%" }}
                        options={EntityRowObserversTypesOptions}
                        value={type}
                        onChange={onChangeType}
                    />
                    {/* <ErrorText text={parseErrorMessageByKey(error, "name")} /> */}
                </>
            </FormItem>
            <FormItem label="Бизнес-процесс">
                <>
                    <Select
                        style={{ width: "100%" }}
                        options={bizProcTemplateOptions}
                        value={bizProcTempalateUuid}
                        onChange={onChangeBizProcTemplate}
                    />
                    {/* <ErrorText text={parseErrorMessageByKey(error, "name")} /> */}
                </>
            </FormItem>
            <FlexContainer type="row-end">
                <Space>
                    <Button onClick={onClose}>Отмена</Button>
                    <Button
                        loading={loading}
                        disabled={!bizProcTempalateUuid}
                        type="primary"
                        onClick={onSubmit}
                    >
                        Сохранить
                    </Button>
                </Space>
            </FlexContainer>
        </div>
    );
};

export default EntityRowObserverForm;
