import { calculatedValueModel } from "@entities/Portal/CalculatedValues";
import { CardError } from "@shared/ui/Cards/CardError";
import { useSelector } from "react-redux";
import CalculatedValueNormalItem from "./CalculatedValueNormalItem";
import "./CalculatedValuesList.scss";
import CalculatedValuesSkeletonList from "./CalculatedValuesSkeletonList";

const CalculatedValuesList = () => {
    const calculatedValues = useSelector(calculatedValueModel.selectCalculatedValues);
    const isLoading = useSelector(calculatedValueModel.selectIsLoading);
    const error = useSelector(calculatedValueModel.selectError);

    if (error) {
        const { message } = error?.error;
        const { status } = error?.payload;
        return <CardError message={message} status={status} />;
    }
    if (calculatedValues.length > 0) {
        return (
            <>
                <div className="calculatedValues-list">
                    {calculatedValues.map((calculatedValue) => {
                        return (
                            <div
                                key={calculatedValue.id}
                                className="calculatedValues-list__item"
                            >
                                <CalculatedValueNormalItem
                                    calculatedValue={calculatedValue}
                                />
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }
    if (isLoading && calculatedValues.length < 1) {
        return <CalculatedValuesSkeletonList />;
    }
    return <></>;
    //return <EmptyPage text="Переменные не найдены" button={<CalculatedValueCreateButton />} />;
};

export default CalculatedValuesList;
