import { DeleteOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import { useConditionItem } from "../model";
import "./Condition.scss";
import { FormInputItem } from "@entities/Portal/Datatype";

interface IConditionItem {
    condition: TIfElseBranchCondition;
    bizProcTemplate: TBizProcTemplateItem;
    templateItem: TBizProcTemplateAction;
    disabled?: boolean;
    onChange: (
        conditionGroup: TIfElseBranchConditionGroup | TIfElseBranchCondition
    ) => void;
    onDelete: (
        conditionGroup: TIfElseBranchConditionGroup | TIfElseBranchCondition
    ) => void;
}

const ConditionItem = ({
    condition,
    bizProcTemplate,
    templateItem,
    disabled,
    onChange,
    onDelete,
}: IConditionItem) => {
    const variables = bizProcTemplate.variables ?? [];
    const {
        onSelectVariable,
        onChangeValue,
        onDeleteItem,
        onChangeLogicalComparison,
        onSelectCondition,
    } = useConditionItem({
        condition,
        bizProcTemplate,
        templateItem,
        onChange,
        onDelete,
    });

    return (
        <div className="condition-item">
            <Button disabled={disabled} onClick={onChangeLogicalComparison} type="link">
                {condition.logicalComparison === "and" ? "И" : "ИЛИ"}
            </Button>
            <Select
                disabled={disabled}
                style={{
                    marginRight: 10,
                    width: "100%",
                }}
                options={variables.map((item) => ({
                    value: `${item.variable_type}-${item.key}`,
                    label: item.name,
                    variable: item,
                }))}
                onSelect={onSelectVariable}
                value={
                    condition.variable
                        ? `${condition.variable.variable_type}-${condition.variable.key}`
                        : null
                }
            />
            <Select
                disabled={disabled}
                style={{
                    marginRight: 10,
                    maxWidth: 120,
                    minWidth: 120,
                }}
                onSelect={onSelectCondition}
                options={condition.variable?.comparison_types ?? []}
                value={condition.comparison}
            />
            <div style={{ width: "100%", marginRight: 10 }}>
                {condition.comparison != "null" &&
                    condition.comparison != "not_null" &&
                    condition.variable && (
                        <FormInputItem
                            onlyRead={disabled}
                            additionalParameters={{ params: { withCalcValues: 1 } }}
                            field={condition.variable}
                            inputId={`${condition.variable.variable_type}-${condition.variable.id}`}
                            datatype={condition.variable.datatype as unknown as string}
                            value={condition.value}
                            onChange={onChangeValue}
                        />
                    )}
            </div>

            <Button
                disabled={disabled}
                type="link"
                onClick={onDeleteItem}
                danger
                icon={<DeleteOutlined />}
            />
        </div>
    );
};

export default ConditionItem;
