import { TMOperationsList } from "@entities/Production/TechMap";
import { useTMOperations } from "../model";
import "./TMOperations.scss";
import { Skeleton } from "antd";

interface ITMOperations {}

const TMOperations = ({}: ITMOperations) => {
    const { templateOperationsWithMapOperation, loading, startedTm } = useTMOperations();
    return (
        <div className="tm-operations">
            <div className="tm-operations__header">
                <div>Выполняется:</div>
                <div className="tm-operations__header__name">
                    {loading ? (
                        <Skeleton
                            active
                            rootClassName="tm-skeleton"
                            title={false}
                            paragraph={{ rows: 1, width: [200] }}
                        />
                    ) : (
                        startedTm?.template.name
                    )}
                </div>
            </div>
            <TMOperationsList
                templateOperationsWithMapOperation={templateOperationsWithMapOperation}
                loading={loading}
            />
        </div>
    );
};

export default TMOperations;
