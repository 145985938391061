import { estimationLib } from "@entities/Portal/Estimations";

export const getViewModeForTemplate = (
    estimationTemplate: TCreatingEstimationTemplate | TEstimationTemplate | null,
    user: TUser
): TEstimationFormViewMode => {
    if (!estimationTemplate) return null;
    if (!("id" in estimationTemplate)) return "creating";
    if (
        estimationLib.checkAccessForEditDeleteTemplate(
            user,
            estimationTemplate.created_by_id
        )
    )
        return "editing";
    return "viewing";
};
