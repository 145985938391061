import { techMapModel } from "@entities/Production/TechMap";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useTMTBarItem = (item: TTechMapTemplateExecuble) => {
    const startingLoading = useSelector((state: RootState) =>
        techMapModel.selectStartingLoading(state, item.id)
    );
    const isStartedTmt = useSelector((state: RootState) =>
        techMapModel.selectIsStartedTmt(state, item.uuid)
    );
    const startedTmt = useSelector(techMapModel.selectStartedTm);
    const dispatch = useDispatch<AppDispatch>();

    const onStartTmt = () => {
        if (startedTmt === null) dispatch(techMapModel.startTmtThunk({ tmtId: item.id }));
        else
            notificationEmit({
                type: "warning",
                title: "Уже есть запущенная операция",
            });
    };
    return {
        isStartedTmt,
        startingLoading,
        onStartTmt,
    };
};
