import { useDispatch } from "react-redux";
import { setEditingVariable } from "./VariableSlice";

export const useVariableItem = (
    variable: TVariable,
    onDelete: (variable: TVariable) => void
) => {
    const dispatch = useDispatch<AppDispatch>();
    const onSubmitDelete = () => {
        onDelete(variable);
    };
    const onEdit = () => {
        dispatch(setEditingVariable(variable));
    };

    return {
        onEdit,
        onSubmitDelete,
    };
};
