import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { useTMTBar } from "../model";
import "./TMTBar.scss";
import TMTBarItem from "./TMTBarItem";
import { Skeleton } from "antd";

interface ITMTBar {}

const TMTBar = ({}: ITMTBar) => {
    const { executableTmts, loading } = useTMTBar();
    return (
        <div className="tmt-bar">
            <div className="tmt-bar__header">
                <span className="tmt-bar__header__label">Доступные процессы</span>
            </div>
            <div className="tmt-bar__content">
                {loading ? (
                    <div className="tmt-bar__content__skeleton">
                        <Skeleton
                            active
                            title={false}
                            paragraph={{ rows: 5, width: "100%" }}
                        />
                    </div>
                ) : (
                    <div className="tmt-bar__content__items">
                        {executableTmts.map((item) => {
                            return (
                                <IndentContainer key={item.uuid}>
                                    <TMTBarItem item={item} />
                                </IndentContainer>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TMTBar;
