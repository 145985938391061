import { datatypeModel } from "@entities/Portal/Datatype";
import { entityApi } from "@entities/Portal/Entity";
import { EntityFieldItemList } from "@features/Portal/Entity/EntityFieldItemList";
import { EntityRowObserversList } from "@features/Portal/Entity/EntityRowObserversList";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { useHeaderLayout } from "@shared/ui/Header";
import { CategoriesListWithEditing } from "@widgets/Portal/CategoryWidgets/CategoriesListWithEditing";
import { CreateUpdateEntityForm } from "@widgets/Portal/Entity/CreateUpdateEntity";
import { UpdateCreateEntityField } from "@widgets/Portal/Entity/EntityFieldUpdating";
import { Card, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

interface IEntityPage {}

const EntityPage = ({}: IEntityPage) => {
    const entityId = Number(useParams().id ?? 0);
    const datatypes = useSelector(datatypeModel.selectDatatypes);
    const [entity, setEntity] = useState<TEntity | null>(null);
    const [loading, setLoading] = useState(true);
    datatypeModel.useLoadDatatypes();
    useEffect(() => {
        setLoading(true);
        entityApi
            .getEntity(entityId)
            .then((response) => {
                const entity = response.data.data;
                setEntity(entity);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    }, [entityId]);
    useHeaderLayout({
        title: entity?.name,
    });
    if (loading)
        return (
            <Card>
                <FlexContainer>
                    <Spin />
                </FlexContainer>
            </Card>
        );
    return (
        <div>
            <IndentContainer defaultValue={20}>
                <Card bordered={false}>
                    <Typography.Title level={5} style={{ marginTop: 0 }}>
                        Настройка сущности
                    </Typography.Title>
                    <CreateUpdateEntityForm entityId={entityId} editingEntity={entity} />
                </Card>
            </IndentContainer>
            <IndentContainer defaultValue={20}>
                <Card bordered={false}>
                    <Typography.Title level={5} style={{ marginTop: 0 }}>
                        Настройка полей
                    </Typography.Title>
                    <UpdateCreateEntityField datatypes={datatypes} entityId={entityId} />
                    <EntityFieldItemList entityId={entityId} view="list" />
                </Card>
            </IndentContainer>
            <IndentContainer defaultValue={20}>
                <Card bordered={false}>
                    {entity && <EntityRowObserversList entityUuid={entity?.uuid} />}
                </Card>
            </IndentContainer>

            <Card bordered={false}>
                <CategoriesListWithEditing entityId={entityId} />
            </Card>
        </div>
    );
};

export default EntityPage;
