import { $api } from "@shared/api/api";
const route = "api/portal-setting";
export const getPortalSettings = () => {
    return $api.get<TResponseList<TPortalSetting>>(route);
};
export const createPortalSetting = (portalSetting: TCreatingPortalSetting) => {
    return $api.post<TResponse<TPortalSetting>>(route, portalSetting);
};
export const deletePortalSetting = (id: number) => {
    return $api.delete<TResponse<TPortalSetting>>(`${route}/${id}`);
};
export const updatePortalSetting = (portalSetting: TPortalSetting) => {
    return $api.put<TResponse<TPortalSetting>>(
        `${route}/${portalSetting.id}`,
        portalSetting
    );
};
