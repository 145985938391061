import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectEntityFields } from "./EntityFieldSelectors";
import { useDispatch } from "react-redux";
import { loadEntityFieldsThunk } from "./EntityFieldThunk";
import { setEntityFields } from "./EntityFieldSlice";

export const useLoadEntityFields = (
    entityId: number | undefined,
    entityUuid: string | undefined
) => {
    const [loading, setLoading] = useState(false);
    const fields = useSelector(selectEntityFields);

    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        if (entityId || entityUuid) {
            setLoading(true);
            dispatch(loadEntityFieldsThunk({ entityId, entityUuid })).finally(() => {
                setLoading(false);
            });
        }

        return () => {
            dispatch(setEntityFields([]));
        };
    }, [entityId, entityUuid]);

    return {
        loading,
        fields,
    };
};
