import ActionCard from "./ActionCard";

interface ICreateChildEntityRowAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const CreateChildEntityRowAction = ({
    onDeleteItem,
    templateItem,
}: ICreateChildEntityRowAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="indianred"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Создание дочерней записи сущности"}
        ></ActionCard>
    );
};

export default CreateChildEntityRowAction;
