import { v4 as uuid } from "uuid";

export const getNecessaryItemMock = (
    tmtoUuid: string
): TOperationNecessaryItemCreating => {
    const itemUuid = uuid();
    return {
        tmp_id: itemUuid,
        type: "filling",
        label: "Необходимый элемент",
        count: 1,
        technical_map_template_operation_uuid: tmtoUuid,
        related_entity_uuid: null,
        related_entity_row_id: null,
        uuid: itemUuid,
        necessary_type: "components",
        condition_items: [],
    };
};
