import { FormItem } from "@shared/ui/Form";
import { Button, Input, Space } from "antd";
import { useCategoryCreateUpdateForm } from "../model";

interface ICategoryCreateUpdateForm {
    category: TCategory | TCategoryCreating;
    entityId: number;
    onSave: (category: TCategory | TCategoryCreating) => void;
    onCancel: () => void;
}

const CategoryCreateUpdateForm = ({
    category,
    entityId,
    onSave,
    onCancel,
}: ICategoryCreateUpdateForm) => {
    const { loading, isCreated, editingCategory, onChangeCategoryLabel, onSubmit } =
        useCategoryCreateUpdateForm(category, entityId, onSave);
    return (
        <div>
            <FormItem label="Имя">
                <Input value={editingCategory.label} onChange={onChangeCategoryLabel} />
            </FormItem>
            {isCreated && (
                <FormItem label="UUID">
                    <Input
                        disabled
                        value={(editingCategory as TCategory).uuid}
                        onChange={onChangeCategoryLabel}
                    />
                </FormItem>
            )}

            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                <Space>
                    {onCancel && <Button onClick={onCancel}>Закрыть</Button>}
                    <Button type="primary" onClick={onSubmit} loading={loading}>
                        Сохранить
                    </Button>
                </Space>
            </div>
        </div>
    );
};

export default CategoryCreateUpdateForm;
