import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    deleteCapabilitiesRoles,
    deleteUserRole,
    getCapabilitiesRoles,
    saveCapabilitiesRoles,
} from "../api";

export const loadCapabilitiesRolesThunk = createAsyncThunk(
    "rolesCapabilitySlice/loadCapabilitiesRolesThunk",
    async (params: TGetCapabilitiesRolesParams, { rejectWithValue }) => {
        try {
            const response = await getCapabilitiesRoles(params);
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateCapabilitiesRolesThunk = createAsyncThunk(
    "rolesCapabilitySlice/updateCapabilitiesRolesThunk",
    async (
        {
            id,
            items,
            newInitialValues,
        }: {
            id: number;
            items: TCapabilitiesRolesTableData;
            newInitialValues: TCapabilitiesRolesInitialValues;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await saveCapabilitiesRoles({ entityId: id, items });
            return { data: response.data.data, newInitialValues };
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const addCapabilitiesRolesThunk = createAsyncThunk(
    "rolesCapabilitySlice/addCapabilitiesRolesThunk",
    async (
        {
            id,
            items,
            newCapabilityEntityRowRolesOnly,
        }: {
            id: number;
            items: TCapabilitiesRolesTableData;
            newCapabilityEntityRowRolesOnly: TCapabilitiesRolesOnly;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await saveCapabilitiesRoles({ entityId: id, items });
            return { data: response.data.data, newCapabilityEntityRowRolesOnly };
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const deleteCapabilitiesRolesThunk = createAsyncThunk(
    "rolesCapabilitySlice/deleteCapabilitiesRolesThunk",
    async ({ entityId, entityRowId }: Record<string, number>, { rejectWithValue }) => {
        try {
            const response = await deleteCapabilitiesRoles(entityId, entityRowId);
            return { data: response.data.data, entityRowId };
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const deleteUserRoleThunk = createAsyncThunk(
    "rolesCapabilitySlice/deleteUserRoleThunk",
    async (
        { entityId, entityRowId }: { entityId: number; entityRowId: number },
        { rejectWithValue }
    ) => {
        try {
            const response = await deleteUserRole(entityId, entityRowId);
            return { data: response.data.data, entityRowId };
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
