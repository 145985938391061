import { FormInputItem } from "@entities/Portal/Datatype";
import { useUserTimezone } from "./model";

interface ISelectUserTimezone {
    timezoneId: string | null;
    userId: number;
    onlyRead?: boolean;
}

const SelectUserTimezone = ({ userId, onlyRead, timezoneId }: ISelectUserTimezone) => {
    const { onChangeTimezone, timezone, loading } = useUserTimezone(userId, timezoneId);
    return (
        <FormInputItem
            field={{
                name: "Временная зона",
                datatype: "entityList",
                key: "timezone_id",
                view: false,
                is_unwritable: false,
                through_field: false,
                related_entity_uuid: "31dca36f-626f-47d8-856a-eeeb6c9939d7",
                key_label: "name",
                key_value: "id",
            }}
            onlyRead={onlyRead}
            additionalParameters={{
                loading: loading,
            }}
            datatype={"entityList"}
            value={timezone}
            onChange={onChangeTimezone}
        />
    );
};

export default SelectUserTimezone;
