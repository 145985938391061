import React, { CSSProperties } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "./Column";
import "./DnDList.scss";
import { prepareDataForDndList } from "./lib";
import { useDnDList } from "./model";

interface IDnDList<T> {
    data: T[];
    columnTypes: TColumnType[];
    columnValue: keyof T;
    unicKey: keyof T;
    height?: string;
    customTitleStyle?: CSSProperties;
    isPaginationLoading: { [columnValue: string]: boolean };
    columnBottomReachingHandler: (item: any) => void;
    onDragEndFunc?: (item: T, lastColumnValue: any, currentColumnValue: any) => void;
    columnItem: (pureItem: T) => React.ReactNode;
}

const DnDList = <T,>({
    data,
    columnTypes,
    columnValue,
    unicKey,
    height,
    customTitleStyle,
    isPaginationLoading,
    columnBottomReachingHandler,
    onDragEndFunc,
    columnItem,
}: IDnDList<T>) => {
    const preparedData = prepareDataForDndList(data, columnTypes, columnValue, unicKey);

    const { handleDragAndDrop, handleDragStart, handleDragEnd } = useDnDList(
        data,
        unicKey,
        onDragEndFunc
    );

    return (
        <div
            className="dnd-block"
            style={{ height: height ? height : "calc(100vh - 120px)" }}
        >
            <div className="dnd">
                <DragDropContext
                    onDragEnd={(results, provided) => {
                        handleDragAndDrop(results);

                        handleDragEnd(results);
                    }}
                    onDragStart={handleDragStart}
                >
                    <div className="dnd__columns">
                        {preparedData.map((item, index) => {
                            return (
                                <div key={index} className="dnd__column" style={{}}>
                                    <div
                                        className="dnd__column-title"
                                        style={{
                                            ...customTitleStyle,
                                            borderRadius: 0,
                                            zIndex: 998,
                                            top: 0,
                                            position: "sticky",
                                            width: "100%",
                                            minHeight: 48,
                                        }}
                                    >
                                        {item.label}
                                    </div>
                                    <Column<T>
                                        column={item}
                                        columnBottomReachingHandler={
                                            columnBottomReachingHandler
                                        }
                                        columnItem={columnItem}
                                        isPaginationLoading={isPaginationLoading}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </DragDropContext>
            </div>
        </div>
    );
};

export default DnDList;
