import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLoadBizProcsIds } from "./BizProcSelectors";
import { loadBizProcTemplateThunk, loadBizProcsAgreementsThunk } from "./BizProcThunk";

export const usePostLoadBizProcs = () => {
    const loadBizProcsIds = useSelector(selectLoadBizProcsIds);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const bizProcTemplateId = loadBizProcsIds?.[0];

        if (bizProcTemplateId) {
            dispatch(
                loadBizProcTemplateThunk({
                    filter: {
                        key: "id",
                        value: `${bizProcTemplateId}`,
                        condition: "=",
                    },
                })
            );
        }
    }, [loadBizProcsIds?.[0]]);
};
