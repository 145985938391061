export const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: "none",
    marginTop: 8,
    display: "flex",
    alignItem: "center",
    ...draggableStyle,
});

export const getListStyle = (isDraggingOver: any) => ({
    padding: 8,
});

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
