import { $api } from "@shared/api/api";
import { downloadJsonAsFile } from "@shared/lib/download-json";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { MouseEvent, useState } from "react";

export const useExport = (url: string, filename: string) => {
    const [loading, setLoading] = useState(false);

    const exportItems = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setLoading(true);
        $api.get("/api/" + url)
            .then((response: any) => {
                downloadJsonAsFile(response.data.data, `${filename}.json`);
            })
            .catch((error) => {
                notificationEmit({
                    error: error,
                    title: "Не удалось сделать экспорт бизнес-процесса",
                    type: "error",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return {
        loading,
        exportItems,
    };
};
