import { $api } from "@shared/api/api";
const route = "/api/entity";

export const getStages = (
    entityId: number,
    categoryId: number | null,
    params?: TParams
) => {
    return $api.get<TResponseList<TStage>>(
        categoryId
            ? `${route}/${entityId}/categories/${categoryId}/stages`
            : `${route}/${entityId}/all-categories/all-stages`,
        { params: params }
    );
};
export const getEntityRowStages = (
    entityTableName: string,
    entityRowId: number,
    params?: TParams
) => {
    return $api.get<
        TResponse<{ stages: TStage[]; currentStage: string }> | TResponse<null>
    >(`${route}/by-name/${entityTableName}/stages/${entityRowId}`, { params: params });
};

export const updateStage = (
    entityId: number,
    categoryId: number,
    stage: TStage,
    updatePrevStageUuid?: boolean
) => {
    return $api.put<TResponse<TStage | TStage[]>>(
        `${route}/${entityId}/categories/${categoryId}/stages/${stage.id}${updatePrevStageUuid ? "?updatePrevStageUuid=1" : ""}`,
        stage
    );
};

export const deleteStage = (entityId: number, categoryId: number, stageId: number) => {
    return $api.delete<TResponseList<TStage>>(
        `${route}/${entityId}/categories/${categoryId}/stages/${stageId}`
    );
};

export const createStage = (
    entityId: number,
    categoryId: number,
    stage: TStageCreating
) => {
    return $api.post<TResponse<TStage>>(
        `${route}/${entityId}/categories/${categoryId}/stages`,
        stage
    );
};
export const deleteStages = (entityId: number, categoryId: number, id: number) => {
    return $api.delete<TResponse<TStage>>(
        `${route}/${entityId}/categories/${categoryId}/stages/${id}`
    );
};
