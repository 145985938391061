import { techMapModel } from "@entities/Production/TechMap";
import { techMapTemplateLib } from "@entities/Production/TechMapTemplate";
import { useMemo } from "react";

export const useTMOperations = () => {
    const { startedTm, loading } = techMapModel.useLoadStartedTms();

    const templateOperationsWithMapOperation = useMemo(() => {
        return techMapTemplateLib.getTemplateWithMapOperations(startedTm);
    }, [startedTm?.operations]);

    return { templateOperationsWithMapOperation, startedTm, loading };
};
