import { ArrowDownOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Dropdown, MenuProps } from "antd";
import "./StagesEntityRow.scss";
import { contrastColor } from "@shared/lib/colors";
interface IStageEntityRowFinalButton {
    finalStages: TStage[];
    type: "successful" | "unsuccessful";
    onClickStage: (stageId: number) => void;
}

const StageEntityRowFinalButton = ({
    finalStages,
    type,
    onClickStage,
}: IStageEntityRowFinalButton) => {
    const stages = finalStages.filter(
        (stage) => stage.prev_stage_uuid !== null && stage.type === type
    );
    const mainStage = stages?.[0];
    const items: MenuProps["items"] = stages.map((stage) => {
        return {
            key: stage.id,
            style: {
                padding: 0,
                width: "100%",
            },
            label: (
                <div
                    style={{
                        width: 300,
                        padding: 10,
                    }}
                >
                    {stage.label}
                </div>
            ),
        };
    });

    const handleStageClick = () => {
        onClickStage(Number(mainStage.id));
    };

    const handleMenuClick: MenuProps["onClick"] = (e) => {
        onClickStage(Number(e.key));
    };
    const menuProps = {
        items: items,
        onClick: handleMenuClick,
    };

    if (!mainStage) return <></>;
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: mainStage.background_color,
                },
            }}
        >
            <div className={"final-stages-modal__button"}>
                <Button
                    style={{
                        width: "100%",
                        borderTopRightRadius: stages.length > 1 ? 0 : undefined,
                        borderBottomRightRadius: stages.length > 1 ? 0 : undefined,
                        color: contrastColor(mainStage.background_color),
                    }}
                    type="primary"
                    onClick={handleStageClick}
                >
                    {mainStage.label}
                </Button>

                {stages.length > 1 && (
                    <Dropdown
                        trigger={["click"]}
                        menu={menuProps}
                        placement="bottomRight"
                    >
                        <Button
                            style={{
                                width: 60,
                                borderLeft: "1px solid #cecece",
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                            }}
                            icon={<ArrowDownOutlined />}
                            type="primary"
                        />
                    </Dropdown>
                )}
            </div>
        </ConfigProvider>
    );
};

export default StageEntityRowFinalButton;
