import { TechMapTmlEditor } from "@widgets/Production/TechMapTmlEditor";
import { useParams } from "react-router-dom";

interface ITechMapEditorPage {}

const TechMapEditorPage = ({}: ITechMapEditorPage) => {
    const techMapTemplateId = useParams().id;

    return (
        <div>
            <TechMapTmlEditor
                techMapTemplateId={techMapTemplateId ? Number(techMapTemplateId) : null}
            />
        </div>
    );
};

export default TechMapEditorPage;
