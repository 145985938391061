import { Fragment } from "react";
import { TSkeletonItem } from "./types";
import { Skeleton as AntdSkeleton } from "antd";
interface ISkeleton {
    items: TSkeletonItem[];
}

const Skeleton = ({ items }: ISkeleton) => {
    return (
        <>
            {items.map((item, index) => {
                return (
                    <Fragment key={index}>
                        {item.type === "div" ? (
                            <div style={item.containerStyle}>
                                <Skeleton items={item.items} />
                            </div>
                        ) : item.type === "skeleton" ? (
                            <AntdSkeleton active {...item} />
                        ) : item.type === "group" ? (
                            <div style={item.containerStyle}>
                                <Skeleton
                                    items={Array.from(
                                        { length: item.count },
                                        (value, index) => item.item
                                    )}
                                />
                            </div>
                        ) : item.type === "node" ? (
                            <AntdSkeleton.Node style={item.containerStyle} active>
                                {item.node}
                            </AntdSkeleton.Node>
                        ) : (
                            <></>
                        )}
                    </Fragment>
                );
            })}
        </>
    );
};

export default Skeleton;
