import { entityRowModel } from "@entities/Portal/EntityRow";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useEntityRowCategorySelect = (entityId: number) => {
    const currentCategory = useSelector(entityRowModel.selectCurrentCategory);
    const dispatch = useDispatch();
    useEffect(() => {
        const category = localStorage.getItem(`${entityId}_rows_category`);
        dispatch(
            entityRowModel.setCurrentCategory({
                categoryId: category ? Number(category) : null,
                entityId,
            })
        );
        // return () => {
        //     dispatch(
        //         entityRowModel.setCurrentCategory({
        //             categoryId: null,
        //             entityId,
        //         })
        //     );
        // };
    }, []);
    const onChangeCategory = (value: number) => {
        if (value) localStorage.setItem(`${entityId}_rows_category`, value.toString());
        else localStorage.removeItem(`${entityId}_rows_category`);
        dispatch(
            entityRowModel.setCurrentCategory({
                categoryId: value,
                entityId,
            })
        );
    };

    return { currentCategory, onChangeCategory };
};
