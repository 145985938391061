import { FloatingContainer } from "@shared/ui/Containers/FloatingContainer";
import { TextWithLabel } from "@shared/ui/Text";
import { Card, Divider } from "antd";
import { ReactNode } from "react";

interface IBizProcTemplateListItem {
    bizProcTemplateItem: TBizProcTemplateItem;
    bottomActions?: ReactNode;
    topActions?: ReactNode;
    setIsEditing?: (state: boolean) => void;
    onClick?: () => void;
}

const BizProcTemplateListItem = (props: IBizProcTemplateListItem) => {
    const { bizProcTemplateItem, onClick, topActions, bottomActions } = props;

    return (
        <Card onClick={onClick}>
            <FloatingContainer>{topActions && topActions}</FloatingContainer>
            <TextWithLabel
                editing={false}
                label={"Наименование"}
                text={bizProcTemplateItem.name}
            />
            <div>
                <Divider style={{ marginBottom: 10 }} />
                {bottomActions}
            </div>
        </Card>
    );
};

export default BizProcTemplateListItem;
