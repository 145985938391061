import { entityFieldModel } from "@entities/Portal/EntityField";
import { techMapTemplateConstant } from "@entities/Production/TechMapTemplate";
import { useMemo } from "react";
import { codeDatatypes, qtyDatatypes } from "../constants";
import { compareResultById } from "../lib";

export const useTechMapTemplateResult = (
    entityUuid: string,
    tmtResults: (TTechMapTemplateResult | TTechMapTemplateResultCreating)[],
    onChange: (
        tmtResults: (TTechMapTemplateResult | TTechMapTemplateResultCreating)[]
    ) => void
) => {
    const { fields } = entityFieldModel.useLoadEntityFields(undefined, entityUuid);
    const entityFieldsOptions: TSelectOption<TEntityField>[] = useMemo(() => {
        return fields.map((field) => ({
            item: field,
            label: field.name,
            value: field.key,
        }));
    }, [fields]);

    const entityFieldsOptionsForQty = useMemo(() => {
        return entityFieldsOptions.filter((option) =>
            qtyDatatypes.includes(option.item.datatype)
        );
    }, [entityFieldsOptions]);
    const entityFieldsOptionsForCodes = useMemo(() => {
        return entityFieldsOptions.filter((option) =>
            codeDatatypes.includes(option.item.datatype)
        );
    }, [entityFieldsOptions]);

    const onChangeItem = (
        item: TTechMapTemplateResult | TTechMapTemplateResultCreating
    ) => {
        onChange(
            tmtResults.map((result) => {
                return compareResultById(item, result) ? item : result;
            })
        );
    };

    const onCreate = () => {
        onChange([...tmtResults, techMapTemplateConstant.techMapTemplateResultMock]);
    };
    const onDelete = (item: TTechMapTemplateResult | TTechMapTemplateResultCreating) => {
        onChange(
            tmtResults.filter((result) => {
                return !compareResultById(item, result);
            })
        );
    };
    return {
        entityFieldsOptionsForQty,
        entityFieldsOptionsForCodes,
        entityFieldsOptions,
        onChangeItem,
        onCreate,
        onDelete,
    };
};
