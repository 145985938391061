import { useMemo } from "react";

export const useTechMapTmplRoles = (
    reviewerRoles: TUserRole[],
    executorRoles: TUserRole[],
    onChange: (key: keyof TTechMapTemplate, value: any) => void
) => {
    const reviewerRoleIds = useMemo(() => {
        return reviewerRoles.map((role) => role.id);
    }, [reviewerRoles]);

    const executorRoleIds = useMemo(() => {
        return executorRoles.map((role) => role.id);
    }, [executorRoles]);

    const onChangeExecutors = (value: number[], entityRows: TUserRole[]) => {
        onChange("executor_roles", entityRows);
    };
    const onChangeReviewers = (value: number[], entityRows: TUserRole[]) => {
        onChange("reviewer_roles", entityRows);
    };

    return {
        reviewerRoleIds,
        executorRoleIds,
        onChangeExecutors,
        onChangeReviewers,
    };
};
