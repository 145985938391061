import { Modal } from "antd";
import CreateCapabilitiesRolesForm from "./CreateCapabilitiesRolesForm";
import React from "react";

interface ICreateCapabilitiesRolesModal {
    role: TUserRole;
    open: boolean;
    onCloseModal: () => void;
    entityCapabilitiesRolesId: number;
    extraFormItem: (
        entityRow: TCapabilitiesRolesRow,
        value: string | null,
        onChangeRow: (capType: TCapType, value: any) => void
    ) => React.ReactNode;
}

const CreateCapabilitiesRolesModal = ({
    role,
    open,
    extraFormItem,
    onCloseModal,
    entityCapabilitiesRolesId,
}: ICreateCapabilitiesRolesModal) => {
    return (
        <Modal
            style={{ top: 20 }}
            destroyOnClose
            open={open}
            footer={<></>}
            width={"60%"}
            onCancel={onCloseModal}
        >
            <CreateCapabilitiesRolesForm
                entityCapabilitiesRolesId={entityCapabilitiesRolesId}
                role={role}
                onSubmitted={onCloseModal}
                onCanceled={onCloseModal}
                extraFormItem={extraFormItem}
            />
        </Modal>
    );
};

export default CreateCapabilitiesRolesModal;
