import { CardWithHead } from "@shared/ui/Cards/CardWithHead";
import { Skeleton } from "antd";
import "./BizProcTimelineItemChildrenSkeleton.scss";
const BizProcTimelineItemChildrenSkeleton = () => {
    return (
        <CardWithHead
            title={
                <Skeleton
                    title={false}
                    paragraph={{ rows: 1, width: 300 }}
                    active={true}
                />
            }
            noColor={true}
            titleClassName="bizproc-action__title bizproc-action-title--skeleton"
            childrenClassName="bizproc-action__body"
        >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Skeleton paragraph={{ rows: 3 }} active={true} />
            </div>
        </CardWithHead>
    );
};

export default BizProcTimelineItemChildrenSkeleton;
