export const getEntityRowsByEntityFieldAfterDnD = (
    prevEntityRowsByEntityField: TEntityRowsByEntityField,
    movedElement: any,
    lastColumnValue: any,
    currentColumnValue: any,
    choosenEntityField: TEntityField | null
) => {
    const newEntityRowsByEntityField = { ...prevEntityRowsByEntityField };

    newEntityRowsByEntityField[lastColumnValue ?? ""].count -= 1;
    newEntityRowsByEntityField[lastColumnValue ?? ""].data = newEntityRowsByEntityField[
        lastColumnValue ?? ""
    ].data.filter((item: any) => {
        return item.id !== movedElement.id;
    });
    newEntityRowsByEntityField[currentColumnValue ?? ""].count += 1;
    newEntityRowsByEntityField[currentColumnValue ?? ""].data = [
        ...newEntityRowsByEntityField[currentColumnValue ?? ""].data,
        {
            ...movedElement,
            [choosenEntityField!.key]: currentColumnValue ?? "",
            is_invisible_for_pagination: false,
        },
    ].sort((a, b) => b.id - a.id);
    return newEntityRowsByEntityField;
};
export const getIdForPaginationRequest = (entityRowsByEntityField: {
    data: any[];
    count: number;
    is_ended: boolean;
}) => {
    const firstEl = entityRowsByEntityField.data[0];
    return entityRowsByEntityField.data.reduce((smallestId, item) => {
        if (item.id < smallestId || item.is_invisible_for_pagination) return item.id;
        else return smallestId;
    }, firstEl.id);
};

export const saveKanbanConfigurationToLocalStorage = (
    entityUuid: string,
    nameOfConfig: "filteringEntityField" | "visibleEntityFields",
    value: string[] | number | null
) => {
    if (!!value)
        localStorage.setItem(
            `${entityUuid}_kanban_${nameOfConfig}`,
            JSON.stringify(value)
        );
    else localStorage.removeItem(`${entityUuid}_kanban_${nameOfConfig}`);
};
