import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";

const BizProcDeleteAction = ({ onConfirmDelete }: { onConfirmDelete: () => void }) => {
    return (
        <Popconfirm
            title="Удалить бизнес-процесс?"
            okText="Удалить"
            onConfirm={onConfirmDelete}
            cancelText="Отмена"
        >
            <div style={{ padding: "5px 12px", display: "inline-block" }}>
                <DeleteOutlined style={{ marginRight: 8 }} />
                Удалить бизнес-процесс
            </div>
        </Popconfirm>
    );
};

export default BizProcDeleteAction;
