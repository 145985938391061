import { bizProcApi } from "@entities/Portal/BizProc";
import { bizProcTemplateApi } from "@entities/Portal/BizProcTemplate";
import { userModel } from "@entities/Portal/User";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useSelector } from "react-redux";

export const useBizProcHistoryActions = (action: TBizProcAction) => {
    const userIsAdmin = useSelector(userModel.selectUserIsAdmin);
    const [isEditing, setIsEditing] = useState(false);
    const [templateItem, setTemplateItem] = useState<
        | (TBizProcTemplateAction & {
              biz_proc_template?: TBizProcTemplateItem;
          })
        | null
    >(null);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const openGoTemplateModal = () => {
        getTemplateItem();
    };
    const getTemplateItem = () => {
        setLoading(true);
        bizProcTemplateApi
            .getBizProcTemplateItem(action.template_item_id, {
                withTemplate: true,
            })
            .then((response) => {
                setTemplateItem(response.data.data);
                setIsEditing(true);
            })
            .catch(() => {
                notificationEmit({
                    title: "Не удалось загрузить информацию",
                    type: "error",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const goTo = () => {
        return bizProcApi.goToBizProcActions(action.id);
    };

    const onSubmit = (templateItem: TBizProcTemplateAction) => {
        setSubmitLoading(true);
        bizProcTemplateApi
            .updateBizProcTemplateItem(templateItem)
            .then(async () => {
                await goTo();
                setIsEditing(false);
            })
            .catch(() => {
                notificationEmit({
                    type: "error",
                    title: "Не удалось перейти к действию",
                });
            })
            .finally(() => {
                setSubmitLoading(false);
            });
    };

    return {
        userIsAdmin,
        isEditing,
        templateItem,
        loading,
        submitLoading,
        goTo,
        setIsEditing,
        openGoTemplateModal,
        onSubmit,
    };
};
