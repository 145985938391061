import { useDispatch, useSelector } from "react-redux";
import { selectLoadBizProcFormsIds } from "./BizProcSelectors";
import { useEffect } from "react";
import { loadBizProcsFormsThunk } from "./BizProcThunk";

export const usePostLoadForms = () => {
    const loadBizProcFormsIds = useSelector(selectLoadBizProcFormsIds);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const bizProcTemplateId = loadBizProcFormsIds?.[0];

        if (bizProcTemplateId) {
            dispatch(loadBizProcsFormsThunk({ add: true, bizProcTemplateId }));
        }
    }, [loadBizProcFormsIds?.[0]]);
};
