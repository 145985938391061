import { apiUrl } from "@shared/api/api";
import { FormItem } from "@shared/ui/Form";
import { RequestedSelect } from "@shared/ui/RequestedSelect";
interface IEstimationTemplateEditUsers {
    viewMode: TEstimationFormViewMode;
    users: TUser[];
    onChangeEstimationTemplate: (name: string, value: boolean) => void;
}

const EstimationTemplateEditUsers = ({
    viewMode,
    users,
    onChangeEstimationTemplate,
}: IEstimationTemplateEditUsers) => {
    const values = users.map((user) => user.id);
    return (
        <FormItem label="Пользователи, имеющие доступ к шаблону">
            <RequestedSelect
                onlyRead={viewMode === "viewing"}
                mode="multiple"
                values={values}
                onSelect={(value, fullItem) =>
                    onChangeEstimationTemplate(
                        "users",
                        fullItem.map((item: any) => item.item)
                    )
                }
                requestUrl={`${apiUrl}/api/user/employees`}
                queryTypedParam="name"
                queryChoosenParams="usersIds"
                valueLabel="name"
                valueKey="id"
            />
        </FormItem>
    );
};

export default EstimationTemplateEditUsers;
