import { LOCATION_ENTITY_ROW_ID_INDEX, LOCATION_ENTITY_TABLE_INDEX } from "./constants";

export const parseErpLocationData = (patterns: string[], windowLocation: string) => {
    const pattern = patterns.find((pattern) => windowLocation.search(pattern) !== -1);
    if (!pattern) return null;
    const index = windowLocation.search(pattern);
    if (index === -1) return null;
    const erpLocation = windowLocation.slice(index);
    const splittedLocation = erpLocation.split("/").filter((item) => item !== "");
    const id = Number(splittedLocation[LOCATION_ENTITY_ROW_ID_INDEX]);
    const name = splittedLocation[LOCATION_ENTITY_TABLE_INDEX];

    return {
        id: Number.isNaN(id) ? null : Number(id),
        name: !name ? null : name,
        location: erpLocation,
        pattern,
    };
};

export const getDrawerManagerItem = (
    patterns: string[],
    windowLocation: string
): TDrawerManagerItemPrepared | null => {
    const queryIndex = windowLocation.indexOf("?");
    const query = queryIndex !== -1 ? windowLocation.slice(queryIndex + 1) : "";
    const clearWindowLocation = windowLocation.replace(query, "");

    const erpLocationData = parseErpLocationData(patterns, clearWindowLocation);
    if (!erpLocationData || !erpLocationData.id || !erpLocationData.name) return null;

    return {
        location: erpLocationData.location,
        id: erpLocationData?.id,
        name: erpLocationData?.name,
        pattern: erpLocationData.pattern,
        isOpen: true,
    };
};

export const parseOriginalLocation = (patterns: string[], windowLocation: string) => {
    const queryIndex = windowLocation.indexOf("?");
    const query = queryIndex !== -1 ? windowLocation.slice(queryIndex + 1) : "";
    const clearWindowLocation = windowLocation.replace(query, "");
    const erpLocationData = parseErpLocationData(patterns, clearWindowLocation);
    if (!erpLocationData || !erpLocationData.id) return windowLocation;

    return `${erpLocationData?.location.replace(`/${erpLocationData.id}`, "")}${query}`;
};

export const isHigherItem = () => {};
export const getItemLevel = () => {};
export const generateLocation = (item: TDrawerManagerItem) => {
    return `${item.pattern}/${item.name}/${item.id ?? "create"}`;
};
