import { Modal } from "antd";
import StageCreateUpdateForm from "./StageCreateUpdateForm";

interface IStageCreateUpdateModal {
    stage: TStage | TStageCreating;
    entityId: number;
    categoryId: number;
    isOpen: boolean;
    onCancel: () => void;
}

const StageCreateUpdateModal = ({
    stage,
    categoryId,
    entityId,
    isOpen,
    onCancel,
}: IStageCreateUpdateModal) => {
    return (
        <Modal
            title={`${"id" in stage ? "Редактирование" : "Создание"} стадии`}
            open={isOpen}
            onCancel={onCancel}
            destroyOnClose
            footer={[]}
        >
            <StageCreateUpdateForm
                entityId={entityId}
                categoryId={categoryId}
                stage={stage}
                onCancel={onCancel}
                onSave={onCancel}
            />
        </Modal>
    );
};

export default StageCreateUpdateModal;
