import { techMapModel } from "@entities/Production/TechMap";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useTMList = () => {
    const loading = useSelector(techMapModel.selectGetTMLoading);
    const techMaps = useSelector(techMapModel.selectTechMaps);
    const techMapsForReview = useSelector(techMapModel.selectTechMapsForReview);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(techMapModel.loadTechMaps());
    }, []);
    return {
        loading,
        techMaps,
        techMapsForReview,
    };
};
