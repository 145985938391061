import "./CreateEditEntityRowHistory.scss";

interface ICreateEditEntityRowHistory {
    entityRowId?: number | null;
    history?: React.ReactNode;
}

const CreateEditEntityRowHistory = ({
    entityRowId,
    history,
}: ICreateEditEntityRowHistory) => {
    return (
        <>
            {entityRowId && (
                <div className="create-edit-entity-row__history">
                    {history && history}
                </div>
            )}
        </>
    );
};

export default CreateEditEntityRowHistory;
