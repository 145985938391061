import { $api, $apiAuth, clientId, clientSecret, grantType } from "@shared/api/api";

export const signIn = (username: string, password: string) => {
    return $apiAuth.post<TSignInResponse>("/oauth/token", {
        username,
        password,
        client_id: clientId,
        client_secret: clientSecret,
        grant_type: grantType,
    });
};

export const getUserInfo = () => {
    return $apiAuth.get<TUserInfoResponse>("/api/user/get");
};
export const getUser = (userId: number) => {
    return $apiAuth.get<TUserInfoResponse>(`/api/user/${userId}`);
};
export const updateUserTimezone = (userId: number, timezoneId: number | string) => {
    return $apiAuth.post<TUserInfoResponse>(`/api/user/${userId}/timezone`, {
        timezone: timezoneId,
    });
};
export const updateUserPassword = (userId: number, password: string) => {
    return $apiAuth.post<TUserInfoResponse>(`/api/user/${userId}/password`, {
        password: password,
    });
};

export const linkUserTelegram = () => {
    return $apiAuth.get<any>("api/telegram/auth_url", {
        baseURL: process.env.REACT_APP_API_URL,
    });
};
export const isLinkedUserTelegram = () => {
    return $apiAuth.get<any>("api/telegram/", { baseURL: process.env.REACT_APP_API_URL });
};
export const unlinkUserTelegram = () => {
    return $apiAuth.post<any>(`${process.env.REACT_APP_API_URL}/api/telegram/unlink`);
};

export const getAllUsers = () => {
    return $apiAuth.get<any>("/api/user/all");
};
export const getUsersByName = (name: string, userIds: number[] | undefined) => {
    return $api.get<any>("/api/user/employees", { params: { [name]: name } });
};
export const startRecoverPassword = (email: string) => {
    return $api.post<TResponse<null>>("/api/user/recover/password/start", { email });
};
export const checkRecoverPassword = (code: string) => {
    return $api.post<TResponse<null>>("/api/user/recover/password/check", { code });
};
export const recoverPassword = (code: string, password: string) => {
    return $api.post<TResponse<null>>("/api/user/recover/password", { code, password });
};
