import { $api } from "@shared/api/api";
const route = "/api/production/tm-template";

export const getTechMapTemplates = (params?: TParams) => {
    return $api.get<TResponseList<TTechMapTemplate>>(route, { params: params });
};
export const getTechMapTemplate = (techMapTemplateId: number) => {
    return $api.get<TResponse<TTechMapTemplateFull>>(`${route}/${techMapTemplateId}`);
};
export const updateTechMapTemplate = (techMapTemplate: TTechMapTemplateReq) => {
    return $api.put<TResponse<TTechMapTemplate>>(
        `${route}/${techMapTemplate.id}`,
        techMapTemplate
    );
};
export const createTechMapTemplate = (techMapTemplate: TTechMapTemplateReq) => {
    return $api.post<TResponse<TTechMapTemplate>>(route, techMapTemplate);
};
export const deleteTechMapTemplate = (id: number) => {
    return $api.delete<TResponse<null>>(`${route}/${id}`);
};
