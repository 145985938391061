import { TMOperations } from "@features/Production/TechMapFeatures/TMOperations";
import { TMTBar } from "@features/Production/TechMapFeatures/TMTBar";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { TMOperationProccess } from "@widgets/Production/TMOperationProccess";
import "./ProductionPage.scss";
interface IProductionPage {}

const ProductionPage = ({}: IProductionPage) => {
    return (
        <div className="production-page">
            <TMTBar />
            <div className="production-page__main">
                <IndentContainer>
                    <TMOperations />
                </IndentContainer>
                <TMOperationProccess />
            </div>
        </div>
    );
};

export default ProductionPage;
