import { ConfigProvider } from "antd";
import { AppRouter } from "./router";
import { theme } from "@shared/theme";
import { Provider } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "./App.scss";
import { store } from "./store";
import ruRU from "antd/locale/ru_RU";
import "dayjs/locale/ru";
import { getTimezoneOffsetHours } from "@shared/lib/date";
import { useEffect } from "react";

dayjs.locale("ru");
dayjs.extend(utc);
dayjs.extend(timezone);

declare global {
    interface Window {
        Pusher: any;
        Echo: any;
        Offset: any;
    }
}

function App() {
    useEffect(() => {
        window.Offset = 0 - getTimezoneOffsetHours();
    }, []);
    return (
        <ConfigProvider locale={ruRU} theme={theme}>
            <Provider store={store}>
                <div className="App">
                    <AppRouter />
                </div>
            </Provider>
        </ConfigProvider>
    );
}

export default App;
