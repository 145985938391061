import { DeleteOutlined } from "@ant-design/icons";
import { Button, Flex, Select } from "antd";
import { comparsionTypesBoolean } from "../../constants";

interface IGroupConditionActions {
    depth: number;
    onAddConditionGroup: () => void;
    onAddConditionSingle: () => void;
    onDeleteCondition?: () => void;
    onChangeConditionOfGroup: (value: string) => void;
    conditionGroupValue: string;
}

const GroupConditionActions = ({
    depth,
    onAddConditionGroup,
    onAddConditionSingle,
    onDeleteCondition,
    onChangeConditionOfGroup,
    conditionGroupValue,
}: IGroupConditionActions) => {
    return (
        <Flex justify="space-between">
            <Flex>
                <Button
                    onClick={onAddConditionGroup}
                    type={depth === 0 ? "link" : undefined}
                >
                    Добавить группу
                </Button>
                <Button
                    onClick={onAddConditionSingle}
                    type={depth === 0 ? "link" : undefined}
                >
                    Добавить условие
                </Button>
                <Select
                    dropdownStyle={{ width: "fit-content" }}
                    bordered={depth !== 0}
                    value={conditionGroupValue}
                    placeholder="Выберите условие"
                    onChange={onChangeConditionOfGroup}
                    options={comparsionTypesBoolean}
                />
            </Flex>
            {onDeleteCondition && (
                <Button
                    icon={<DeleteOutlined />}
                    onClick={onDeleteCondition}
                    type="link"
                    danger
                />
            )}
        </Flex>
    );
};

export default GroupConditionActions;
