export const getValidatedEditingEntityField = (editingEntityField: TEntityField) => {
    return {
        ...editingEntityField,
        on_create_default:
            editingEntityField.is_multiple &&
            editingEntityField.on_create_default &&
            [
                ...(editingEntityField.on_create_default as unknown as TEntityFieldCreateUpdateDefault[]),
            ].length === 0
                ? null
                : editingEntityField.on_create_default,
        on_update_default:
            editingEntityField.is_multiple &&
            editingEntityField.on_update_default &&
            [
                ...(editingEntityField.on_update_default as unknown as TEntityFieldCreateUpdateDefault[]),
            ].length === 0
                ? null
                : editingEntityField.on_update_default,
    };
};
