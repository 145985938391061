import "./BizProcHistoryActionItem.scss";
import { AgreementAction, EmailMessageAction } from "../BizProcHistoryActionItems";
import DefaultActionItem from "../BizProcHistoryActionItems/DefaultActionItem";
import FormAction from "../BizProcHistoryActionItems/FormAction";
import InitialAction from "../BizProcHistoryActionItems/InitialAction";

interface IBizProcHistoryActionItem {
    action: any;
    onUserClick?: (user: TUser) => void;
}

const BizProcHistoryActionItem = ({ action, onUserClick }: IBizProcHistoryActionItem) => {
    if (action.action === "AgreementAction") {
        return <AgreementAction action={action} onUserClick={onUserClick} />;
    }
    if (action.action === "InitialAction") {
        return <InitialAction action={action} onUserClick={onUserClick} />;
    }
    if (action.action === "FormAction") {
        return <FormAction action={action} onUserClick={onUserClick} />;
    }
    if (action.action === "EmailMessageAction") {
        return <EmailMessageAction action={action} />;
    } else {
        return <DefaultActionItem action={action} />;
    }
};

export default BizProcHistoryActionItem;
