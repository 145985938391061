import { bizProcLib } from "@entities/Portal/BizProc";
import { getNormalizedTime } from "@shared/lib/date";
import { Button, Tag } from "antd";
import { UsersAssygnment } from "../BizProcsUsers";
import "./UserAgreementListItem.scss";

interface IUserAgreementListItem {
    agreement: TBizProcAction<TBizProcAgreementParameters>;
    onClick?: (agreement: TBizProcAction<TBizProcAgreementParameters>) => void;
    isMyAgreements?: boolean;
    onUserClick?: (user: TUser) => void;
}

const UserAgreementListItem = (props: IUserAgreementListItem) => {
    const { agreement, onClick, onUserClick } = props;
    const header = agreement.collected_parameters.header;
    //const header = "Процесс согласования включает в себя ряд шагов, которые помогают достичь единства мнений и создать единую стратегию действий. Во-первых, необходимо определить цели и задачи, которые нужно достичь. Это поможет участникам ясно представить, чего они ожидают от согласования и какие результаты они хотят получить.";
    //const body = "Согласование – важный аспект успешной работы в любой сфере деятельности. Оно позволяет достичь взаимопонимания, согласованности и согласия между участниками процесса, а также избежать конфликтов и недоразумений";
    const body = agreement.collected_parameters.body;

    const onClick_ = () => {
        onClick && onClick(agreement);
    };

    return (
        <div className="user-agreement">
            <div className="user-agreement__header">
                <div className="user-agreement__header__name">{header}</div>
                <Tag
                    className="user-agreement__header__status"
                    color={bizProcLib.getBizProcStatusColor(agreement.status)}
                >
                    {bizProcLib.getBizProcStatusName(agreement.status)}
                </Tag>
            </div>
            <div className="user-agreement__desc">
                <div className="user-agreement__desc__body">{body}</div>
            </div>
            <div className="user-agreement__additional">
                <div className="user-agreement__additional__approve-info">
                    <UsersAssygnment
                        users={agreement.collected_parameters.users}
                        onUserClick={onUserClick}
                        status={agreement.status}
                    />
                    {(agreement.status === "approve" ||
                        agreement.status === "reject") && (
                        <span style={{ marginLeft: 15 }}>
                            {`${
                                agreement.status === "approve"
                                    ? "Согласовано"
                                    : "Отказано"
                            }: ${getNormalizedTime(agreement.updated_at)}`}
                        </span>
                    )}
                </div>

                <Button
                    type={agreement.status === "executing" ? "link" : "text"}
                    onClick={onClick_}
                >
                    {agreement.status === "executing" ? `Рассмотреть` : "Подробнее"}
                </Button>
            </div>
        </div>
    );
};

export default UserAgreementListItem;
