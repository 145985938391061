import ActionCard from "./ActionCard";

interface IGotoAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const GotoAction = ({ onDeleteItem, templateItem }: IGotoAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="DarkOrange"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Переход к действию БП"}
        ></ActionCard>
    );
};

export default GotoAction;
