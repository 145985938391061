import { Modal } from "antd";
import NavigationRouteEditOrUpdateForm from "./NavigationRouteEditOrUpdateForm";

interface INavigationRouteEditUpdateModal {
    navigationRoute?: TNavigationRoute | null;
    isOpen: boolean;
    onCancel: () => void;
}

const NavigationRouteEditUpdateModal = ({
    isOpen,
    navigationRoute,
    onCancel,
}: INavigationRouteEditUpdateModal) => {
    return (
        <Modal
            title={`${navigationRoute?.id ? "Редактирование" : "Создание"} элемента`}
            open={isOpen}
            onCancel={onCancel}
            destroyOnClose
            footer={[]}
        >
            <NavigationRouteEditOrUpdateForm
                navigationRoute={navigationRoute}
                onCancel={onCancel}
                onSave={onCancel}
            />
        </Modal>
    );
};

export default NavigationRouteEditUpdateModal;
