import { Spin } from "antd";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import "./Column.scss";
interface IColumn<T> {
    column: {
        key: string;
        value: string;
        label: ReactNode | string;
        isEndedPagination?: boolean;
        items: TChildBaseProps<T>[];
    };
    columnBottomReachingHandler: (columnValue: any) => void;
    isPaginationLoading: { [columnValue: string]: boolean };
    columnItem: (pureItem: T) => React.ReactNode;
}

const Column = <T,>({
    column,
    columnBottomReachingHandler,
    isPaginationLoading,
    columnItem,
}: IColumn<T>) => {
    const columnByItems = useRef<HTMLDivElement>(null);
    const paginationLoading = !!isPaginationLoading[column.value];
    const [isScrollToBottom, setIsScrollToBottom] = useState<boolean>(false);
    useEffect(() => {
        if (paginationLoading && isScrollToBottom) {
            if (columnByItems.current)
                columnByItems.current!.scrollTop = columnByItems.current!.scrollHeight;
            setIsScrollToBottom(false);
        }
    }, [paginationLoading]);

    return (
        <Droppable droppableId={String(column.key)}>
            {(provided) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="column-container"
                >
                    <div
                        className="column-items"
                        style={{
                            overflowY: "auto",
                            padding: 8,
                        }}
                        ref={columnByItems}
                        onScroll={(event) => {
                            setIsScrollToBottom(true);
                            //@ts-ignore
                            const { scrollHeight, scrollTop, clientHeight } =
                                event.nativeEvent.target;
                            const isBottomReached =
                                scrollHeight - Math.round(scrollTop) === clientHeight;
                            if (isBottomReached && !column.isEndedPagination) {
                                columnBottomReachingHandler(column.value);
                            }
                        }}
                    >
                        {column.items.map((item: TChildBaseProps<T>, index: number) => {
                            return (
                                <Draggable
                                    draggableId={item.key}
                                    index={index}
                                    key={item.key}
                                >
                                    {(provided, snapshot) => {
                                        return (
                                            <div
                                                className="column-item"
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                                // @ts-ignore
                                                ref={provided.innerRef}
                                            >
                                                {columnItem(item.pureItem)}
                                            </div>
                                        );
                                    }}
                                </Draggable>
                            );
                        })}
                        {paginationLoading && (
                            <div className="column-spinner">
                                <Spin />
                            </div>
                        )}
                    </div>
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default Column;
