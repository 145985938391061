import { Input } from "antd";
import { ChangeEvent } from "react";
import Barcode from "react-barcode";

interface IBarCodeInput {
    value: any;
    onlyRead?: boolean;
    onChangeValue: (value: any) => void;
    disabled?: boolean;
}

const BarCodeInput = ({ value, onlyRead, onChangeValue, disabled }: IBarCodeInput) => {
    const downloadQRCode = () => {
        const canvas = document
            .getElementById(`bar-${value.value}`)
            ?.querySelector<HTMLCanvasElement>("canvas");

        if (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement("a");
            a.download = `${value.value}.jpg`;
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChangeValue({
            value: event.target.value === "" ? null : event.target.value,
        });
    };
    if (onlyRead) {
        return value?.value ? (
            <div onClick={downloadQRCode} id={`bar-${value.value}`}>
                <Barcode
                    renderer="canvas"
                    height={40}
                    width={1}
                    fontSize={12}
                    value={value.value}
                />
            </div>
        ) : (
            <>-</>
        );
    }
    return <Input disabled={disabled} value={value?.value} onChange={onChange} />;
};

export default BarCodeInput;
