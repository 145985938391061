import { $api } from "@shared/api/api";
const route = "/api/calculated-value";

export const getCalculatedValues = () => {
    return $api.get<TResponseList<TCalculatedValue>>(route);
};
export const createCalculatedValue = (CalculatedValue: TCalculatedValue) => {
    return $api.post<TResponse<TCalculatedValue>>(route, CalculatedValue);
};
export const updateCalculatedValue = (CalculatedValue: TCalculatedValue) => {
    return $api.put<TResponse<TCalculatedValue>>(
        `${route}/${CalculatedValue.id}`,
        CalculatedValue
    );
};
export const deleteCalculatedValue = (id: number) => {
    return $api.delete<TResponse<TCalculatedValue>>(`${route}/${id}`);
};
