import { calculatedValueModel } from "@entities/Portal/CalculatedValues";
import { useDispatch } from "react-redux";
import { creatingCalculatedValue } from "../constants";

export const useCreateButton = () => {
    const dispatch = useDispatch<AppDispatch>();

    const onClick = () => {
        dispatch(calculatedValueModel.setEditingCalculatedValue(creatingCalculatedValue));
    };
    return {
        onClick,
    };
};
