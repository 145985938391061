import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    createTechMapTemplate,
    deleteTechMapTemplate,
    getTechMapTemplate,
    getTechMapTemplates,
    updateTechMapTemplate,
} from "../../api/api";

export const loadTechMapTemplatesThunk = createAsyncThunk(
    "TechMapTemplateSlice/loadTechMapTemplatesThunk",
    async (props: { params?: TParams }, { rejectWithValue }) => {
        try {
            const response = await getTechMapTemplates(props.params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const loadTechMapTemplateThunk = createAsyncThunk(
    "TechMapTemplateSlice/loadTechMapTemplateThunk",
    async ({ techMapTemplateId }: { techMapTemplateId: number }, { rejectWithValue }) => {
        try {
            const response = await getTechMapTemplate(techMapTemplateId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateTechMapTemplateThunk = createAsyncThunk(
    "TechMapTemplateSlice/updateTechMapTemplateThunk",
    async (props: { techMapTemplate: TTechMapTemplateReq }, { rejectWithValue }) => {
        try {
            const response = await updateTechMapTemplate(props.techMapTemplate);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const createTechMapTemplateThunk = createAsyncThunk(
    "TechMapTemplateSlice/createTechMapTemplateThunk",
    async (props: { techMapTemplate: TTechMapTemplateReq }, { rejectWithValue }) => {
        try {
            const response = await createTechMapTemplate(props.techMapTemplate);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const deleteTechMapTemplateThunk = createAsyncThunk(
    "TechMapTemplateSlice/deleteTechMapTemplateThunk",
    async (props: { id: number }, { rejectWithValue }) => {
        try {
            const response = await deleteTechMapTemplate(props.id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
