import { EntityRowObserverItem } from "@entities/Portal/Entity";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { Divider } from "antd";
import { Fragment } from "react";
import DeleteEntityRowObserver from "./DeleteEntityRowObserver";

interface IEntityRowObserversList {
    items: (TEntityRowObserver & { bizProcName: string })[];
    label?: string;
}

const EntityRowObserversListItems = ({ items, label }: IEntityRowObserversList) => {
    return (
        <>
            {label && <Divider orientation="left">{label}</Divider>}
            <div
                style={{
                    margin: "0 auto",
                    display: "grid",
                    gap: "10px 20px",
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                }}
            >
                {items.map((item) => {
                    return (
                        <Fragment key={item.id}>
                            <IndentContainer>
                                <EntityRowObserverItem
                                    item={item}
                                    actions={(item) => (
                                        <DeleteEntityRowObserver item={item} />
                                    )}
                                />
                            </IndentContainer>
                        </Fragment>
                    );
                })}
            </div>
        </>
    );
};

export default EntityRowObserversListItems;
