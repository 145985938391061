import { Input, QRCode } from "antd";
import { ChangeEvent } from "react";

interface IQrCodeInput {
    value: any;
    onlyRead?: boolean;
    onChangeValue: (value: any) => void;
    disabled?: boolean;
}

const QrCodeInput = ({ value, onlyRead, onChangeValue, disabled }: IQrCodeInput) => {
    const downloadQRCode = () => {
        const canvas = document
            .getElementById(`qr-${value.value}`)
            ?.querySelector<HTMLCanvasElement>("canvas");

        if (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement("a");
            a.download = `${value.value}.jpg`;
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChangeValue({
            value: event.target.value === "" ? null : event.target.value,
        });
    };
    if (onlyRead) {
        return value?.value ? (
            <div onClick={downloadQRCode} id={`qr-${value.value}`}>
                <QRCode
                    style={{ padding: 10 }}
                    bgColor="white"
                    color="black"
                    value={value?.value}
                    size={100}
                />
            </div>
        ) : (
            <>-</>
        );
    }
    return <Input disabled={disabled} value={value?.value} onChange={onChange} />;
};

export default QrCodeInput;
