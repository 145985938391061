import { bizProcApi, bizProcModel } from "@entities/Portal/BizProc";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";

export const useBizProcFillVariables = (
    bizProc: TBizProc,
    setBizProc: (bizProc: TBizProc) => void
) => {
    const [loading, setLoading] = useState(false);
    const [variables, setVariables] = useState<TBizProcFormField[]>(
        bizProc.variables ?? []
    );
    const [isEdit, setIsEdit] = useState(false);
    // const dispatch = useDispatch<AppDispatch>();
    const onCancel = () => {
        setIsEdit(false);
    };

    const { loadingVariables, fields, setFields } = bizProcModel.useFormFields(
        bizProc.id,
        bizProc.variables
    );
    const onChangeFormFields = (fields: TBizProcFormField[]) => {
        if (bizProc) {
            setFields(fields);
        }
    };
    const onStartEdit = () => {
        setIsEdit(true);
        // setFields(bizProc.variables ?? []);
    };

    const onSubmit = () => {
        setLoading(true);

        bizProcApi
            .updateBizProcVariables(bizProc.id as number, fields)
            .then((response: any) => {
                setIsEdit(false);
                if (bizProc)
                    setBizProc({
                        ...bizProc,
                        variables,
                    });
            })
            .catch((e: any) => {
                notificationEmit({
                    title: e.response?.data?.message ?? "Не удалось сохранить изменения",
                    type: "error",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        onChangeFormFields,
        onCancel,
        onSubmit,
        loadingVariables,
        fields,
        loading,
        isEdit,
        onStartEdit,
        variables,
    };
};
