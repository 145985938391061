import { FormItem } from "@shared/ui/Form";
import React from "react";
import { TFilterInputItem } from "../../FilterKitTypes";
import { Input } from "antd";

const FilterInputItem = ({ field, onChange }: TFilterInputItem) => {
    const { value, name, label } = field;
    const onChangeInput = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;
        onChange(name, value);
    };
    return (
        <FormItem label={label}>
            <>
                <Input name={name} onChange={onChangeInput} value={value} />
            </>
        </FormItem>
    );
};

export default FilterInputItem;
