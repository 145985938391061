import { useState } from "react";

export const useAddEntityRowObserver = (entityUuid: string) => {
    const [isOpen, setIsOpen] = useState(false);
    const onAdd = () => {
        setIsOpen(true);
    };

    const onClose = () => {
        setIsOpen(false);
    };
    return {
        isOpen,
        onAdd,
        onClose,
    };
};
