import ActionCard from "./ActionCard";

interface IStartAnotherBizProcAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const StartAnotherBizProcAction = ({
    onDeleteItem,
    templateItem,
}: IStartAnotherBizProcAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="DarkOrange"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Запуск другого БП"}
        ></ActionCard>
    );
};

export default StartAnotherBizProcAction;
