import { Flex, Tag } from "antd";

interface ITagList {
    items: any[];
    wrap?: boolean;
    gapVertical?: number;
    gapHorizontal?: number;
}

const TagList = ({ items, wrap, gapVertical, gapHorizontal }: ITagList) => {
    return (
        <Flex
            gap={`${gapHorizontal ?? 4}px ${gapHorizontal ?? 0}px`}
            wrap={wrap ? "wrap" : undefined}
        >
            {items.map((item, index) => (
                <Tag key={index}>{item}</Tag>
            ))}
        </Flex>
    );
};

export default TagList;
