import { FormItem } from "@shared/ui/Form";
import SelectDocument from "./SelectDocument";
import SelectVariable from "./SelectVariable";

interface IEditDocumentGenerateActionAction {
    templateItem: TBizProcTemplateAction;
    disabled?: boolean;
    bizProcTemplate: TBizProcTemplateItem;
    setItem: (templateItem: TBizProcTemplateAction) => void;
}

const EditDocumentGenerateAction = ({
    templateItem,
    bizProcTemplate,
    disabled,
    setItem,
}: IEditDocumentGenerateActionAction) => {
    const document = templateItem.parameters?.document;

    const onSelectDocument = (document: number) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                document,
            },
        });
    };
    const variables = bizProcTemplate.variables ?? [];

    return (
        <>
            <FormItem label="Выберите документ">
                <SelectDocument
                    disabled={disabled}
                    document={document}
                    onSelectDocument={onSelectDocument}
                />
            </FormItem>
            <FormItem label="Выберите переменную для записи ссылки">
                <SelectVariable
                    disabled={disabled}
                    variables={variables}
                    setItem={setItem}
                    templateItem={templateItem}
                />
            </FormItem>
        </>
    );
};

export default EditDocumentGenerateAction;
