import { Button } from "antd";
import { useBizProcTemplateStartMenu } from "./model";

interface IBizProcTemplateStartMenu {
    bizProcTemplateId: number;
    text?: string;
}

const BizProcTemplateStartButton = ({
    bizProcTemplateId,
    text,
}: IBizProcTemplateStartMenu) => {
    const { onStart, loading } = useBizProcTemplateStartMenu(null);

    return (
        <>
            <Button
                style={{ marginRight: 8 }}
                type={"primary"}
                onClick={() => onStart(bizProcTemplateId)}
                loading={loading === bizProcTemplateId}
            >
                {text ?? "Создать"}
            </Button>
        </>
    );
};

export default BizProcTemplateStartButton;
