import { Button } from "antd";

interface IGenerateQRCodeDrawerActions {
    onPreload: () => void;
    onOkDrawer: () => void;
}

const GenerateQRCodeDrawerActions = ({
    onPreload,
    onOkDrawer,
}: IGenerateQRCodeDrawerActions) => {
    return (
        <div>
            <Button onClick={onPreload}>Предпросмотр</Button>
            <Button style={{ marginLeft: 8 }} type="primary" onClick={onOkDrawer}>
                ОК
            </Button>
        </div>
    );
};

export default GenerateQRCodeDrawerActions;
