import { capabilitiesRolesConstants } from "@entities/Portal/CapabilitiesRoles";
import { Col, Row, Tooltip } from "antd";
import { memo } from "react";
import { isEqualCapabilitiesRolesTableRowProps } from "../lib";
import { useCapabilitiesRolesRow } from "../model";
import CapabilitiesRolesCell from "./CapabilitiesRolesCell";
import CapabilitiesRolesRowModal from "./CapabilitiesRolesRowModal";
import DeleteCapabilitiesRolesCell from "./DeleteCapabilitiesRolesCell";

interface ICapabilitiesRowTableRow {
    entityCapabilitiesRolesId: number;
    entityRow: TCapabilitiesRolesRow;
    initialEntityRow: TCapabilitiesRolesOnly;
    entityOfEntityRow: { name: string; id: number } | undefined;
}

const CapabilitiesRolesRow = ({
    entityCapabilitiesRolesId,
    entityRow,
    initialEntityRow,
    entityOfEntityRow,
}: ICapabilitiesRowTableRow) => {
    const { isOpen, onChangeRow, onOpenModal, onCloseModal } = useCapabilitiesRolesRow(
        entityRow,
        initialEntityRow
    );
    return (
        <>
            {entityRow && (
                <CapabilitiesRolesRowModal
                    open={isOpen}
                    onClose={onCloseModal}
                    entityRow={entityRow}
                    entityCapabilitiesRolesId={entityCapabilitiesRolesId}
                    onChangeRow={onChangeRow}
                />
            )}
            <Row
                gutter={0}
                style={{
                    borderBottom: "1px solid #efefef",
                    height: "fit-content",
                    display: "flex",
                    alignItems: "stretch",
                }}
                key={entityRow.id}
            >
                <Col
                    key={entityRow.id}
                    span={3}
                    className="capabilities-roles-table__cell--entity"
                >
                    {entityOfEntityRow?.name ?? (
                        <Tooltip
                            placement="topLeft"
                            color="white"
                            overlayInnerStyle={{ color: "black" }}
                            title={`Сущность с UUID: ${entityRow.entity_uuid} не найдена!`}
                        >
                            Сущность не найдена
                        </Tooltip>
                    )}
                </Col>
                {capabilitiesRolesConstants.capabilitiesTypes.map(
                    (type, index, array) => (
                        <CapabilitiesRolesCell
                            entityCapabilitiesRolesId={entityCapabilitiesRolesId}
                            oldValue={initialEntityRow[type.key]}
                            onChange={onChangeRow}
                            span={2}
                            key={index}
                            entityUuid={entityRow.entity_uuid!}
                            value={entityRow[type.key]}
                            entityId={entityOfEntityRow?.id}
                            type={type.key}
                            isMultiSelectType={type.isMultiSelect}
                            extraActionOfCapabilityType={onOpenModal}
                        />
                    )
                )}
                <DeleteCapabilitiesRolesCell
                    entityRowCapabiitiesByRole={entityRow}
                    entityCapabilitiesRolesId={entityCapabilitiesRolesId}
                />
            </Row>
        </>
    );
};

export default memo(CapabilitiesRolesRow, isEqualCapabilitiesRolesTableRowProps);
