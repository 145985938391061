import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { getTechMaps, getTechMapsFoReview } from "../../api";
import { setGetTMLoading, setTechMaps, setTechMapsForReview } from "../TechMapSlice";

export const loadTechMaps =
    () => async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(setGetTMLoading(true));
        try {
            const [response, responseReview] = await Promise.all([
                getTechMaps(),
                getTechMapsFoReview(),
            ]);
            dispatch(setTechMaps(response.data.data));
            dispatch(setTechMapsForReview(responseReview.data.data));
        } catch (err) {
            notificationEmit({
                type: "error",
                title: "Не удалось загрузить информацию",
            });
        }
        dispatch(setGetTMLoading(false));
    };
