import { viewPatternItemLib } from "@entities/Portal/ViewPatternItem";

type TotalValues = { [key: string]: number };

export const countJobHours = (value: number) => {
    return value - Math.floor(value / 9);
};

export const initNumberColumns = (entityFields: TEntityField[]) => {
    const initedNumberColumns: TotalValues = {};
    entityFields.forEach(({ key, datatype }) => {
        if (datatype === "float" || datatype === "integer") initedNumberColumns[key] = 0;
    });
    return initedNumberColumns;
};

export const summarizeEachNumberColumn = (
    initedNumberColumns: TotalValues,
    pageData: readonly any[]
) => {
    const summarizedEachNumberColumn: TotalValues = { ...initedNumberColumns };
    pageData.forEach((row) => {
        Object.keys(initedNumberColumns).forEach((key) => {
            summarizedEachNumberColumn[key] += row[key];
        });
    });
    return summarizedEachNumberColumn;
};

const getEntityFieldPatternColumn = (
    column: TViewPatternItem,
    entityFields: TEntityField[]
) => {
    const entityField = entityFields.find((field) => field.id == column.type_value);

    return {
        label: column.label,
        key: entityField!.key,
        entityField: entityField,
        type: "entity-field",
    };
};
const getStartChildBizProcPatternColumn = (column: TViewPatternItem) => {
    return {
        label: column.label,
        key: column.id,
        type_value: column.type_value,
        type: "start_child_biz_proc",
    };
};
const getStartBizProcPatternColumn = (column: TViewPatternItem) => {
    return {
        label: column.label,
        key: column.id,
        type_value: column.type_value,
        type: "start_biz_proc",
    };
};

const filterUndefinedEntityField = (
    item: TViewPatternItem,
    entityFields: TEntityField[]
) => {
    if (item.type === "entity-field") {
        const entityField = entityFields.find((field) => field.id == item.type_value);
        return entityField != undefined;
    }

    return true;
};
export const getPatternColumns = (
    viewPattern: TViewPattern | undefined,
    viewPatternItems: TViewPatternItem[],
    entityFields: TEntityField[]
): TPatternColumn[] => {
    return viewPattern
        ? viewPatternItemLib
              .prepareViewPatternItems(viewPatternItems)
              .filter((item: TViewPatternItem) =>
                  filterUndefinedEntityField(item, entityFields)
              )
              .map((item: TViewPatternItem) => {
                  if (item.type === "entity-field") {
                      return getEntityFieldPatternColumn(item, entityFields);
                  }
                  if (item.type === "start_child_biz_proc") {
                      return getStartChildBizProcPatternColumn(item);
                  }
                  if (item.type === "start_biz_proc") {
                      return getStartBizProcPatternColumn(item);
                  }
                  return {
                      label: item.label,
                      key: item.id,
                      type: item.type,

                      children: (item.children ?? [])
                          .filter((item: TViewPatternItem) =>
                              filterUndefinedEntityField(item, entityFields)
                          )
                          .map((innerColumn) => {
                              if (innerColumn.type === "start_child_biz_proc") {
                                  return getStartChildBizProcPatternColumn(innerColumn);
                              }
                              if (innerColumn.type === "start_biz_proc") {
                                  return getStartBizProcPatternColumn(innerColumn);
                              }

                              return getEntityFieldPatternColumn(
                                  innerColumn,
                                  entityFields
                              );
                          }),
                  };
              })
        : (entityFields as any)
              .toSorted((a: TEntityField, b: TEntityField) => {
                  return a.order! - b.order!;
              })
              .map((field: TEntityField) => ({
                  label: field.name,
                  key: field.key,
                  entityField: field,
                  type: "entity-field",
              }));
};
