import { capabilitiesRolesBuilder } from "./CapabilitiesRolesBuilder";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICapabilitiesRoles {
    entities: TEntity[];
    error: any;
    loading: boolean;
    userRoles: TUserRole[];
    currentPage: number;
    lastPage: number;
    editingCapabilitiesRolesIds: number[];
    capabilitiesRoles: TCapabilitiesRolesTableData;
    initialCapabilitiesRoles: TCapabilitiesRolesInitialValues;
}

const initialState: ICapabilitiesRoles = {
    entities: [],
    userRoles: [],
    error: null,
    loading: false,
    currentPage: 1,
    lastPage: 1,
    editingCapabilitiesRolesIds: [],
    initialCapabilitiesRoles: {},
    capabilitiesRoles: [],
};

export const capabilitiesRolesSlice = createSlice({
    name: "CapabilitiesRolesSlice",
    initialState,
    reducers: {
        setUserRoles: (state, action: PayloadAction<TUserRole[]>) => {
            state.userRoles = action.payload;
        },
        addUserRole: (state, action: PayloadAction<TUserRole>) => {
            state.userRoles = [action.payload, ...state.userRoles];
        },
        updateUserRoles: (state, action: PayloadAction<TUserRole>) => {
            const updatedUserRole = action.payload;
            state.userRoles = state.userRoles.map((userRole) =>
                userRole.id === updatedUserRole.id ? updatedUserRole : userRole
            );
        },
        setCapabilitiesRoles: (
            state,
            action: PayloadAction<TCapabilitiesRolesTableData>
        ) => {
            state.capabilitiesRoles = action.payload;
        },
        updateCapabilitiesRoles: (
            state,
            action: PayloadAction<TCapabilitiesRolesRow>
        ) => {
            const editedEntityRow = action.payload;
            state.capabilitiesRoles = state.capabilitiesRoles.map(
                (capabilityEntityRowByRole) =>
                    editedEntityRow.id === capabilityEntityRowByRole.id
                        ? editedEntityRow
                        : capabilityEntityRowByRole
            );
        },
        setEditingCapabilitiesRolesId: (
            state,
            action: PayloadAction<TEditingEntityRowData>
        ) => {
            state.editingCapabilitiesRolesIds = action.payload;
        },
        updateEditingCapabilitiesRolesId: (state, action: PayloadAction<number>) => {
            const editingEnityRowId = action.payload;
            if (state.editingCapabilitiesRolesIds.find((id) => editingEnityRowId === id))
                return;
            state.editingCapabilitiesRolesIds = [
                ...state.editingCapabilitiesRolesIds,
                editingEnityRowId,
            ];
        },
        deleteEditingCapabilitiesRolesId: (state, action: PayloadAction<number>) => {
            const editingEnityRowId = action.payload;
            state.editingCapabilitiesRolesIds = state.editingCapabilitiesRolesIds.filter(
                (id) => id !== editingEnityRowId
            );
        },
        setInitialCapabilitiesRoles: (
            state,
            action: PayloadAction<TCapabilitiesRolesInitialValues>
        ) => {
            state.initialCapabilitiesRoles = action.payload;
        },
        setLastPage: (state, action: PayloadAction<number>) => {
            state.lastPage = action.payload;
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
    },
    extraReducers: (builder) => capabilitiesRolesBuilder(builder),
});

export const {
    setUserRoles,
    addUserRole,
    updateUserRoles,
    setEditingCapabilitiesRolesId,
    updateEditingCapabilitiesRolesId,
    deleteEditingCapabilitiesRolesId,
    setInitialCapabilitiesRoles,
    updateCapabilitiesRoles,
    setCapabilitiesRoles,
    setLastPage,
    setCurrentPage,
    setError,
} = capabilitiesRolesSlice.actions;

export default capabilitiesRolesSlice.reducer;
