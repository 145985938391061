import { useBizProcHistoryListWithLoad } from "../model/UseBizProcHistoryListWithLoad";
import "./BizProcHistory.scss";
import BizProcHistoryList from "./BizProcHistoryList";

interface IBizProcHistoryListWithLoad {
    entityRowId: number;
    entityId?: number;
    entityTableName?: string;
}

const BizProcHistoryListWithLoad = ({
    entityRowId,
    entityId,
    entityTableName,
}: IBizProcHistoryListWithLoad) => {
    const { actions, error, loading } = useBizProcHistoryListWithLoad(
        entityRowId,
        entityId,
        entityTableName
    );
    return <BizProcHistoryList actions={actions} loading={loading} error={error} />;
};

export default BizProcHistoryListWithLoad;
