import { apiUrl } from "@shared/api/api";
import { FormItem } from "@shared/ui/Form";
import { RequestedSelect } from "@shared/ui/RequestedSelect";

interface IEditGotoAction {
    templateItem: TBizProcTemplateAction;
    bizProcTemplate: TBizProcTemplateItem;
    disabled?: boolean;
    setItem: (templateItem: TBizProcTemplateAction) => void;
}

const EditGotoAction = ({
    templateItem,
    setItem,
    disabled,
    bizProcTemplate,
}: IEditGotoAction) => {
    const templateItemKey = templateItem.parameters?.templateItemKey;

    const onSelectUsers = (key: number, items: any) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                templateItemKey: key,
            },
        });
    };

    return (
        <>
            <FormItem label="Выберите этап">
                <RequestedSelect
                    disabled={disabled}
                    values={templateItemKey}
                    onSelect={onSelectUsers}
                    requestUrl={`${apiUrl}/api/bizproc-template/${bizProcTemplate.id}/actions`}
                    queryTypedParam="name"
                    queryChoosenParams="templateItemsKeys"
                    valueLabel="name"
                    valueKey="key"
                />
            </FormItem>
        </>
    );
};

export default EditGotoAction;
