import { Divider } from "antd";
import Markdown from "react-markdown";
import { UsersAssygnment } from "../BizProcsUsers";
import "./AgreementAction.scss";
interface IAgreementAction {
    action: TBizProcAction<TBizProcAgreementParameters>;
    onUserClick?: (user: TUser) => void;
}

const AgreementAction = ({ action, onUserClick }: IAgreementAction) => {
    return (
        <div className="bizproc-action">
            <div className="bizproc-action__content">
                <span style={{ fontWeight: 500 }}>
                    {action.collected_parameters.header}
                </span>
                <br />
                <div style={{ display: "flex", width: "100%" }}>
                    <Markdown>{action.collected_parameters.body ?? ""}</Markdown>
                </div>
                <Divider
                    style={{
                        margin: "0",
                        fontSize: 14,
                        marginBottom: 5,
                        color: "#555555",
                    }}
                    orientation="center"
                ></Divider>
                <div className="assygnement">
                    <span style={{ marginRight: 5 }}>
                        {action.status === "approve" && <>Кто согласовал: </>}
                        {action.status === "executing" && <>Ожидает согласования от: </>}
                        {action.status === "reject" && <>Отказал: </>}
                    </span>
                    <UsersAssygnment
                        customLabel=""
                        users={
                            action.status === "executing"
                                ? action.collected_parameters.users
                                : action.collected_parameters.processed_users ?? []
                        }
                        onUserClick={onUserClick}
                        status={action.status}
                    />
                </div>
            </div>
        </div>
    );
};

export default AgreementAction;
