import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ICapabilitiesRoles } from "./CapabilitiesRolesSlice";
import {
    addCapabilitiesRolesThunk,
    deleteCapabilitiesRolesThunk,
    deleteUserRoleThunk,
    loadCapabilitiesRolesThunk,
    updateCapabilitiesRolesThunk,
} from "./CapabilitiesRolesThunk";
import { capabilitiesTypes } from "../constants";

const loadBuilder = (builder: ActionReducerMapBuilder<ICapabilitiesRoles>) => {
    builder.addCase(loadCapabilitiesRolesThunk.fulfilled, (state, action) => {
        state.lastPage = action.payload.last_page;
        state.capabilitiesRoles = action.payload.data;

        const initialCapabilitiesRoles = action.payload.data.reduce((acc1, entityRow) => {
            return {
                ...acc1,
                [entityRow.id!.toString()]: capabilitiesTypes.reduce((acc2, capType) => {
                    return { ...acc2, [capType.key]: entityRow[capType.key] };
                }, {}),
            };
        }, {});

        state.initialCapabilitiesRoles = initialCapabilitiesRoles;
        state.loading = false;
    });
    builder.addCase(loadCapabilitiesRolesThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
    });
    builder.addCase(loadCapabilitiesRolesThunk.rejected, (state, rejectedValue) => {
        state.error = rejectedValue.error;
        state.loading = false;
    });
};

const updateBuilder = (builder: ActionReducerMapBuilder<ICapabilitiesRoles>) => {
    builder.addCase(updateCapabilitiesRolesThunk.fulfilled, (state, action) => {
        state.initialCapabilitiesRoles = action.payload.newInitialValues;
        state.editingCapabilitiesRolesIds = [];
    });
};

const addBuilder = (builder: ActionReducerMapBuilder<ICapabilitiesRoles>) => {
    builder.addCase(addCapabilitiesRolesThunk.fulfilled, (state, action) => {
        state.initialCapabilitiesRoles[action.payload.data[0].id] =
            action.payload.newCapabilityEntityRowRolesOnly;
        state.capabilitiesRoles = [...action.payload.data, ...state.capabilitiesRoles];
    });
};

const deleteCapabilitiesRolesBuilder = (
    builder: ActionReducerMapBuilder<ICapabilitiesRoles>
) => {
    builder.addCase(deleteCapabilitiesRolesThunk.fulfilled, (state, action) => {
        const removedEntityRowId = action.payload.entityRowId;
        state.capabilitiesRoles = state.capabilitiesRoles.filter(
            (entityRow) => entityRow.id !== removedEntityRowId
        );
        state.editingCapabilitiesRolesIds = state.editingCapabilitiesRolesIds.filter(
            (id) => id !== removedEntityRowId
        );

        state.initialCapabilitiesRoles = Object.fromEntries(
            Object.entries(state.initialCapabilitiesRoles).filter(
                (entityRowInitialValue) =>
                    entityRowInitialValue[0] !== String(removedEntityRowId)
            )
        );
    });
};
const deleteUserRoleBuilder = (builder: ActionReducerMapBuilder<ICapabilitiesRoles>) => {
    builder.addCase(deleteUserRoleThunk.fulfilled, (state, action) => {
        const removedUserRoleId = action.payload.entityRowId;
        state.userRoles = state.userRoles.filter(
            (entityRow) => entityRow.id !== removedUserRoleId
        );
    });
};
export const capabilitiesRolesBuilder = (
    builder: ActionReducerMapBuilder<ICapabilitiesRoles>
) => {
    loadBuilder(builder);
    updateBuilder(builder);
    addBuilder(builder);
    deleteCapabilitiesRolesBuilder(builder);
    deleteUserRoleBuilder(builder);
};
