import { MenuOutlined } from "@ant-design/icons";
import { Dropdown, Spin } from "antd";
import { MenuProps } from "antd/lib";
import "./DropdownActions.scss";

interface IDropdownActions {
    placement?:
        | "topLeft"
        | "topCenter"
        | "topRight"
        | "bottomLeft"
        | "bottomCenter"
        | "bottomRight"
        | "top"
        | "bottom";
    items: MenuProps["items"];
    loading?: boolean;
}

const DropdownActions = ({ placement, items, loading }: IDropdownActions) => {
    return (
        <div className="shared-dropdown-actions">
            {!loading && (
                <Dropdown
                    placement={placement ? placement : "bottomLeft"}
                    menu={{ items }}
                    trigger={["click"]}
                    overlayStyle={{ borderRadius: 8 }}
                    className="custom-dropdown"
                >
                    <MenuOutlined />
                </Dropdown>
            )}
            {loading && <Spin size="small" style={{ color: "red", marginTop: -4 }} />}
        </div>
    );
};

export default DropdownActions;
