import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadDatatypesThunk } from "./DatatypeThunk";
import { selectDatatypes } from "./DatatypeSelectors";

export const useLoadDatatypes = () => {
    const datatypes = useSelector(selectDatatypes);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    const loadDatatypes = () => {
        setLoading(true);
        dispatch(loadDatatypesThunk({})).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        loadDatatypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        datatypes,
        loading,
    };
};
