import { DeleteOutlined } from "@ant-design/icons";
import { entityRowModel } from "@entities/Portal/EntityRow";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { Popconfirm } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";

interface IDeleteEntityRow {
    entityId: number;
    entityRowId: number;
    levelKey?: string | number;
}

const DeleteEntityRow = ({ entityId, entityRowId, levelKey }: IDeleteEntityRow) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    const onDelete = () => {
        setLoading(true);
        dispatch(
            entityRowModel.deleteEntityRowThunk({
                entityId,
                entityRowId,
                key: levelKey,
            })
        )
            .then(() => {})
            .catch((e: any) => {
                notificationEmit({
                    type: "error",
                    title: "Ошибка",
                    description: "Не удалость удалить запись сущности",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Popconfirm
            title="Удалить элемент?"
            okText="Удалить"
            okButtonProps={{
                danger: true,
                loading,
            }}
            onConfirm={(e) => {
                e?.nativeEvent.stopPropagation();
                onDelete();
            }}
            cancelText="Отмена"
        >
            <div style={{ padding: 6 }} onClick={(e) => e.stopPropagation()}>
                <DeleteOutlined style={{ color: "var(--danger)", marginRight: 4 }} />
                Удалить
            </div>
        </Popconfirm>
    );
};

export default DeleteEntityRow;
