import { createAsyncThunk } from "@reduxjs/toolkit";
import { createCategory, deleteCategory, getCategories, updateCategory } from "../api";

export const loadCategoriesThunk = createAsyncThunk(
    "CategorySlice/loadCategoriesThunk",
    async (props: { entityId: number; params?: TParams }, { rejectWithValue }) => {
        try {
            const response = await getCategories(props.entityId, props.params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const createCategoryThunk = createAsyncThunk(
    "CategorySlice/createCategoryThunk",
    async (
        props: { category: TCategoryCreating; entityId: number },
        { rejectWithValue }
    ) => {
        try {
            const response = await createCategory(props.entityId, props.category);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const updateCategoryThunk = createAsyncThunk(
    "CategorySlice/updateCategoryThunk",
    async (props: { category: TCategory; entityId: number }, { rejectWithValue }) => {
        try {
            const response = await updateCategory(props.entityId, props.category);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const deleteCategoryThunk = createAsyncThunk(
    "CategorySlice/deleteCategoryThunk",
    async (props: { id: number; entityId: number }, { rejectWithValue }) => {
        try {
            const response = await deleteCategory(props.entityId, props.id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
