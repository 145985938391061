export const stageTypesOptions = [
    {
        label: "В работе",
        value: "in_progress",
    },
    {
        label: "Успешно",
        value: "successful",
    },
    {
        label: "Неуспешно",
        value: "unsuccessful",
    },
];
