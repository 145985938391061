import { FullscreenOutlined } from "@ant-design/icons";
import { FormReadValue } from "@shared/ui/Form";
import { Button, Modal } from "antd";
import EditEntity from "./EditEntity";
import "./EntityInput.scss";
import EntityView from "./EntityView/EntityView";
import { useEntityInput } from "./model";
interface IEntityInput {
    value: any;
    onChangeValue: (value: any) => void;
    onlyRead?: boolean;
    additionalParameters: TEntityAdditionalParameters;
    field: TEntityField | TVariable;
}

const EntityInput = ({
    value,
    onChangeValue,
    additionalParameters,
    onlyRead,
    field,
}: IEntityInput) => {
    const {
        viewMode,
        countShowFormat,
        error,
        isOpenFullView,
        open,
        editingItem,
        values,
        fields,
        entityTableName,
        setViewMode,
        onOpenFillView,
        onSubmitValue,
        onCloseFillView,
        onClose,
        onDelete,
        onEdit,
        onAdd,
    } = useEntityInput({
        value,
        onChangeValue,
        additionalParameters,
        onlyRead,
        field,
    });

    if (!fields) {
        return (
            <>
                {value ? (
                    Array.isArray(value) ? (
                        value.length > 0 ? (
                            value.length
                        ) : (
                            <FormReadValue value={value ?? "-"} />
                        )
                    ) : (
                        1
                    )
                ) : (
                    <FormReadValue value={value ?? "-"} />
                )}
            </>
        );
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {fields && !countShowFormat && (
                <div style={{ maxWidth: 1200 }}>
                    <EntityView
                        entityTableName={entityTableName}
                        viewMode={viewMode}
                        onlyRead={onlyRead}
                        fields={fields}
                        values={values}
                        entityField={field}
                        additionalAction={
                            <Button
                                size="large"
                                type="link"
                                icon={<FullscreenOutlined />}
                                onClick={onOpenFillView}
                            ></Button>
                        }
                        setMode={setViewMode}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onAdd={onAdd}
                    />
                </div>
            )}
            <Modal
                onCancel={onCloseFillView}
                footer={[
                    <Button key={field.key} onClick={onCloseFillView}>
                        Закрыть
                    </Button>,
                ]}
                open={isOpenFullView}
                styles={{
                    body: {
                        padding: 10,
                    },
                }}
                destroyOnClose
                width={"90%"}
            >
                <EntityView
                    entityTableName={entityTableName}
                    isModal
                    viewMode={viewMode}
                    onlyRead={onlyRead}
                    fields={fields}
                    values={values}
                    entityField={field}
                    setMode={setViewMode}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onAdd={onAdd}
                />
            </Modal>
            {fields && (
                <EditEntity
                    error={error}
                    onSubmitValue={onSubmitValue}
                    initialFields={fields}
                    open={open}
                    initialValue={editingItem}
                    onClose={onClose}
                />
            )}
        </div>
    );
};

export default EntityInput;
