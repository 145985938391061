import { entityModel } from "@entities/Portal/Entity";
import { useDispatch } from "react-redux";
import { createdEntity } from "./constants";

export const useCreateButton = () => {
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(entityModel.setEditingEntity(createdEntity));
    };
    return {
        onClick,
    };
};
