import dayjs, { ManipulateType } from "dayjs";
import { EventProps } from "react-big-calendar";
import { customViewModes } from "./constants";
export const getEvents = (
    entityFields: TEntityField[],
    entityRows: any,
    dateStartKey: any,
    dateEndKey: any,
    eventLabelKey: string | undefined,
    eventDescriptionKey: string | undefined,
    openCloseDrawerWithNavigate: (isOpenDrawer: boolean, entityRowId?: number) => void
): Event[] & EventProps<EventItem> => {
    const now = new Date();
    const nowDayJs = dayjs()
        .add(window.Offset, "hour")
        .add(now.getTimezoneOffset() / 60, "hour");
    return entityRows
        .map((row: any, index: number) => {
            let foundEntityFieldForLabel: TEntityField | undefined;
            let foundEntityFieldForDescription: TEntityField | undefined;
            let foundEntityFieldForDateStart: TEntityField | undefined;
            let foundEntityFieldForDateEnd: TEntityField | undefined;

            entityFields.forEach((entityField) => {
                if (entityField.key === eventLabelKey)
                    foundEntityFieldForLabel = entityField;
                if (entityField.key === eventDescriptionKey)
                    foundEntityFieldForDescription = entityField;
                if (entityField.key === dateStartKey)
                    foundEntityFieldForDateStart = entityField;
                if (entityField.key === dateEndKey)
                    foundEntityFieldForDateEnd = entityField;
            });

            const startDate =
                foundEntityFieldForDateStart?.datatype !== "timestamp"
                    ? dayjs(dateStartKey ? row[dateStartKey] : row.date_start).toDate()
                    : getLocalTime(
                          dayjs(dateStartKey ? row[dateStartKey] : row.date_start)
                      );
            const endDate =
                foundEntityFieldForDateEnd?.datatype !== "timestamp"
                    ? dayjs(
                          dateEndKey
                              ? row[dateEndKey]
                              : dateStartKey
                                ? row[dateStartKey]
                                : row.date_end
                      ).toDate()
                    : getLocalTime(
                          dayjs(
                              dateEndKey
                                  ? row[dateEndKey]
                                  : dateStartKey
                                    ? row[dateStartKey]
                                    : row.date_end
                          )
                      );
            const now = nowDayJs.toDate();
            if (getValueOf(startDate) > getValueOf(endDate)) {
                return null;
            }

            const baseEvent = {
                start: startDate,
                openCloseDrawerWithNavigate: openCloseDrawerWithNavigate,
                data: {
                    start: startDate,
                    dateStartKey: dateStartKey,
                    dateEndKey: dateEndKey,
                    eventLabelKey: eventLabelKey,
                    eventDescriptionKey: eventDescriptionKey,
                    labelField: foundEntityFieldForLabel,
                    descriptionField: foundEntityFieldForDescription,
                    dateStartField: foundEntityFieldForDateStart,
                    dateEndField: foundEntityFieldForDateEnd,
                    id: row.id,
                    value: isValidDate(row[eventLabelKey!])
                        ? row[eventLabelKey!]
                        : row[eventLabelKey!] ?? `Нет информации`,
                    [dateStartKey]: startDate,
                    [dateEndKey]: endDate,
                    resource: row,
                },
                allDay: false,
            };
            if (isValidDate(startDate) && isValidDate(endDate)) {
                if (endDate.getTime() - startDate.getTime() < 30 * 60 * 1000)
                    return {
                        ...baseEvent,
                        end: dayjs(startDate).add(30, "m").toDate(),
                        data: {
                            ...baseEvent.data,
                            end: dayjs(startDate).add(30, "m").toDate(),
                            realDateEnd: endDate,
                        },
                    };
                else
                    return {
                        ...baseEvent,
                        end: endDate,
                        allDay:
                            endDate.getDate() - startDate.getDate() >= 1
                                ? true
                                : endDate.getMonth() > startDate.getMonth()
                                  ? true
                                  : false,
                        data: {
                            ...baseEvent.data,
                            end: endDate,
                        },
                    };
            } else if (isValidDate(startDate) && !isValidDate(endDate)) {
                return {
                    ...baseEvent,
                    ...baseEvent,
                    end: now,
                    allDay: true,
                    data: { ...baseEvent.data, end: now, [dateEndKey]: now },
                };
            } else return null;
        })
        .filter((row: any) => row !== null);
};

export const getLocalTime = (date: dayjs.Dayjs): Date => {
    return dayjs(date).add(window.Offset, "hour").toDate();
};

export const addOneToUnitInDate = (date: Date, unit: ManipulateType): dayjs.Dayjs => {
    return dayjs(date).add(1, unit);
};

export const subtractOneToUnitInDate = (date: Date, unit: ManipulateType): dayjs.Dayjs =>
    dayjs(date).subtract(1, unit);

export const isValidDate = (date: Date) => dayjs(date).isValid();

export const getValueOf = (date: Date) =>
    isValidDate(date) ? dayjs(date).valueOf() : NaN;

export const getDateText = (viewMode: TScheduleViewMode, date: Date) => {
    if (viewMode === customViewModes.DAY || viewMode === customViewModes.AGENDA)
        return dayjs(date).format("dddd, MMMM DD").toUpperCase();
    if (viewMode === customViewModes.WEEK) {
        const from = dayjs(date)?.startOf("week");
        const to = dayjs(date)?.endOf("week");
        return `${from.format("MMMM DD").toUpperCase()} - ${to.format("MMMM DD").toUpperCase()}`;
    }
    if (viewMode === customViewModes.MONTH) {
        return dayjs(date).format("MMMM YYYY").toUpperCase();
    }
    if (viewMode === customViewModes.YEAR) {
        return `${dayjs(date).format("YYYY").toUpperCase()}, ${dayjs(Date.now()).format("MMMM DD").toUpperCase()}`;
    }
};

export const isEventInCurrentDate = (event: EventItem, currentDate: Date) => {
    return (
        dayjs(event.start).isSame(dayjs(currentDate), "day") ||
        dayjs(event.end).isSame(dayjs(currentDate), "day") ||
        (dayjs(event.start).valueOf() < dayjs(currentDate).valueOf() &&
            dayjs(event.end).valueOf() > dayjs(currentDate).valueOf())
    );
};

export const getTileClassName =
    ({ date, view }: any) =>
    (events: EventItem[]) => {
        if (
            view === "month" &&
            events?.find((event) => isEventInCurrentDate(event as EventItem, date))
        ) {
            return "event-day";
        }
        return null;
    };

export const getEventsInCurrentDate = (currentDate: Date) => (events: EventItem[]) =>
    events.filter((event) => isEventInCurrentDate(event, currentDate));

export const getEntityFieldBasedOnTime = (entityFields: TEntityField[]) => {
    return entityFields?.find(
        (item) =>
            item.datatype === "datetime" ||
            item.datatype === "date" ||
            item.datatype === "timestamp"
    );
};
