import { Layout } from "antd";
import { ReactNode } from "react";
import "./Header.scss";
import { useHeader } from "./model";
interface IHeader {
    collapse: boolean;
    preNode?: ReactNode;
    postNode?: ReactNode;
}

const Header = ({ preNode, postNode, collapse }: IHeader) => {
    const { layout } = useHeader();
    return (
        <Layout.Header
            className="header"
            style={{
                overflow: !collapse ? "hidden" : "",
                position: "sticky",
                top: 0,
                zIndex: 1,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <div className="header-title-of-layout">
                {preNode && preNode}
                <div className="header__title-container">
                    <div className="header__title">{layout?.title}</div>
                    {layout?.subtitle && (
                        <div className="header__subtitle">{layout?.subtitle}</div>
                    )}
                </div>
            </div>
            {postNode}
        </Layout.Header>
    );
};

export default Header;
