import { userModel } from "@entities/Portal/User";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useEntityRowsKanbanWS = (
    entityId: number,
    entityRowsByEntityField: { [key: string]: any },
    choosenEntityField: TEntityField | null,
    setEntityRowsByEntityField: React.Dispatch<
        React.SetStateAction<{
            [key: string]: any;
        }>
    >
) => {
    const user = useSelector(userModel.selectUser);

    useEffect(() => {
        if (choosenEntityField && entityRowsByEntityField) {
            window.Echo.private(`User.${user.id}.EntityRow.${entityId}`)
                .listen(".entityRow.create", ({ entityRow }: { entityRow: any }) => {
                    setEntityRowsByEntityField((prevEntityRowsByEntityField) => {
                        const newEntityRowsByEntityField = JSON.parse(
                            JSON.stringify(prevEntityRowsByEntityField)
                        );
                        if (
                            newEntityRowsByEntityField[
                                entityRow[choosenEntityField.key] ?? ""
                            ] !== undefined
                        ) {
                            newEntityRowsByEntityField[
                                entityRow[choosenEntityField.key] ?? ""
                            ].data = [
                                ...newEntityRowsByEntityField[
                                    entityRow[choosenEntityField.key] ?? ""
                                ].data,
                                { ...entityRow, is_invisible_for_pagination: true },
                            ].sort((a, b) => b.id - a.id);
                        } else {
                            newEntityRowsByEntityField[
                                entityRow[choosenEntityField.key] ?? ""
                            ] = {
                                count: 1,
                                data: [entityRow],
                                is_ended: true,
                            };
                        }
                        return newEntityRowsByEntityField;
                    });
                })
                .listen(".entityRow.update", ({ entityRow }: { entityRow: any }) => {
                    setEntityRowsByEntityField((prevEntityRowsByEntityField) => {
                        const newEntityRowsByEntityField = JSON.parse(
                            JSON.stringify(prevEntityRowsByEntityField)
                        );
                        Object.keys(newEntityRowsByEntityField).forEach((key) => {
                            const foundedElement = newEntityRowsByEntityField[
                                key
                            ].data.find((item: any) => item.id === entityRow.id);
                            if (!!foundedElement) {
                                newEntityRowsByEntityField[key].data =
                                    newEntityRowsByEntityField[key].data.filter(
                                        (item: any) => item.id !== foundedElement.id
                                    );
                            }
                        });
                        if (
                            newEntityRowsByEntityField[
                                entityRow[choosenEntityField.key] ?? ""
                            ] === undefined
                        ) {
                            newEntityRowsByEntityField[
                                entityRow[choosenEntityField.key] ?? ""
                            ] = {
                                count: 1,
                                data: [entityRow],
                                is_ended: true,
                            };
                        } else {
                            newEntityRowsByEntityField[
                                entityRow[choosenEntityField.key] ?? ""
                            ].data = [
                                ...newEntityRowsByEntityField[
                                    entityRow[choosenEntityField.key] ?? ""
                                ].data,
                                { ...entityRow, is_invisible_for_pagination: true },
                            ].sort((a, b) => b.id - a.id);
                        }
                        return newEntityRowsByEntityField;
                    });
                })
                .listen(".entityRow.delete", ({ entityRow }: { entityRow: any }) => {
                    setEntityRowsByEntityField((prevEntityRowsByEntityField) => {
                        const newEntityRowsByEntityField = JSON.parse(
                            JSON.stringify(prevEntityRowsByEntityField)
                        );
                        if (
                            newEntityRowsByEntityField[
                                entityRow[choosenEntityField.key]
                            ] !== undefined
                        ) {
                            newEntityRowsByEntityField[
                                entityRow[choosenEntityField.key] ?? ""
                            ].data = newEntityRowsByEntityField[
                                entityRow[choosenEntityField.key] ?? ""
                            ].data
                                .filter((item: any) => item.id !== entityRow.id)
                                .sort((a: any, b: any) => b.id - a.id);
                        }
                        return newEntityRowsByEntityField;
                    });
                });

            return () => {
                window.Echo.leave(`User.${user.id}.EntityRow.${entityId}`);
            };
        }
    }, [choosenEntityField]);
};
