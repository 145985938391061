import { FillActionForm } from "@features/Portal/BizProc/FillActionForm";
import type { TableProps } from "antd";
import { Pagination, Table } from "antd";
import { ReactNode } from "react";
import { useScrollContainer } from "react-indiana-drag-scroll";
import "./EntityRowsTable.scss";
import EntityRowsTablePatternColumn from "./EntityRowsTablePatternColumn";
import { useEntityRowsTable } from "./model";
interface IEntityRowsTable {
    entityId: number;
    level?: number;
    levelId?: number | string;
    additionalFilters?: TFilterValue[];
    rowsActions?: (
        selectedRows: any[],
        onSelectRows: (rows: any[]) => void,
        levelKey: number | string
    ) => ReactNode;
    openCloseDrawerWithNavigate: (isOpenDrawer: boolean, entityRowId?: number) => void;
    actions?: (entityRow: any, levelKey: number | string) => ReactNode;
}

const EntityRowsTable = ({
    entityId,
    level,
    levelId,
    additionalFilters,
    rowsActions,
    openCloseDrawerWithNavigate,
    actions,
}: IEntityRowsTable) => {
    const {
        columns,
        isLoading,
        currentPage,
        lastPage,
        entityRows,
        groupingKey,
        perPage,
        selectedRowIds,
        levelKey,
        onSelectRows,
        onShowSizeChange,
    } = useEntityRowsTable({ entityId, level, levelId, additionalFilters });

    // TODO Refactoring
    const tableColumns: TableProps<any>["columns"] = [
        ...columns.map((column) => {
            return {
                title:
                    column.type === "start_child_biz_proc" ||
                    column.type === "start_biz_proc"
                        ? undefined
                        : column.label,
                dataIndex:
                    column.type === "start_child_biz_proc" ||
                    column.type === "start_biz_proc"
                        ? undefined
                        : column?.key,
                key: column?.key,
                render: (value: any, order: any) => {
                    return (
                        <EntityRowsTablePatternColumn
                            entityId={entityId}
                            value={value}
                            order={order}
                            entityRow={order}
                            column={column}
                        />
                    );
                },
            };
        }),
    ];
    if (!groupingKey) {
        tableColumns.push({
            width: 100,
            key: "actions",
            fixed: "right",
            render: (text: any, record) => {
                return actions && actions(record, levelKey);
            },
        });
    }
    if (groupingKey) {
        tableColumns.sort((a, b) => {
            if (a.key === groupingKey) return -1;
            return 0;
        });
    }
    const scrollContainer = useScrollContainer();

    return (
        <div
            ref={scrollContainer.ref}
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                overflow: "auto",
                height: "100%",
                flex: "1 1 200px",
                background: "white",
            }}
        >
            <FillActionForm />
            <Table
                key={`${entityId}${groupingKey ?? ""}`}
                rowSelection={
                    rowsActions && !groupingKey
                        ? {
                              type: "checkbox",
                              onChange: (_: any, rows: any[]) => onSelectRows(rows),
                              selectedRowKeys: selectedRowIds.map(
                                  (row) => (row as any).id
                              ),
                          }
                        : undefined
                }
                rowClassName={"entity-table-row"}
                onRow={(record) => {
                    return {
                        onDoubleClick: (event) => {
                            if (groupingKey) return;
                            const entityRowId = record.id;
                            event.stopPropagation();
                            openCloseDrawerWithNavigate(true, entityRowId);
                        },
                    };
                }}
                loading={isLoading}
                pagination={false}
                dataSource={isLoading ? [] : entityRows}
                columns={isLoading ? [] : tableColumns}
                rowKey={(record) => {
                    if (groupingKey && !isLoading) {
                        return `${groupingKey}_${record[groupingKey]}`;
                    }
                    return record.id;
                }}
                expandable={
                    groupingKey
                        ? {
                              expandRowByClick: true,
                              expandedRowRender: (record, index, indent, expanded) => {
                                  if (!expanded) return <></>;

                                  return (
                                      <EntityRowsTable
                                          additionalFilters={[
                                              ...(additionalFilters ?? []),
                                              {
                                                  condition: "=",
                                                  value: record[groupingKey],
                                                  key: groupingKey,
                                              },
                                          ]}
                                          levelId={record[groupingKey]}
                                          level={(level ?? 0) + 1}
                                          entityId={entityId}
                                          openCloseDrawerWithNavigate={
                                              openCloseDrawerWithNavigate
                                          }
                                          rowsActions={rowsActions}
                                          actions={actions}
                                      />
                                  );
                              },
                          }
                        : undefined
                }
            />
            <div
                style={{
                    position: "sticky",
                    bottom: 0,
                    right: 0,
                    left: 0,
                    padding: "4px 15px",
                    background: "white",
                    zIndex: 999,
                    marginTop: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent:
                        !groupingKey && rowsActions ? "space-between" : "flex-end",
                }}
            >
                {!groupingKey &&
                    rowsActions &&
                    rowsActions(selectedRowIds, onSelectRows, levelKey)}
                <Pagination
                    showSizeChanger
                    onChange={onShowSizeChange}
                    total={lastPage * perPage}
                    pageSize={perPage}
                    current={currentPage}
                />
            </div>
        </div>
    );
};

export default EntityRowsTable;
