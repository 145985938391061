import { Col, Row } from "antd";
import "dayjs/locale/ru";
import { DateLocalizer, Navigate, ViewProps } from "react-big-calendar";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { getEventsInCurrentDate, getTileClassName } from "../lib";
import CustomTileContent from "./CustomTileContent";
import "./CustomYearView.scss";

export const groupColors = {};
export default function CustomYearView({
    date,
    localizer,
    onView,
    onNavigate,
    events,
}: ViewProps) {
    //@ts-ignore
    const currentRange = CustomYearView.range(date, { localizer });
    return (
        <div
            className="calendar-popup-container"
            style={{
                paddingBottom: 16,
                height: "100%",
                width: "100%",
            }}
        >
            <Row justify="start" gutter={[8, 8]}>
                {currentRange.map((month, index) => {
                    return (
                        <Col
                            key={index}
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}
                            xl={{ span: 6 }}
                        >
                            <Calendar
                                className={"my-react-calendar"}
                                locale="ru"
                                activeStartDate={month}
                                tileClassName={({ date, view }: any) =>
                                    getTileClassName({ date, view })(
                                        (events as EventItem[]) ?? []
                                    )
                                }
                                tileContent={({ date }: { date: Date }) => {
                                    const findedEvents = getEventsInCurrentDate(date)(
                                        (events as EventItem[]) ?? []
                                    );
                                    return (
                                        <CustomTileContent
                                            events={events}
                                            findedEvents={findedEvents}
                                            date={date}
                                            onView={onView}
                                            onNavigate={onNavigate}
                                        />
                                    );
                                }}
                                goToRangeStartOnSelect={true}
                                onClickDay={(day: any) => {}}
                            />
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
}

CustomYearView.range = (date: Date, { localizer }: { localizer: DateLocalizer }) => {
    const start = localizer.startOf(date, "year");
    const end = localizer.endOf(date, "year");

    const range = [];
    let current = start;

    while (localizer.lte(current, end, "year")) {
        range.push(current);
        current = localizer.add(current, 1, "month");
    }

    return range;
};

CustomYearView.navigate = (
    date: Date,
    action: any,
    { localizer }: { localizer: DateLocalizer }
) => {
    if (action instanceof Date) return action;

    switch (action) {
        case Navigate.NEXT:
            return localizer.add(date, 1, "year");
        case Navigate.PREVIOUS:
            return localizer.add(date, -1, "year");
        default:
            return date;
    }
};

CustomYearView.title = (date: Date, { localizer }: { localizer: DateLocalizer }) => {
    return localizer.format(date, "YYYY");
};
