export const getEntityRowFromFields = (
    fields: { key: string; value?: any }[],
    initialValues?: any
) => {
    return initialValues
        ? initialValues
        : fields.reduce((prevValue: any, currentValue) => {
              prevValue[currentValue.key] = currentValue?.value;
              return prevValue;
          }, {});
};

export const prepareEntityFieldsWithValues = (
    entityRow: any,
    onlyReadMode: boolean,
    entityFieldsWithValues: TEntityFieldWithValue[],
    editableEntityFieldsWithValues: TEntityField[],
    readableEntityFieldsWithValues: TEntityField[]
) => {
    return (
        onlyReadMode ? readableEntityFieldsWithValues : editableEntityFieldsWithValues
    )
        .map((field: any): TBizProcTemplateActionField & TEntityFieldWithValue => {
            const entityFieldWithValue = entityFieldsWithValues.find(
                (fieldWithValue) => fieldWithValue.id === field.id
            );

            return {
                ...(entityFieldWithValue ?? field),
                value: entityRow?.[field.key],
                unique_id: `${field.variable_type}-${field.key}`,
                variable_type: "entity",
            };
        })
        .toSorted((a, b) => {
            return a.order! - b.order!;
        });
};
