import { Typography } from "antd";
import { StartRecoverPasswordForm } from "@features/Portal/Auth/StartRecoverPasswordForm";
import { SignInFormLogo } from "@features/Portal/Auth/SignInForm";
import "./StartRecoverPasswordPage.scss";
interface IRecoverPasswordPage {}

const StartRecoverPasswordPage = ({}: IRecoverPasswordPage) => {
    return (
        <div className="start-recover-page">
            <Typography.Title level={4}>Восстановление пароля</Typography.Title>
            <div className="start-recover-page__form">
                <SignInFormLogo />

                <StartRecoverPasswordForm />
            </div>
        </div>
    );
};

export default StartRecoverPasswordPage;
