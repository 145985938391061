import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { selectEntityUserCapabilities } from "../model";

interface IAccess {
    children: ReactNode;
    userId: number | string;
    type: "create" | "update" | "delete";
    creatorUserId?: number | string | null;
}

const Access = ({ children, userId, creatorUserId, type }: IAccess) => {
    const capabilities = useSelector(selectEntityUserCapabilities);
    if (!capabilities) return <></>;
    if (capabilities[type] === 9) return <>{children}</>;

    if (
        capabilities[type] === 4 &&
        creatorUserId &&
        capabilities.subordinate_users.find(
            (userId) => Number(userId) === Number(creatorUserId)
        )
    )
        return <>{children}</>;
    if (capabilities[type] === 2 && Number(userId) === Number(creatorUserId))
        return <>{children}</>;

    return <></>;
};

export default Access;
