import { Select } from "antd";
import { tmtTypesOptions } from "../constants";

interface ITechMapTmlResultTypeSelect {
    disabled?: boolean;
    value: TTechMapTemplateResultType;
    onChange: (value: TTechMapTemplateResultType) => void;
}

const TechMapTmlResultTypeSelect = ({
    disabled,
    value,
    onChange,
}: ITechMapTmlResultTypeSelect) => {
    return (
        <Select
            placeholder="Результат"
            value={value}
            disabled={disabled}
            onChange={onChange}
            options={tmtTypesOptions}
            style={{ width: "100%" }}
        />
    );
};

export default TechMapTmlResultTypeSelect;
