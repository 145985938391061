import { useCapabilitiesRolesConstraintsFormItem } from "../../model";
import GroupConditionActions from "./GroupConditionActions";
import SingleConditionFormItem from "./SingleConditionFormItem";

interface ICapabilitiesRolesConstraintsFormItem {
    onDeleteCondition: () => void;
    onChangeConditionItem: (
        condition: any,
        name: string,
        value: string,
        indexOfEditingCondition: number
    ) => void;
    onAddConditionGroup: () => void;
    onAddConditionSingle: () => void;
    parentIndex: number;
    entityFieldsOptions: { label: string; value: string; option: TEntityField }[];
    parentCondition: any;
    depthLvl: number;
    onChangeConstraintsItem: (constraintItem: any, indexOfItem: number) => void;
    isLoading: boolean;
}

const CapabilitiesRolesConstraintsFormItem = ({
    onDeleteCondition,
    onChangeConditionItem,
    onAddConditionGroup,
    onAddConditionSingle,
    parentIndex,
    entityFieldsOptions,
    parentCondition,
    depthLvl,
    onChangeConstraintsItem,
    isLoading,
}: ICapabilitiesRolesConstraintsFormItem) => {
    const {
        isSingleCondition,
        comparsionTypesOptions,
        field,
        onChangeConditionItem_,
        onChangeConstraintsItem_,
        onChangeEntityField,
        onChangeConditionOfGroup,
    } = useCapabilitiesRolesConstraintsFormItem(
        onChangeConditionItem,
        parentCondition,
        parentIndex,
        entityFieldsOptions,
        onChangeConstraintsItem
    );

    if (isSingleCondition)
        return (
            <SingleConditionFormItem
                isLoading={isLoading}
                parentIndex={parentIndex}
                parentCondition={parentCondition}
                onChangeEntityField={onChangeEntityField}
                depthLvl={depthLvl}
                entityFieldsOptions={entityFieldsOptions}
                onChangeConditionItem={onChangeConditionItem}
                comparsionTypesOptions={comparsionTypesOptions}
                field={field}
                onDeleteCondition={onDeleteCondition}
            />
        );
    else {
        return (
            <div
                style={{
                    background: "rgb(90, 90, 90, 0.1)",
                    padding: 8,
                    marginLeft: (depthLvl - 1) * 8,
                }}
            >
                <GroupConditionActions
                    depth={depthLvl}
                    onAddConditionGroup={onAddConditionGroup}
                    onAddConditionSingle={onAddConditionSingle}
                    onDeleteCondition={onDeleteCondition}
                    onChangeConditionOfGroup={onChangeConditionOfGroup}
                    conditionGroupValue={parentCondition.condition_type}
                />
                {parentCondition.conditions.map(
                    (currentCondition: any, indexOfCurrentCondition: number) => {
                        const onAddConditionGroup_ = () => {
                            onChangeConstraintsItem_(
                                {
                                    ...currentCondition,
                                    conditions: [
                                        ...currentCondition.conditions,
                                        { condition_type: "AND", conditions: [] },
                                    ],
                                },
                                indexOfCurrentCondition
                            );
                        };
                        const onAddConditionSingle_ = () => {
                            onChangeConstraintsItem_(
                                {
                                    ...currentCondition,
                                    conditions: [
                                        ...currentCondition.conditions,
                                        {
                                            key: undefined,
                                            value: undefined,
                                            condition: undefined,
                                        },
                                    ],
                                },
                                indexOfCurrentCondition
                            );
                        };

                        const onDeleteCondition = () => {
                            onChangeConstraintsItem(
                                {
                                    ...parentCondition,
                                    conditions: parentCondition.conditions.filter(
                                        (item: any, deletedIndex: number) =>
                                            indexOfCurrentCondition !== deletedIndex
                                    ),
                                },
                                parentIndex
                            );
                        };

                        return (
                            <CapabilitiesRolesConstraintsFormItem
                                isLoading={isLoading}
                                onDeleteCondition={onDeleteCondition}
                                key={indexOfCurrentCondition}
                                onChangeConditionItem={onChangeConditionItem_}
                                onAddConditionGroup={onAddConditionGroup_}
                                onAddConditionSingle={onAddConditionSingle_}
                                parentIndex={indexOfCurrentCondition}
                                onChangeConstraintsItem={onChangeConstraintsItem_}
                                entityFieldsOptions={entityFieldsOptions}
                                parentCondition={currentCondition}
                                depthLvl={depthLvl + 1}
                            />
                        );
                    }
                )}
            </div>
        );
    }
};

export default CapabilitiesRolesConstraintsFormItem;
