import { $api } from "@shared/api/api";
const comparisonRoute = "/api/comparison-type";
const datatypeRoute = "/api/field-datatype";

export const getComparisonTypes = () => {
    return $api.get<TResponseList<TFieldComparisonType>>(`${comparisonRoute}`);
};
export const getDatatypes = (params?: TParams) => {
    return $api.get<TResponseList<TDatatype>>(`${datatypeRoute}`, { params: params });
};

export const createDatatype = (datatype: TCreatingDatatype) => {
    return $api.post<TResponse<TDatatype>>(datatypeRoute, datatype);
};
export const updateDatatype = (datatype: TDatatype) => {
    return $api.put<TResponse<TDatatype>>(`${datatypeRoute}/${datatype.id}`, datatype);
};
export const deleteDatatype = (id: number) => {
    return $api.delete<TResponse<TDatatype>>(`${datatypeRoute}/${id}`);
};
