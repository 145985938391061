import { $api } from "@shared/api/api";
const route = "/api/variable";

export const getVariables = () => {
    return $api.get<TResponseList<TVariable>>(route);
};
export const createVariable = (variable: TVariable) => {
    return $api.post<TResponse<TVariable>>(route, variable);
};
export const updateVariable = (variable: TVariable) => {
    return $api.put<TResponse<TVariable>>(`${route}/${variable.id}`, variable);
};
export const deleteVariable = (id: number) => {
    return $api.delete<TResponse<TVariable>>(`${route}/${id}`);
};
