import { InputWithVariables } from "@entities/Portal/BizProcTemplate";
import { FormItem } from "@shared/ui/Form";
import UserSelectWithCalc from "../UserSelectWithCalc";

interface IEditNotificationActionAction {
    templateItem: TBizProcTemplateAction;
    bizProcTemplate: TBizProcTemplateItem;
    disabled?: boolean;
    setItem: (templateItem: TBizProcTemplateAction) => void;
}

const EditNotificationAction = ({
    templateItem,
    disabled,
    setItem,
    bizProcTemplate,
}: IEditNotificationActionAction) => {
    const body = templateItem.parameters?.body;

    const onChangeInputs = (name: string, value: string) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                [name]: value,
            },
        });
    };

    return (
        <>
            <FormItem label="Кому уведомление">
                <UserSelectWithCalc
                    multiple
                    bizProcTemplateId={bizProcTemplate.id!}
                    disabled={disabled}
                    templateItem={templateItem}
                    onChangeItem={setItem}
                />
            </FormItem>

            <FormItem label="Текст">
                <InputWithVariables
                    disabled={disabled}
                    isTextArea={true}
                    itemId={bizProcTemplate.id!}
                    onChange={onChangeInputs}
                    name="body"
                    value={body}
                />
            </FormItem>
        </>
    );
};

export default EditNotificationAction;
