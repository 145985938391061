import { capabilitiesRolesModel } from "@entities/Portal/CapabilitiesRoles";
import { useDispatch } from "react-redux";
import { isEqualEntityRowToInitialEntityRow } from "../lib";
import { useState } from "react";

export const useCapabilitiesRolesRow = (
    entityRow: TCapabilitiesRolesRow,
    initialEntityRow: TCapabilitiesRolesOnly
) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const onOpenModal = () => setIsOpen(true);
    const onCloseModal = () => setIsOpen(false);
    const onChangeRow = (capType: TCapType, value: any) => {
        dispatch(
            capabilitiesRolesModel.updateCapabilitiesRoles({
                ...entityRow,
                [capType]: value,
            })
        );
        if (
            isEqualEntityRowToInitialEntityRow(
                { ...entityRow, [capType]: value },
                initialEntityRow
            )
        ) {
            dispatch(
                capabilitiesRolesModel.deleteEditingCapabilitiesRolesId(entityRow.id!)
            );
        } else
            dispatch(
                capabilitiesRolesModel.updateEditingCapabilitiesRolesId(entityRow.id!)
            );
    };
    return { isOpen, onChangeRow, onOpenModal, onCloseModal };
};
