import { bizProcModel } from "@entities/Portal/BizProc";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useBizProcAgreementsList = (isMyAgreements?: boolean) => {
    const [isMounted, setIsMounted] = useState(false);

    const isAgreementsLoading = useSelector(bizProcModel.isAgreementsLoading);
    const bizProcAgreements = useSelector(
        isMyAgreements ? bizProcModel.selectMyAgreements : bizProcModel.selectAgreements
    );
    const bizProcAgreementLoadingError = useSelector(
        bizProcModel.bizProcAgreementLoadingError
    );

    const dispatch = useDispatch<AppDispatch>();

    const onClick = (agreement: TBizProcAction) => {
        dispatch(
            bizProcModel.setTargetAgreement({
                ...agreement,
                isMy: isMyAgreements,
            })
        );
    };

    useEffect(() => {
        if (!isAgreementsLoading) {
            setIsMounted(true);
        }
        return () => {
            setIsMounted(false);
        };
    }, [isAgreementsLoading]);

    return {
        bizProcAgreements,
        isMounted,
        isAgreementsLoading,
        bizProcAgreementLoadingError,
        onClick,
    };
};
