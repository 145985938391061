import { FormOptionItem } from "@shared/ui/FormOptions";
import { ErrorText } from "@shared/ui/Text";
import { Input, Modal } from "antd";
import { useCreateUpdateDatatype } from "../model";
import { ReactNode } from "react";

import { parseErrorMessageByKey } from "@shared/api/ParseResponse";

interface IDatatypeModifyModa {
    extraDatatypeInputs?: (
        datatype: TDatatype | TCreatingDatatype | null,
        onChangeDatatype: (datatype: TDatatype | TCreatingDatatype) => void
    ) => ReactNode;
}

const DatatypeModifyModa = ({ extraDatatypeInputs }: IDatatypeModifyModa) => {
    const {
        datatype,
        isOpen,
        error,
        onClose,
        onOk,
        onChangeDatatype,
        onChangeNamedValue,
    } = useCreateUpdateDatatype();
    return (
        <Modal
            destroyOnClose
            title={`${datatype?.id ? "Создание" : "Редактирование"} поля`}
            open={isOpen}
            onOk={onOk}
            onCancel={onClose}
        >
            {" "}
            <FormOptionItem label="Тип">
                <>
                    <Input
                        maxLength={40}
                        name="datatype"
                        value={datatype?.datatype}
                        onChange={onChangeNamedValue}
                    />
                    <ErrorText text={parseErrorMessageByKey(error, "datatype")} />
                </>
            </FormOptionItem>
            <FormOptionItem label="Наименование">
                <>
                    <Input
                        maxLength={40}
                        name="name"
                        status={error.data?.["name"] ? "error" : undefined}
                        value={datatype?.name}
                        onChange={onChangeNamedValue}
                    />
                    <ErrorText text={parseErrorMessageByKey(error, "name")} />
                </>
            </FormOptionItem>
            <FormOptionItem label="Описание">
                <>
                    <Input.TextArea
                        maxLength={300}
                        autoSize
                        style={{ maxHeight: 180 }}
                        name="description"
                        value={datatype?.description ?? ""}
                        onChange={onChangeNamedValue}
                    />
                    <ErrorText text={parseErrorMessageByKey(error, "description")} />
                </>
            </FormOptionItem>
            {extraDatatypeInputs && extraDatatypeInputs(datatype, onChangeDatatype)}
        </Modal>
    );
};

export default DatatypeModifyModa;
