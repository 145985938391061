import { emitter } from "@shared/emitter";
import { getUnixTimestamp } from "@shared/lib/date";
import { useEffect, useState } from "react";

export const useAlerNotification = () => {
    const [notifications, setNotifications] = useState<TAlertNotification[]>([]);
    const removeNotification = (notificationId: number) => {
        setNotifications((notifications) => {
            return notifications.filter(
                (notification) => notification.id !== notificationId
            );
        });
    };
    const clearAllNotifications = () => {
        setNotifications([]);
    };
    const handleEventAlertNotification = (notification: TAlertNotification) => {
        const notificationId = getUnixTimestamp();
        setNotifications((notifications) => {
            return [
                ...notifications,
                {
                    id: notificationId,
                    ...notification,
                },
            ];
        });

        setTimeout(
            () => {
                removeNotification(notificationId);
            },
            (notification.duration ?? 3) * 1000
        );
    };

    useEffect(() => {
        emitter.on("alert-notification-emit", handleEventAlertNotification);
        return () => {
            emitter.removeListener(
                "alert-notification-emit",
                handleEventAlertNotification
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        notifications,
        clearAllNotifications,
        removeNotification,
    };
};
