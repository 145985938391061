import { bizProcModel } from "@entities/Portal/BizProc";
import { userModel } from "@entities/Portal/User";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useBizProcUserActionWS = (onlyExecuting?: boolean) => {
    const user = useSelector(userModel.selectUser);
    const dispatch = useDispatch();

    useEffect(() => {
        window.Echo.channel(`User.${user.id}.BizProcAction`)
            .listen(
                ".bizProcAction.create",
                ({ bizProcAction }: { bizProcAction: TBizProcAction }) => {
                    if (onlyExecuting && bizProcAction.status !== "executing") {
                        return;
                    }

                    if (
                        (bizProcAction.action === "FormAction" &&
                            bizProcAction.collected_parameters.users_ids.includes(
                                user.id
                            )) ||
                        (bizProcAction.action === "InitialAction" &&
                            bizProcAction.collected_parameters.users_ids.includes(
                                user.id
                            ) &&
                            bizProcAction.parameters?.triggerType === "form")
                    ) {
                        dispatch(bizProcModel.addUserAction(bizProcAction));
                    }

                    if (
                        bizProcAction.action === "AgreementAction" &&
                        bizProcAction.collected_parameters.users_ids.includes(user.id)
                    ) {
                        dispatch(bizProcModel.addUserAction(bizProcAction));
                    }
                    if (
                        bizProcAction.action === "AgreementAction" &&
                        bizProcAction?.biz_proc?.assigned_user.id === user.id
                    ) {
                        dispatch(bizProcModel.addUserAgreement(bizProcAction));
                    }
                }
            )
            .listen(
                ".bizProcAction.update",
                ({ bizProcAction }: { bizProcAction: TBizProcAction }) => {
                    if (
                        !(
                            bizProcAction.action === "AgreementAction" ||
                            bizProcAction.action === "FormAction" ||
                            bizProcAction.parameters?.triggerType === "form"
                        )
                    )
                        return;
                    if (onlyExecuting && bizProcAction.status !== "executing") {
                        // if (
                        //     bizProcAction.action === "AgreementAction" &&
                        //     bizProcAction.collected_parameters.users_ids.includes(user.id)
                        // ) {
                        //     dispatch(bizProcModel.deleteUserAction(bizProcAction));
                        // }
                        // if (
                        //     bizProcAction.action === "AgreementAction" &&
                        //     bizProcAction?.biz_proc?.assigned_user.id === user.id
                        // ) {
                        //     dispatch(bizProcModel.deleteUserAgreement(bizProcAction));
                        // }
                        // if (
                        //     bizProcAction.action === "FormAction" &&
                        //     bizProcAction.collected_parameters.users_ids.includes(user.id)
                        // ) {
                        //     dispatch(bizProcModel.deleteUserAction(bizProcAction));
                        // }

                        // if (
                        //     bizProcAction.action === "InitialAction" &&
                        //     bizProcAction.collected_parameters.users_ids.includes(
                        //         user.id
                        //     ) &&
                        //     bizProcAction.parameters?.triggerType === "form"
                        // ) {
                        //     dispatch(bizProcModel.deleteUserAction(bizProcAction));
                        // }
                        dispatch(bizProcModel.deleteUserAgreement(bizProcAction));
                        dispatch(bizProcModel.deleteUserAction(bizProcAction));
                    } else {
                        if (bizProcAction.action === "AgreementAction") {
                            dispatch(bizProcModel.updateUserAgreement(bizProcAction));
                        } else if (
                            bizProcAction.action === "FormAction" ||
                            bizProcAction.parameters?.triggerType === "form"
                        ) {
                            dispatch(bizProcModel.updateUserAction(bizProcAction));
                        }
                    }
                    // if (
                    //     (bizProcAction.action === "FormAction" &&
                    //         bizProcAction.collected_parameters.users_ids.includes(
                    //             user.id
                    //         )) ||
                    //     (bizProcAction.action === "InitialAction" &&
                    //         bizProcAction.collected_parameters.users_ids.includes(
                    //             user.id
                    //         ) &&
                    //         bizProcAction.collected_parameters.triggerType === "form")
                    // ) {
                    //     dispatch(bizProcModel.updateUserAction(bizProcAction));
                    // }
                }
            );

        return () => {
            window.Echo.leave(`User.${user.id}.BizProcAction`);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.id]);
};
