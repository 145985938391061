import { entityRowModel } from "@entities/Portal/EntityRow";
import { useLoadEntityRowsWithPage } from "@entities/Portal/EntityRow/model";
import { estimationModel } from "@entities/Portal/Estimations";
import { viewPatternModel } from "@entities/Portal/ViewPattern";
import { viewPatternItemModel } from "@entities/Portal/ViewPatternItem";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPatternColumns } from "../lib";
import { useEntityRowsTableWs } from "./UseEntityRowsTableWs";

interface IUseEntityRowsTable {
    entityId: number;
    level?: number;
    levelId?: number | string;
    additionalFilters?: TFilterValue[];
}

export const useEntityRowsTable = ({
    entityId,
    level,
    levelId,
    additionalFilters,
}: IUseEntityRowsTable) => {
    const levelKey = level === undefined ? 0 : `${levelId ?? ""}_${level ?? 0}`;

    const {
        isLoading,
        entityFields,
        currentPage,
        lastPage,
        entityRows,
        perPage,
        groupingKey,
        setPerPage,
    } = useLoadEntityRowsWithPage({ entityId, level, levelId, additionalFilters });
    useEntityRowsTableWs(entityId);
    const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
    const dispatch = useDispatch<AppDispatch>();
    const viewPattern = useSelector((state) =>
        viewPatternModel.selectCurrentViewPattern(state, "table")
    );
    const viewPatternItems = useSelector((state) =>
        viewPatternItemModel.selectorViewPatternItems(state, viewPattern?.id ?? null)
    );
    useEffect(() => {
        if (viewPattern) {
            dispatch(
                viewPatternItemModel.loadViewPatternItemsThunk({
                    viewPatternId: viewPattern.id,
                })
            );
        }
    }, [viewPattern]);
    const onShowSizeChange = (current: number, size: number) => {
        dispatch(
            entityRowModel.setCurrentPageEntityRowsWithPage({
                key: levelKey,
                page: current,
            })
        );
        setPerPage(size);
    };

    const columns = getPatternColumns(viewPattern, viewPatternItems, entityFields);
    const onSelectRows = (selectedRows: any[]) => {
        setSelectedRowIds(selectedRows);
        const selectedRowsIds = selectedRows.map(({ id }) => id);
        dispatch(estimationModel.setChoosenIdsForAdditionalEstimation(selectedRowsIds));
    };

    return {
        onShowSizeChange,
        onSelectRows,
        levelKey,
        selectedRowIds,
        viewPattern,
        entityFields,
        isLoading,
        columns,
        currentPage,
        lastPage,
        entityRows,
        perPage,
        groupingKey,
    };
};
