import { $api } from "@shared/api/api";
const route = "api/navigation-route";
export const getNavigationRoutes = () => {
    return $api.get<TResponseList<TNavigationRoute>>(route);
};
export const createNavigationRoute = (navigationRoute: TCreatingNavigationRoute) => {
    return $api.post<TResponse<TNavigationRoute>>(route, navigationRoute);
};
export const deleteNavigationRoute = (id: number) => {
    return $api.delete<TResponse<TNavigationRoute>>(`${route}/${id}`);
};
export const updateNavigationRoute = (navigationRoute: TNavigationRoute) => {
    return $api.put<TResponse<TNavigationRoute>>(
        `${route}/${navigationRoute.id}`,
        navigationRoute
    );
};
export const massUpdateNavigationRoute = (navigationRoutes: TNavigationRoute[]) => {
    return $api.put<TResponseList<TNavigationRoute>>(
        `${route}/mass-update`,
        navigationRoutes
    );
};
