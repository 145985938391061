import { UsersAssygnment } from "../BizProcsUsers";
import "./FormAction.scss";
interface IFormAction {
    action: TBizProcAction<TBizProcAgreementParameters>;
    onUserClick?: (user: TUser) => void;
}

const FormAction = ({ action, onUserClick }: IFormAction) => {
    return (
        <div className="bizproc-action">
            <div className="bizproc-action__content">
                {
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: 5 }}>
                            {`${action.status === "done" ? "Форма заполнена: " : "Ожидает заполнения формы от:"}`}
                        </span>
                        <UsersAssygnment
                            customLabel=""
                            users={
                                action.status === "executing"
                                    ? action.collected_parameters.users
                                    : action.collected_parameters.processed_users ?? []
                            }
                            onUserClick={onUserClick}
                            status={action.status}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default FormAction;
