import { BarsOutlined, TableOutlined } from "@ant-design/icons";
import { Segmented } from "antd";

interface IEntityViewMode {
    id?: number;
    name?: string;
    mode: TEntityInputViewMode;
    setMode: (item: TEntityInputViewMode) => void;
}

const EntityViewMode = ({ id, name, mode, setMode }: IEntityViewMode) => {
    const onChangeMode = (mode: TEntityInputViewMode) => {
        setMode(mode);
        localStorage.setItem(`${id}__${name}__entityViewMode`, mode);
    };

    return (
        <Segmented
            value={mode}
            options={[
                { value: "table", icon: <BarsOutlined /> },
                { value: "list", icon: <TableOutlined /> },
            ]}
            onChange={(value) => onChangeMode(value as TEntityInputViewMode)}
        />
    );
};

export default EntityViewMode;
