import { Fragment, ReactNode } from "react";
import { useDrawerManager } from "../model";
import { Drawer } from "antd";
//TODO Refactor (BP remove)

interface IDrawerManager {
    patterns: string[];
    drawer: (
        drawerItem: TDrawerManagerItemPrepared,
        closeDrawer: (item: TDrawerManagerItemPrepared) => void,
        level?: number
    ) => ReactNode;
}

const DrawerManager = ({ patterns, drawer }: IDrawerManager) => {
    const { openedDrawers, closeDrawer } = useDrawerManager(patterns);
    const windowWidth = window.innerWidth;
    const shortVariant = windowWidth <= 900;

    return (
        <>
            {openedDrawers.map((drawerItem, index, fullArr) => {
                const level = fullArr.filter((item) => item.isOpen).length - 1 - index;
                const onClose = () => {
                    closeDrawer(drawerItem);
                };
                return (
                    <Fragment key={drawerItem.location}>
                        <Drawer
                            destroyOnClose={true}
                            onClose={onClose}
                            width={!shortVariant ? "fit-content" : "100vw"}
                            styles={{
                                body: {
                                    background: "rgb(143,133,232, 0.1)",
                                    padding: 0,
                                    overflow: "visible",
                                },
                                content: {
                                    overflow: "visible",
                                },
                                mask: {
                                    backdropFilter: "blur(1px)",
                                },
                            }}
                            closeIcon={shortVariant}
                            zIndex={1000}
                            open={drawerItem.isOpen}
                        >
                            {drawer(drawerItem, closeDrawer, level)}
                        </Drawer>
                    </Fragment>
                );
            })}
        </>
    );
};

export default DrawerManager;
