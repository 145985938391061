import { CalculatedValueItemSkeleton } from "@entities/Portal/CalculatedValues";
import "./CalculatedValuesList.scss";

const CalculatedValuesSkeletonList = () => {
    const skeletonCounts = Number(localStorage.getItem("variablesLength"));
    const multiply = Array.from({ length: skeletonCounts > 0 ? skeletonCounts : 1 });
    return (
        <div className="variables-list">
            {multiply.map((_, index) => {
                return (
                    <div className="variables-list__item" key={index}>
                        <CalculatedValueItemSkeleton />
                    </div>
                );
            })}
        </div>
    );
};

export default CalculatedValuesSkeletonList;
