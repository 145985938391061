import {
    getDatetimeFormattedValue,
    getDatetimeHumanFormat,
    getDatetimePickerFormat,
} from "@shared/lib/date";
import { FormReadValue } from "@shared/ui/Form";
import { DatePicker } from "antd";

interface IDateTimeInput {
    value: any;
    additionalParameters: TAdditionalParameters;
    onChangeValue: (value: any) => void;
    onlyRead?: boolean;
    disabled?: boolean;
}

const DateTimeInput = ({
    value,
    onlyRead,
    additionalParameters,
    onChangeValue,
    disabled,
}: IDateTimeInput) => {
    const onChange = (value: any | null, dateString: string) => {
        onChangeValue(getDatetimeFormattedValue(value));
    };
    const onChangeBetween = (values: any, formatString: [string, string]) => {
        onChangeValue([
            getDatetimeFormattedValue(values?.[0]),
            getDatetimeFormattedValue(values?.[1]),
        ]);
    };
    const isBetween = additionalParameters?.isBetween;

    if (onlyRead) {
        if (isBetween) {
            return (
                <div style={{ display: "flex" }}>
                    <FormReadValue
                        value={value ? getDatetimeHumanFormat(value?.[0]) : "-"}
                    />
                    {`->`}
                    <FormReadValue
                        value={value ? getDatetimeHumanFormat(value?.[1]) : "-"}
                    />
                </div>
            );
        } else {
            return <FormReadValue value={value ? getDatetimeHumanFormat(value) : "-"} />;
        }
    }
    if (isBetween) {
        return (
            <DatePicker.RangePicker
                disabled={disabled}
                style={{ width: "100%" }}
                format={"DD.MM.YYYY HH:mm"}
                value={[
                    getDatetimePickerFormat(value?.[0]),
                    getDatetimePickerFormat(value?.[1]),
                ]}
                picker="date"
                changeOnBlur
                // placeholder={field.name}
                onChange={onChangeBetween}
            />
        );
    }

    return (
        <DatePicker
            style={{ width: 180 }}
            format={"YYYY-MM-DD HH:mm"}
            value={getDatetimePickerFormat(value)}
            picker="date"
            showTime
            changeOnBlur
            // placeholder={field.name}
            onChange={onChange}
        />
    );
};

export default DateTimeInput;
