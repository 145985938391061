import { $api } from "@shared/api/api";

export const getValuesByKeys = (
    typedKey: string,
    choosenKeys: any,
    requestedUrl: string,
    queryTypedParam: string,
    queryChoosenParams: string
) => {
    const params: any = {};
    params[queryTypedParam] = typedKey;
    params[queryChoosenParams] = choosenKeys;
    return $api.get<any>("", { params: params, baseURL: requestedUrl });
};
