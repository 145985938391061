import { Select } from "antd";
import CapabilitiesRolesTypeInputReadOnly from "./CapabilitiesRolesTypeInputReadOnly";

interface ICapabilitiesRolesTypeMultiSelectInput {
    isFocused: boolean;
    onFocus?: () => void;
    additionalOptions: { label: string; value: string }[];
    bordered: boolean;
    value: string;
    onChange: (value: string[]) => void;
    isDefaultOpen: boolean;
    isLoading: boolean;
    maxCount?: number;
}

const CapabilitiesRolesTypeMultiSelectInput = ({
    isFocused,
    onFocus,
    additionalOptions,
    bordered,
    value,
    onChange,
    isDefaultOpen,
    isLoading,
    maxCount,
}: ICapabilitiesRolesTypeMultiSelectInput) => {
    return (
        <>
            {!isFocused ? (
                <CapabilitiesRolesTypeInputReadOnly
                    value={value}
                    onFocus={onFocus}
                    additionalOptions={additionalOptions}
                />
            ) : (
                <Select
                    maxTagCount={maxCount}
                    options={additionalOptions}
                    mode={"multiple"}
                    bordered={bordered || isFocused}
                    value={value ? value.split(",") : []}
                    defaultOpen={isDefaultOpen}
                    loading={isLoading}
                    onChange={onChange}
                    style={{ width: "100%", height: "100%" }}
                />
            )}
        </>
    );
};

export default CapabilitiesRolesTypeMultiSelectInput;
