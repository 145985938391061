import { TMOperationsList } from "@entities/Production/TechMap";
import { techMapTemplateLib } from "@entities/Production/TechMapTemplate";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { Button, Space, Tag } from "antd";
import { useTMItem } from "../model";
import { tmStatusNames, tmStatusTagColor } from "../constants";

interface ITMItem {
    item: TTechMapStarted;
    review?: boolean;
}

const TMItem = ({ item, review }: ITMItem) => {
    const { approveLoading, rejectLoading, onApprove, onReject } = useTMItem(item);
    if (!item.template) return <>---</>;
    const tmtOperations = techMapTemplateLib.getTemplateWithMapOperations(item);
    return (
        <div className="item-card">
            <IndentContainer>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            fontWeight: 500,
                        }}
                    >
                        {item.template?.name}
                    </div>
                    <div style={{ display: "flex" }}>
                        <Tag
                            style={{ fontWeight: 600 }}
                            color={tmStatusTagColor[item.status ?? "failed"]}
                        >
                            {tmStatusNames[item.status ?? "failed"]}
                        </Tag>

                        {review && item.status === "waiting" && (
                            <div>
                                <Space>
                                    <Button
                                        style={{ margin: 0, padding: 0, minHeight: 10 }}
                                        type="link"
                                        danger
                                        size="small"
                                        loading={rejectLoading}
                                        disabled={approveLoading}
                                        onClick={onReject}
                                    >
                                        Отклонить
                                    </Button>
                                    <Button
                                        loading={approveLoading}
                                        disabled={rejectLoading}
                                        style={{ margin: 0, padding: 0, minHeight: 10 }}
                                        type="link"
                                        size="small"
                                        onClick={onApprove}
                                    >
                                        Согласовать
                                    </Button>
                                </Space>
                            </div>
                        )}
                    </div>
                </div>
            </IndentContainer>
            <div
                style={{
                    display: "flex",
                }}
            >
                <TMOperationsList
                    small
                    templateOperationsWithMapOperation={tmtOperations}
                    loading={false}
                />
            </div>
        </div>
    );
};

export default TMItem;
