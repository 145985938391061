import { Select } from "antd";

interface ISelectVariable {
    templateItem: TBizProcTemplateAction;
    setItem: (templateItem: TBizProcTemplateAction) => void;
    variables: (TEntityField | TVariable)[];
    disabled?: boolean;
}
type VariableOption = {
    value: string;
    label: string;
    variable: TEntityField | TVariable;
};

const SelectVariable = ({
    disabled,
    templateItem,
    variables,
    setItem,
}: ISelectVariable) => {
    const templateItemVariables = (templateItem.parameters.variables ??
        []) as TChangeActionVariable[];

    const onSelectVariable = (value: string, option: VariableOption) => {
        //TODO
        const field = option.variable as any;

        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                variables: [
                    ...templateItemVariables,
                    {
                        id: field?.id,
                        is_multiple: field?.is_multiple,
                        key_label: field?.key_label,
                        key_value: field?.key_value,
                        unique_id: `${field.variable_type}-${field.key}`,
                        key: field?.key,
                        datatype: field?.datatype,
                        variable_type: field.variable_type,
                        related_entity_uuid: field.related_entity_uuid,
                        is_calculation: field.is_calculation,
                        calculation_field_type: field.calculation_field_type,
                        textVariables: field.textVariables,
                        name: field.name,
                    },
                ],
            },
        });
    };
    const onDeSelectVariable = (value: string, option: VariableOption) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                variables: templateItemVariables.filter(
                    (item) => item.unique_id !== value
                ),
            },
        });
    };

    return (
        <Select
            disabled={disabled}
            style={{ width: "100%" }}
            options={variables.map((item) => ({
                value: `${item.variable_type}-${item.key}`,
                label: item.name,
                variable: item,
            }))}
            filterOption={(input: any, option) => {
                return option?.label?.includes(input) === true;
            }}
            onSelect={onSelectVariable}
            onDeselect={onDeSelectVariable}
            value={templateItemVariables.map((item) => item.unique_id)}
            mode="multiple"
        />
    );
};

export default SelectVariable;
