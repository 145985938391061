import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import {
    createFieldOptionThunk,
    deleteFieldOptionThunk,
    loadFieldOptionsThunk,
    updateFieldOptionThunk,
} from "./FieldOptionThunk";
import { IFieldOptionSlice } from "./FieldOptionSlice";
import { updateArrByItemId } from "@shared/lib/object-helper";

const loadBuilder = (builder: ActionReducerMapBuilder<IFieldOptionSlice>) => {
    builder.addCase(loadFieldOptionsThunk.fulfilled, (state, action) => {
        state.fieldOptions = action.payload.data;
        localStorage.setItem("fieldOptionsLength", state.fieldOptions.length.toString());
    });
    builder.addCase(loadFieldOptionsThunk.pending, (state) => {});
    builder.addCase(loadFieldOptionsThunk.rejected, (state, rejectedValue) => {});
};
const createBuilder = (builder: ActionReducerMapBuilder<IFieldOptionSlice>) => {
    builder.addCase(createFieldOptionThunk.fulfilled, (state, action) => {
        state.fieldOptions.unshift(action.payload.data);
        localStorage.setItem("fieldOptionsLength", state.fieldOptions.length.toString());
    });
};
const updateBuilder = (builder: ActionReducerMapBuilder<IFieldOptionSlice>) => {
    builder.addCase(updateFieldOptionThunk.fulfilled, (state, action) => {
        state.fieldOptions = updateArrByItemId(state.fieldOptions, action.payload.data);
    });
};
const deleteBuilder = (builder: ActionReducerMapBuilder<IFieldOptionSlice>) => {
    builder.addCase(deleteFieldOptionThunk.fulfilled, (state, action) => {
        state.fieldOptions = state.fieldOptions.filter(
            (item) => item.id !== action.payload.data.id
        );
        localStorage.setItem("fieldOptionsLength", state.fieldOptions.length.toString());
    });
};

export const fieldOptionBuilder = (
    builder: ActionReducerMapBuilder<IFieldOptionSlice>
) => {
    loadBuilder(builder);
    createBuilder(builder);
    updateBuilder(builder);
    deleteBuilder(builder);
};
