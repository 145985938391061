import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { selectUserActions } from "./BizProcSelectors";
import { loadBizProcUserActionsThunk } from "./BizProcThunk";

export const useLoadBizProcUserActions = (preload?: boolean) => {
    const actions = useSelector(selectUserActions);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        if (preload) {
            loadActions();
        }
    }, []);

    const loadActions = () => {
        setLoading(true);

        dispatch(
            loadBizProcUserActionsThunk({
                filter: {
                    condition_type: "AND",
                    conditions: [
                        {
                            value: "executing",
                            condition: "=",
                            key: "status",
                        },
                    ],
                },
            })
        ).then(() => {
            setLoading(false);
        });
    };
    return {
        actions,
        loading,
        loadActions,
    };
};
