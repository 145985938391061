import { $api } from "@shared/api/api";

export const generateQrCodes = (
    entityId: number,
    selectedEntityFieldId: number,
    bizProcTemplateId: number,
    departmentsIds: number[]
) => {
    return $api.post(`api/entity/${entityId}/qrcode`, {
        selectedEntityFieldId,
        bizProcTemplateId,
        departmentsIds,
    });
};
