import { ChangeEvent } from "react";

export const useTechMapTemplateResultItem = (
    item: TTechMapTemplateResult | TTechMapTemplateResultCreating,
    onChange: (tmtResult: TTechMapTemplateResult | TTechMapTemplateResultCreating) => void
) => {
    const onChangeType = (value: TTechMapTemplateResultType) => {
        onChange({
            ...item,
            quantity: value === "create" ? 1 : item.quantity,
            type: value,
        });
    };

    const onChangeQuantity = (event: ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value === "" ? 1 : event.target.value);
        onChange({
            ...item,
            quantity: value > 100 ? 100 : value === 0 ? 1 : value,
        });
    };
    const onChangeQuantityKey = (value: string) => {
        onChange({
            ...item,
            entity_row_quantity_key: value,
        });
    };
    const onChangeCodeKey = (value: string) => {
        onChange({
            ...item,
            qr_code_field_key: value,
        });
    };
    const onChangeEntityRowId = (value: number, entityRow: any) => {
        onChange({
            ...item,
            entity_row_id: value,
        });
    };
    return {
        onChangeQuantityKey,
        onChangeQuantity,
        onChangeType,
        onChangeCodeKey,
        onChangeEntityRowId,
    };
};
