export const orderViewPatternItems = (items: TViewPatternItem[]) => {
    return (items as any).toSorted((a: TViewPatternItem, b: TViewPatternItem) => {
        return a.order! - b.order!;
    });
};

export const prepareViewPatternItems = (viewPatternItems: TViewPatternItem[]) => {
    const viewPatternItemsWithoutParent = viewPatternItems.filter(
        (item) => !item.parent_id
    );
    const viewPatternItemsWithParent = viewPatternItems.filter((item) => item.parent_id);

    return orderViewPatternItems(
        viewPatternItemsWithoutParent.map((item) => {
            if (item.type === "group") {
                const childrens = viewPatternItemsWithParent.filter(
                    (innItem) =>
                        innItem.parent_id &&
                        innItem.parent_id.toString() === item.id.toString()
                );

                return {
                    ...item,
                    children: orderViewPatternItems(childrens),
                };
            }
            return item;
        })
    );
};
