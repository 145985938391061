import { QrcodeOutlined } from "@ant-design/icons";
import { Skeleton } from "@shared/ui/Skeleton";
import { Spin } from "antd";

interface IQrCodeListSkeleton {}

const QrCodeListSkeleton = ({}: IQrCodeListSkeleton) => {
    const skeletonItemsCount = localStorage.getItem("skeleton_qr_codes_qty") ?? "0";
    if (skeletonItemsCount === "0") {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 100,
                }}
            >
                <Spin />
            </div>
        );
    }
    return (
        <div>
            <Skeleton
                items={[
                    {
                        type: "group",
                        count: 2,

                        item: {
                            type: "div",
                            containerStyle: {
                                marginBottom: 10,
                            },
                            items: [
                                {
                                    type: "skeleton",

                                    title: false,
                                    paragraph: {
                                        rows: 1,
                                        width: "100%",
                                    },
                                },
                                {
                                    type: "group",
                                    count: 3,
                                    containerStyle: {
                                        display: "flex",
                                        marginTop: 10,
                                    },
                                    item: {
                                        type: "node",
                                        containerStyle: {
                                            marginRight: 10,
                                            height: 150,
                                            width: 150,
                                        },
                                        node: (
                                            <QrcodeOutlined
                                                style={{
                                                    fontSize: 80,
                                                    color: "#bfbfbf",
                                                }}
                                            />
                                        ),
                                    },
                                },
                            ],
                        },
                    },
                ]}
            />
        </div>
    );
};

export default QrCodeListSkeleton;
