import { useSelector } from "react-redux";
import { selectGetLoading, selectTechMapTemplates } from "./TechMapTemplateSelectors";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadTechMapTemplatesThunk } from "./TechMapTemplateThunk";
import { setTechMapTemplates } from "../TechMapTemplateSlice";

export const useTechMapTemplatesLoad = () => {
    const loading = useSelector(selectGetLoading);
    const techMapTemplates = useSelector(selectTechMapTemplates);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(loadTechMapTemplatesThunk({}));
        return () => {
            dispatch(setTechMapTemplates([]));
        };
    }, []);

    return {
        techMapTemplates,
        loading,
    };
};
