import { Button } from "antd";
import { useViewPatternCreate } from "../model";
import { PlusOutlined } from "@ant-design/icons";

interface IViewPatternCreateButton {
    entityId: number;
}

const ViewPatternCreateButton = ({ entityId }: IViewPatternCreateButton) => {
    const { onClick, loading } = useViewPatternCreate(entityId);
    return (
        <Button type="link" onClick={onClick} icon={<PlusOutlined />} loading={loading}>
            Создать
        </Button>
    );
};

export default ViewPatternCreateButton;
