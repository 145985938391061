import { useEffect, useState } from "react";
import { getUserQrCodes } from "../api";

export const useQrCodeList = (userId: number) => {
    const [loading, setLoading] = useState(false);
    const [qrCodes, setQrCodes] = useState<TQrCodeEntityGroup[]>([]);
    const getQrCodes = () => {
        setLoading(true);
        getUserQrCodes(userId)
            .then((response) => {
                setQrCodes(response.data.data);
                localStorage.setItem(
                    "skeleton_qr_codes_qty",
                    response.data.data.length.toString()
                );
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getQrCodes();
    }, []);

    return {
        qrCodes,
        loading,
    };
};
