import { useState } from "react";

export const useEstimationTemplatesWithEdit = () => {
    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
    const onCloseDrawer = () => {
        setIsOpenDrawer(false);
    };
    const onOpenDrawer = () => {
        setIsOpenDrawer(true);
    };

    return { isOpenDrawer, onCloseDrawer, onOpenDrawer };
};
