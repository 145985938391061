import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "./TMTONecessaryItems.scss";
import { CardWithAvatar } from "@shared/ui/Cards/CardWithAvatar";
interface ITMTONecessaryItem {
    item: TOperationNecessaryItem | TOperationNecessaryItemCreating;
    editingMode?: boolean;
    onEdit: (item: TOperationNecessaryItem | TOperationNecessaryItemCreating) => void;
    onDelete: (item: TOperationNecessaryItem | TOperationNecessaryItemCreating) => void;
}

const TMTONecessaryItem = ({
    item,
    editingMode,
    onEdit,
    onDelete,
}: ITMTONecessaryItem) => {
    // id: number;
    // type: TOperationNecessaryItemType;
    // label: string;
    // count: number | null;
    // technical_map_template_operation_uuid: string;
    // related_entity_uuid: string | null;
    // related_entity_row_id: number | null;
    // uuid: string;
    // necessary_type: TOperationNecessaryItemNecessaryType;
    // created_at: string;
    // updated_at: string;
    // condition_items: any[];
    const onClickEdit = () => {
        onEdit(item);
    };
    const onClickDelete = () => {
        onDelete(item);
    };
    return (
        <CardWithAvatar
            hideAvatar
            header={<div>{item.label}</div>}
            description={
                <div style={{ display: "flex" }}>
                    <span>Количество:</span>
                    <span style={{ marginLeft: 5 }}>{item.count ?? 1}</span>
                </div>
            }
            actions={
                editingMode && (
                    <div>
                        <Button
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={onClickDelete}
                        />
                        <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={onClickEdit}
                        />
                    </div>
                )
            }
        />
    );
};

export default TMTONecessaryItem;
