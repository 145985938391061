import { useLocation, useNavigate } from "react-router-dom";

export const useQueryParams = (key: string) => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const value = queryParams.get(key);
    const setParam = (value: string | number) => {
        queryParams.append(key, value.toString());
        navigate({
            search: queryParams.toString(),
        });
    };
    const deleteParam = () => {
        queryParams.delete(key);
        navigate({
            search: queryParams.toString(),
        });
    };
    return {
        setParam,
        deleteParam,
        value,
    };
};
