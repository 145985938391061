import { $api } from "@shared/api/api";
const route = "/api/production/tm-template";
const routeResult = "/api/production/tmt-result";

export const getTechMapTemplateResults = (
    techMapTemplateId: number,
    params?: TParams
) => {
    return $api.get<TResponseList<TTechMapTemplateResult>>(
        `${route}/${techMapTemplateId}/result`,
        { params: params }
    );
};
export const getTechMapTemplateResult = (techMapTemplateResultId: number) => {
    return $api.get<TResponse<TTechMapTemplateResult>>(
        `${routeResult}/${techMapTemplateResultId}`
    );
};
export const updateTechMapTemplateResult = (
    techMapTemplateResult: TTechMapTemplateResult
) => {
    return $api.put<TResponse<TTechMapTemplateResult>>(
        `${routeResult}/${techMapTemplateResult.id}`,
        techMapTemplateResult
    );
};
export const createTechMapTemplateResult = (
    techMapTemplateId: number,
    techMapTemplateResult: TTechMapTemplateResultCreating
) => {
    return $api.post<TResponse<TTechMapTemplateResult>>(
        `${route}/${techMapTemplateId}/result`,
        techMapTemplateResult
    );
};
export const deleteTechMapTemplateResult = (id: number) => {
    return $api.delete<TResponse<TTechMapTemplateResult>>(`${routeResult}/${id}`);
};
