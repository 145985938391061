import { LogoutOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useSignOutButton } from "./model";

interface ISignOutButton {
    color?: string;
    size?: number;
}

const SignOutButton = ({ color, size }: ISignOutButton) => {
    const buttonStyle = {
        color: color ?? "#002140",
        fontSize: size,
    };
    const { onSignOut } = useSignOutButton();
    return (
        <Button
            type="link"
            danger
            style={buttonStyle}
            icon={<LogoutOutlined />}
            onClick={onSignOut}
        >
            Выход
        </Button>
    );
};

export default SignOutButton;
