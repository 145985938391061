import { $api } from "@shared/api/api";
const route = "/api/entity-field";
export const updateEntityField = (entityField: TEntityField) => {
    return $api.put<TResponse<TEntityField>>(`${route}/${entityField.id}`, entityField);
};
export const deleteEntityField = (entityFieldId: number) => {
    return $api.delete<TResponse<TEntityField>>(`${route}/${entityFieldId}`);
};
export const createEntityField = (entityField: TEntityField) => {
    return $api.post<TResponse<TEntityField>>(`${route}`, entityField);
};
export const reorderEntityFields = (
    order: {
        id: number;
        order: number;
    }[]
) => {
    return $api.post<TResponse<any>>(`${route}/reorder`, { order });
};
export const getEntityFields = (
    entityUuid?: string,
    entityId?: number,
    params?: TParams
) => {
    return $api.get<TResponseList<TEntityField>>(
        `${route}?filter[condition_type]=AND&filter[conditions][0][key]=${entityUuid ? "entity_uuid" : "entity_id"}&filter[conditions][0][condition]=LIKE&filter[conditions][0][value]=${entityUuid ?? entityId}`,
        {
            params: params,
        }
    );
};
export const getEntityFieldsWithValues = (entityUuid: string) => {
    return $api.get<TResponseList<TEntityField>>(`${route}/values/${entityUuid}`);
};
export const getEntityRowWithEntityFields = (entityUuid: string, entityRowId: number) => {
    return $api.get<TResponse<TEntityRowResponse>>(
        `${route}/values/${entityUuid}/${entityRowId}`
    );
};
