import "./EditableCard.scss";
import { Button, Popconfirm } from "antd";
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
} from "@ant-design/icons";
import { ReactNode } from "react";
interface IEditableCard {
    children: ReactNode;
    isEditing?: boolean;
    onSubmit: () => void;
    onClick?: () => void;
    onClose: () => void;
    onSubmitDelete: () => void;
    onEdit: () => void;
    deleteLoading?: boolean;
}

const EditableCard = ({
    children,
    onClick,
    onSubmitDelete,
    deleteLoading,
    onEdit,
    onSubmit,
    onClose,
    isEditing,
}: IEditableCard) => {
    return (
        <div
            className={`editable-card ${onClick && !isEditing ? "clickable" : ""}`} // может использовать другой компонент
            //чтобы не сломать этот функционал?
            onClick={isEditing ? undefined : onClick}
        >
            <div>{children}</div>
            <div className="editable-card__buttons" onClick={(e) => e.stopPropagation()}>
                {isEditing ? (
                    <>
                        <Button
                            key={"save"}
                            onClick={onSubmit}
                            type="link"
                            style={{
                                color: "green",
                            }}
                            icon={<CheckOutlined />}
                        />
                        <Button
                            onClick={onClose}
                            type="link"
                            style={{
                                color: "silver",
                            }}
                            icon={<CloseOutlined />}
                        />
                    </>
                ) : (
                    <>
                        <Button type="link" onClick={onEdit} icon={<EditOutlined />} />
                        <Popconfirm
                            title="Удалить элемент?"
                            okText="Удалить"
                            onConfirm={onSubmitDelete}
                            cancelText="Отмена"
                        >
                            <Button
                                loading={deleteLoading}
                                type="link"
                                danger
                                icon={<DeleteOutlined />}
                            />
                        </Popconfirm>
                    </>
                )}
            </div>
        </div>
    );
};

export default EditableCard;
