import { emitter } from "@shared/emitter";
import { message } from "antd";
import { useEffect } from "react";

export const useMessage = () => {
    const [apiMessage, contextHolderMessage] = message.useMessage();

    const handleEvenMessage = (value: TMessage) => {
        apiMessage.open({
            type: value.type,
            content: value.content,
            duration: 3,
        });
    };

    useEffect(() => {
        emitter.on("message-emit", handleEvenMessage);
        return () => {
            emitter.removeListener("message-emit", handleEvenMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        contextHolderMessage,
    };
};
