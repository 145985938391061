import { Select } from "antd";
import { useAddEntityField } from "../model";
import { notAllowedDatatypes } from "../constants";

interface IAddEntityField {
    datatypes: TDatatype[];
    entityUuid?: string | undefined;
    entityId: number | undefined;
}
const AddEntityField = ({ entityUuid, entityId, datatypes }: IAddEntityField) => {
    const { addEntityField, selectRef } = useAddEntityField(entityUuid, entityId);
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Select
                showAction={["focus"]}
                ref={selectRef}
                style={{
                    width: "100%",
                }}
                value={null}
                options={datatypes
                    .filter(
                        (datatype) => !notAllowedDatatypes.includes(datatype.datatype)
                    )
                    .map((item) => {
                        return {
                            label: item.name,
                            value: item.datatype,
                            item: item,
                        };
                    })}
                placeholder="Добавить новое поле"
                onChange={addEntityField}
            />
        </div>
    );
};

export default AddEntityField;
