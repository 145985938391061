import { calculatedValueModel } from "@entities/Portal/CalculatedValues";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useCalculatedValueListItem = (initialCalculatedValue?: TCalculatedValue) => {
    const dispatch = useDispatch<AppDispatch>();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const onDelete = (calculatedValue: TCalculatedValue) => {
        setDeleteLoading(true);
        dispatch(
            calculatedValueModel.deleteCalculatedValueThunk(calculatedValue.id)
        ).finally(() => {
            setDeleteLoading(false);
        });
    };

    return {
        deleteLoading,
        onDelete,
    };
};
