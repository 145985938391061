import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { Button, Space, Table } from "antd";
import FormInputItem from "../../FormInputItem";
import "../EntityInput.scss";

interface IEntityTableView {
    dataSource: any[];
    onlyRead?: boolean;
    fields: (TBizProcTemplateActionField & TEntityFieldWithValue)[];
    onEdit: (item: any) => void;
    onDelete: (item: any) => void;
}

const EntityTableView = ({
    dataSource,
    onlyRead,
    fields,
    onEdit,
    onDelete,
}: IEntityTableView) => {
    const data = dataSource ?? [];
    if (data.length === 0)
        return (
            <FlexContainer>
                <div
                    style={{
                        fontSize: 15,
                    }}
                >
                    Нет добавленных записей
                </div>
            </FlexContainer>
        );

    return (
        <Table
            pagination={false}
            className="table"
            rowKey={(row) => row.id ?? row.tmp_id}
            size="small"
            style={{ overflow: "scroll" }}
            dataSource={data}
        >
            {fields
                .filter((field) => field.view)
                .map((field) => {
                    return (
                        <Table.Column
                            title={field.name}
                            width={100}
                            dataIndex={field.key}
                            key={field.key}
                            render={(value) => (
                                <div
                                    className="table_item"
                                    style={{ width: "100%", maxWidth: 300 }}
                                >
                                    <FormInputItem
                                        field={field}
                                        inputId={field.unique_id}
                                        additionalParameters={{
                                            ...(field.additional_parameters ?? {}),
                                            fileSmallFormat: true,
                                        }}
                                        datatype={field.datatype as unknown as string}
                                        value={value}
                                        onlyRead={true}
                                        onChange={() => {}}
                                    />
                                </div>
                            )}
                        />
                    );
                })}
            {!onlyRead && (
                <Table.Column
                    key="action"
                    render={(_: any, record: any) => (
                        <Space size="small">
                            <Button
                                type="link"
                                icon={<EditOutlined />}
                                onClick={() => onEdit(record)}
                            />
                            <Button
                                danger
                                type="link"
                                icon={<DeleteOutlined />}
                                onClick={() => onDelete(record)}
                            />
                        </Space>
                    )}
                />
            )}
        </Table>
    );
};

export default EntityTableView;
