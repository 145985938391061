import { Spin } from "antd";
import { CSSProperties } from "react";
import { useUserTelegramLink } from "../model";
import TelegramLinkButton from "./TelegramLinkButton";

interface IUserTelegramLinkButton {
    onlyReadTg?: boolean | undefined;
    style?: CSSProperties;
}

const UserTelegramLinkButton = ({ onlyReadTg, style }: IUserTelegramLinkButton) => {
    const { loading, telegramUsername, isTgLinkCreated, unlinkTg, linkTg } =
        useUserTelegramLink();

    if (onlyReadTg && !telegramUsername) return <></>;
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            {!onlyReadTg && (
                <>
                    {!loading && isTgLinkCreated && telegramUsername && (
                        <div>
                            <span>{telegramUsername}</span>{" "}
                            <TelegramLinkButton text={"Отвязать"} action={unlinkTg} />
                        </div>
                    )}
                    {loading && !(isTgLinkCreated && telegramUsername === null) && (
                        <Spin />
                    )}
                    {((loading && isTgLinkCreated && telegramUsername === null) ||
                        (!loading &&
                            (!isTgLinkCreated || telegramUsername === null))) && (
                        <TelegramLinkButton
                            text={"Привязать"}
                            action={linkTg}
                            icon={
                                loading &&
                                isTgLinkCreated &&
                                telegramUsername === null && (
                                    <Spin style={{ marginRight: 8 }} />
                                )
                            }
                        />
                    )}
                </>
            )}
            {onlyReadTg && telegramUsername && <span>{telegramUsername}</span>}
        </div>
    );
};

export default UserTelegramLinkButton;
