import { useEffect } from "react";
import { selectExecutableTmts, selectGetExecutableLoading } from "./TechMapSelectors";
import { useDispatch, useSelector } from "react-redux";
import { loadExecutableTmtsThunk } from "./TechMapApiThunk";

export const useLoadExecutableTmts = () => {
    const loading = useSelector(selectGetExecutableLoading);
    const executableTmts = useSelector(selectExecutableTmts);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(loadExecutableTmtsThunk({}));
    }, []);
    return {
        executableTmts,
        loading,
    };
};
