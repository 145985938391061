import { Radio } from "antd";
import { TFilterInputItem } from "../../FilterKitTypes";

const FilterRadioButtonItem = ({ field, onChange }: TFilterInputItem) => {
    const { label } = field;

    return <Radio>{label}</Radio>;
};

export default FilterRadioButtonItem;
