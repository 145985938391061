import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    applyEntitySumTemplate,
    applyEstimationByChoosenEntityFields,
    createEntitySumTemplate,
    deleteEntitySumTemplate,
    getEntitySumTemplates,
    updateEntitySumTemplate,
} from "../api";

export const loadEntitySumTemplatesThunk = createAsyncThunk(
    "estimationSlice/loadEntitySumTemplates",
    async (entityId: number, { rejectWithValue }) => {
        try {
            const response = await getEntitySumTemplates(entityId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateSumEstimationTemplateThunk = createAsyncThunk(
    "estimationSlice/updateEntitySumTemplates",
    async (estimationTemplate: TReqEstimationTemplate, { rejectWithValue }) => {
        try {
            const response = await updateEntitySumTemplate(estimationTemplate);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const createSumEstimationTemplateThunk = createAsyncThunk(
    "estimationSlice/createEntitySumTemplates",
    async (params: TReqEstimationTemplate, { rejectWithValue }) => {
        try {
            const response = await createEntitySumTemplate(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const applySumEstimationTemplateThunk = createAsyncThunk(
    "estimationSlice/applyEntitySumTemplates",
    async (
        { id, name, filters }: { id: number; name: string; filters?: TMultipleCondition },
        { rejectWithValue }
    ) => {
        try {
            const response = await applyEntitySumTemplate(id, filters);
            return { data: response.data, name, id };
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const applyAdditionalSumEstimationTemplateThunk = createAsyncThunk(
    "estimationSlice/applyAdditionalEntitySumTemplates",
    async (
        {
            entityFieldsKeys,
            selectedRowsIds,
            entityId,
        }: { entityFieldsKeys: string[]; selectedRowsIds: number[]; entityId: number },
        { rejectWithValue }
    ) => {
        try {
            const response = await applyEstimationByChoosenEntityFields(
                entityFieldsKeys,
                selectedRowsIds,
                entityId
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const deleteSumEstimationTemplateThunk = createAsyncThunk(
    "estimationSlice/deleteEntitySumTemplates",
    async (id: number, { rejectWithValue }) => {
        try {
            await deleteEntitySumTemplate(id);
            return id;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
