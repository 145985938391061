import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { Skeleton } from "antd";
import "./SkeletonFormBuilder.scss";

interface ISkeletonFormBuilder {
    labelSkeletonWidth: number[];
    valueSkeletonWidth: number[];
}

const fields = Array.from({ length: 6 });
const SkeletonFormBuilder = ({
    labelSkeletonWidth,
    valueSkeletonWidth,
}: ISkeletonFormBuilder) => {
    return (
        <div className="fill-action-form-builder-skeletons">
            <FlexContainer
                customStyle={{ flexDirection: "column", alignItems: "flex-start" }}
            >
                {fields.map((_, index) => {
                    return (
                        <div
                            key={index}
                            className="fill-action-form-builder-skeletons__item"
                        >
                            <div className="fill-action-form-builder-skeletons__label">
                                <Skeleton
                                    title={false}
                                    active={true}
                                    paragraph={{
                                        rows: 1,
                                        width: labelSkeletonWidth[
                                            labelSkeletonWidth.length % (index + 1)
                                        ],
                                    }}
                                />
                            </div>
                            <div className="fill-action-form-builder-skeletons__value">
                                <Skeleton
                                    title={false}
                                    active={true}
                                    paragraph={{
                                        rows: 1,
                                        width: valueSkeletonWidth[
                                            valueSkeletonWidth.length % (index + 1)
                                        ],
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </FlexContainer>
        </div>
    );
};

export default SkeletonFormBuilder;
