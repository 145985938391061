import FormListItem from "@entities/Portal/BizProc/ui/FormListItem";
import { EmptyPage } from "@shared/EmptyPage";
import { ListAnimation } from "@shared/animations";
import { UseBizProcFormWS } from "../BizProcWebsocket";
import "./BizProcsFormsList.scss";
import BizProcsFormsSkeletonList from "./BizProcsFormsSkeletonList";
import { useBiProcFormsList } from "./model";

interface BizProcsFormsListInterface {
    isMyForms?: boolean;
}

const BizProcsFormsList = ({ isMyForms }: BizProcsFormsListInterface) => {
    const { onClick, bizProcForms, isFormsLoading, isMounted } = useBiProcFormsList();
    UseBizProcFormWS();

    if (bizProcForms.length > 0) {
        return (
            <>
                <div>
                    {bizProcForms.map((bizProc) => {
                        return (
                            <div key={`formsList-${bizProc.id}`}>
                                <div className="form-header">{bizProc.name}</div>
                                <ListAnimation
                                    layoutId={`formsList-${bizProc.id}`}
                                    isMounted={isMounted}
                                    classNameList="forms-list"
                                    classNameItem="forms-list__item"
                                    items={bizProc.biz_proc_actions}
                                    itemRenderItem={(form: TBizProcAction) => (
                                        <FormListItem onClick={onClick} form={form} />
                                    )}
                                />
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }
    if (isFormsLoading) {
        return <BizProcsFormsSkeletonList />;
    }
    return <EmptyPage text="Формы отсутствуют" />;
};

export default BizProcsFormsList;
