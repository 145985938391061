import { apiUrl } from "@shared/api/api";
import { Image } from "antd";
import { portalSettingModel } from "@entities/Portal/PortalSetting";
import { useSelector } from "react-redux";
import "./SignInForm.scss";
interface ISignInFormLogo {}

const SignInFormLogo = ({}: ISignInFormLogo) => {
    const setting = useSelector(portalSettingModel.selectPortalSettingsPrepared);
    const logo = setting?.logoLogin;
    const name = setting?.name;

    return (
        <div className="signin-form__image-container">
            <div className="signin-form__image-frame">
                <Image preview={false} src={`${apiUrl}/${logo}`} alt="logo" />
            </div>
            <div className="signin-form__image-description">{name}</div>
        </div>
    );
};

export default SignInFormLogo;
