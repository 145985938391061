import { CardWithHead } from "@shared/ui/Cards/CardWithHead";
import EditTemplateBar from "../EditTemplateBar";

interface INotFoundAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}
const NotFoundAction = ({ onDeleteItem, templateItem }: INotFoundAction) => {
    return (
        <CardWithHead
            //backgroundColor="#"
            bordered
            title={
                <EditTemplateBar
                    onDeleteItem={onDeleteItem}
                    textColor="black"
                    title={"Блок не реализован"}
                />
            }
        />
    );
};

export default NotFoundAction;
