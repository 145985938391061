import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useCreateBizProcTemplateItem } from "../model";

interface ICreateBizProcTemplateItemButton {
    categoryUuid: string;
    entityUuid: string;
}

const CreateBizProcTemplateItemButton = ({
    entityUuid,
    categoryUuid,
}: ICreateBizProcTemplateItemButton) => {
    const { onAdd, loading } = useCreateBizProcTemplateItem(entityUuid, categoryUuid);

    return (
        <Button type="link" onClick={onAdd} loading={loading} icon={<PlusOutlined />}>
            Создать шаблон
        </Button>
    );
};

export default CreateBizProcTemplateItemButton;
