import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPortalSettings } from "../api";

export const getPortalSettingsThunk = createAsyncThunk(
    "PortalSettingSlice/getPortalSettings",
    async (_, { rejectWithValue }) => {
        try {
            const response = await getPortalSettings();
            return response.data.data;
        } catch (error: any) {
            console.log(error);
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
