import {
    getDateFormattedValue,
    getDateHumanFormat,
    getDatePickerFormat,
} from "@shared/lib/date";
import { FormReadValue } from "@shared/ui/Form";
import { DatePicker } from "antd";

interface IDateInput {
    value: any;
    onChangeValue: (value: any) => void;
    onlyRead?: boolean;
    additionalParameters: TAdditionalParameters;
    disabled?: boolean;
}

const DateInput = ({
    value,
    additionalParameters,
    onlyRead,
    onChangeValue,
    disabled,
}: IDateInput) => {
    const onChange = (value: any | null, dateString: string) => {
        onChangeValue(getDateFormattedValue(value));
    };
    const onChangeBetween = (values: any, formatString: [string, string]) => {
        onChangeValue([
            getDateFormattedValue(values?.[0]),
            getDateFormattedValue(values?.[1]),
        ]);
    };

    const isBetween = additionalParameters?.isBetween;
    if (onlyRead) {
        if (isBetween) {
            return (
                <div style={{ display: "flex" }}>
                    <FormReadValue value={value ? getDateHumanFormat(value?.[0]) : "-"} />
                    {`->`}
                    <FormReadValue value={value ? getDateHumanFormat(value?.[1]) : "-"} />
                </div>
            );
        } else {
            return <FormReadValue value={value ? getDateHumanFormat(value) : "-"} />;
        }
    }
    if (isBetween) {
        return (
            <DatePicker.RangePicker
                disabled={disabled}
                style={{ width: "100%" }}
                format={"DD.MM.YYYY"}
                value={[getDatePickerFormat(value?.[0]), getDatePickerFormat(value?.[1])]}
                picker="date"
                changeOnBlur
                // placeholder={field.name}
                onChange={onChangeBetween}
            />
        );
    }

    return (
        <DatePicker
            style={{ width: 180 }}
            format={"DD.MM.YYYY"}
            value={getDatePickerFormat(value)}
            picker="date"
            // placeholder={field.name}
            onChange={onChange}
        />
    );
};

export default DateInput;
