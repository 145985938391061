import { CreateBizProcTemplateItemButton } from "@features/Portal/BizProcTemplate/CreateBizProcTemplateItem";
import { CategoriesList } from "@features/Portal/CategoryFeatures/CategoriesList";
import BizProcTemplatesByCategory from "./BizProcTemplatesByCategory";

interface ICategoriesListWithBizProcTemplates {
    entityId: number;
}

const CategoriesListWithBizProcTemplates = ({
    entityId,
}: ICategoriesListWithBizProcTemplates) => {
    return (
        <CategoriesList
            entityId={entityId}
            panelChildren={(category) => (
                <>
                    <BizProcTemplatesByCategory
                        entityUuid={category.entity_uuid ?? ""}
                        categoryUuid={category.uuid}
                    />
                    <CreateBizProcTemplateItemButton
                        entityUuid={category.entity_uuid ?? ""}
                        categoryUuid={category.uuid}
                    />
                </>
                // <BizProcTemplateListItem

                // />
            )}
        />
    );
};

export default CategoriesListWithBizProcTemplates;
