import { TMNecessaryItems } from "@features/Production/TechMapFeatures/TMNecessaryItems";
import "./TMOperationProccess.scss";
import { useTMOperationProccess } from "../model";
import { Empty, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { TMActions } from "@features/Production/TechMapFeatures/TMActions";
interface ITMOperationProccess {}

const TMOperationProccess = ({}: ITMOperationProccess) => {
    const { loading, operation } = useTMOperationProccess();
    return (
        <div className="tmo-process">
            {loading || !operation ? (
                <div
                    style={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {loading ? (
                        <Spin size="large" indicator={<LoadingOutlined />} />
                    ) : (
                        <Empty description={"Нет запущенный операций"} />
                    )}
                </div>
            ) : (
                <>
                    <div className="tmo-process__items">
                        <TMNecessaryItems />
                    </div>
                    <div className="tmo-process__actions">
                        <TMActions />
                    </div>
                </>
            )}
        </div>
    );
};

export default TMOperationProccess;
