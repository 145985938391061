import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectFieldOptions } from "./FieldOptionSelectors";
import { useDispatch } from "react-redux";
import { setFieldOptions } from "./FieldOptionSlice";
import { loadFieldOptionsThunk } from "./FieldOptionThunk";

export const useLoadFieldOptions = (
    fieldId: number | undefined,
    type: TFieldOptionType
) => {
    const [loading, setLoading] = useState(false);
    const options = useSelector(selectFieldOptions);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        if (fieldId) {
            setLoading(true);
            dispatch(loadFieldOptionsThunk({ fieldId, type })).finally(() => {
                setLoading(false);
            });
        }

        return () => {
            dispatch(setFieldOptions([]));
        };
    }, [fieldId]);

    return {
        loading,
        options,
    };
};
