import { EditableCard } from "@shared/ui/Cards/EditableCard";
import { TextWithLabel } from "@shared/ui/Text";
import React, { ReactNode } from "react";
import { UseActionItem } from "../model/UseActionItem";
import { Divider } from "antd";

interface IActionListItem {
    action: TAction;
    error: TActionError;
    isEditing: boolean;
    exampleRender?: ReactNode;
    deleteLoading: boolean;
    setIsEditing?: (state: boolean) => void;
    onDelete: (action: TAction) => void;
    onCloseCustom?: (action: TAction) => void;
    onSave: (action: TAction) => void;
}

const ActionListItem = (props: IActionListItem) => {
    const { isEditing, error, exampleRender, deleteLoading } = props;

    const {
        name,
        parameters,
        onChangeParameters,
        type,
        onChangeName,
        onChangeType,
        onSubmit,
        onEdit,
        onClose,
        onSubmitDelete,
    } = UseActionItem(props);

    return (
        <EditableCard
            onSubmitDelete={onSubmitDelete}
            deleteLoading={deleteLoading}
            onEdit={onEdit}
            onSubmit={onSubmit}
            onClose={onClose}
            isEditing={isEditing}
        >
            <TextWithLabel
                error={error?.data?.["name"]}
                placeholder={"Имя блока"}
                onChange={onChangeName}
                allowEdit
                editing={isEditing}
                label={"Наименование"}
                text={name}
            />
            <TextWithLabel
                error={error?.data?.["type"]}
                placeholder={"Тип блока (должен быть уникальным)"}
                onChange={onChangeType}
                allowEdit
                editing={isEditing}
                label={"Тип"}
                text={type}
            />
            <TextWithLabel
                area
                type="json"
                error={error?.data?.["parameters"]}
                onChange={onChangeParameters}
                allowEdit
                editing={isEditing}
                label={"Параметры"}
                text={parameters}
            />
            {!isEditing && (
                <div>
                    <Divider />
                    {exampleRender}
                </div>
            )}
        </EditableCard>
    );
};

export default ActionListItem;
