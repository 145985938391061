import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addUserRole, updateUserRoles } from "./CapabilitiesRolesSlice";

export const useUserRolesWS = (user: TUser) => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.Echo.private(`User.${user.id}.EntityRow.1`)
            .listen(".entityRow.create", ({ entityRow }: { entityRow: any }) => {
                dispatch(addUserRole(entityRow));
            })
            .listen(".entityRow.update", ({ entityRow }: { entityRow: any }) => {
                dispatch(updateUserRoles(entityRow));
            });

        return () => {
            window.Echo.leave(`User.${user.id}.EntityRow.1`);
        };
    }, []);
};
