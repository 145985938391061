import { isArray } from "lodash";
import { useRef, useState } from "react";

export const useFieldSelect = (
    fields: TBizProcTemplateField[],
    onChangeFields: (fields: TBizProcTemplateField[]) => void
) => {
    const selectRef = useRef<any>();
    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean | undefined>(undefined);

    const onChange = (
        _: string[],
        options: { field: TBizProcTemplateField } | { field: TBizProcTemplateField }[]
    ) => {
        if (isArray(options)) onChangeFields(options.map((option) => option.field));
    };
    const onBlur = () => {
        setDropdownIsOpen(false);
    };
    const onFocus = () => {
        setDropdownIsOpen(true);
    };
    const onButtonClick = () => {
        selectRef.current?.focus();
    };
    const fieldOptions = fields.map((field) => ({
        value: `${field.variable_type}-${field.key}`,
        label: field.name,
        field,
    }));
    return {
        fieldOptions,
        selectRef,
        dropdownIsOpen,
        onChange,
        onBlur,
        onFocus,
        onButtonClick,
    };
};
