import { FormItem } from "@shared/ui/Form";
import { TimeInput } from "@shared/ui/TimeInput";

interface IEditDelayAction {
    templateItem: TBizProcTemplateAction;
    disabled?: boolean;
    bizProcTemplate: TBizProcTemplateItem;
    setItem: (templateItem: TBizProcTemplateAction) => void;
}

const EditDelayAction = ({
    templateItem,
    bizProcTemplate,
    disabled,
    setItem,
}: IEditDelayAction) => {
    const onChangeTime = (time: number) => {
        setItem({
            ...templateItem,
            parameters: {
                ...templateItem.parameters,
                delay: {
                    ...templateItem.parameters.delay,
                    value: time,
                    type: "timedelta",
                },
            },
        });
    };
    return (
        <FormItem label="Время задержки">
            <>
                <TimeInput
                    disabled={disabled ?? false}
                    onChangeValue={onChangeTime}
                    value={templateItem.parameters.delay.value}
                />
            </>
        </FormItem>
    );
};

export default EditDelayAction;
