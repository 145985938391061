import { entityFieldApi, entityFieldModel } from "@entities/Portal/EntityField";
import { useDefferedCallback } from "@shared/hooks/UseDefferedCallback";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useEntityFieldItemList = (
    entityId: number | undefined,
    entityUuid: string | undefined
) => {
    const { fields, loading } = entityFieldModel.useLoadEntityFields(
        entityId,
        entityUuid
    );
    const { defferedCallback } = useDefferedCallback(1000);

    const [deleteLoading, setDeleteLoading] = useState<number[]>([]);
    const dispatch = useDispatch<AppDispatch>();
    const onEdit = (field: TEntityField) => {
        dispatch(entityFieldModel.setEditingEntityField(field));
    };

    const onDelete = (field: TEntityField) => {
        if (!entityId || !field.id) {
            dispatch(entityFieldModel.deleteEntityField(field));
        } else {
            setDeleteLoading([...deleteLoading, field.id]);
            dispatch(entityFieldModel.deleteEntityFieldThunk(field.id))
                .catch(() => {})
                .finally(() => {
                    setDeleteLoading(deleteLoading.filter((id) => id !== field.id));
                });
        }
    };
    const reorderFieldsReq = (items: TEntityField[]) => {
        const newOrder = items.map((item, index) => ({
            id: item.id as number,
            order: index,
        }));
        return entityFieldApi
            .reorderEntityFields(newOrder)
            .then((response) => {})
            .catch(() => {});
    };
    const reorderFields = (items: TEntityField[]) => {
        dispatch(
            entityFieldModel.setEntityFields(
                items.map((item, index) => ({
                    ...item,
                    order: index,
                }))
            )
        );
        defferedCallback(() => reorderFieldsReq(items));
    };
    return {
        fields,
        loading,
        deleteLoading,
        onEdit,
        onDelete,
        reorderFields,
    };
};
