import dayjs from "dayjs";
import { View } from "react-big-calendar";
import CustomEventWrapper from "./CustomEventWrapper";
import { groupColors } from "./CustomYearView";
import { getColor } from "@shared/lib/colorizing";

interface ICustomPopup {
    events: EventItem[];
    date: Date;
    onView: ((view: View) => void) | undefined;
    onNavigate: any;
}

const CustomPopup = ({ events, date, onView, onNavigate }: ICustomPopup) => {
    const findedEvents = events
        .filter(
            (event) =>
                dayjs(event.start).isSame(dayjs(date), "day") ||
                dayjs(event.end).isSame(dayjs(date), "day") ||
                (dayjs(event.start).valueOf() < dayjs(date).valueOf() &&
                    dayjs(event.end).valueOf() > dayjs(date).valueOf())
        )
        .sort((a, b) => dayjs(a.start).valueOf() - dayjs(b.start).valueOf());
    const onClickDay = () => {
        onView && onView("day");
        onNavigate(date);
    };

    return (
        <div
            className="rbc-overlay"
            style={{
                position: "relative",
            }}
        >
            <div className="rbc-overlay-header">
                {dayjs(date).format("YYYY MMMM D dd")}
            </div>
            <div className="rbc-content">
                {findedEvents.length > 0
                    ? findedEvents.map((event, key) => (
                          <div
                              key={key}
                              style={{
                                  height: "fit-content",
                                  marginBottom: 2,
                                  background: getColor(
                                      event.data.resource[event.data.eventLabelKey],
                                      groupColors
                                  ),
                              }}
                          >
                              <div className="rbc-event rbc-event-allday rbc-event-continues-after">
                                  <div className="rbc-event-content" onClick={onClickDay}>
                                      <CustomEventWrapper
                                          backgroundColor={getColor(
                                              event.data.resource[
                                                  event.data.eventLabelKey
                                              ],
                                              groupColors
                                          )}
                                          data={event.data}
                                          allDay={event.allDay}
                                          openCloseDrawerWithNavigate={
                                              event.openCloseDrawerWithNavigate
                                                  ? event.openCloseDrawerWithNavigate
                                                  : () => {}
                                          }
                                      />
                                  </div>
                              </div>
                          </div>
                      ))
                    : "Пусто"}
            </div>
        </div>
    );
};

export default CustomPopup;
