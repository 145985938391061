import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { Button, Result, Select } from "antd";
import "./ScannerQRCode.scss";
import { useScannerQRCode } from "./model";

const ScannerQRCode = () => {
    const {
        selectedDeviceId,
        isAllowed,
        isCollapsed,
        devices,
        qrDecodeResult,
        isCanReadQr,
        statusScanner,
        selectDevice,
        collapseWindow,
    } = useScannerQRCode();
    const isMobileView = window.innerWidth < 500;
    return (
        <>
            <section className="qr-scanner">
                {isCanReadQr ? (
                    <>
                        <div className={`qr-scanner__video-container`}>
                            {isAllowed && devices?.length > 0 ? (
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        alignItems: "center",
                                        width: isMobileView ? "100%" : undefined,
                                        marginBottom: 16,
                                    }}
                                >
                                    <span>Выберите видеоустройство: </span>
                                    <Select
                                        onSelect={selectDevice}
                                        value={selectedDeviceId ?? undefined}
                                        options={devices}
                                        style={{
                                            marginLeft: isMobileView ? 0 : 8,
                                            width: isMobileView ? "100%" : 200,
                                        }}
                                    />
                                    {!isMobileView && (
                                        <Button
                                            type="text"
                                            icon={
                                                isCollapsed ? (
                                                    <FullscreenExitOutlined />
                                                ) : (
                                                    <FullscreenOutlined />
                                                )
                                            }
                                            onClick={collapseWindow}
                                            style={{
                                                marginLeft: 10,
                                                fontSize: "16px",
                                                minWidth: 64,
                                                height: 64,
                                            }}
                                        />
                                    )}
                                </div>
                            ) : (
                                <Result
                                    status="warning"
                                    title="Отсутствуют подключенные видеоустройства"
                                />
                            )}

                            <div
                                className={`${isCollapsed ? "video-window__full" : "video-window"}`}
                            >
                                <video
                                    className={`${isCollapsed ? "video-window__full" : "video-window"}`}
                                    preload="true"
                                    poster="Поднести QR код к камере"
                                    id="video"
                                    width="100%"
                                    style={{ borderRadius: 16 }}
                                />
                            </div>
                            <div
                                className={`qr-scanner-info-block ${isCollapsed ? "qr-scanner-info-block__fixed" : "qr-scanner-info-block__absolute"} ${statusScanner === "success" ? "success-block-show" : "success-block-hide"}`}
                            >
                                <Result
                                    status="success"
                                    title="QR код отсканирован!"
                                    subTitle={qrDecodeResult}
                                />
                            </div>
                            <div
                                className={`qr-scanner-info-block ${isCollapsed ? "qr-scanner-info-block__fixed" : "qr-scanner-info-block__absolute"} ${statusScanner === "failed" ? "failed-block-show" : "failed-block-hide"}`}
                            >
                                <Result
                                    status="error"
                                    title="Ошибка сканирования QR кода!"
                                />
                            </div>
                            {isCollapsed && (
                                <Button
                                    className="video-window__full-button"
                                    type="text"
                                    size="large"
                                    icon={
                                        isCollapsed ? (
                                            <FullscreenExitOutlined />
                                        ) : (
                                            <FullscreenOutlined />
                                        )
                                    }
                                    onClick={collapseWindow}
                                />
                            )}
                        </div>
                    </>
                ) : (
                    <div className={`failed-block failed-block-show`}>
                        <Result
                            status="error"
                            title="У Вас нет доступа к сканированию!"
                        />
                    </div>
                )}
            </section>
        </>
    );
};

export default ScannerQRCode;
