import { Empty, Skeleton } from "antd";
import { useTMList } from "../model";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import TMItem from "./TMItem";

interface ITMList {}
const tm = [1, 2, 3, 4];
const TMList = ({}: ITMList) => {
    const { loading, techMaps, techMapsForReview } = useTMList();
    return (
        <div>
            {loading ? (
                tm.map((item) => {
                    return (
                        <IndentContainer key={item}>
                            <div className="item-card" style={{ maxWidth: 700 }}>
                                <Skeleton active paragraph={{ rows: 1, width: [200] }} />
                            </div>
                        </IndentContainer>
                    );
                })
            ) : (
                <>
                    {techMapsForReview.length > 0 && (
                        <div
                            style={{
                                display: "flex",
                                maxWidth: 700,
                                width: "100%",
                                flexDirection: "column",
                            }}
                        >
                            <IndentContainer>
                                <span style={{ fontWeight: 500, fontSize: 15 }}>
                                    Процессы для согласования
                                </span>
                            </IndentContainer>
                            <div>
                                {techMapsForReview.map((tm) => {
                                    return (
                                        <IndentContainer key={`r-${tm.id}`}>
                                            <TMItem item={tm} review={true} />
                                        </IndentContainer>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            maxWidth: 700,
                            flexDirection: "column",
                        }}
                    >
                        <IndentContainer>
                            <span style={{ fontWeight: 500, fontSize: 15 }}>
                                Список процессов
                            </span>
                        </IndentContainer>
                        <div>
                            {techMaps.length === 0 ? (
                                <div>
                                    <Empty />
                                </div>
                            ) : (
                                techMaps.map((tm) => {
                                    return (
                                        <IndentContainer key={`o-${tm.id}`}>
                                            <TMItem item={tm} review={false} />
                                        </IndentContainer>
                                    );
                                })
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default TMList;
