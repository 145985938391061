import {
    navigationRouteApi,
    navigationRouteModel,
} from "@entities/Portal/NavigationRoute";
import { getReorderedItems } from "../lib";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useDefferedCallback } from "@shared/hooks/UseDefferedCallback";
import { useDispatch } from "react-redux";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";

export const useSidebarReorder = () => {
    const { defferedCallback } = useDefferedCallback();
    const dispatch = useDispatch();
    const navigationRoutes = useSelector(navigationRouteModel.selectNavigationRoutes);
    const initialRoutes = useRef(navigationRoutes);

    const updateNaviationRoutes = (
        navigationRoutes: TNavigationRoute[],
        newNavigationRoutes: TNavigationRoute[]
    ) => {
        return navigationRouteApi
            .massUpdateNavigationRoute(navigationRoutes)
            .then((response) => {
                initialRoutes.current = newNavigationRoutes;
            })
            .catch((error) => {
                notificationEmit({
                    error: error,
                    title: "Не удалось переместить элемент",
                    type: "error",
                });
                dispatch(navigationRouteModel.setRoutes(initialRoutes.current));
            })
            .finally(() => {});
    };

    const onReorder = (items: any, moveIndex: number, targetIndex: number) => {
        const newNavigationRoutes = items.map((item: any, index: number) => {
            return {
                ...item.navigationRoute,
                order: index,
            };
        }) as TNavigationRoute[];
        const reorderedItems = getReorderedItems(
            initialRoutes.current,
            newNavigationRoutes
        );
        if (reorderedItems.length > 0) {
            dispatch(navigationRouteModel.setRoutes(newNavigationRoutes));
            defferedCallback(() =>
                updateNaviationRoutes(reorderedItems, newNavigationRoutes)
            );
        }
    };
    return {
        onReorder,
    };
};
