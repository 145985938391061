import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { ITechMapTemplate } from "../TechMapTemplateSlice";
import {
    createTechMapTemplateOperationThunk,
    loadTechMapTemplateOperationsThunk,
} from "./TechMapTemplateOperationThunk";

const techMapTemplateOperationsLoadBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    builder.addCase(loadTechMapTemplateOperationsThunk.fulfilled, (state, action) => {
        state.techMapTemplateOperations = action.payload.data;
        state.getOperationLoading = false;
    });
    builder.addCase(loadTechMapTemplateOperationsThunk.pending, (state, action) => {
        state.getOperationLoading = true;
    });
    builder.addCase(
        loadTechMapTemplateOperationsThunk.rejected,
        (state, rejectedValue) => {
            state.getOperationLoading = false;
            notificationEmit({
                title: "Не удалось загрузить элемент",
                type: "error",
            });
        }
    );
};

const techMapTemplateOperationCreateBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    builder.addCase(createTechMapTemplateOperationThunk.fulfilled, (state, action) => {
        state.techMapTemplateOperations = [
            action.payload.data,
            ...state.techMapTemplateOperations,
        ];
        state.createOperationLoading = false;
    });
    builder.addCase(createTechMapTemplateOperationThunk.pending, (state, action) => {
        state.createOperationLoading = true;
    });
    builder.addCase(
        createTechMapTemplateOperationThunk.rejected,
        (state, rejectedValue) => {
            state.createOperationLoading = false;
            notificationEmit({
                title: "Не удалось создать элемент",
                type: "error",
            });
        }
    );
};

const techMapTemplateOperationUpdateBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    // builder.addCase(updateTechMapTemplateOperationThunk.fulfilled, (state, action) => {
    //     const id = action.meta.arg.techMapTemplateOperation.id;
    //     state.techMapTemplateOperations = updateArrByItemId(
    //         state.techMapTemplateOperations,
    //         action.payload.data
    //     );
    //     state.updateOperationLoading = state.updateOperationLoading.filter(
    //         (item) => item !== id
    //     );
    // });
    // builder.addCase(updateTechMapTemplateOperationThunk.pending, (state, action) => {
    //     state.updateOperationLoading.push(action.meta.arg.techMapTemplateOperation.id);
    // });
    // builder.addCase(
    //     updateTechMapTemplateOperationThunk.rejected,
    //     (state, rejectedValue) => {
    //         state.updateOperationLoading = state.updateOperationLoading.filter(
    //             (item) => item !== rejectedValue.meta.arg.techMapTemplateOperation.id
    //         );
    //         notificationEmit({
    //             title: "Не удалось обновить элемент",
    //             type: "error",
    //         });
    //     }
    // );
};

const techMapTemplateOperationDeleteBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    // builder.addCase(deleteTechMapTemplateOperationThunk.fulfilled, (state, action) => {
    //     const id = action.meta.arg.id;
    //     state.techMapTemplateOperations = state.techMapTemplateOperations.filter(
    //         (item) => item.id !== id
    //     );
    //     state.deleteOperationLoading = state.deleteOperationLoading.filter(
    //         (item) => item !== id
    //     );
    // });
    // builder.addCase(deleteTechMapTemplateOperationThunk.pending, (state, action) => {
    //     state.deleteOperationLoading.push(action.meta.arg.id);
    // });
    // builder.addCase(
    //     deleteTechMapTemplateOperationThunk.rejected,
    //     (state, rejectedValue) => {
    //         state.deleteOperationLoading = state.deleteOperationLoading.filter(
    //             (item) => item !== rejectedValue.meta.arg.id
    //         );
    //         notificationEmit({
    //             title: "Не удалось удалить элемент",
    //             type: "error",
    //         });
    //     }
    // );
};

export const techMapTemplateOperationBuilder = (
    builder: ActionReducerMapBuilder<ITechMapTemplate>
) => {
    techMapTemplateOperationsLoadBuilder(builder);
    techMapTemplateOperationCreateBuilder(builder);
    techMapTemplateOperationUpdateBuilder(builder);
    techMapTemplateOperationDeleteBuilder(builder);
};
