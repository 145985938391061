import { useState } from "react";
import { prepareItemChildren } from "../lib";

export const useBizProcUpdateAction = (
    isAllowSelfChild: boolean,
    templateItem: TBizProcTemplateAction,
    onChangeTemplateItem: (templateItem: TBizProcTemplateAction) => void,
    onAddTemplateItem: (
        templateItem: TBizProcTemplateAction,
        newTemplateItem: TBizProcTemplateAction
    ) => void,
    onDeleteTemplateItem: (templateItem: TBizProcTemplateAction) => void,
    parentItem?: TBizProcTemplateAction
) => {
    const [isEditing, setIsEditing] = useState(false);

    const onChangeTemplateItem_ = (updatedTemplateItem: TBizProcTemplateAction) => {
        onChangeTemplateItem({
            ...templateItem,
            children: prepareItemChildren(
                templateItem.children.map((item) =>
                    item.key === updatedTemplateItem.key ? updatedTemplateItem : item
                )
            ),
        });
    };
    const onUpdateTemplateItem = (updatedTemplateItem: TBizProcTemplateAction) => {
        onChangeTemplateItem({
            ...updatedTemplateItem,
            children: prepareItemChildren(templateItem.children),
        });
        setIsEditing(false);
    };
    const onAddTemplateItem_ = (
        templateItem: TBizProcTemplateAction,
        newTemplateItem: TBizProcTemplateAction
    ) => {
        onAddTemplateItem(templateItem, { ...newTemplateItem });
    };
    const onAddTemplateItemParent = (
        prevTemplateItem: TBizProcTemplateAction,
        newTemplateItem: TBizProcTemplateAction
    ) => {
        const index = templateItem.children.findIndex(
            (item) => item.key === prevTemplateItem.key
        );

        templateItem.children.splice(index + 1, 0, { ...newTemplateItem });
        onChangeTemplateItem_({
            ...templateItem,
            children: prepareItemChildren(templateItem.children),
        });
    };
    const onDeleteTemplateItem_ = (deletedTemplateItem: TBizProcTemplateAction) => {
        onDeleteTemplateItem(deletedTemplateItem);
    };
    const onDeleteTemplateItemParent = (deletedTemplateItem: TBizProcTemplateAction) => {
        onChangeTemplateItem({
            ...templateItem,
            children: prepareItemChildren(
                templateItem.children.filter(
                    (item) => item.key !== deletedTemplateItem.key
                )
            ),
        });
    };

    const addTemplateItemAfter = (newTemplateItem: TBizProcTemplateAction) => {
        isAllowSelfChild
            ? onAddTemplateItemParent(templateItem, newTemplateItem)
            : onAddTemplateItem_(templateItem, newTemplateItem);
    };

    const editAction = () => {
        setIsEditing(true);
    };

    return {
        onDeleteTemplateItem_,
        editAction,
        onDeleteTemplateItemParent,
        addTemplateItemAfter,
        onAddTemplateItemParent,
        setIsEditing,
        onUpdateTemplateItem,
        isEditing,
        onChangeTemplateItem_,
    };
};
