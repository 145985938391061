import { useDispatch } from "react-redux";
import { getValidParams } from "../lib/getValidParams";
import { bizProcModel } from "@entities/Portal/BizProc";
import { useSelector } from "react-redux";
import { userModel } from "@entities/Portal/User";

export const useBizProcFilters = () => {
    const dispatch = useDispatch<AppDispatch>();
    const isAdmin = useSelector(userModel.selectUserIsAdmin);
    const request = (values: any, reset: boolean) => {
        const conditions: any[] = getValidParams(values);

        const parameters: TMultipleCondition = {
            condition_type: "AND",
            conditions: conditions.length > 0 ? conditions : [],
        };

        if (!reset && parameters) {
            dispatch(
                bizProcModel.loadBizProcsThunk(
                    parameters.conditions.length > 0 ? { filter: parameters } : {}
                )
            );
        } else {
            dispatch(bizProcModel.loadBizProcsThunk({})).then();
        }
    };

    const initialState = {
        name: null,
        status: "executing",
        assigned_id: null,
    };

    return {
        initialState,
        isAdmin,
        request,
    };
};
