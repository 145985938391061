import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { getStartedTms, getTm } from "../../api";
import { setGetStartedTmsLoading, setStartedTm } from "../TechMapSlice";

const loadStartedTms = async (userId: number) => {
    try {
        const response = await getStartedTms(userId);
        return response.data.data;
    } catch (err) {
        throw err;
    }
};

const loadTm = async (tmId: number) => {
    try {
        const response = await getTm(tmId);
        return response.data.data;
    } catch (err) {
        throw err;
    }
};

export const loadStartedTm =
    (userId: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(setGetStartedTmsLoading(true));
        try {
            const tms = await loadStartedTms(userId);
            if (tms.length > 0) {
                const tm = await loadTm(tms[0].id);

                dispatch(setStartedTm(tm));
            }
        } catch (err) {
            notificationEmit({
                type: "error",
                title: "Не удалось загрузить информацию о запущенных процессах",
            });
        }
        dispatch(setGetStartedTmsLoading(false));
    };
