import { ViewPatternItem } from "@features/Portal/ViewPatternItem/ViewPatternItem";
import {
    ViewPatternCreatingSelectsGroup,
    ViewPatternItemCreateBizProcButton,
    ViewPatternItemCreateChildBizProcButton,
    ViewPatternItemCreateSelect,
} from "@features/Portal/ViewPatternItem/ViewPatternItemCreateSelect";
import { DragAndDropList } from "@shared/ui/DragAndDropList";
import { FormItem } from "@shared/ui/Form";
import { Checkbox, Flex, Input, Select, Typography } from "antd";
import { viewTypeOptions } from "../constants";
import { useViewPatternEdit } from "../model";
import "./ViewPatternEditForm.scss";
import { SelectEntityRow } from "@entities/Portal/EntityRow";
import { UserAccess } from "@entities/Portal/User";

interface IViewPatternEditForm {
    entityId: number;
    initialViewPattern: TViewPattern;
}

const ViewPatternEditForm = ({ entityId, initialViewPattern }: IViewPatternEditForm) => {
    const {
        viewPatternName,
        viewPatternViewTypes,
        viewPatternIsDefault,
        viewPatternIsPublic,
        viewPatternRolesIds,
        viewPatternDepartmentsIds,
        viewPatternUsersIds,
        viewPattern,
        entityFields,
        viewPatternItems,
        allowEdit,
        createLoading,
        setCreateLoading,
        onReorderItems,
        onChangeViewTypes,
        onChangeName,
        onChangeIsDefault,
        onChangeIsPublic,
        onChangeRolesIds,
        onChangeDepartmentsIds,
        onChangeUsersIds,
    } = useViewPatternEdit(entityId, initialViewPattern);
    return (
        <div className="view-pattern-edit-form">
            {viewPattern && (
                <Flex wrap="wrap" gap={8} style={{ marginBottom: 16 }}>
                    <Flex
                        align="top"
                        wrap="wrap"
                        gap="0 8px"
                        style={{
                            height: "fit-content",
                            flex: "1 1 100%",
                            borderRadius: 8,
                            padding: 10,
                            backgroundColor: "rgba(0, 0, 0, 0.05)",
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 2px",
                        }}
                    >
                        <FormItem
                            label="Имя шаблона"
                            style={{ flex: "1 0 45%", marginBottom: 8 }}
                        >
                            <Input
                                disabled={!allowEdit}
                                value={viewPatternName}
                                onChange={onChangeName}
                                style={{ width: "100%" }}
                            />
                        </FormItem>
                        <FormItem
                            label="Используется для"
                            style={{ flex: "1 0 150px", marginBottom: 8 }}
                        >
                            <Select
                                disabled={!allowEdit}
                                onChange={onChangeViewTypes}
                                value={viewPatternViewTypes}
                                options={viewTypeOptions}
                                style={{ width: "100%" }}
                            />
                        </FormItem>
                        <Flex align="center" style={{ flex: "1 0 100%", zIndex: 1350 }}>
                            <FormItem
                                label="Доступен для департаментов"
                                style={{ marginRight: 10, zIndex: 1350 }}
                            >
                                <SelectEntityRow
                                    entityInfo="departments"
                                    multiple
                                    value={viewPatternDepartmentsIds}
                                    onChange={onChangeDepartmentsIds}
                                />
                            </FormItem>
                            <FormItem
                                label="Доступен для ролей"
                                style={{ marginRight: 16 }}
                            >
                                <SelectEntityRow
                                    entityInfo="roles"
                                    multiple
                                    value={viewPatternRolesIds}
                                    onChange={onChangeRolesIds}
                                />
                            </FormItem>
                            <FormItem
                                label="Доступен для пользователей"
                                style={{ marginRight: 16 }}
                            >
                                <SelectEntityRow
                                    entityInfo="users"
                                    multiple
                                    value={viewPatternUsersIds}
                                    onChange={onChangeUsersIds}
                                />
                            </FormItem>
                        </Flex>
                        <Flex
                            align="start"
                            style={{ flex: "1 0 300px", marginBottom: 0 }}
                            justify="center"
                            vertical
                        >
                            <UserAccess.AdminAccess>
                                <Checkbox
                                    disabled={!allowEdit}
                                    checked={viewPatternIsDefault}
                                    onChange={onChangeIsDefault}
                                >
                                    Используется по умолчанию
                                </Checkbox>
                            </UserAccess.AdminAccess>
                            <Checkbox
                                disabled={!allowEdit}
                                checked={viewPatternIsPublic}
                                onChange={onChangeIsPublic}
                            >
                                Публичный шаблон
                            </Checkbox>
                        </Flex>
                    </Flex>

                    <ViewPatternCreatingSelectsGroup
                        setCreateLoading={setCreateLoading}
                        viewPattern={viewPattern}
                        viewPatternItems={viewPatternItems}
                        entityFields={entityFields}
                    />
                </Flex>
            )}
            {viewPattern && "id" in viewPattern && (
                <ViewPatternItemCreateSelect
                    createLoading={createLoading}
                    viewPattern={viewPattern}
                    entityFields={entityFields}
                />
            )}
            <Typography.Text style={{ marginTop: 16, marginBottom: 0, color: "gray" }}>
                Выбор порядка колонок
            </Typography.Text>
            <DragAndDropList
                disabled={!allowEdit}
                direction="horizontal"
                keyName="id"
                className="view-pattern-edit-form__items"
                items={viewPatternItems}
                onChangeItems={onReorderItems}
                draggableItem={(dragHandleProps, viewPatternItem, index) => (
                    <div
                        key={viewPatternItem.id}
                        style={{ width: 250, flexGrow: 1, marginRight: 8 }}
                        {...dragHandleProps}
                    >
                        <ViewPatternItem
                            additionalItem={(viewPattern, onCreate) => {
                                return (
                                    <UserAccess.AdminAccess>
                                        <div onClick={(e) => e.stopPropagation()}>
                                            <div style={{ marginBottom: 10 }}>
                                                <label>Запуск бизнес-процесса</label>
                                                <ViewPatternItemCreateBizProcButton
                                                    onCreate={onCreate}
                                                    entityId={viewPattern.entity_id}
                                                />
                                            </div>

                                            <label>
                                                Запуск бизнес-процесса для связанного поля
                                            </label>
                                            <ViewPatternItemCreateChildBizProcButton
                                                onCreate={onCreate}
                                                entityFields={(entityFields ?? []).filter(
                                                    (field) =>
                                                        field.datatype === "childEntity"
                                                )}
                                            />
                                        </div>
                                    </UserAccess.AdminAccess>
                                );
                            }}
                            onReorderItems={onReorderItems}
                            viewPattern={viewPattern as TViewPattern}
                            viewPatternItem={viewPatternItem}
                            entityFields={entityFields}
                        />
                    </div>
                )}
            />
        </div>
    );
};

export default ViewPatternEditForm;
