import { Button, Drawer, Tooltip } from "antd";
import { useEstimationTemplatesWithEdit } from "../model";
import { BarChartOutlined } from "@ant-design/icons";
import EstimationTemplatesWithEdit from "./EstimationTemplatesWithEdit";

interface IEstimationTemplatesWithEditDrawer {
    entityId: number;
    entityUuid: string;
}

const EstimationTemplatesWithEditDrawer = ({
    entityId,
    entityUuid,
}: IEstimationTemplatesWithEditDrawer) => {
    const { isOpenDrawer, onCloseDrawer, onOpenDrawer } =
        useEstimationTemplatesWithEdit();

    return (
        <>
            <Drawer
                destroyOnClose
                title="Список шаблонов"
                size="large"
                open={isOpenDrawer}
                onClose={onCloseDrawer}
            >
                <EstimationTemplatesWithEdit
                    onClickItem={onCloseDrawer}
                    entityId={entityId}
                    entityUuid={entityUuid}
                />
            </Drawer>
            <Tooltip title={"Шаблоны суммирования строк"} placement="topLeft">
                <Button onClick={onOpenDrawer} icon={<BarChartOutlined />} type="link" />
            </Tooltip>
        </>
    );
};

export default EstimationTemplatesWithEditDrawer;
