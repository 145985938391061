import { Select } from "antd";
import { useLoadBizProcTemplateVersions } from "../model/UseLoadBizProcTemplateVersions";

interface IBizProcTemplateSelectVersion {
    value: string;
    bizProcTemplateId: number;
    currentVersion: string;
    onChangeVersion: (version: string) => void;
}

const BizProcTemplateSelectVersion = ({
    value,
    currentVersion,
    bizProcTemplateId,
    onChangeVersion,
}: IBizProcTemplateSelectVersion) => {
    const { loading, options } = useLoadBizProcTemplateVersions(bizProcTemplateId);
    const preparedOptions = [
        { value: currentVersion, label: `${currentVersion} (Актуальная)` },
        ...options.filter((option) => option.value !== currentVersion),
    ];

    return (
        <Select
            bordered={false}
            onChange={onChangeVersion}
            options={preparedOptions}
            loading={loading}
            style={{ width: "100%", backgroundColor: "white", borderRadius: 8 }}
            value={value}
        />
    );
};

export default BizProcTemplateSelectVersion;
