import { Input } from "antd";
import { ChangeEvent } from "react";

interface IPortalSettingInputString {
    value: string;
    name?: string;
    onChange: (value: string, name: string) => void;
}

const PortalSettingInputString = ({
    value,
    name,
    onChange,
}: IPortalSettingInputString) => {
    const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value, event.target.name);
    };
    return (
        <Input
            style={{ width: "100vh", maxWidth: 400 }}
            value={value}
            name={name}
            onChange={_onChange}
        />
    );
};

export default PortalSettingInputString;
