import { FormItem } from "@shared/ui/Form";
import { Input } from "antd";
import { ChangeEvent } from "react";

interface IEstimationTemplateEditName {
    viewMode: TEstimationFormViewMode;
    name: string;
    onChangeEstimationTemplate: (name: string, value: string) => void;
}

const EstimationTemplateEditName = ({
    viewMode,
    name,
    onChangeEstimationTemplate,
}: IEstimationTemplateEditName) => {
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChangeEstimationTemplate("name", event.target.value);
    };
    return (
        <FormItem label="Название шаблона">
            {viewMode === "viewing" ? (
                <>{name ?? "-"}</>
            ) : (
                <Input value={name ?? null} onChange={onChange} />
            )}
        </FormItem>
    );
};

export default EstimationTemplateEditName;
