import { CloseOutlined } from "@ant-design/icons";
import "./DeleteEntityFieldInput.scss";
import { Button } from "antd";

interface IDeleteEntityFieldInput {
    action: (event: any) => void;
    value: string;
}

const DeleteEntityFieldInput = ({ action, value }: IDeleteEntityFieldInput) => {
    return (
        <Button
            onClick={(event) => {
                action(event);
                event.stopPropagation();
            }}
            type="link"
            className="delete-entity-field-input"
            data-filter-key={value}
            icon={<CloseOutlined style={{ fontSize: 10 }} />}
        />
    );
};

export default DeleteEntityFieldInput;
