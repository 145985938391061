import { DropdownActions } from "@shared/ui/DropdownActions";
import { MenuProps } from "antd";
import BizProcInfoDeleteAction from "./BizProcInfoDeleteAction";
import { useBizProcInfoActions } from "../../model/UseBizProcInfoActions";

interface IBizProcInfoActions {
    bizProc: TBizProc;
}

const BizProcInfoActions = ({ bizProc }: IBizProcInfoActions) => {
    const { onConfirmDelete } = useBizProcInfoActions(bizProc);

    const items: MenuProps["items"] = [
        {
            label: <BizProcInfoDeleteAction onConfirmDelete={onConfirmDelete} />,
            key: "deleteAction",
            danger: true,
            style: { padding: 0 },
        },
    ];

    return <DropdownActions placement="bottomRight" items={items} />;
};

export default BizProcInfoActions;
