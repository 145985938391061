import { CardWithScroll } from "@shared/ui/Cards/CardWithScroll";
import { Divider } from "antd";
import { Fragment } from "react";
import { useActionsListWidget } from "../model";
import { BizProcUserAction } from "@features/Portal/BizProc/BizProcUserActionDelete";
interface IActionsListWidgets {
    containerStyle?: React.CSSProperties;
}

const ActionsListWidgets = ({ containerStyle }: IActionsListWidgets) => {
    const { actions, visible, loading } = useActionsListWidget();

    if (!visible) return <></>;
    return (
        <CardWithScroll
            containerStyle={containerStyle}
            loading={loading}
            isEmpty={actions.length === 0}
            header="Ожидают заполнения"
        >
            <>
                {actions.map((action) => {
                    return (
                        <Fragment key={action.id}>
                            <BizProcUserAction action={action} />
                            <Divider style={{ margin: 5 }} />
                        </Fragment>
                    );
                })}
            </>
        </CardWithScroll>
    );
};

export default ActionsListWidgets;
