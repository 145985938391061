export const selectStages = (state: RootState, categoryId: number): TStage[] =>
    state.stage.stages?.[categoryId];
export const selectGetLoading = (state: RootState, categoryId: number): boolean =>
    state.stage.getLoading.includes(categoryId);
export const selectUpdateLoading = (state: RootState, stageId: number): boolean =>
    state.stage.updateLoading.includes(stageId);
export const selectCreateLoading = (state: RootState, categoryId: number): boolean =>
    state.stage.createLoading.includes(categoryId);
export const selectDeleteLoading = (state: RootState, stageId: number): boolean =>
    state.stage.deleteLoading.includes(stageId);
