import { techMapModel } from "@entities/Production/TechMap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useTMItem = (item: TTechMapStarted) => {
    const approveLoading = useSelector((state: RootState) =>
        techMapModel.selectIsApproveLoading(state, item.id)
    );
    const rejectLoading = useSelector((state: RootState) =>
        techMapModel.selectIsRejectLoading(state, item.id)
    );
    const dispatch = useDispatch<AppDispatch>();

    const onApprove = () => {
        dispatch(
            techMapModel.approveTmThunk({
                tmtId: item.id,
                decision: "approve",
            })
        );
    };
    const onReject = () => {
        dispatch(
            techMapModel.approveTmThunk({
                tmtId: item.id,
                decision: "reject",
            })
        );
    };
    return {
        approveLoading,
        rejectLoading,
        onApprove,
        onReject,
    };
};
