import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    createBizProcTemplateItem,
    deleteBizProcTemplateItem,
    getBizProcTemplate,
    getBizProcEntityTemplates,
    updateBizProcEntityTemplateItem,
    getBizProcAllTemplates,
} from "../api";

export const loadBizProcTemplatesThunk = createAsyncThunk(
    "BizProcTemplateItemSlice/loadizProcTemplates",
    async (_, { rejectWithValue }) => {
        try {
            const response = await getBizProcAllTemplates();
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
export const loadBizProcEntityTemplatesThunk = createAsyncThunk(
    "BizProcTemplateItemSlice/loadbizProcEntityTemplates",
    async (entityId: number, { rejectWithValue }) => {
        try {
            const response = await getBizProcEntityTemplates(entityId);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
export const loadBizProcTemplateItemThunk = createAsyncThunk(
    "BizProcTemplateItemSlice/loadbizProcTemplateItem",
    async (
        {
            id,
            withTemplateActions,
            version,
        }: { id: number; withTemplateActions?: boolean; version?: string },
        { rejectWithValue }
    ) => {
        try {
            const response = await getBizProcTemplate(
                id,
                withTemplateActions ?? false,
                version
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
export const updateBizProcEntityTemplateItemThunk = createAsyncThunk(
    "BizProcTemplateItemSlice/saveBizProcTemplateItem",
    async (entity: TBizProcTemplateItem, { rejectWithValue }) => {
        try {
            const response = await updateBizProcEntityTemplateItem(entity);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
export const createBizProcEntityTemplateItemThunk = createAsyncThunk(
    "BizProcTemplateItemSlice/createBizProcTemplateItem",
    async (entity: TCreateBizProcTemplateItem, { rejectWithValue }) => {
        try {
            const response = await createBizProcTemplateItem(entity);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
export const deleteBizProcEntityTemplateItemThunk = createAsyncThunk(
    "BizProcTemplateItemSlice/deleteBizProcTemplateItem",
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await deleteBizProcTemplateItem(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
