import { entityApi } from "@entities/Portal/Entity";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { getEntityRowsByEntityFieldAfterDnD } from "../lib";
import { useLoadEntityRowsKanban } from "./LoadEntityRowsKanban";
import { useEntityRowsKanbanWS } from "./UseEntityRowsKanbanWs";

export const useEntityRowsKanban = (entityId: number, entityUuid: string) => {
    const [isOpenFullscreen, setIsOpenFullscreen] = useState<boolean>(false);
    const [choosenEntityFieldId, setChoosenEntityFieldId] = useState<number | null>(
        Number(localStorage.getItem(`${entityUuid}_kanban_filteringEntityField`)) ?? null
    );

    const [isEditingMode, setIsEditingMode] = useState<boolean>(true);
    const {
        isPaginationLoading,
        isLoading,
        entityFields,
        entityRowsByEntityField,
        setEntityRowsByEntityField,
        loadEntityRowsByPagination,
    } = useLoadEntityRowsKanban(entityId, choosenEntityFieldId);

    const choosenEntityField =
        entityFields.find((entityField) => entityField.id === choosenEntityFieldId) ??
        null;

    const [choosenVisibleFieldsOptionsKeys, setChoosenVisibleFieldsOptionsKeys] =
        useState<string[]>(
            JSON.parse(
                localStorage.getItem(`${entityUuid}_kanban_visibleEntityFields`) ?? "[]"
            )
        );

    const choosenVisibleFieldsOptions = entityFields.filter((entityField) => {
        if (entityField?.id) {
            return !!choosenVisibleFieldsOptionsKeys?.find(
                (key) => key === entityField?.key
            );
        } else return false;
    });

    const entityFieldValues = choosenEntityField
        ? Object.keys(entityRowsByEntityField)
              .map((key) => entityRowsByEntityField[key]["data"])
              .flat()
        : [];
    useEntityRowsKanbanWS(
        entityId,
        entityRowsByEntityField,
        choosenEntityField,
        setEntityRowsByEntityField
    );

    const onDragEndFunc = (
        movedElement: any,
        lastColumnValue: any,
        currentColumnValue: any
    ) => {
        setIsEditingMode(false);
        const oldEntityRowsByEntityField = JSON.parse(
            JSON.stringify(entityRowsByEntityField)
        );
        setEntityRowsByEntityField((prevEntityRowsByEntityField) => {
            return getEntityRowsByEntityFieldAfterDnD(
                prevEntityRowsByEntityField,
                movedElement,
                lastColumnValue,
                currentColumnValue,
                choosenEntityField
            );
        });

        if (choosenEntityField) {
            entityApi
                .saveEntityRowItem(entityId, {
                    ...movedElement,
                    [choosenEntityField.key]: currentColumnValue,
                })
                .then(() => {
                    if (
                        !entityRowsByEntityField[lastColumnValue ?? ""].is_ended &&
                        entityRowsByEntityField[lastColumnValue ?? ""].data.length < 20
                    ) {
                        loadEntityRowsByPagination(lastColumnValue ?? "");
                    }
                })
                .catch((e) => {
                    notificationEmit({
                        type: "error",
                        title: "Ошибка",
                        description: "Не удалось изменить запись сущности",
                    });
                    setEntityRowsByEntityField(oldEntityRowsByEntityField);
                });
        }
    };

    const onOpen = () => {
        setIsOpenFullscreen(true);
    };

    const onClose = () => {
        setIsOpenFullscreen(false);
    };

    return {
        isOpenFullscreen,
        entityFields,
        entityFieldValues,
        choosenEntityField,
        choosenVisibleFieldsOptions,
        isEditingMode,
        isLoading,
        isPaginationLoading,
        entityRowsByEntityField,
        setChoosenEntityFieldId,
        setChoosenVisibleFieldsOptionsKeys,
        setIsEditingMode,
        onDragEndFunc,
        loadEntityRowsByPagination,
        onOpen,
        onClose,
    };
};
