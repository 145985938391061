import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useCreateButton } from "../model";
import "./VariableCreateButton.scss";

const VariableCreateButton = ({ isEmptyPage }: { isEmptyPage?: boolean }) => {
    const { onClick } = useCreateButton();

    return (
        <div
            className={`variable-create-button ${
                isEmptyPage ? "variable-create-button--empty" : ""
            }`}
        >
            <Button onClick={onClick} icon={<PlusOutlined />}>
                Создать переменную
            </Button>
        </div>
    );
};

export default VariableCreateButton;
