import { estimationModel } from "@entities/Portal/Estimations";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export const useEstimationTemplateEditActions = (
    estimationTemplate: TEstimationTemplate | TCreatingEstimationTemplate
) => {
    const dispatch = useDispatch<AppDispatch>();

    const currentAppliedEstimationTemplate = useSelector(
        estimationModel.selectAppliedEstimation
    );
    const onCancel = () => {
        dispatch(estimationModel.setCreatingEditingEstimation(null));
    };

    const onSubmit = () => {
        const newTemplate: TReqEstimationTemplate = {
            id: "id" in estimationTemplate ? estimationTemplate.id : undefined,
            name: estimationTemplate.name,
            is_public: estimationTemplate.is_public,
            entity_uuid: estimationTemplate.entity_uuid,
            created_by_id: estimationTemplate.created_by_id,
            fields_ids: estimationTemplate.sum_view_fields.map((field) => field.id),
            users_ids: estimationTemplate.users.map((user) => user.id),
        };

        dispatch(
            newTemplate.id
                ? estimationModel.updateSumEstimationTemplateThunk(newTemplate)
                : estimationModel.createSumEstimationTemplateThunk(newTemplate)
        )
            .then(parseFormResponse)
            .then(() => {
                notificationEmit({
                    type: "success",
                    title: `Шаблон ${newTemplate.name} ${newTemplate.id ? "изменён" : "создан"}`,
                });

                if (
                    newTemplate.id &&
                    newTemplate.id === currentAppliedEstimationTemplate?.id
                ) {
                    dispatch(
                        estimationModel.applySumEstimationTemplateThunk({
                            id: newTemplate.id,
                            name: estimationTemplate.name,
                        })
                    )
                        .then(parseFormResponse)
                        .then(() => onCancel());
                    return;
                } else {
                    onCancel();
                }
            })
            .catch((e: any) => {
                console.log("ERROR", e);

                notificationEmit({
                    type: "error",
                    title: "Ошибка",
                    description: "Не удалось сохранить шаблон",
                });
            });
    };

    return {
        onCancel,
        onSubmit,
    };
};
