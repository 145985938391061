export const routeTypeOptions = [
    {
        label: "Портальная ссылка",
        value: "route",
    },
    {
        label: "Внешняя ссылка",
        value: "link",
    },
];
export const routeBlankOptions = [
    {
        label: "На новой странице",
        value: 1,
    },
    {
        label: "На той же странице",
        value: 0,
    },
];
