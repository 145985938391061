import { ReactNode } from "react";
import EntityDefaultCard from "./EntityDefaultCard";

interface IEntityDefaultCardList {
    onlyRead?: boolean;
    nameField?: TEntityField;
    descriptionFields?: TEntityField[];
    entityRowsOrIds: any;
    actions?: (entityRow: any) => ReactNode;
    onClick: (item: any) => void;
}

const EntityDefaultCardList = ({
    onlyRead,
    nameField,
    descriptionFields,
    entityRowsOrIds,
    actions,
    onClick,
}: IEntityDefaultCardList) => {
    if (!entityRowsOrIds) return <>-</>;
    return (
        <div style={{ width: "100%" }}>
            {entityRowsOrIds.map((entityRowOrId: any, index: number) => {
                return (
                    <div
                        key={
                            (typeof entityRowOrId === "object"
                                ? entityRowOrId.id
                                : entityRowOrId) ?? index
                        }
                        style={{ marginBottom: index < entityRowsOrIds.length ? 10 : 0 }}
                    >
                        <EntityDefaultCard
                            actions={
                                !!(
                                    typeof entityRowOrId === "object" &&
                                    actions !== undefined
                                ) && actions(entityRowOrId)
                            }
                            onClick={onlyRead ?? true ? onClick : undefined}
                            descriptionFields={descriptionFields}
                            entityRowOrId={entityRowOrId}
                            nameField={nameField}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default EntityDefaultCardList;
