import { $api } from "@shared/api/api";
const route = "/api/sum-view";

export const getEntitySumTemplates = (entityId: number) =>
    $api.get<TResponse<TEstimationTemplate[]>>(`/api/entity/${entityId}/sum-view`);

export const getEntitySumTemplate = (templateId: number) =>
    $api.get<TResponse<TEstimationTemplate>>(`${route}/${templateId}`);

export const applyEntitySumTemplate = (
    templateId: number,
    filters?: TMultipleCondition
) =>
    $api.post<TResponse<(TEntityField & { id: number; value: any })[]>>(
        `${route}/${templateId}/apply`,
        { filter: filters }
    );

export const updateEntitySumTemplate = (estimationTemplate: TReqEstimationTemplate) =>
    $api.put<TResponse<TEstimationTemplate>>(
        `${route}/${estimationTemplate.id}`,
        estimationTemplate
    );

export const createEntitySumTemplate = (estimationTemplate: TReqEstimationTemplate) =>
    $api.post<TResponse<TEstimationTemplate>>(`${route}`, estimationTemplate);

export const deleteEntitySumTemplate = (templateId: number) =>
    $api.delete<TResponse<TEstimationTemplate>>(`${route}/${templateId}`);

export const applyEstimationByChoosenEntityFields = (
    entityFieldsKeys: string[],
    selectedRowsIds: number[],
    entityId: number
) =>
    $api.post<TResponse<TEstimationValues>>(`${route}/apply/custom`, {
        row_ids: selectedRowsIds,
        field_keys: entityFieldsKeys,
        entity_id: entityId,
    });
