import { FormItem } from "@shared/ui/Form";
import { Checkbox } from "antd";

interface IEstimationTemplateEditAccess {
    viewMode: TEstimationFormViewMode;
    isPublic: boolean;
    onChangeEstimationTemplate: (name: string, value: boolean) => void;
}

const EstimationTemplateEditAccess = ({
    viewMode,
    isPublic,
    onChangeEstimationTemplate,
}: IEstimationTemplateEditAccess) => {
    return (
        <FormItem label="Доступ к шаблону">
            {viewMode === "viewing" ? (
                <>
                    {isPublic
                        ? "Доступен для всех"
                        : "Доступен для выбранных пользователей"}
                </>
            ) : (
                <Checkbox
                    onChange={(event) =>
                        onChangeEstimationTemplate("is_public", event.target.checked)
                    }
                    checked={isPublic}
                >
                    Доступен для всех
                </Checkbox>
            )}
        </FormItem>
    );
};

export default EstimationTemplateEditAccess;
