import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useCategoryCreate } from "../model";
import CategoryCreateUpdateModal from "./CategoryCreateUpdateModal";

interface ICategoryCreateUpdateButton {
    entityId: number;
}

const CategoryCreateUpdateButton = ({ entityId }: ICategoryCreateUpdateButton) => {
    const { isOpen, onOpen, onCancel } = useCategoryCreate(entityId);
    return (
        <>
            <Button
                icon={<PlusCircleOutlined />}
                onClick={onOpen}
                shape="circle"
                style={{ border: "none", boxShadow: "none" }}
            />
            <CategoryCreateUpdateModal
                category={{
                    label: "",
                }}
                entityId={entityId}
                onCancel={onCancel}
                isOpen={isOpen}
            />
        </>
    );
};

export default CategoryCreateUpdateButton;
