import { ReactNode } from "react";
import { CSSProperties } from "styled-components/dist/types";
type TIndentType =
    | "margin"
    | "padding"
    | "marginTop"
    | "marginRight"
    | "marginLeft"
    | "marginBottom";
interface IIndentContainer {
    type?: TIndentType[];
    values?: number[] | number;
    defaultValue?: number;
    children?: ReactNode;
    customStyle?: CSSProperties;
}

const IndentContainer = ({
    type = ["marginBottom"],
    values,
    defaultValue = 10,
    children,
    customStyle,
}: IIndentContainer) => {
    const getStyle = () => {
        let resultStyle: any = {};
        type.forEach((item, index) => {
            resultStyle[item] =
                (typeof values === "number" ? values : values?.[index]) ?? defaultValue;
        });
        return resultStyle;
    };

    return <div style={{ ...getStyle(), ...customStyle }}>{children}</div>;
};

export default IndentContainer;
