import { bizProcTemplateApi } from "@entities/Portal/BizProcTemplate";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useCreateBizProcTemplateItem = (
    entityUuid: string,
    categoryUuid: string
) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const onAdd = () => {
        setLoading(true);
        bizProcTemplateApi
            .createBizProcTemplateItem({
                entity_uuid: entityUuid,
                category_uuid: categoryUuid,
                name: "Бизнес-процесс",
                template: [
                    {
                        action: "InitialAction",
                        name: "Начало",
                        parameters: {},
                        key: "start",
                        children: [],
                    },
                ],
            })
            .then((response: any) => {
                const bizProcTemplate = response.data.data;
                const bizProcTemplateId = bizProcTemplate.id;
                navigate(`/bizproc-editor/${bizProcTemplateId}`);
            })
            .catch((e) => {
                notificationEmit({
                    title: "Ошибка создания бизнес-процесса",
                    description: e.response?.data?.message,
                    type: "error",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return {
        loading,
        onAdd,
    };
};
