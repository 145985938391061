import { ConfigProvider, Input } from "antd";
import React, { useEffect, useState } from "react";
import "./TimeInput.scss";
import { getSeconds, timeSplitter } from "@shared/lib/time";

interface ITimeInput {
    disabled: boolean;
    value: number | null | undefined;
    onChangeValue: (time: number) => void;
}
const TimeInput = ({ onChangeValue, value, disabled }: ITimeInput) => {
    const [minutes, setMinutes] = useState<number>(0);
    const [hours, setHours] = useState<number>(0);
    const [days, setDays] = useState<number>(0);

    useEffect(() => {
        if (value) {
            const [minutes, hours, days] = timeSplitter(value);
            setMinutes(minutes);
            setHours(hours);
            setDays(days);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;
        if (!/^\d*$/g.test(value)) {
            return null;
        } else {
            const valueNumber = Number(value);
            if (name === "minutes") {
                setMinutes(valueNumber < 1000 ? valueNumber : 1000);
            }
            if (name === "hours") {
                setHours(valueNumber < 100 ? valueNumber : 100);
            }
            if (name === "days") {
                setDays(valueNumber < 31 ? valueNumber : 31);
            }
            onChangeValue(getSeconds({ minutes, hours, days, [name]: value }));
        }
    };
    // в будущем сделать селект для маленьких разрешений с соответствующей обработкой
    return (
        <div className="times">
            <ConfigProvider
                theme={{
                    token: {
                        borderRadius: 0,
                    },
                }}
            >
                <div className="times__input" style={{ marginRight: 10 }}>
                    <Input
                        disabled={disabled}
                        name="days"
                        addonBefore={<div className="format">Дни:</div>}
                        placeholder="0"
                        value={days}
                        onChange={onChange}
                    />
                </div>
                <div className="times__input" style={{ marginRight: 10 }}>
                    <Input
                        disabled={disabled}
                        name="hours"
                        addonBefore={<div className="format">Часы:</div>}
                        placeholder="0"
                        value={hours}
                        onChange={onChange}
                    />{" "}
                </div>
                <div className="times__input">
                    <Input
                        disabled={disabled}
                        name="minutes"
                        addonBefore={<div className="format">Минуты:</div>}
                        placeholder="0"
                        value={minutes}
                        onChange={onChange}
                    />{" "}
                </div>
            </ConfigProvider>
        </div>
    );
};
export default TimeInput;
