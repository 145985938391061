import { userModel } from "@entities/Portal/User";
import { viewPatternItemModel } from "@entities/Portal/ViewPatternItem";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useViewPatternItemGroup = (
    viewPattern: TViewPattern,
    viewPatternItemGroup: TViewPatternItem,
    entityFields: TEntityField[]
) => {
    const type = viewPatternItemGroup.type;
    const viewPatternItems = viewPatternItemGroup.children!;
    const userIsAdmin = useSelector(userModel.selectUserIsAdmin);
    const userId = useSelector(userModel.selectUserId);
    const dispatch = useDispatch<AppDispatch>();
    const items = entityFields
        .filter(
            (field: TEntityField) =>
                !viewPatternItems.some((item) => item.type_value === field.id!.toString())
        )
        .map((field: TEntityField) => {
            return {
                label: field.name!,
                key: field.id!,
            };
        }) as { label: string | ReactNode; key: number | string }[];

    const onSelect = ({ key }: { key: string }) => {
        const field = entityFields.find((field) => field.id!.toString() === key);
        if (!field) return;

        const newViewPatternItem: TViewPatternItemCreating = {
            label: field.name,
            type: "entity-field",
            parent_id: viewPatternItemGroup.id,
            view_pattern_id: viewPattern.id,

            type_value: key,
            order: viewPatternItems.length ?? 1,
        };
        dispatch(viewPatternItemModel.createViewPatternItemThunk(newViewPatternItem));
    };
    const onCreate = (
        label: string,
        type: TViewPatternItemType,
        type_value: string | number | null
    ) => {
        const newViewPatternItem: TViewPatternItemCreating = {
            label,
            type,
            parent_id: viewPatternItemGroup.id,
            view_pattern_id: viewPattern.id,
            type_value,
            order: viewPatternItems.filter((item) => item.parent_id === null).length,
        };

        dispatch(
            viewPatternItemModel.createViewPatternItemThunk(newViewPatternItem)
        ).then(() => {});
    };
    const allowEdit = userIsAdmin || userId === viewPattern.creator_id;
    return {
        type,
        viewPatternItems,
        items,
        allowEdit,
        onSelect,
        onCreate,
    };
};
