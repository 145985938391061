export const unicKeysKey = (value: string, uniqKeys?: string[]): boolean => {
    if (value && uniqKeys) {
        let unicKeys = true;
        if (uniqKeys.includes(value)) {
            return false;
        }
        return unicKeys;
    }
    return true;
};
export const getEntityFields = (
    gettedEntityFields: TEntityField[] = [],
    relations: string[] = [],
    entities: TEntity[] = []
): TEntityField[] => {
    const entities_fields: TEntityField[] = [...gettedEntityFields];
    for (const entity of entities) {
        if (relations.includes(entity.uuid)) {
            for (const entField of entity.fields) {
                const buff = { ...entField, parent_entity_uuid: entity.uuid };
                if (entities_fields.find((item) => item.id === buff.id) ? false : true) {
                    entities_fields.push(buff);
                }
            }
        }
    }
    const result: TEntityField[] = [];
    for (const entity of entities_fields) {
        if (entity.parent_entity_uuid === undefined) {
            result.push(entity);
        }
        if (entity.parent_entity_uuid && relations.includes(entity.parent_entity_uuid)) {
            result.push(entity);
        }
    }
    return result;
};

export const getNotUniqKeys = (fields: TEntityField[] | undefined): string[] => {
    const notUniq: string[] = [];
    if (fields) {
        const buff = fields.map(({ key }) => key);

        fields.forEach(({ key }) => {
            buff.splice(buff.indexOf(key), 1);
            if (buff.includes(key) && !notUniq.includes(key)) {
                notUniq.push(key);
            }
        });
    }
    return notUniq;
};
